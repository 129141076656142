import React, { Component } from 'react';
import './App.css';
import Amplify, {Analytics} from 'aws-amplify';
import aws_exports from './aws-exports';
//import {withAuthenticator} from 'aws-amplify-react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from "./components/Home";
import Location from "./components/Location";
import Alarms from "./components/Alarms";
import NewNotes from "./components/NewNotes";
import Error from "./components/Error";
import Settings from "./components/Settings"
import DeviceSet from "./components/DeviceSet"
import MainSettings from "./components/MainSettings"
import SignIn from "./components/SignIn"
import ForgotPass from "./components/ForgotPass"
import ChangePass from "./components/ChangePass"
import SignUp from "./components/SignUp"
import Authenticate from "./components/Authenticate"
import Verify from "./components/Verify"
import Intro from "./components/Intro"
import Onboard from "./components/Onboard"
import Organization from "./components/Organization"
import OrganizationInvite from "./components/OrganizationInvite"
import OrganizationGet from "./components/OrganizationGet"
import Inventory from "./components/Inventory"
import Enterprise from "./components/Enterprise"
import DeviceSync from "./components/DeviceSync"
import EntHome from "./components/EntHome"
import EntOnboard from "./components/EntOnboard"
import EnterpriseInvite from "./components/EnterpriseInvite"
import SettingsPage from "./components/SettingsPage"
import EntUserOnboard from "./components/EntUserOnboard"
import EntAddDivOrg from "./components/EntAddDivOrg"
import UserInvite from "./components/UserInvite"
import EntInventory from "./components/EntInventory"
import EntCustomCSV from "./components/EntCustomCSV"
import EntChangePass from "./components/EntChangePass"
import DivSettings from "./components/DivSettings"
import DivUserOnboard from "./components/DivUserOnboard"
import DivAddOrg from "./components/DivAddOrg"
import DivUserInvite from "./components/DivUserInvite"
import OrgSettings from "./components/OrgSettings"
import OrgUserOnboard from "./components/OrgUserOnboard"
import OrgUserInvite from "./components/OrgUserInvite"
import Device from "./components/Device"
import DeviceSettings from "./components/DeviceSettings"
import DeviceProgram from "./components/DeviceProgram"
import OnboardDevice from "./components/OnboardDevice"
import OnlineStatus from "./components/OnlineStatus"
import CustomCSV from "./components/CustomCSV"
import Overview from "./components/Overview"
import Log from "./components/Log"
import HiddenAlarms from "./components/HiddenAlarms"

Amplify.configure(aws_exports);

Analytics.disable();

class App extends Component {

  render() {
    return (
      <BrowserRouter>
      <Switch>
        <Route path="/" component={Home} exact/>
        <Route path="/Location" component={Location}/>
        <Route path="/Alarms" component={Alarms}/>
        <Route path="/NewNotes" component={NewNotes}/>
        <Route path="/Settings" component={Settings}/>
        <Route path="/DeviceSet" component={DeviceSet}/>
        <Route path="/MainSettings" component={MainSettings}/>
        <Route path="/SignIn" component={SignIn}/>
        <Route path="/ForgotPass" component={ForgotPass}/>
        <Route path="/ChangePass" component={ChangePass}/>
        <Route path="/SignUp" component={SignUp}/>
        <Route path="/Verify" component={Verify}/>
        <Route path="/Authenticate" component={Authenticate}/>
        <Route path="/Intro" component={Intro}/>
        <Route path="/Onboard" component={Onboard}/>
        <Route path="/Organization" component={Organization}/>
        <Route path="/OrganizationInvite" component={OrganizationInvite}/>
        <Route path="/OrganizationGet" component={OrganizationGet}/>
        <Route path="/Inventory" component={Inventory}/>
        <Route path="/Enterprise" component={Enterprise}/>
        <Route path="/EnterpriseInvite" component={EnterpriseInvite}/>
        <Route path="/DeviceSync" component={DeviceSync}/>
        <Route path="/EntHome" component={EntHome}/>
        <Route path="/EntOnboard" component={EntOnboard}/>
        <Route path="/SettingsPage" component={SettingsPage}/>
        <Route path="/EntUserOnboard" component={EntUserOnboard}/>
        <Route path="/EntAddDivOrg" component={EntAddDivOrg}/>
        <Route path="/UserInvite" component={UserInvite}/>
        <Route path="/EntCustomCSV" component={EntCustomCSV}/>
        <Route path="/EntInventory" component={EntInventory}/>
        <Route path="/EntChangePass" component={EntChangePass}/>
        <Route path="/DivSettings" component={DivSettings}/>
        <Route path="/DivUserOnboard" component={DivUserOnboard}/>
        <Route path="/DivAddOrg" component={DivAddOrg}/>
        <Route path="/DivUserInvite" component={DivUserInvite}/>
        <Route path="/OrgSettings" component={OrgSettings}/>
        <Route path="/OrgUserOnboard" component={OrgUserOnboard}/>
        <Route path="/OrgUserInvite" component={OrgUserInvite}/>
        <Route path="/Device" component={Device}/>
        <Route path="/DeviceSettings" component={DeviceSettings}/>
        <Route path="/DeviceProgram" component={DeviceProgram}/>
        <Route path="/OnboardDevice" component={OnboardDevice}/>
        <Route path="/OnlineStatus" component={OnlineStatus}/>
        <Route path="/CustomCSV" component={CustomCSV}/>
        <Route path="/Overview" component={Overview}/>
        <Route path="/Log" component={Log}/>
        <Route path="/HiddenAlarms" component={HiddenAlarms}/>
        <Route component={Error} />
      </Switch>
      </BrowserRouter>

    );
  }
}

//export default withAuthenticator(App, {includeGreetings: false});
export default App
