import React, { Component } from 'react';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';
import * as queries from '../graphql/queries.js';
import AlarmSettings from './AlarmSettings.js';

class Alarms extends Component {
    constructor(props){
        super(props)

        this.state = {
            devices: props.data,
            group: props.group,
            loading: false,
            alarmsArray: [],
            readingCount: '',
            index: '',
            menuClicked: false
        }
        this.menuSelected = this.menuSelected.bind(this);   
        this.menuClosed = this.menuClosed.bind(this)   
        this.setStateCount = this.setStateCount.bind(this) 
    }

    menuSelected(index){
        this.setState({
            index: index,
            menuClicked: true
        })
    }
    menuClosed(index){
        this.setState({
            index: '',
            menuClicked: false
        })
    }

    setStateCount(count){
        this.setState({readingCount: count})
    }

    async componentDidMount() {
        let devices = this.state.devices
        let serialArray = []
        this.setState({
            loading: true,
        })

        let user = await Auth.currentAuthenticatedUser();
        const userName = user.username;

        // array of just serial numbers for latest data api
        devices.forEach(unit => {
            serialArray.push(unit.location)
        })
        let serialArrayString = "[" + serialArray.map(serial => `"${serial}"`).join(',') + "]"

        // api send array of serial number returns the last logged data for every device
        function getLatestData() {
            const apiName = 'DeviceDataStatus';
            const path = '/items';
    
            const myInit = {
                queryStringParameters: {
                  name: serialArrayString
                }
            };
            return API.get(apiName, path, myInit);
        }
    
        let responseData = {};
        try {
            responseData = await getLatestData()
        } catch(error) {
            console.log(error)
            if(error.message === 'Network Error'){
                alert('network error check your internet connection')
                return
            } else {
                alert('error loading devices data')
                return
            }
        };
        if (responseData.result === 'error') {
            alert('error loading device data')
            return
        }

        let finalDeviceData = responseData.return

        // get all parent db id number create an array with id numbers and device information
        let serialIdArray = [];
        let cleanedBlogIds = []
        
        let field = "location"

        let filterArray = serialArray.map((item)  => JSON.parse(`{"${field}":{"eq":"${item}"}}`))

        if(filterArray.length > 100){
            let array1 = filterArray.slice(0,100)
            let array2 = filterArray.slice(100)
            let finalFilter1 = {or:array1}
            let finalFilter2 = {or:array2}

            let blogIds1 = []
            let blogIds2 = []
            try{
                blogIds1 = await API.graphql(graphqlOperation(queries.listBlogs,{limit: 1000, filter:finalFilter1}));
            } catch(error){
                console.log(error)
                alert('error loading alarms')
            }
            try{
                blogIds2 = await API.graphql(graphqlOperation(queries.listBlogs,{limit: 1000, filter:finalFilter2}));
            } catch(error){
                console.log(error)
            }
            let cleanedBlogIds1 = blogIds1.data.listBlogs.items
            let cleanedBlogIds2 = blogIds2.data.listBlogs.items
            cleanedBlogIds = cleanedBlogIds1.concat(cleanedBlogIds2)


        } else {
            let finalFilter = {or:filterArray}
            let testBlogIds = []
            try{
                testBlogIds = await API.graphql(graphqlOperation(queries.listBlogs,{limit: 1000, filter:finalFilter}));
            } catch(error){
                console.log(error)
                alert('error loading alarms')
            }       
            cleanedBlogIds = testBlogIds.data.listBlogs.items
        }     
        
    
        
        devices.forEach(device => {
            cleanedBlogIds.forEach(idDevice => {
                if(device.location === idDevice.location){
                    serialIdArray.push({'serial': device.location, 'group': device.group, 'title': device.title, 'blogID': idDevice.id, 'version': device.version})
                }
            })
        })       
        
        let iconsArray = []
        // get all icons attach to device information as an array
        let getIcons = async() => {
            await Promise.all(serialIdArray.map(async (object) => {
                const getIcons = `query GetIcons { getBlog(id:"${object.blogID}") {id location icons { items { value lowest highest order }}}}`
                let iconData = await API.graphql(graphqlOperation(getIcons));
                iconsArray.push({'serial': object.serial, 'group': object.group, 'title': object.title, 'version': object.version, 'icons': iconData.data.getBlog.icons.items})
            }))
        }
        try {
            await getIcons()
        } catch(error)  {
            console.log(error)
        }

        let finalAlarmArray = []
        let alarmCaught = false
        let alarmCounter = 0

        // match icon information to latest device data create array of readings out of range
        iconsArray.forEach(device => {
            alarmCaught = false
            let alarmCaughtArray = []
            finalDeviceData.forEach(deviceData => {               
                try {
                    if(deviceData.device === device.serial){
                        if(deviceData.device_status === "input board not found"){
                            alarmCounter++
                            alarmCaughtArray.push({'name': '', 'reading': 'no input board', 'info': ''})
                            alarmCaught = true
                        }
                        device.icons.forEach(deviceIcon => {
                            Object.entries(deviceData).map(reading => {
                                if(deviceIcon.value === reading[0]){
                                    if(reading[1] >= deviceIcon.highest || reading[1] <= deviceIcon.lowest ){
                                        let tempInfo = ''
                                        alarmCounter++
                                        if(reading[1] >= deviceIcon.highest){
                                            tempInfo = `above ${deviceIcon.highest}`
                                        }
                                        if(reading[1] <= deviceIcon.lowest){
                                            tempInfo = `below ${deviceIcon.lowest}`
                                        }
                                        alarmCaughtArray.push({'name': reading[0], 'reading': reading[1], 'info': tempInfo})
                                        alarmCaught = true
                                    }
                                }
                                return alarmCaught
                            })
                        })
                    }
                } catch(error) {
                    
                }              
            })
            if(alarmCaught === true){
                finalAlarmArray.push({'serial': device.serial, 'name': device.title, 'group': device.group, 'title': device.title, 'version': device.version, 'alarms': alarmCaughtArray})
            }
        })

        // fetch user group
        let group = await API.graphql(
            graphqlOperation(queries.getUser, { userName: userName })
        );
        this.groupName = group.data.getUser.groupName;

        const hiddenAlarms = `query GetHidden {
            getOrganizationLink(orgName: "${this.groupName}") {
                id
                    hiddenAlarms {
                        items {
                            groupName
                            readingName
                            serial
                            subGroup
                            deviceName
                        }
                    }
            }
        }`

    
        let hiddenAlarmsArray = []
        // fetch hidden alarms attached to users group
        try{
            let hiddenAlarmsFetched = await API.graphql(graphqlOperation(hiddenAlarms));
            hiddenAlarmsArray = hiddenAlarmsFetched.data.getOrganizationLink.hiddenAlarms.items
        } catch(error) {
            console.log(error)
        };

        let trimmedFinalAlarmArray = []

        finalAlarmArray.forEach(device => {
            if(hiddenAlarmsArray.some(i => i.serial.includes(device.serial))){
                let displayAlarmsArray = []
                device.alarms.forEach(alarm => {
                   let found = hiddenAlarmsArray.some( e => e.serial === device.serial && e.readingName === alarm.name)
                        if(!found){
                            displayAlarmsArray.push({'name': alarm.name, 'reading': alarm.reading, 'info': alarm.info})
                        } else {
                            alarmCounter--
                        }
                })
                if(displayAlarmsArray.length !== 0){
                    trimmedFinalAlarmArray.push({'serial': device.serial, 'name': device.deviceName, 'group': device.group, 'title': device.title, 'version': device.version, 'alarms': displayAlarmsArray})
                }
            } else {
                trimmedFinalAlarmArray.push(device)
            }
        })


        // order alph device name then group
        let orderAlarmdevice = trimmedFinalAlarmArray.sort((a, b) => a.serial.localeCompare(b.serial, undefined, { numeric: true }))
        let orderedAlarmArray = orderAlarmdevice.sort((a, b) => a.group.localeCompare(b.group, undefined, { numeric: true }))
        let readingCountHeld = alarmCounter

        this.setState({
            loading: false,
            alarmsArray: orderedAlarmArray,
            readingCount: readingCountHeld,
        })
    
    }

    render(){
        var menuIcon = require('../icons8-menu-100.png')

        return(
            <div className='App'>
                <div className='Two-space'/>
                <div className='Device-wrapper'>
                    {this.state.loading &&
                        <div>
                            <div className='One-space'/>
                            <div className="Loading-buttonstatus">Loading...</div>
                            <div className='One-space'/>
                        </div>
                    }
                    {!this.state.loading &&
                        <div>  
                            <div className='One-space'/>
                            {this.state.readingCount === 0 &&
                                <div style={{fontSize: '40px', color: '#529857', fontWeight: 'bold'}}>{this.state.readingCount}</div>
                            }
                            {this.state.readingCount !== 0 &&
                                <div style={{fontSize: '40px', color: '#d42d0a', fontWeight: 'bold'}}>{this.state.readingCount}</div>
                            }
                            {this.state.readingCount !== 1 &&
                                <div style={{fontSize: '20px', color: 'white'}}>readings out of range</div>
                            }
                            {this.state.readingCount === 1 &&
                               <div style={{fontSize: '20px', color: 'white'}}>reading out of range</div> 
                            }                          
                            <div className='One-space'/>                          
                        </div>
                    }
                </div>
                { !this.state.loading &&
                    <Link style={{ textDecoration: 'none' }} to={{ pathname: '/HiddenAlarms', state: { device: this.props.data, group: this.props.group} }}>
                        <button className="signup-link">hidden alarms</button>
                    </Link>
                }  
                

                {!this.state.loading &&
                    this.state.alarmsArray.map((alarms, index) => (
                        <div key={index}>
                            <div className='One-space'/>
                            <div className='Device-wrapper'>
                                {this.state.index !== index &&
                                    <div style={{width: '100%'}}>
                                        <button onClick={() => this.menuSelected(index)}style={{backgroundColor: '#3c3c3c', border: 'none', height: '40px', display: 'inline-block', position: 'relative', marginLeft: '80%'}}>
                                            <img style={{height: '40px'}}src={menuIcon} alt="settings" />
                                        </button>    
                                    </div>

                                }
                                {this.state.index === index && this.state.menuClicked === true &&
                                    <div>
                                        <button onClick={() => this.menuClosed(index)} className="Dropdown-button">
                                            <div>close</div>
                                        </button>
                                    </div>
                                }
                                {this.state.group !== 'multiloc' &&
                                    <div style={{fontSize: '30px', color: 'white', fontWeight: 'bold'}}>{alarms.group}</div>
                                }
                                <div style={{fontSize: '25px', color: 'white', fontWeight: 'bold'}}>{alarms.title}</div>
                                {this.state.group === 'enerstar' && this.state.index !== index &&
                                    <Link style={{ textDecoration: 'none' }} to={{ pathname: '/location', state: { loc: alarms.serial, title: alarms.title, org: alarms.group, version: alarms.version} }}>
                                        <button className="goto-device">go to device</button>
                                    </Link>
                                }
                                {this.state.group === 'multiloc' && this.state.index !== index &&
                                    <Link style={{ textDecoration: 'none' }} to={{ pathname: '/location', state: { loc: alarms.serial, title: alarms.title, org: 'none', version: alarms.version} }}>
                                        <button className="goto-device">go to device</button>
                                    </Link>
                                }
                                {this.state.menuClicked === true && this.state.index === index && 
                                    <AlarmSettings group={this.groupName} count={this.state.readingCount} setStateCount={this.setStateCount} name={alarms.title} subGroup={alarms.group} serial={alarms.serial} currentAlarms={alarms.alarms} route='alarm'/>
                                }
                                {this.state.index !== index &&            
                                    alarms.alarms.map((alarm, index) => (
                                        <div key={index}>
                                            
                                            <div style={{fontSize: '30px', color: 'white', fontWeight: 'bold'}}>{alarm.name.replace(/_/g, ' ')}</div>
                                            <div style={{fontSize: '15px', color: 'white', fontWeight: 'bold'}}>{alarm.info}</div>
                                            <div style={{fontSize: '30px', color: '#d42d0a', fontWeight: 'bold'}}>{alarm.reading}</div>
                                        </div>
                                        
                                    ))
                                }
                                <div className='One-space'/>
                            </div>
                        </div>                   
                    ))                  
                }
                <div className='div-height'/>
            </div>
        )
    }
}

export default Alarms