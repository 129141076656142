import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class NowOrgButtons extends Component {

    constructor(props) {
        super(props);

        this.state = {
            devices: []
        }
    }

    async UNSAFE_componentWillReceiveProps(nextProps) {
        let receivedData = nextProps.data

        this.setState({
            devices: receivedData
        })
    }
    render() {
        return(
            this.state.devices.map((device, index) =>  (
                <div key={index}>
                    <div className='One-space'/>
                    <div className="Homebuttonwrapper-90">
                        <Link style={{ textDecoration: 'none' }} key={index} to={{ pathname: '/location', state: { loc: device.location, title: device.title, org: 'none', version: device.version } }}>
                            <button className="Homebutton-90">{device.title}</button>
                        </Link>
                    </div>
                </div>
            ))
        )
    }
}

export default NowOrgButtons