import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries.js';

class DivUserOnboard extends Component {
  constructor(props){
    super(props)

    this.state = {
      enterprise: '',
      division: '',
      serial: '',
      nickname:'',
      noOrgs: false,
      loading: true,
      dropDownOrgs: false,
      selectedOrg: '',
      orgArray: [],
      loadingCreate: false
    }
    this.handleChange = this.handleChange.bind(this);
    this.selectOrg = this.selectOrg.bind(this);
    this.hideOrgs = this.hideOrgs.bind(this);
    this.addOrg = this.addOrg.bind(this);
    this.removeOrg = this.removeOrg.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  async selectOrg(event){
    this.setState({
      dropDownOrgs: true,
    })
  }
  async hideOrgs(event){
    this.setState({
      dropDownOrgs: false
    })
  }
  async addOrg(org){
    this.setState({
      selectedOrg: org,
      dropDownOrgs: false
    })
  }
  async removeOrg(org){
    this.setState({
      selectedOrg: ''
    })
  }

  async handleSubmit(event) {
    event.preventDefault();

    let finalEnterprise = this.state.enterprise
    let finalDivision = this.state.division
    let finalOrganization = this.state.selectedOrg
    let finalSerial = this.state.serial
    let finalNickname = this.state.nickname

    if(finalSerial === ''){
      alert('the serial number can not be blank')
    }

    if(finalOrganization === '' || finalOrganization === 'null'){
      alert('You are onboarding without an organizatation. Someone from the organization will need to onboard it later. If you know the organization you are onboarding to attach an organization and resubmit.')
    }

    this.setState({
      loadingCreate: true
    })

    let deviceFoundMatched = false
    let foundUnitInfo = []

    try{
      let unitInfo = await API.graphql(graphqlOperation(queries.getEntUnit, { serial: finalSerial }));
      if(unitInfo.data.getEntUnit === null){
        alert('The serial number was not found in the system. Please check that the number is correct and try again')
        return
      } else {
        if(unitInfo.data.getEntUnit.entName !== finalEnterprise){
          alert('The serial number was not found in the system. Please check that the number is correct and try again')
          return
        } else {
          foundUnitInfo = unitInfo.data.getEntUnit
          deviceFoundMatched = true
        }
      }
    } catch(error) {
      console.log(error)
      alert('An error occurred while trying to find the device in the system. Please try again')
      this.setState({loadingCreate: false})
      return
    }

    if(deviceFoundMatched === true){
      if(finalOrganization === ''){
        finalOrganization = 'null'
      }
      if(finalNickname === ''){
        finalNickname = 'null'
      }

      const updateUnit = `mutation UpdateEntUnit {
        updateEntUnit(input: {
          id: "${foundUnitInfo.id}"
          serial: "${foundUnitInfo.serial}"
          divName: "${finalDivision}"
          orgName: "${finalOrganization}"
          nickName: "${finalNickname}"
        }){
          id
        }
      }`
      try {
        API.graphql(graphqlOperation(updateUnit));
      } catch(error) {
        alert('An error occurred onboarding please try again')
        return
      }

      this.setState({
        loadingCreate: false,
        serial: '',
        nickname: ''
      })
    }
    this.props.history.push('/DeviceSync');
  }

  async componentDidMount(){
    Auth.currentAuthenticatedUser({
      bypassCache: false
      }).then(user => {
        this.loggedIn = true
      }
    )
    .catch(error => {
      if(error === 'not authenticated'){
        this.props.history.push('/SignIn');
      }
    }
    );

    let user = await Auth.currentAuthenticatedUser()
    let userGroupGet = user.signInUserSession.accessToken.payload["cognito:groups"];
    const userName = user.username;

    let userInfo = await API.graphql(graphqlOperation(queries.getEntUser, { userName: userName }));

    let userEnt = userInfo.data.getEntUser.entName
    let userDiv = userInfo.data.getEntUser.divName

    let getEntOrgs = `query GetEntDivsOrgs { getEnt(entName: "${userEnt}") { id entdivsorgs (filter: {divName: {eq: "${userDiv}"}, isOrg: {eq: "true"}}) { items { id entName divName orgName orgName isOrg }}}}`

    let foundOrgs = []

    try{
      let returnedOrgs = await API.graphql(graphqlOperation(getEntOrgs));
      foundOrgs = returnedOrgs.data.getEnt.entdivsorgs.items
    } catch(error) {
      console.log(error)
    };
    console.log(foundOrgs)

    let noOrgsFound = false
    if(foundOrgs.length === 0){
      noOrgsFound = true
    }
    foundOrgs.sort((a,b) => a.orgName.localeCompare(b.orgName, undefined, { numeric: true }))

    this.setState({
      group: userGroupGet[0],
      userName: user.username,
      enterprise: userEnt,
      division: userDiv,
      orgArray: foundOrgs,
      noOrgs: noOrgsFound,
      loading: false
    });
  }

  render(){
    var backIcon = require('../Back-01.png');

    return(
      <div className="App">
        {
          <div className="Nav-header">
            <Link style={{textDecoration: 'none'}} to="/SettingsPage">
              <button className="Back-wrap">
                <img className="Back-button" src={backIcon} alt="back"/>
              </button>
            </Link>
          </div>
        }
        <div className="Title">Onboard a Device</div>
        <div className="Settings-wrapper">
          <div className='One-space'/>
          {!this.state.loading && this.state.noOrgs &&
            <div>
              <div className="form-name">No organizations found would you like to create one?</div>
              <Link style={{textDecoration: 'none'}} to="/DivAddOrg">
                <button className="resend-link">create</button>
              </Link>
              <div className='One-space'/>
            </div>
          }
          {!this.state.loading && !this.state.noOrgs &&
            <div>
              {!this.state.dropDownOrgs && this.state.selectedOrg === '' &&
                <div>
                  <div className="form-name">select an organization</div>
                  <button className="resend-link" onClick={this.selectOrg}>select</button>
                  <div className='One-space'/>
                </div>
              }
              {
                <div>
                {this.state.dropDownOrgs &&
                  <button className="resend-link" onClick={(e) => this.hideOrgs()}>hide</button>
                }
                {this.state.dropDownOrgs &&
                  <Link style={{ textDecoration: 'none' }} to={{ pathname: '/DivAddOrg' }}>
                    <button className="resend-link">ADD ORGANIZATION</button>
                  </Link>
                }
                {this.state.dropDownOrgs &&
                  this.state.orgArray.map((org, index) => (
                    <div className="resend-link" key={index} onClick={() => this.addOrg(org.orgName, index)}>{org.orgName}</div>
                  ))
                }
                </div>
              }
              {!this.state.dropDownOrgs && this.state.selectedOrg !== '' &&
                <div>
                  <div className="form-name">you have selected the organization....</div>
                  <div className="Title">{this.state.selectedOrg}</div>
                  <button className="resend-link" onClick={this.removeOrg}>remove</button>
                  <div className='One-space'/>
                </div>
              }
            </div>
          }
          {!this.state.loadingCreate &&
            <form onSubmit={this.handleSubmit}>
              <div className="form-name">serial number</div>
              <input value={this.state.serial} name="serial" className="Reading-name" type="text" onChange={this.handleChange}/>
              <div className="form-name">device name</div>
              <input value={this.state.nickname} name="nickname" className="Reading-name" type="text" onChange={this.handleChange}/>
              <div className='One-space'/>
              <input type="submit" className="Icon-submit" value="submit"/>
            </form>
          }
        </div>
      </div>
    )
  }
}

export default DivUserOnboard
