import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SyncDevicesButton from "../components/SyncDevicesButton";

class NowOrgSettings extends Component {
    constructor(props){
    super(props)

    this.state = {
        group: '',
        userName: ''
        }
    }

    async UNSAFE_componentWillReceiveProps(nextProps) {
    let receivedGroup = nextProps.group
    let receivedUserName = nextProps.userName

    this.setState({
        group: receivedGroup,
        userName: receivedUserName
    })
    }

    render() {
        return(
            <div className="App">
                {
                <div className="Title">Main Settings</div>
                }
                {
                    <div className="Settings-wrapper">
                        <div className='One-space'/>
                        <div className="title-two">Organization ID to invite a user</div>
                        <Link style={{ textDecoration: 'none' }} to={{ pathname: '/OrganizationGet' }}>
                            <button className="resend-link">get id</button>
                        </Link>
                        <div className='One-space'/>
                    </div>
                }
                {
                    <div>
                        <div className='One-space'/>
                        <div className="title-two">Change your password</div>
                        <Link style={{ textDecoration: 'none' }} to={{ pathname: '/ChangePass' }}>
                            <button className="signup-link">change</button>
                        </Link>
                    </div>
                }
                {
                    <div>
                        <div className='Two-space'/>
                        <div className='location-dev'>Check for devices by syncing</div>
                        <SyncDevicesButton />
                    </div>
                }
            </div>
        )
    }
}

export default NowOrgSettings