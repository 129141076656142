import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Alarms from '../components/Alarms';
import NewNotes from '../components/NewNotes';
import Offline from '../components/Offline';

class Overview extends Component {
    constructor(props) {
        super(props);

        this.buttonPressed = 'alarms';

        this.state = {
            devices: props.location.state.data,
            group: props.location.state.group,
            loading: false,
            timeColor1: '#529857',
            timeColor2: '#3c3c3c',
            timeColor3: '#3c3c3c',
        };
        this.timeLine = this.timeLine.bind(this);
    }

    timeLine() {
        this.buttonPressed = this.click;

        if (this.buttonPressed === 'alarms') {
            this.setState({
                timeColor1: '#529857',
                timeColor2: '#3c3c3c',
                timeColor3: '#3c3c3c',
            });
        }

        if (this.buttonPressed === 'notes') {
            this.setState({
                timeColor1: '#3c3c3c',
                timeColor2: '#529857',
                timeColor3: '#3c3c3c',
            });
        }

        if (this.buttonPressed === 'offline') {
            this.setState({
                timeColor1: '#3c3c3c',
                timeColor2: '#3c3c3c',
                timeColor3: '#529857',
            });
        }
    }

    render() {
        var backIcon = require('../Back-01.png');
        return (
            <div className='App'>
                {
                    <div className='Nav-header'>
                        {
                            <Link style={{ textDecoration: 'none' }} to='/'>
                                <button onClick={this.stopInterval} className='Back-wrap'>
                                <img className='Back-button' src={backIcon} alt='back' />
                                </button>
                            </Link>
                        }
                    </div>
                }
                {
                    <div>
                        <div className='title-one'>overview</div>
                    </div>
                }
                {
                    <div className='Overviewbutton-wrapper'>
                        <button
                            type='button'
                            className='Graph-time'
                            onClick={(e) => this.timeLine((this.click = 'offline'))}
                            style={{ backgroundColor: this.state.timeColor3 }}>
                            <div>offline</div>
                        </button>
                        <button
                            type='button'
                            className='Graph-time'
                            onClick={(e) => this.timeLine((this.click = 'alarms'))}
                            style={{ backgroundColor: this.state.timeColor1 }}>
                            <div>alarms</div>
                        </button>
                        <button
                            type='button'
                            className='Graph-time'
                            onClick={(e) => this.timeLine((this.click = 'notes'))}
                            style={{ backgroundColor: this.state.timeColor2 }}>
                            <div>notes</div>
                        </button>
                    </div>
                }
                {this.buttonPressed === 'offline' && (
                    <Offline
                        data={this.state.devices}
                        group={this.state.group}
                        groupName={this.groupName}
                    />
                )}
                {this.buttonPressed === 'alarms' && (
                    <Alarms
                        data={this.state.devices}
                        group={this.state.group}
                        groupName={this.groupName}
                        hiddenAlarms={this.hiddenAlarms}
                    />
                )}
                {this.buttonPressed === 'notes' && (
                    <NewNotes data={this.state.devices} group={this.state.group} />
                )}

            </div>
        );
    }
}

export default Overview;
