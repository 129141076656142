import React from 'react';
import '../App.css';

const Error = () => {
  return(
    <div className="Title">Path Error</div>
  )
}

export default Error;
