import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';

class ForgotPass extends Component {
    constructor(props){
        super(props)

        this.state = {
            username: '',
            code: '',
            newPassword: '',
            confirmNewPassword: '',
            resetting: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.resendCode = this.resendCode.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    async resendCode(event) {
        event.preventDefault()

        if(this.state.username === ''){
            alert('please enter a valid username')
            return
        }

        let username = this.state.username.toLowerCase()

        try {
            await Auth.forgotPassword(username)
            alert('verification code sent to your email')
        } catch (error) {
            if(error.code === 'UserNotFoundException'){
                let upperFirst = username[0].toUpperCase() + username.slice(1)
                let indexGrab = upperFirst.indexOf("@")
                let finalName =upperFirst.slice(0, indexGrab + 1) + upperFirst[indexGrab + 1].toUpperCase() + upperFirst.slice(indexGrab + 2)
            try {
                await Auth.forgotPassword(finalName)
                alert('verification code sent to your email')
            } catch (error) {
                if(error.code === 'UserNotFoundException') {
                    alert('username not found')
                    return
                }
                if(error.code === 'LimitExceededException') {
                    alert('too many attempts time out try again in an hour')
                    return
                }
                else {
                    alert('error attempting to send the verification code please try again')
                    return
                }
            }
            } else {
                alert('error attempting to send the verification code please try again')
                return
            }
        }

    }

    async handleConfirm(event){
        event.preventDefault()

        this.setState({resetting: true})

        if(this.state.username === ''){
            alert('please enter a valid username')
            this.setState({resetting: false})
            return
        }
        if(this.state.newPassword !== this.state.confirmNewPassword){
            alert("passwords do not match")
            this.setState({resetting: false})
            return
        }

        try {
            await Auth.forgotPasswordSubmit(this.state.username.toLowerCase(), this.state.code, this.state.newPassword)
            alert('password reset')
            this.props.history.push('/SignIn');
        } catch (error) {
            if(error.name === "InvalidPasswordException"){
                this.setState({resetting: false})
                alert("passwords must be 8 characters long and include an uppercase letter, a lowercase letter, a number and a symbol")
                return
            }
            if(error.code === 'LimitExceededException') {
                this.setState({resetting: false})
                alert('too many attempts time out try again later')
                return
            }

            if(error.code === 'UserNotFoundException'){
                let username = this.state.username.toLowerCase();
                let upperFirst = username[0].toUpperCase() + username.slice(1)
                let indexGrab = upperFirst.indexOf("@")
                let finalName =upperFirst.slice(0, indexGrab + 1) + upperFirst[indexGrab + 1].toUpperCase() + upperFirst.slice(indexGrab + 2)

                try {
                    await Auth.forgotPasswordSubmit(finalName, this.state.code, this.state.newPassword)
                    alert('password reset')
                    this.props.history.push('/SignIn');
                } catch (error){
                    if(error.code === 'UserNotFoundException') {
                        this.setState({resetting: false})
                        alert('error username not found')
                        return
                    } else {
                        this.setState({resetting: false})
                        alert('error attempting to reset password please try again')
                        return
                    }
                }
            }
            else{
                this.setState({resetting: false})
                alert('error attempting to reset password please try again')
                return
            }
        }
    }

    render(){
        var backIcon = require('../Back-01.png');

        return(
            <div className="App">

            <div className="Nav-header">
                <Link style={{textDecoration: 'none'}} to="/SignIn">
                    <button className="Back-wrap">
                        <img className="Back-button" src={backIcon} alt="back"/>
                    </button>
                </Link>
            </div>

            <div className="Title">Reset Password</div>
            {this.state.resetting &&
                <div>
                    <div className="Settings-wrapper">
                        <div className='Two-space'/>
                        <div className="Loading-buttonstatus">Resetting...</div>
                        <div className='Two-space'/>
                    </div>
                </div>
            }

            {!this.state.resetting &&
                <div className="Settings-wrapper">
                    <div className="form-name">username</div>
                    <input value={this.state.username} name="username" className="Reading-name" type="text" onChange={this.handleChange}/>

                    <button className="resend-link" onClick={this.resendCode} >click to email code</button>
                    
                    <form onSubmit={this.handleConfirm}>
                        <div className="form-name">verification code</div>
                            <input value={this.state.code} name="code" className="Reading-name" type="text" onChange={this.handleChange}/>
                        <div className='Two-space'/>
                        <div className="form-name">new password</div>
                            <input value={this.state.newPassword} name="newPassword" className="Reading-name" type="password" onChange={this.handleChange}/>
                        <div className="form-name">confirm new password</div>
                            <input value={this.state.confirmNewPassword} name="confirmNewPassword" className="Reading-name" type="password" onChange={this.handleChange}/>
                        <div className='Two-space'/>
                            <input type="submit" className="Add-button" value="submit"/>
                    </form>
                </div>
            }
            </div>
        )
    }

}

export default ForgotPass
