/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDevice = /* GraphQL */ `
  query GetDevice($id: ID!) {
    getDevice(id: $id) {
      id
      group
      location
      title
      version
      createdAt
      updatedAt
    }
  }
`;
export const listDevices = /* GraphQL */ `
  query ListDevices(
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        location
        title
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMultidevice = /* GraphQL */ `
  query GetMultidevice($id: ID!) {
    getMultidevice(id: $id) {
      id
      group
      location
      title
      version
      createdAt
      updatedAt
    }
  }
`;
export const listMultidevices = /* GraphQL */ `
  query ListMultidevices(
    $filter: ModelMultideviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMultidevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        location
        title
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBlog = /* GraphQL */ `
  query GetBlog($id: ID!) {
    getBlog(id: $id) {
      id
      location
      posts {
        nextToken
      }
      icons {
        nextToken
      }
      docs {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listBlogs = /* GraphQL */ `
  query ListBlogs(
    $filter: ModelBlogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        location
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      author
      content
      status
      blog {
        id
        location
        createdAt
        updatedAt
      }
      createdAt
      img
      updatedAt
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        author
        content
        status
        createdAt
        img
        updatedAt
      }
      nextToken
    }
  }
`;
export const getIcon = /* GraphQL */ `
  query GetIcon($id: ID!) {
    getIcon(id: $id) {
      id
      value
      lowest
      low
      high
      highest
      order
      blog {
        id
        location
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listIcons = /* GraphQL */ `
  query ListIcons(
    $filter: ModelIconFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIcons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        value
        lowest
        low
        high
        highest
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDoc = /* GraphQL */ `
  query GetDoc($id: ID!) {
    getDoc(id: $id) {
      id
      name
      filename
      createdAt
      uploadtime
      username
      blog {
        id
        location
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const listDocs = /* GraphQL */ `
  query ListDocs(
    $filter: ModelDocFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        filename
        createdAt
        uploadtime
        username
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrganizationLink = /* GraphQL */ `
  query GetOrganizationLink($orgName: String!) {
    getOrganizationLink(orgName: $orgName) {
      id
      orgName
      hiddenAlarms {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listOrganizationLinks = /* GraphQL */ `
  query ListOrganizationLinks(
    $orgName: String
    $filter: ModelOrganizationLinkFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrganizationLinks(
      orgName: $orgName
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        orgName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHideAlarm = /* GraphQL */ `
  query GetHideAlarm($id: ID!) {
    getHideAlarm(id: $id) {
      id
      groupName
      subGroup
      serial
      readingName
      deviceName
      hideUntilDate
      createdAt
      orgLink {
        id
        orgName
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const listHideAlarms = /* GraphQL */ `
  query ListHideAlarms(
    $filter: ModelHideAlarmFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHideAlarms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupName
        subGroup
        serial
        readingName
        deviceName
        hideUntilDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGroup = /* GraphQL */ `
  query GetGroup($groupName: String!) {
    getGroup(groupName: $groupName) {
      id
      groupName
      users {
        nextToken
      }
      units {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $groupName: String
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGroups(
      groupName: $groupName
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        groupName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($userName: String!) {
    getUser(userName: $userName) {
      id
      userName
      groupName
      group {
        id
        groupName
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $userName: String
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      userName: $userName
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userName
        groupName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUnit = /* GraphQL */ `
  query GetUnit($id: ID!) {
    getUnit(id: $id) {
      id
      serial
      nickName
      group {
        id
        groupName
        createdAt
        updatedAt
      }
      createdAt
      version
      updatedAt
    }
  }
`;
export const listUnits = /* GraphQL */ `
  query ListUnits(
    $filter: ModelUnitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUnits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        serial
        nickName
        createdAt
        version
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEnt = /* GraphQL */ `
  query GetEnt($entName: String!) {
    getEnt(entName: $entName) {
      id
      entName
      entusers {
        nextToken
      }
      entunits {
        nextToken
      }
      entdivsorgs {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listEnts = /* GraphQL */ `
  query ListEnts(
    $entName: String
    $filter: ModelEntFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEnts(
      entName: $entName
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        entName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEntUser = /* GraphQL */ `
  query GetEntUser($userName: String!) {
    getEntUser(userName: $userName) {
      id
      userName
      level
      entName
      divName
      orgName
      ent {
        id
        entName
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listEntUsers = /* GraphQL */ `
  query ListEntUsers(
    $userName: String
    $filter: ModelEntUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEntUsers(
      userName: $userName
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userName
        level
        entName
        divName
        orgName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEntUnit = /* GraphQL */ `
  query GetEntUnit($serial: String!) {
    getEntUnit(serial: $serial) {
      id
      serial
      nickName
      entName
      divName
      orgName
      ent {
        id
        entName
        createdAt
        updatedAt
      }
      createdAt
      version
      updatedAt
    }
  }
`;
export const listEntUnits = /* GraphQL */ `
  query ListEntUnits(
    $serial: String
    $filter: ModelEntUnitFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEntUnits(
      serial: $serial
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        serial
        nickName
        entName
        divName
        orgName
        createdAt
        version
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEntDivsOrgs = /* GraphQL */ `
  query GetEntDivsOrgs($id: ID!) {
    getEntDivsOrgs(id: $id) {
      id
      entName
      isOrg
      divName
      orgName
      ent {
        id
        entName
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listEntDivsOrgss = /* GraphQL */ `
  query ListEntDivsOrgss(
    $filter: ModelEntDivsOrgsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntDivsOrgss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        entName
        isOrg
        divName
        orgName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEnterpriseButtons = /* GraphQL */ `
  query GetEnterpriseButtons($id: ID!) {
    getEnterpriseButtons(id: $id) {
      user
      id
      ent
      div
      org
      serial
      title
      version
      createdAt
      updatedAt
    }
  }
`;
export const listEnterpriseButtonss = /* GraphQL */ `
  query ListEnterpriseButtonss(
    $filter: ModelEnterpriseButtonsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEnterpriseButtonss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        user
        id
        ent
        div
        org
        serial
        title
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDivisionButtons = /* GraphQL */ `
  query GetDivisionButtons($id: ID!) {
    getDivisionButtons(id: $id) {
      user
      id
      ent
      div
      org
      serial
      title
      version
      createdAt
      updatedAt
    }
  }
`;
export const listDivisionButtonss = /* GraphQL */ `
  query ListDivisionButtonss(
    $filter: ModelDivisionButtonsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDivisionButtonss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        user
        id
        ent
        div
        org
        serial
        title
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrganizationButtons = /* GraphQL */ `
  query GetOrganizationButtons($id: ID!) {
    getOrganizationButtons(id: $id) {
      user
      id
      ent
      div
      org
      serial
      title
      version
      test
      createdAt
      updatedAt
    }
  }
`;
export const listOrganizationButtonss = /* GraphQL */ `
  query ListOrganizationButtonss(
    $filter: ModelOrganizationButtonsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizationButtonss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        user
        id
        ent
        div
        org
        serial
        title
        version
        test
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEntBlog = /* GraphQL */ `
  query GetEntBlog($id: ID!) {
    getEntBlog(id: $id) {
      id
      location
      posts {
        nextToken
      }
      icons {
        nextToken
      }
      docs {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listEntBlogs = /* GraphQL */ `
  query ListEntBlogs(
    $filter: ModelEntBlogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntBlogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        location
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEntPost = /* GraphQL */ `
  query GetEntPost($id: ID!) {
    getEntPost(id: $id) {
      id
      author
      content
      status
      blog {
        id
        location
        createdAt
        updatedAt
      }
      createdAt
      img
      updatedAt
    }
  }
`;
export const listEntPosts = /* GraphQL */ `
  query ListEntPosts(
    $filter: ModelEntPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        author
        content
        status
        createdAt
        img
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEntIcon = /* GraphQL */ `
  query GetEntIcon($id: ID!) {
    getEntIcon(id: $id) {
      id
      value
      lowest
      low
      high
      highest
      order
      blog {
        id
        location
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listEntIcons = /* GraphQL */ `
  query ListEntIcons(
    $filter: ModelEntIconFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntIcons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        value
        lowest
        low
        high
        highest
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEntDoc = /* GraphQL */ `
  query GetEntDoc($id: ID!) {
    getEntDoc(id: $id) {
      id
      name
      filename
      createdAt
      uploadtime
      username
      blog {
        id
        location
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const listEntDocs = /* GraphQL */ `
  query ListEntDocs(
    $filter: ModelEntDocFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntDocs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        filename
        createdAt
        uploadtime
        username
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEntInventory = /* GraphQL */ `
  query GetEntInventory($id: ID!) {
    getEntInventory(id: $id) {
      entName
      id
      serial
      createdAt
      divName
      orgName
      onboardedAt
      updatedAt
    }
  }
`;
export const listEntInventorys = /* GraphQL */ `
  query ListEntInventorys(
    $filter: ModelEntInventoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntInventorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        entName
        id
        serial
        createdAt
        divName
        orgName
        onboardedAt
        updatedAt
      }
      nextToken
    }
  }
`;
