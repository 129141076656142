import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';
import EntSettings from "../components/EntSettings"
import DivSettings from "../components/DivSettings"
import OrgSettings from "../components/OrgSettings"

class SettingsPage extends Component {

    constructor(props){
        super(props)

        this.state = {
        group: '',
        userName: ''
        }
    }

    signOut = () => {
        Auth.signOut()
        .then(data => console.log(data))
        .catch(err => console.log(err));
        this.props.history.push('/SignIn');
    }

    async componentDidMount(){

        Auth.currentAuthenticatedUser({
        bypassCache: false
        }).then(user => {
            this.loggedIn = true
        }
        )
        .catch(error => {
            if(error === 'not authenticated'){
                this.props.history.push('/SignIn');
            }
        }
        );

        let user = await Auth.currentAuthenticatedUser()
        let userGroupGet = user.signInUserSession.accessToken.payload["cognito:groups"];

        this.setState({
            group: userGroupGet[0],
            userName: user.username
        });

    }

    render() {
        var backIcon = require('../Back-01.png');

        return(
            <div className="App">
                {
                    <div className="Nav-header">
                        <Link style={{textDecoration: 'none'}} to="/EntHome">
                        <button className="Back-wrap">
                            <img className="Back-button" src={backIcon} alt="back"/>
                        </button>
                        </Link>
                        <button onClick={this.signOut} className="Settings-button">signout</button>
                    </div>
                }
                {this.state.group === 'ent' &&
                    <EntSettings group={this.state.group} userName={this.state.userName}/>
                }
                {this.state.group === 'div' &&
                    <DivSettings group={this.state.group} userName={this.state.userName}/>
                }
                {this.state.group === 'org' &&
                    <OrgSettings group={this.state.group} userName={this.state.userName}/>
                }
            </div>
        )
    }
}

export default SettingsPage
