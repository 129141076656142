import React, { Component } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries.js';

class Log extends Component {
    constructor(props){
        super(props)
        this.testSerial = ''
        this.timeZone = 'America/Chicago'

        this.state = {
            serial: props.serial,
            log: [],
            noLogs: false,
            loading: false,
            timeColor1: '',
            timeColor2: ''
        }      
    }

    convertTimestamp (date) {
        let finalTimestamp = ''
        if(this.timeZone === 'America/Chicago'){

            // reformat timestamp
            let year = date.slice(0,4);
            let month = date.slice(5,7);
            let newMonth = Number(month);
            let day = date.slice(8,10);
            let newDay = Number(day);
            let hour = date.slice(11,13);
            let newHour = Number(hour)
            let minute = date.slice(14,16);
            let amPm = "am";
            if(newHour === 12){
                amPm = "pm"
            };
            if(newHour > 12){
                newHour = newHour - 12
                amPm = "pm"
            };
            if(hour === "00"){
                newHour = "12"
            };
            let reform = newMonth + "/" + newDay + "/" + year + " " + newHour + ":" + minute + amPm;
            finalTimestamp = reform;
        } else {
            let dateObject = new Date(date)
            let changedStamp = new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'long', timeZone: this.timeZone }).format(dateObject)
            let monthZone = changedStamp.slice(3,5)
            let dayZone = changedStamp.slice(0,2)
            let yearZone = changedStamp.slice(6,10)
            let hourZone = changedStamp.slice(12,14)
            let newHourZone = Number(hourZone)
            let minuteZone = changedStamp.slice(15,17)
            let amPm = "am"
            if(newHourZone === 12){
                amPm = "pm"
            };
            if(newHourZone > 12){
                newHourZone = newHourZone - 12
                amPm = "pm"
            };
            if(hourZone === "00"){
                newHourZone = "12"
            };

            let reform = monthZone + "/" + dayZone + "/" + yearZone + " " + newHourZone + ":" + minuteZone + amPm;
            finalTimestamp = reform;
        }
        return finalTimestamp
    }

    async logResults (time) {
        if(time === "week"){
            this.setState({
                timeColor1: '#529857',
                timeColor2: '#666666',
            })
        }
    
        if(time === "month"){
            this.setState({
                timeColor1: '#666666',
                timeColor2: '#529857',
            })
        }   
        this.setState({
            loading: true
        })

        let queryName = '/items/'+this.state.serial
        let blogQuery = this.state.serial
        let dataDevice = []
        let iconData = []

        if(time === 'month'){
            try{
                dataDevice = await API.get('monthGraphs', queryName);
            } catch(error){
                alert('network error')
                console.log(error)
            }
        } else {
            try{
                dataDevice = await API.get('dataqueryapi', queryName);
            } catch(error){
                alert('network error')
                console.log(error)
            }
        }



        try{
            let blogCall = await API.graphql(graphqlOperation(queries.listBlogs,{limit: 1000, filter:{location:{eq: blogQuery}}}));
            this.blogID = blogCall.data.listBlogs.items[0].id
        } catch(error) {
            console.log(error)
        }
        const getIcons = `query GetIcons { getBlog(id:"${this.blogID}") {id location icons { items { id value lowest low high highest order }}}}`

        iconData = await API.graphql(graphqlOperation(getIcons));
        let cleanedIconData = iconData.data.getBlog.icons.items;

        //check for users timezone offset compared to central times offset
        let changedCentral = new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'long', timeZone: 'America/Chicago' }).format()
        let offsetCentral = Number(changedCentral.slice(-1))
        let localDate = new Date()
        let localOffset = localDate.getTimezoneOffset() / 60

        let minLate = 360000;
        let logArray = []


        if(offsetCentral === localOffset){
            this.timeZone = 'America/Chicago'

        } else {
            let offsetDifference = offsetCentral - localOffset
            if(offsetDifference === 1){
                this.timeZone = 'America/Halifax'
            }
            if(offsetDifference === -1){
                this.timeZone = 'America/Los_Angeles'
            }
            if(offsetDifference === -2){
                this.timeZone = 'America/Anchorage'
            }
        }

        let readingInAlarm = {}
        
        await cleanedIconData.forEach(icon => {
            readingInAlarm[icon.value] = false
        })

        let nowDateTime = new Date()
        let year = nowDateTime.getFullYear()
        let month = nowDateTime.getMonth() + 1
        let day = nowDateTime.getDate()
        let hour = nowDateTime.getHours()
        let minute = nowDateTime.getMinutes()
        let second = nowDateTime.getSeconds()
        if (month.toString().length < 2) month = '0' + month;
        if (day.toString().length < 2) day = '0' + day;
        if (hour.toString().length < 2) hour = '0' + hour;
        if (minute.toString().length < 2) minute = '0' + minute;
        if (second.toString().length < 2) second = '0' + second;
        let previousTime = year + '-' + month + '-' + day + 'T' + hour + ':' + minute + ':' + second

        let newestTimestamp = dataDevice[0].timestamps
        let testTimestamp = ''
        if(this.timeZone === 'America/Chicago'){
            testTimestamp = newestTimestamp.replace(/\s/, 'T')
        } else {
            let dateObject = new Date(newestTimestamp)
            let changedStamp = new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'long', timeZone: this.timeZone }).format(dateObject)
            let monthTest = changedStamp.slice(3,5)
            let dayTest = changedStamp.slice(0,2)
            let yearTest = changedStamp.slice(6,10)
            let hourTest = changedStamp.slice(12,14)
            let minuteTest = changedStamp.slice(15,17)
            let milliSeconds = changedStamp.slice(18,20)
    
            testTimestamp = yearTest + "-" + monthTest + "-" + dayTest + "T" + hourTest + ":" + minuteTest + ":" + milliSeconds
        }
        
        if((nowDateTime - new Date(testTimestamp)) > minLate) {
            let logAlarmMod = this.convertTimestamp(testTimestamp)
            logArray.push({'icon': 'stop', 'message': ' device currently offline since ', 'timestamp': logAlarmMod})
        }
        


        let noInputBoard = ''
        let currentCheck = true
        let finalCheck = dataDevice.length -1
   
        if(dataDevice[0].device_status === "input board not found"){
            let logAlarmMod = this.convertTimestamp(previousTime)
            logArray.push({'icon': 'stop', 'message': 'no input board now ', 'timestamp': logAlarmMod})
            noInputBoard = true
        } else {
            noInputBoard = false
        }

        dataDevice.forEach( (data, index) => {
            cleanedIconData.forEach(icon => {
                Object.entries(data).map(reading => {
                    if(icon.value === reading[0]){
                        if(reading[1] >= icon.highest || reading[1] <= icon.lowest ){
                            if(currentCheck){
                                let logAlarmMod = this.convertTimestamp(previousTime)
                                logArray.push({'icon': 'stop', 'message': icon.value + ' is in alarm now ', 'timestamp': logAlarmMod})
                                readingInAlarm[icon.value] = true
                            }
                            if(index === finalCheck){
                                let logAlarmMod = this.convertTimestamp(data.timestamps)
                                logArray.push({'icon': 'stop', 'message': icon.value + ' is in alarm ', 'timestamp': logAlarmMod})
                                readingInAlarm[icon.value] = true
                            }
                            if(readingInAlarm[icon.value] === false && currentCheck === false){
                                let logAlarmMod = this.convertTimestamp(previousTime)
                                logArray.push({'icon': 'check', 'message': icon.value + ' in range ', 'timestamp': logAlarmMod})
                                readingInAlarm[icon.value] = true
                            }

                            
                        }
                        if(readingInAlarm[icon.value] === true){
                            if(reading[1] < icon.highest && reading[1] > icon.lowest ){
                                let logNoAlarmMod = this.convertTimestamp(previousTime)
                                logArray.push({'icon': 'stop', 'message': icon.value + ' in alarm ', 'timestamp': logNoAlarmMod})
                                readingInAlarm[icon.value] = false
                            }
                        }

                    }
                    return logArray
                })
            })

            if(data.device_status === undefined && noInputBoard === true){
                let logAlarmMod = this.convertTimestamp(previousTime)
                logArray.push({'icon': 'stop', 'message': 'input board not found', 'timestamp': logAlarmMod})
                noInputBoard = false
            }
            if(data.device_status === 'input board not found' && noInputBoard === false){
                let logAlarmMod = this.convertTimestamp(previousTime)
                logArray.push({'icon': 'check', 'message': 'input board found', 'timestamp': logAlarmMod})
                noInputBoard = true
            }
            let modTime = data.timestamps.replace(/\s/, 'T')
            if((new Date(previousTime) - new Date(modTime)) > minLate){
                if(currentCheck){
                    console.log('offline')
                } else {
                    let logPrevious = this.convertTimestamp(previousTime)
                    let logMod = this.convertTimestamp(modTime)
                    logArray.push({'icon': 'check', 'message': 'online ', 'timestamp': logPrevious})
                    logArray.push({'icon': 'stop', 'message': 'offline ', 'timestamp': logMod})
                }
            }

            previousTime = modTime
            currentCheck = false

        })
        let noLogsFound = false
        if(logArray.length === 0){
            noLogsFound = true
        }

        this.setState({
            log: logArray,
            noLogs: noLogsFound,
            loading: false
        })
    }

    async componentDidMount() {
        this.logResults('week')
    }

    render(){
        var imageCheck = require('../Nowledge-App-Icons-CHECK.png')
        var imageStop = require('../Nowledge-App-Icons-STOP.png')
        return(
            <div className='Log-background'>
                {
                    <div className="Button2-wrapper-alarm">
                        <button type="button" className="Button2-select-alarm" onClick={(e) => this.logResults('week')} style={{backgroundColor:this.state.timeColor1}}>
                            <div>week</div>
                        </button>
                        <button type="button" className="Button2-select-alarm" onClick={(e) => this.logResults('month')} style={{backgroundColor:this.state.timeColor2}}>
                            <div>month</div>
                        </button>
                    </div>
                }
                {
                    <div className='One-space'/>
                }
                {this.state.loading &&
                    <div>
                        <div className="Loading-noback">Loading....</div>
                    </div>
                }
                {this.state.noLogs &&
                    <div>
                        <div className='Two-space'/>
                        <img className="Log-icon" src={imageCheck} alt="check" />
                        <div className='Log-message'>No disconnects found and</div>
                        <div className='Log-message'>No readings have been in alarm</div>
                    </div>
                }
                {!this.state.loading &&
                    this.state.log.map((log, index) => (
                        <div className='Log-wrapper' key={index}>
                            {log.icon === 'check' &&
                                <img className="Log-icon" src={imageCheck} alt="check" />
                            }
                            {log.icon === 'stop' &&
                                <img className="Log-icon" src={imageStop} alt="stop" />
                            }
                            <div className='Log-message'>{log.message.replace(/_/g, ' ')}</div>
                            <div className='Log-timestamp'>{log.timestamp}</div>
                        </div>
                        
                    ))                  
                }
                
            </div>
        )
    }
}

export default Log