import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries.js';

class OrgUserOnboard extends Component {
    constructor(props){
        super(props)

        this.state = {
            enterprise: '',
            division: '',
            organization: '',
            serial: '',
            nickName: '',
            loading: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    async handleSubmit(event) {
        event.preventDefault()

        let finalEnterprise = this.state.enterprise
        let finalDivision = this.state.division
        let finalOrganization = this.state.organization
        let finalSerial = this.state.serial
        let finalNickname = this.state.nickName

        if(finalSerial === ''){
            alert('Please enter a serial number and try again')
            return
        }
        if(finalNickname === ''){
            alert('The device must be given a name')
            return
        }

        this.setState({
            loading: true
        })
        
        let deviceFoundMatched = false
        let foundUnitInfo = []
    
        try{
            let unitInfo = await API.graphql(graphqlOperation(queries.getEntUnit, { serial: finalSerial }));
            if(unitInfo.data.getEntUnit === null){
                alert('The serial number was not found in the system. Please check that the number is correct and try again')
                this.setState({loading: false})
                return
            } else {
                if(unitInfo.data.getEntUnit.entName !== finalEnterprise){
                    alert('The serial number was not found in the system. Please check that the number is correct and try again')
                    this.setState({loading: false})
                    return
                } else {
                    foundUnitInfo = unitInfo.data.getEntUnit
                    deviceFoundMatched = true
                }
            }
        } catch(error) {
            console.log(error)
            alert('An error occurred while trying to find the device in the system. Please try again')
            this.setState({loading: false})
            return
        }

        if(deviceFoundMatched === true){
            const updateUnit = `mutation UpdateEntUnit {
                updateEntUnit(input: {
                  id: "${foundUnitInfo.id}"
                  serial: "${foundUnitInfo.serial}"
                  divName: "${finalDivision}"
                  orgName: "${finalOrganization}"
                  nickName: "${finalNickname}"
                }){
                  id
                }
              }`
            try {
                API.graphql(graphqlOperation(updateUnit));
            } catch(error) {
                alert('An error occurred onboarding please try again')
                return
            }
        
            this.setState({
                loading: false,
                serial: '',
                nickname: ''
            })
            alert('Device was sucessfully onboarded')
            this.props.history.push('/DeviceSync');
        }
    }

    async componentDidMount(){
        Auth.currentAuthenticatedUser({
            bypassCache: false
            }).then(user => {
              this.loggedIn = true
            }
          )
          .catch(error => {
            if(error === 'not authenticated'){
              this.props.history.push('/SignIn');
            }
          }
          );
      
        let user = await Auth.currentAuthenticatedUser()
        const userName = user.username;
    
        let userInfo = await API.graphql(graphqlOperation(queries.getEntUser, { userName: userName }));
    
        let userEnt = userInfo.data.getEntUser.entName
        let userDiv = userInfo.data.getEntUser.divName
        let userOrg = userInfo.data.getEntUser.orgName
        
        this.setState({
            enterprise: userEnt,
            division: userDiv,
            organization: userOrg
        })
    }

    render() {
        var backIcon = require('../Back-01.png');

        return(
            <div className="App">
                {
                    <div className="Nav-header">
                        <Link style={{textDecoration: 'none'}} to="/SettingsPage">
                            <button className="Back-wrap">
                                <img className="Back-button" src={backIcon} alt="back"/>
                            </button>
                        </Link>
                    </div>
                }
                <div className="Title">Onboard a Device</div>
                <div className="Settings-wrapper">
                    <div className='One-space'/>
                    { this.state.loading &&
                        <div>
                            <div className='One-space'/>
                            <div className="Loading-inbanner">onboarding device...</div>
                            <div className='One-space'/>
                        </div>
                    }
                    {!this.state.loading &&
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-name">serial number</div>
                            <input value={this.state.serial} name="serial" className="Reading-name" type="text" onChange={this.handleChange}/>
                            <div className="form-name">device name</div>
                            <input value={this.state.nickName} name="nickName" className="Reading-name" type="text" onChange={this.handleChange}/>
                            <div className='One-space'/>
                            <input type="submit" className="Icon-submit" value="submit"/>
                        </form>
                    }
                </div>
            </div>
        )
    }
}

export default OrgUserOnboard