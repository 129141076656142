import React, { Component } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { Link } from 'react-router-dom';
import * as queries from '../graphql/queries.js';

class OrganizationInvite extends Component{

    constructor(props){
        super(props)

        this.state ={
            orgName: '',
            organizations: [],
            clicked: false,
            id: '',
            loading: true,
            index: '',
            hideCopyEmail: false
        }
        this.close = this.close.bind(this);
        this.emailId = this.emailId.bind(this);
    }

    async handleClick(groupName, index) {
        this.setState({
            clicked: true,
            index: index,
            orgName: groupName,
            id: 'loading....',
            hideCopyEmail: true
        });

        function getId() {
            const apiName = 'organizationid';
            const path = '/items';

            const myInit = {
                queryStringParameters: {
                    name: groupName,
                }
            };
            return API.get(apiName, path, myInit);
        }

        let response = [];
        try {
            response = await getId()
        } catch(error) {
            console.log(error)
            alert('error fetching this organization ID')
            return
        };

        if(response.status === 'success'){
            this.setState({
                id: response.id,
                hideCopyEmail: false
            })
        } else {
            alert('error fetching this organization ID')
        }
    }

    copyId = (event) => {
        this.textArea.select();
        document.execCommand('copy');
        event.target.focus();
        alert('id copied to clipboard')
    };

      // pull up email on click
    emailId(event) {
        let emailBody = "You have been given an ACCESS ID number for nowledgeapp.com. \n\nYour ACCESS ID number is: " + this.state.id + "\n\nGo to nowledgeapp.com and click signup where it asks if you are new to nowledge. \n\nCopy and paste this number during the step where it asks for an ACCESS ID number." 
        let mailToLink = "mailto:?subject=Invitation to nowledgeapp.com&body=" + encodeURIComponent(emailBody);
        window.location.href = mailToLink;
    }

    async componentDidMount(){
        let data = [];

        try{
            data = await API.graphql(graphqlOperation(queries.listGroups, { limit: 1000 }));
        } catch(error) {
            console.log(error)
            alert('Error fetching organization IDs')
            return
        };

        data.data.listGroups.items.sort((a, b) => a.groupName.localeCompare(b.groupName));
        const finalData = data.data.listGroups.items;

        this.setState({
            organizations: finalData,
            loading: false
        })
    }

    async close(event){
        this.setState({
            orgName: '',
            clicked: false,
            id: '',
            loading: false,
            index: ''
        })
    }

  render(){
    var backIcon = require('../Back-01.png');

    return(
        <div className="App">
            {
                <div className="Nav-header">
                    <Link style={{textDecoration: 'none'}} to="/MainSettings">
                        <button className="Back-wrap">
                        <img className="Back-button" src={backIcon} alt="back"/>
                        </button>
                    </Link>
                </div>
            }
            <div className="Title">Organization Ids</div>
            {this.state.loading &&
                <div className="Device-wrapper">
                    <div className='One-space'/>
                    <div className="Loading-buttonstatus">Loading...</div>
                    <div className='One-space'/>
                </div>
            }
            {!this.state.loading &&
                this.state.organizations.map((organization, index) =>  (
                    <div key={index}>
                        {this.state.index === index &&
                            <div>
                                {!this.state.loading &&
                                    <div className="Device-wrapper">
                                        <button className="Dropdown-button"  onClick={this.close}>hide</button>
                                        <div className="notesdocopen-link">{this.state.orgName}</div>
                                        <div className="form-name">organization id</div>
                                        <textarea className="copy-id" readOnly = {true} ref={(textarea) => this.textArea = textarea} value={this.state.id} />
                                        {!this.state.hideCopyEmail &&
                                            <div>
                                                <div className='One-space'/>
                                                <div className='Two-button-wrapper'>
                                                    <button onClick={this.copyId} className='Two-button'>copy</button>
                                                    <button onClick={this.emailId} className='Two-button'>email</button>
                                                </div>

                                                <div className='One-space'/>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        }
                        {this.state.index !== index &&
                            <div>
                                <div className='One-space'/>
                                <div className="Device-wrapper">
                                    <button onClick={() => this.handleClick(organization.groupName, index)} className="org-get-id">
                                        <div>{organization.groupName}</div>
                                    </button>
                                </div>
                                <div className='One-space'/>
                            </div>
                        }
                    </div>
                ))
            }

        </div>
    )
  }
}

export default OrganizationInvite
