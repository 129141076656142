import React, { Component } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import Documents from '../components/Documents';
import DocumentSubmit from '../components/DocumentSubmit'; 

class Document extends Component {

    constructor(props){
        super(props);

        this.blogID = props.blogID
        this.userName = props.userName
        this.title = props.title
    
        this.state = {
            documents: [],
            addDoc: false,
            addDocForm: false,
            addDocHide: false,
            noDocs: false,
            loading: true
        }
        this.getDocs = this.getDocs.bind(this);
        this.getDocForm = this.getDocForm.bind(this);
    }

    // fetch docs only after the section is clicked return all
    // will also be called from submitdocument after a new document was added
    async getDocs(event) {
        const getDocs = `query GetDocs {
            getBlog(id:"${this.blogID}") {
                id
                location
                docs(sortDirection:DESC) {
                    items {
                        id
                        name
                        filename
                        uploadtime
                        createdAt
                        username
                    }
                }
            }
        }`

        let queryResults = await API.graphql(graphqlOperation(getDocs));
        let allDocs = queryResults.data.getBlog.docs.items;
        let noDocsCaught = false
        if(allDocs.length === 0){
            noDocsCaught = true
        } 

        this.setState({
            documents: allDocs,
            addDoc: true,
            noDocs: noDocsCaught,
            loading: false
        })
    }

    async componentDidMount(){
        const getDocs = `query GetDocs {
            getBlog(id:"${this.blogID}") {
                id
                location
                docs(sortDirection:DESC) {
                    items {
                        id
                        name
                        filename
                        uploadtime
                        createdAt
                        username
                    }
                }
            }
        }`

        let queryResults = await API.graphql(graphqlOperation(getDocs));
        let allDocs = queryResults.data.getBlog.docs.items;
        let noDocsCaught = false
        if(allDocs.length === 0){
            noDocsCaught = true
        } 

        this.setState({
            documents: allDocs,
            addDoc: true,
            noDocs: noDocsCaught,
            loading: false
        })
    }

    // toggle doc form open/close
    async getDocForm(){
        this.setState(prevState => ({
            addDocForm: !prevState.addDocForm,
            addDocHide: !prevState.addDocHide
        }));
    }

    render(){
        var addIcon = require('../icons8-plus-100.png');
        return(
          <div className='App'>
                {this.state.loading &&
                    <div>
                        <div className='Two-space'/>
                        <div className="Loading-button">Loading....</div>
                    </div>
                }
                <div className='One-space'/>
                {this.state.addDoc && !this.state.addDocHide &&
                    <button className='Add-button' onClick={(e) => this.getDocForm()}>
                        <img className="Plus-button" src={addIcon} alt="add"/>
                        document
                    </button>                  
                }
                {this.state.addDoc && this.state.addDocHide &&
                    <button className="Dropdown-button-full" onClick={(e) => this.getDocForm()}>close</button>
                }
                {this.state.addDocForm &&
                    <DocumentSubmit blogID={this.blogID} userName={this.userName} title={this.title} getDocs={this.getDocs}/>
                }
                {
                    <Documents documents={this.state.documents} noDocs={this.state.noDocs}/>
                }
          </div>
        )
    }
}

export default Document