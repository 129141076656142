import React, { Component } from 'react';
import {LineChart, ReferenceLine, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';
import { isMobile } from 'react-device-detect'

class Graphs extends Component {
    constructor(props){
        super(props);

        this.state = {
            dataFinal: [],
            icons: [],
            timeZone: '',
            receivedIcons: [],
            oneDataCaught: false,
            legendX: 150
        }
    }

    async UNSAFE_componentWillReceiveProps(nextProps) {
        let receivedData = nextProps.dataForGraph
        let receivedIcons = nextProps.icons
        let receivedTimeZone = nextProps.timezone
        let legendXCatch = 150

        if(isMobile){
            legendXCatch = 150
        } else {
            legendXCatch = 500
        }

        let oneData = false
        if(receivedData.length === 1){
            oneData = true
        }

        this.setState({
            dataFinal: receivedData,
            timeZone: receivedTimeZone,
            icons: receivedIcons,
            oneDataCaught: oneData,
            legendX: legendXCatch
        })
    }

    renderGraphs(data, icons){

        function graphTimestamp(timestamp){
            let year = timestamp.slice(0,4);
            let month = timestamp.slice(5,7);
            let newMonth = Number(month);
            let day = timestamp.slice(8,10);
            let newDay = Number(day);
            let hour = timestamp.slice(11,13);
            let amPm = "am";
            if(hour === "12"){
                amPm = "pm"
            };
            if(hour > 12){
                hour = hour - 12
                amPm = "pm"
            };
            if(hour === "00"){
                hour = 12
            };
            let newHour = Number(hour);
            let minute = timestamp.slice(14,16);
            let reform = newMonth + "/" + newDay + "/" + year + " " + newHour + ":" + minute + amPm;

            return reform;
        }
        function graphTimestampZoned(timestamp, timezone){
            let dateObject = new Date(timestamp) 
            let changedStamp = new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'long', timeZone: timezone }).format(dateObject)
            let monthZone = changedStamp.slice(3,5)
            let dayZone = changedStamp.slice(0,2)
            let yearZone = changedStamp.slice(6,10)
            let hourZone = changedStamp.slice(12,14)
            let newHourZone = Number(hourZone)
            let minuteZone = changedStamp.slice(15,17)

            let amPm = "am"
            if(newHourZone === 12){
                amPm = "pm"
            };
            if(newHourZone > 12){
                hourZone = newHourZone - 12
                amPm = "pm"
            };
            if(hourZone === "00"){
                hourZone = "12"
            };
            let reform = monthZone + "/" + dayZone + "/" + yearZone + " " + hourZone + ":" + minuteZone + amPm;
            return reform
        }

        const CustomTooltip = ({ active, payload, label }) => {
            if (active) {
                try{
                    return (
                        <div className="custom-tooltip">
                            {payload[0].value !== 'undefined' && this.state.timeZone === 'America/Chicago' &&
                                <p className="label">{graphTimestamp(label)} = {payload[0].value}</p>
                            }
                            {payload[0].value !== 'undefined' && this.state.timeZone !== 'America/Chicago' &&
                                <p className="label">{graphTimestampZoned(label, this.state.timeZone)} = {payload[0].value}</p>
                            }
                        </div>
                    );
                }catch(e){
                    console.log(e)
                    console.log('missing data');
                }
            }
            return null;
        };

        return( 
            <div>
                {!this.state.oneDataCaught &&
                    icons.map((icon, index) => (
                        <div key={index}>
                            {
                                <div className="graphs">
                                    <div className="graphs-container">
                                        <ResponsiveContainer width="100%" height="100%">
                                            <LineChart data={data} syncId="connect" margin={{top: 15, right: 0, left: 0, bottom: 15}}>
                                                <XAxis dataKey="timestamps" hide={true} axisLine={false} reversed={true}/>
                                                <YAxis scale="auto" axisLine={false} domain={['dataMin', 'dataMax']}/>
                                                <CartesianGrid strokeDasharray="0 0" vertical={false} horizontal={false}/>
                                                <Tooltip offset={10} content={<CustomTooltip />} position={{ x: this.state.legendX, y: 0 }} />
                                                <Legend verticalAlign="top" height={36} payload={[{value: icon.value.replace(/_/g, ' ')}]} align='left' iconSize={0} wrapperStyle={{color: 'white', fontSize: '20px'}}/>
                                                <ReferenceLine y={icon.lowest} stroke="#d42d0a" strokeWidth={2}/>
                                                <ReferenceLine y={icon.highest} stroke="#d42d0a" strokeWidth={2}/>
                                                <Line type="monotone" dataKey={icon.value} stroke="#529857" activeDot={{r: 8}} dot={false} strokeWidth={4} connectNulls={true} isAnimationActive={false}/>
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                            }                       
                        </div>
                    ))
                }
                {this.state.oneDataCaught &&
                    icons.map((icon, index) => (
                        <div key={index}>
                            {
                                <div className="graphs">
                                    <div className="graphs-container">
                                        <ResponsiveContainer width="100%" height="100%">
                                            <LineChart data={data} syncId="connect" margin={{top: 15, right: 0, left: 0, bottom: 15}}>
                                                <XAxis dataKey="timestamps" hide={true} axisLine={false} reversed={true}/>
                                                <YAxis scale="auto" axisLine={false} domain={['dataMin', 'dataMax']}/>
                                                <CartesianGrid strokeDasharray="0 0" vertical={false} horizontal={false}/>
                                                <Tooltip offset={10} content={<CustomTooltip />} position={{ x: 150, y: 0 }} />
                                                <Legend verticalAlign="top" height={36} payload={[{value: icon.value.replace(/_/g, ' ')}]} align='left' iconSize={0} wrapperStyle={{color: 'white', fontSize: '20px'}}/>
                                                <Line type="monotone" dataKey={icon.value} stroke="#529857" activeDot={{r: 8}} dot={false} strokeWidth={4} connectNulls={true} isAnimationActive={false}/>
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                            }                       
                        </div>
                    ))
                }
            </div>         

        )
    }

    render(){
        return(
            <div>
                <div className="graphs-container">
                    {this.renderGraphs(this.state.dataFinal, this.state.icons)}
                </div>
                <div className="Space-div2" />
                <br />
                <br />
                <br />
            </div>
        )
    }
}

export default Graphs
