/* eslint-disable no-unused-expressions */
import React, { Component } from 'react';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as queries from '../graphql/queries.js';
import { Link } from 'react-router-dom';
import Status from "../components/Status"
import Graphs from "../components/Graphs"
import Documents from "../components/Documents"
import EntDocumentSubmit from "../components/EntDocumentSubmit"
import DownloadCSV from "../components/DownloadCSV"
import Notes from "../components/Notes"
import EntNoteSubmit from "../components/EntNoteSubmit"

class Device extends Component {

  constructor(props) {
    super(props);
    //empty props
    this.data = [];
    this.status = [];
    this.notedata = [];
    this.timeStatus = 'week';
    this.noData = true;
    this.errorMessage = '';
    this.deviceError = false;
    this.loggedIn = '';

    // serial number from home
    const {loc} = props.location.state
    this.local = loc
    // nickname from home
    const {title} = props.location.state
    this.title = title
    // device software version number
    const {version} = props.location.state
    this.version = version
    console.log('constructor')
    console.log(this.local)

    this.state = {
      data: [],
      status: [],
      notes: [],
      icons: [],
      docs: [],
      loading: true,
      loadall: true,
      timeColor1: '#529857',
      timeColor2: '#666666',
      graphLoading: false,
      docsOrHide: true,
      addDoc: false,
      addDocForm: false,
      noteHide: true,
      addDocHide: false,
      noDeviceData: true,
      noteSection: false,
      notesOrHide: true,
      noNotes: false,
      noDocs: false,
      intervalState: '',
      showCSV: false
     }

     // bindings
     this.handleShowAll = this.handleShowAll.bind(this);
     this.refreshPage = this.refreshPage.bind(this);
     this.timeLine = this.timeLine.bind(this);
     this.getDocs = this.getDocs.bind(this);
     this.getDocForm = this.getDocForm.bind(this);
     this.stopInterval = this.stopInterval.bind(this);
     this.getCSV = this.getCSV.bind(this);
     this.getNotes = this.getNotes.bind(this);
  }

  // clear interval for the data call for new data
  stopInterval(event) {
    clearInterval(this.state.intervalState);
  }

  // toggle week/month worth of data for the graphs
  async timeLine() {
    this.timeStatus = this.click
    let queryName = '/items/'+this.local

    if(this.timeStatus === "week"){
      this.setState({
        timeColor1: '#529857',
        timeColor2: '#666666',
        graphLoading: true
      })
      this.data = await API.get('dataqueryapi', queryName);
      var a = Math.floor(this.data.length / 3);
      while (a--){
        this.data.splice((a + 1) * 3, 2);
      }
      this.setState({
        data: this.data,
        graphLoading: false
      })
    }

    if(this.timeStatus === "month"){
      this.setState({
        timeColor1: '#666666',
        timeColor2: '#529857',
        graphLoading: true
      })

      this.data = await API.get('monthGraphs', queryName);
      this.data.splice(8641)

      var b = Math.floor(this.data.length / 3);
      while (b--){
        this.data.splice((b + 1) * 3, 2);
      }

      this.setState({
        data: this.data,
        graphLoading: false
      })
    }
  }

  // toggle the get CSV form section open/closed
  async getCSV(event) {
    await this.setState(prevState => ({
      showCSV: !prevState.showCSV
    }));
  }

  // fetch docs only after the section is clicked return all
  // will also be called from submitdocument after a new document was added
  async getDocs(event) {
    this.setState({
      docsOrHide: false
    })

    const getDocs = `query GetEntDocs {
      getEntBlog(id:"${this.blogID}") {
        id
        location
        docs(sortDirection:DESC) {
          items {
            id
            name
            filename
            uploadtime
            createdAt
            username
          }
        }
      }
    }`

    let queryResults = await API.graphql(graphqlOperation(getDocs));
    let allDocs = queryResults.data.getEntBlog.docs.items;
    if(allDocs.length === 0){
      this.setState({noDocs: true})
    }

    await this.setState({
      docs: allDocs,
      addDoc: true
    })
  }

  // hide and clear out docs when clicked to close
  async hideDocs(){
    await this.setState({
      docs: [],
      addDoc: false,
      docsOrHide: true,
      addDocForm: false,
      addDocHide: false,
      noDocs: false
    })
  }

  // fetch and render last 3 notes when notes section clicked
  // will also be called from submit note after new note added
  async getNotes(){
    this.setState({notesOrHide: false})

    const getNotes = `query GetConvo {
      getEntBlog(id:"${this.blogID}") {
        id
        location
        posts(sortDirection:DESC limit: 3) {
          items {
            id
            author
            content
            status
            createdAt
            img
          }
        }
      }
    }`
    this.notedata = await API.graphql(graphqlOperation(getNotes));
    if(this.notedata.data.getEntBlog.posts.items.length === 0){
      this.setState({noNotes: true})
    }

    this.setState({
        notes: this.notedata.data.getEntBlog.posts.items,
        noteSection: true
      });

    if(this.notedata.data.getEntBlog.posts.items.length >= 3){
      this.setState({loadall: true});
    } else {
      this.setState({loadall: false});
    }
  }

  // hide and clear out notes when clicked to close
  async hideNotes(){
    this.setState(
      {
        notes: [],
        noteSection: false,
        notesOrHide: true,
        noNotes: false,
        noteHide: true
      })
  }

  // toggle note submit form open/close
  async noteForm(){
    await this.setState(prevState => ({
      noteHide: !prevState.noteHide
    }));
  }

  // toggle doc form open/close
  async getDocForm(){
    await this.setState(prevState => ({
      addDocForm: !prevState.addDocForm,
      addDocHide: !prevState.addDocHide
    }));
  }

  // signout current user
  signOut = () => {
    Auth.signOut()
      .then(data => console.log(data))
      .catch(err => console.log(err));
  }

  // fetch and render all notes linked to the device
  async handleShowAll(event) {
    event.preventDefault();

    const allNotes = `query GetConvo {
      getEntBlog(id:"${this.blogID}") {
        id
        location
        posts(sortDirection:DESC limit: 100) {
          items {
            id
            author
            content
            status
            createdAt
            img
          }
        }
      }
    }`
    this.notedata = await API.graphql(graphqlOperation(allNotes));

    this.setState({
      notes: this.notedata.data.getEntBlog.posts.items,
      loadall: false
    })
  }

  // interval check for new data
  async refreshPage() {
    let queryName = '/items/'+this.local

    // refresh based on week or month being selected currently
    if(this.timeStatus === "week"){
      this.data = await API.get('dataqueryapi', queryName);

    }
    if(this.timeStatus === "month"){
      this.data = await API.get('monthGraphs', queryName);
      this.data.splice(8641)
    }

    // check if no data found for device and show message if not
    if(this.data.length === 0){
      this.noData = true;
    } else {
      this.noData = false;
      this.status = this.data.slice(0)[0]
      this.copiedStatus = JSON.parse(JSON.stringify(this.status));
      // check if device is sending error and set its message
      // no input board, device not programmed or both
      if(typeof this.copiedStatus["device_status"] === 'undefined'){
        this.deviceError = false;
      } else {
        this.deviceError = true;
        this.errorMessage = this.copiedStatus["device_status"];
      }

      delete this.copiedStatus["timestamps"];
      delete this.copiedStatus["groups"];
      delete this.copiedStatus["device"];
      delete this.copiedStatus["ttl"];

      //data trim for mobile viewing 15 minute gaps
      var i = Math.floor(this.data.length / 3);
      while (i--){
        this.data.splice((i + 1) * 3, 2);
      }
    }

    this.modIcons = this.icondata.data.getEntBlog.icons.items;
    this.orderedIcons =this.modIcons.sort(function(a, b) {
        return (a.order===null)-(b.order===null) || +(a.order>b.order)||-(a.order<b.order);
    });
    //clean up icons
    var testObject = this.orderedIcons.map(function(elem) {
      return {
        value: elem.value,
        order: elem.order,
      }
    });

    //put status into an array of arrays
    var sortable = [];
    for (var value in this.copiedStatus) {
        sortable.push([value, this.copiedStatus[value]]);
    };

    //map through ordered sorted icons when matched with status reading add to array
    var orderedArray = []
    testObject.map(function(item){
      for (let index = 0; index < sortable.length; ++index) {
        if(item.value === sortable[index][0]){
          orderedArray.push(sortable[index])
        }

      }
      return "checked";
    })

    this.copiedStatus = orderedArray.reduce((result, [key, value]) => {
      result[key] = value;
      return result;
    }, {});

    this.setState({
      data: this.data,
      status: this.copiedStatus,
      loadall: this.state.loadall,
      noDeviceData: this.noData,
      sendData: this.status,
      sendIcons: this.modIcons,
      sendNoData: this.noData
    })
  }


  async componentDidMount(){

    window.scrollTo(0, 0)

    Auth.currentAuthenticatedUser({
      bypassCache: false
      }).then(user => {
        this.loggedIn = true
      }
    )
    .catch(error => {
      if(error === 'not authenticated'){
        this.props.history.push('/SignIn');
      }
    }
    );

    let user = await Auth.currentAuthenticatedUser()
    this.userName = user.username

    let queryName = '/items/'+this.local
    let noteQuery = this.local

    this.interval = setInterval(this.refreshPage, 30000);

    this.data = await API.get('dataqueryapi', queryName);

    if(this.data.length === 0){
      this.noData = true;
    } else {
      this.noData = false;

      this.status = this.data.slice(0)[0];

      this.copiedStatus = JSON.parse(JSON.stringify(this.status));

      if(typeof this.copiedStatus["device_status"] === 'undefined'){
        this.deviceError = false;
      } else {
        this.deviceError = true;
        this.errorMessage = this.copiedStatus["device_status"];
      }

      delete this.copiedStatus["timestamps"];
      delete this.copiedStatus["groups"];
      delete this.copiedStatus["device"];
      delete this.copiedStatus["ttl"];

      //data trim for mobile viewing
      var i = Math.floor(this.data.length / 3);
      while (i--){
        this.data.splice((i + 1) * 3, 2);
      }
    }

    //Call the blog based on location to get the id
    this.blogCall = await API.graphql(graphqlOperation(queries.listEntBlogs, {
    limit: 1000,
    filter: {
        location:{
        eq: noteQuery
        }
    }
    }));

    //set the id to this.blogID
    this.blogID = this.blogCall.data.listEntBlogs.items[0].id

    const getIcons = `query GetEntIcons {
    getEntBlog(id:"${this.blogID}") {
    id
    location
    icons {
        items {
        id
        value
        lowest
        low
        high
        highest
        order
        }
    }
    }
    }`

    this.icondata = await API.graphql(graphqlOperation(getIcons));

    this.modIcons = this.icondata.data.getEntBlog.icons.items;

    this.orderedIcons = this.modIcons.sort(function(a, b) {
        return (a.order===null)-(b.order===null) || +(a.order>b.order)||-(a.order<b.order);
    });

    //clean up icons
    var testObject = this.orderedIcons.map(function(elem) {
      return {
        value: elem.value,
        order: elem.order,
      }
    });

    var sortable = [];
    //put status into an array of arrays
    for (var value in this.copiedStatus) {
        sortable.push([value, this.copiedStatus[value]]);
    };

    //map through ordered sorted icons when matched with status reading add to array
    var orderedArray = []

    testObject.map(function(item){
      for (let index = 0; index < sortable.length; ++index) {
        if(item.value === sortable[index][0]){
          orderedArray.push(sortable[index])
        }

      }
      return "checked";
    })

    this.copiedStatus = orderedArray.reduce((result, [key, value]) => {
      result[key] = value;
      return result;
    }, {});

    this.setState({
      icons: this.orderedIcons,
      status: this.copiedStatus,
      data: this.data,
      loading: false,
      intervalState: this.interval,
      sendData: this.status,
      sendIcons: this.modIcons,
      sendNoData: this.noData,
      noDeviceData: this.noData
    });

  }

  UNSAFE_componentWillUnmount() {
    //cant perform react state update on unmounted component
    clearInterval(this.state.intervalState);
    this.setState = (state,callback)=>{
      return;
    };
  }


  render() {

    var backIcon = require('../Back-01.png');
    var settingsIcon = require('../Settings-01.png')
    var imageCheck = require('../Nowledge-App-Icons-CHECK.png')

    return (

      <div className="App">
        {
          <div className="Nav-header">
            {
            <Link style={{textDecoration: 'none'}} to="/EntHome">
              <button onClick={this.stopInterval} className="Back-wrap">
                <img className="Back-button" src={backIcon} alt="back"/>
              </button>
            </Link>
            }
            <Link style={{ textDecoration: 'none' }} to={{ pathname: '/DeviceSettings', state: { loc: this.local, title: this.title, version: this.version } }}>
              <button onClick={this.stopInterval} className="Settings-button">
                <img className="Settings-icon" src={settingsIcon} alt="settings" />
              </button>
            </Link>
          </div>
        }

        {
          <button className="Local-button">
          <div>{this.title}</div>
          </button>
        }

        <Status data={this.state.sendData} icons={this.state.sendIcons} noData={this.state.sendNoData}/>

        {!this.state.loading && !this.state.noDeviceData && !this.deviceError &&
        <div className="Time-wrapper">
          <br />
          <button type="button" className="Time-box" onClick={(e) => this.timeLine(this.click = 'week')} style={{backgroundColor:this.state.timeColor1}}>
            <span role="img" aria-label="check">week</span>
          </button>
          <button type="button" className="Time-box2" onClick={(e) => this.timeLine(this.click = 'month')} style={{backgroundColor:this.state.timeColor2}}>
              <span role="img" aria-label="check">month</span>
          </button>
        </div>
        }

        {this.state.graphLoading &&
          <div>
            <br />
            <div className='title-two'>Loading....</div>
          </div>
        }
        <Graphs data={this.state.data} status={this.state.status} />

        <div className="Device-wrapper">
        {this.state.docsOrHide && !this.state.loading &&
          <div>
            <img className="notesdoc-icon" src={imageCheck} alt="check" />
            <button className="notesdoc-link" onClick={(e) => this.getDocs()}>documents</button>
          </div>
        }
        {!this.state.docsOrHide && !this.state.loading &&
          <div>
            <button className="notesdocopen-link" onClick={(e) => this.hideDocs()}>documents</button>
          </div>
        }
        </div>
        {this.state.addDoc && !this.state.addDocHide &&
          <button className="add-link" onClick={(e) => this.getDocForm()}>add</button>
        }
        {this.state.addDoc && this.state.addDocHide &&
          <button className="add-link" onClick={(e) => this.getDocForm()}>hide</button>
        }
        {this.state.addDocForm &&
          <EntDocumentSubmit blogID={this.blogID} userName={this.userName} title={this.title} getDocs={this.getDocs}/>
        }

        {
          <Documents documents={this.state.docs} noDocs={this.state.noDocs}/>
        }

        <div className="Space-div2" />
        <br />

        <div className="Device-wrapper">
        {!this.state.showCSV && !this.state.loading &&
          <div>
            <img className="notesdoc-icon" src={imageCheck} alt="check" />
            <button className="notesdoc-link" onClick={(e) => this.getCSV()}>csv</button>
          </div>
        }
        {this.state.showCSV && !this.state.loading &&
          <div>
            <button className="notesdocopen-link" onClick={(e) => this.getCSV()}>csv</button>
          </div>
        }
        </div>
        {this.state.showCSV &&
          <div>       
            <Link style={{ textDecoration: 'none' }} to={{ pathname: '/EntCustomCSV', state: { loc: this.local, title: this.title, version: this.version } }}>
              <button onClick={this.stopInterval} className="add-link">
                custom
              </button>
            </Link>
            <DownloadCSV csvSerial={this.local}/>
          </div>
        }

        <div className="Space-div2" />
        <br />

        <div className="Device-wrapper">
        {this.state.notesOrHide && !this.state.loading &&
          <div>
            <img className="notesdoc-icon" src={imageCheck} alt="check" />
            <button className="notesdoc-link" onClick={(e) => this.getNotes()}>notes</button>
          </div>
        }
        {!this.state.notesOrHide && !this.state.loading &&
          <div>
            <button className="notesdocopen-link" onClick={(e) => this.hideNotes()}>notes</button>
          </div>
        }
        </div>
        {this.state.noteHide && !this.state.loading && this.state.noteSection &&
          <button className="add-link" onClick={(e) => this.noteForm()}>add</button>
        }
        {!this.state.noteHide && !this.state.loading && this.state.noteSection &&
          <button className="add-link" onClick={(e) => this.noteForm()}>hide</button>
        }
        {!this.state.noteHide &&
          <div>
          <EntNoteSubmit blogID={this.blogID} userName={this.userName} serial={this.local} getNotes={this.getNotes}/>
          </div>
        }
        {this.state.noNotes &&
          <div className="title-two">no notes posted</div>
        }
        {
          <Notes notes={this.state.notes} noNotes={this.state.noNotes}/>
        }
        { this.state.loadall && !this.state.loading && this.state.noteSection &&
          <button onClick={this.handleShowAll} className="add-link">
          <div>show all</div>
          </button>
        }
        <div className = "div-height" />
      </div>
    );
  }
}

export default Device
