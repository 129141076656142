import React, { Component } from 'react';
import { deleteEntDivsOrgs } from '../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';

class RemoveDivOrg extends Component {
    constructor(props){
        super(props)

        this.state = {
        dropDownDivs: false,
        dropDownOrgs: false,
        divisions: [],
        organizations: [],
        selectedDiv: '',
        selectedOrg: '',
        divIndex: '',
        orgIndex: ''
        }
        this.getDivisions = this.getDivisions.bind(this);
        this.hideDivisions = this.hideDivisions.bind(this);
        this.getOrganizations = this.getOrganizations.bind(this);
        this.hideOrganizations = this.hideOrganizations.bind(this);
        this.selectDiv = this.selectDiv.bind(this);
        this.selectOrg = this.selectOrg.bind(this);
        this.confirmDiv = this.confirmDiv.bind(this);
        this.confirmOrg = this.confirmOrg.bind(this);
    }

    async getDivisions(event){
        this.setState({
        dropDownDivs: true
        })
    }
    async hideDivisions(event){
        this.setState({
        dropDownDivs: false
        })
    }
    async getOrganizations(event){
        this.setState({
        dropDownOrgs: true
        })
    }
    async hideOrganizations(event){
        this.setState({
        dropDownOrgs: false
        })
    }
    async selectDiv(divID, index){
        this.setState({
        selectedDiv: divID,
        divIndex: index
        })
    }
    async selectOrg(orgID, index){
        this.setState({
        selectedOrg: orgID,
        orgIndex: index
        })
    }

    async confirmDiv(divID, yesNo){
        if(yesNo === 'yes'){
            try{
                API.graphql(graphqlOperation(deleteEntDivsOrgs, { input: { id: divID }}));
                let newDivArray = this.state.divisions.filter(div => div.id !== divID)
                this.setState({
                    selectedDiv: '',
                    divIndex: '',
                    divisions: newDivArray
                })
                alert('division successfully removed')
            } catch(error) {
                alert('there was an error attempting to delete the division')
            }
        } else {
            this.setState({
                selectedDiv: '',
                divIndex: ''
            })
        }
    }

    async confirmOrg(orgID, yesNo){
        if(yesNo === 'yes'){
            try{
                API.graphql(graphqlOperation(deleteEntDivsOrgs, { input: { id: orgID }}));
                let newOrgArray = this.state.organizations.filter(org => org.id !== orgID)
                this.setState({
                    selectedOrg: '',
                    orgIndex: '',
                    organizations: newOrgArray
                })
                alert('organization successfully removed')
            } catch(error) {
                alert('there was an error attempting to delete the organization')
            }
        } else {
            this.setState({
                selectedOrg: '',
                orgIndex: ''
            })
        }
    }

    async UNSAFE_componentWillReceiveProps(nextProps) {
        let receivedDivisions = nextProps.divisions
        let receivedOrganizations = nextProps.organizations
        let receivedNoDivs = nextProps.noDivisions

        await this.setState({
            divisions: receivedDivisions,
            organizations: receivedOrganizations,
            noDivisions: receivedNoDivs
        })
    }

    render(){
        return(
            <div>
                <div className='One-space'/>
                {!this.state.dropDownDivs && !this.state.noDivisions &&
                    <button className="signup-link" onClick={(e) => this.getDivisions()}>remove division</button>
                }
                {this.state.dropDownDivs && !this.state.noDivisions &&
                    <button className="signup-link" onClick={(e) => this.hideDivisions()}>hide</button>
                }
                {this.state.dropDownDivs && !this.state.noDivisions &&
                    this.state.divisions.map((div, index) => (
                        <div key={index}>
                        {this.state.divIndex !== index &&
                            <div>
                                <div className='One-space'/>
                                <div className="signup-link" onClick={() => this.selectDiv(div.id, index)}>{div.divName}</div>
                                <div className='One-space'/>
                            </div>
                        }
                        {this.state.divIndex === index &&
                            <div className="Settings-wrapper">
                                <div className='One-space'/>
                                <div className="form-name">Are you sure you want to delete the following division?</div>
                                <div className="Title">{div.divName}</div>
                                <div className="confirm-link" onClick={() => this.confirmDiv(div.id, 'no')}>no</div>
                                <div className="confirm-link" onClick={() => this.confirmDiv(div.id, 'yes')}>yes</div>
                                <div className='One-space'/>
                            </div>
                        }
                        </div>
                    ))
                }
                <div className='One-space'/>
                {!this.state.dropDownOrgs &&
                    <button className="signup-link" onClick={(e) => this.getOrganizations()}>remove organization</button>
                }
                {this.state.dropDownOrgs &&
                    <button className="signup-link" onClick={(e) => this.hideOrganizations()}>hide</button>
                }
                {this.state.dropDownOrgs &&
                    this.state.organizations.map((org, index) => (
                        <div key={index}>
                        <div>
                            <div className='One-space'/>
                            <div className="signup-link" key={index} onClick={() => this.selectOrg(org.id, index)}>{org.orgName}</div>
                            <div className='One-space'/>
                        </div>
                        {this.state.orgIndex === index &&
                            <div className="Settings-wrapper">
                                <div className='One-space'/>
                                <div className="form-name">Are you sure you want to delete the following organization?</div>
                                <div className="Title">{org.orgName}</div>
                                <div className="confirm-link" onClick={() => this.confirmOrg(org.id, 'no')}>no</div>
                                <div className="confirm-link" onClick={() => this.confirmOrg(org.id, 'yes')}>yes</div>
                                <div className='One-space'/>
                            </div>
                        }
                        </div>
                    ))
                }
                <div className='Space-div'/>
            </div>
        )
    }

}


export default RemoveDivOrg
