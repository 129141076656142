import React, { Component } from 'react';
import { API } from 'aws-amplify';

class EmailSignup extends Component {

  constructor(props){
    super(props);

    this.state = {
      serial: props.serial,
      email1: '',
      email2: ''
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmitEmail = this.handleSubmitEmail.bind(this);
  }

  handleChange(event) {
      this.setState({
        [event.target.name]: event.target.value
      });
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {

    let serialReceived = nextProps.serial

    await this.setState({
      serial: serialReceived
    })

  }

  async handleSubmitEmail(event) {
      event.preventDefault();

      if(this.state.email1 !== this.state.email2){
        alert("emails do not match")
        return
      }

      let myInit = {
        body: {
          email: this.state.email1,
          serial: this.state.serial
        }
      }

      try{
        await API.put('SubscribeEmailAPI', '/subscription', myInit);
      } catch(error){
        console.log(error)
        alert('Error signing up for email alerts. Make sure you are on nowledge version 3.9.8 or above (Click the menu button from your homescreen and look at the bottom of the page.)')
        return
      }

      alert('confirmation email sent to ' + this.state.email1 + ' NOTE...activate email alerts must be selected and configured in the device programming to recieve emails');

      this.setState({
        email1: '',
        email2: ''
      })

  }

  render(){

    return (
      <div>
        {
          <form onSubmit={this.handleSubmitEmail}>
            <input type="string" value={this.state.email1} name="email1" placeholder="Email" onChange={this.handleChange} className="Email-area" />
            <input type="string" value={this.state.email2} name="email2" placeholder="Confirm Email" onChange={this.handleChange} className="Email-area" />
            <br/>
            <br/>
            <div>
            <input type="submit" className="Add-button" value="add"/>
            </div>
          </form>
        }
      </div>
    )

  }
}

export default EmailSignup
