import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as queries from '../graphql/queries.js';
import { createEntBlog, createEntInventory } from '../graphql/mutations';

class EntOnboard extends Component {

    constructor(props){
        super(props)

        this.state = {
        serial: '',
        loading: false,
        selectEntDrop: false,
        entList: [],
        selectedEnt: '',
        }
        this.handleChange = this.handleChange.bind(this);
        this.selectEnt = this.selectEnt.bind(this);
        this.removeEnt = this.removeEnt.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    async selectEnt(event){
        this.setState({
            selectEntDrop: true
        })

        try{
            let data = await API.graphql(graphqlOperation(queries.listEnts, { limit: 10000 }));
            let orderedEnts = data.data.listEnts.items.sort((a, b) => a.entName.localeCompare(b.entName))

            this.setState({
                entList: orderedEnts
            })
        } catch(error) {
            alert('there was an error selecting a group please try again')
        }
    }

    async addEnt(entName){
        this.setState({
            selectedEnt: entName,
            entList: []
        })
    }

    async removeEnt(event){
        this.setState({
            selectedEnt: '',
            selectEntDrop: false
        })
    }

    async handleSubmit(event) {
        if(this.state.serial === ''){
            alert('must include a serial number to onboard')
            return
        }

        if(this.state.selectedEnt === ''){
            alert('must select an enteprise to onboard')
            return
        }

        this.setState({loading: true});

        this.serialCheck = this.state.serial;

        
        // check inventory to confirm the device by that serial number exists
        // and that the software has been loaded
        const apiNameCheck = 'InventoryCheck';
        const pathCheck = '/items';

        const myInitCheck = {
            queryStringParameters: {
                name: this.serialCheck
            }
        };

        try{
            this.checkedInventory = await API.get(apiNameCheck, pathCheck, myInitCheck);
            this.checkInventory = 'success'
        } catch(error) {
            alert('an error occurred accessing the inventory database please try again')
        }
        
        if(this.checkedInventory.status === 'fail'){
            alert('an error occurred accessing the inventory database please try again')
            return
        }
        if(this.checkedInventory.status === 'no device'){
            alert('the device was not found in inventory check that you have the correct serial number and try again')
            return
        }
        

        let createdDate = ''
        let onboardCheck = ''
        
        if(this.checkedInventory.status === 'success'){
            // set to send on the mutation to inventory
            createdDate = this.checkedInventory.return.createdAt.S;
            onboardCheck = this.checkedInventory.return.onboard.S;
        }
        // onboard should always be false at this point
        if(onboardCheck === 'true'){
            alert("device is already onboarded")
            this.setState({loading:false});
            return
        };

        // onboard device lambda
        let serialFinal = this.state.serial;

        function addDevice() {
            const apiName = 'OnboardDevice';
            const path = '/device';

            const myInit = {
                body: {
                    serial: serialFinal
                }
            };
            return API.put(apiName, path, myInit);
        }

        let response = {}
        try{
            response = await addDevice();
        } catch(error) {
            alert('error creating resources for this device')
        }
        let errorCheck = response.error

        if(errorCheck === "true"){
            this.setState({loading: false});
            alert("error occurred creating resources please try again")
            return
        }
        
        let sendSerial = this.state.serial
        let sendEnt = this.state.selectedEnt

        const addEntUnit = `mutation CreateEntUnit {
            createEntUnit(input: {
                serial: "${sendSerial}"
                entName: "${sendEnt}"
                nickName: "null"
                divName: "null"
                orgName: "null"
                entUnitEntId: "${sendEnt}"
            }) {
                id
            }
            }`

        //add unit if not found in User db
        try {
            await API.graphql(graphqlOperation(addEntUnit));
        } catch(error) {
            console.log('error adding unit to system')
        };

        // create entry in parent db for icons/notes/docs
        await API.graphql(graphqlOperation(createEntBlog, { input: { location: sendSerial }}));

        // get current time to use as onboard time in inventory db
        let timeNowUnix = Date.now();
        let timeNow = new Date(timeNowUnix);
        let timeNowIso = timeNow.toISOString();

        let serialDb = this.state.serial;
        let titleDb = 'enterprise'
        let groupSubmit = this.state.selectedEnt;

        // keep group name as none in inventory database no empty string
        if(groupSubmit === ''){
            groupSubmit = 'none'
        }

        
        // update inventory database with serial/group name/onboard time/ nickname
        function updateInventory() {
            const apiName2 = 'DeviceInventory';
            const path2 = '/info';

            const myInit2 = {
                body: {
                    serial: serialDb,
                    certificates: "true",
                    createdAt: createdDate,
                    group: groupSubmit,
                    onboard: "true",
                    onboardedAt: timeNowIso,
                    title: titleDb,
                }
            };

            return API.put(apiName2, path2, myInit2);
        }

        try{
            await updateInventory();
        } catch(error) {
            console.log(error)
        }
        
        try{
            await API.graphql(graphqlOperation(createEntInventory, { input: { serial: serialDb, entName: groupSubmit, divName: 'null', orgName: 'null', onboardedAt: 'null' }}));
        } catch(error){
            console.log('error updating enterprise inventory')
        }

        this.setState({
            loading: false,
            serial: ''
        });
        alert('enterprise device has been onboarded')
    }

    async componentDidMount(){
        Auth.currentAuthenticatedUser({
            bypassCache: false
        }).then(user => {
            this.loggedIn = true
        }
        )
        .catch(error => {
            if(error === 'not authenticated'){
                this.props.history.push('/SignIn');
            }
        }
        );
    }
    render() {
        var backIcon = require('../Back-01.png');

        return(
            <div className="App">
                {
                    <div className="Nav-header">
                        <Link style={{textDecoration: 'none'}} to="/Enterprise">
                        <button className="Back-wrap">
                            <img className="Back-button" src={backIcon} alt="back"/>
                        </button>
                        </Link>
                    </div>
                }

                {
                    <div className="Title">Onboard Enterprise Device</div>
                }

                {
                    <div className="Settings-wrapper">
                        { this.state.loading &&
                            <div>
                                <div className='One-space'/>
                                <div className="Loading-inbanner">creating resources...</div>
                                <div className='One-space'/>
                            </div>
                        }

                        {!this.state.selectEntDrop &&
                            <div>
                                <button className="resend-link" onClick={this.selectEnt}>select enterprise</button>
                                <div className='One-space'/>
                            </div>
                        }

                        {this.state.selectEntDrop && this.state.selectedEnt === '' &&
                            <div>
                                <Link style={{ textDecoration: 'none' }} to={{ pathname: '/Enterprise' }}>
                                    <button className="resend-link" >ADD ENTERPRISE</button>
                                </Link>
                            </div>
                        }
                        {
                            this.state.entList.map((ent, index) => (
                                <div className="resend-link" key={index} onClick={() => this.addEnt(ent.entName, index)}>{ent.entName}</div>
                            ))
                        }

                        {this.state.selectedEnt !== '' && !this.state.loading &&
                            <div>
                                <button className="resend-link" onClick={this.removeEnt}>remove</button>
                                <div className='One-space'/>
                                <div className="title-two">{this.state.selectedEnt}</div>
                                <div className='One-space'/>
                            </div>
                        }

                        { !this.state.loading &&
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-name">serial number</div>
                                <input value={this.state.serial} name="serial" className="Reading-name" type="text" onChange={this.handleChange}/>
                                <div className='One-space'/>
                                <input type="submit" className="Icon-submit" value="submit"/>
                            </form>
                        }
                    </div>
                }
            <div className='One-space'/>
        </div>
        )
    }
}

export default EntOnboard
