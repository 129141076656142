import React, { Component } from 'react';
import { API, graphqlOperation, Storage } from 'aws-amplify';

class NoteSubmit extends Component {

    constructor(props){
        super(props);

        let receivedBlogID = props.blogID
        this.userName = props.userName
        this.local = props.serial

        this.state = {
            blogID: receivedBlogID,
            uploadingImg: false,
            noteHide: false,
            uploadImgPercent: '0%',
            filePreview: null,
            value: '',
            inputKey: Date.now(),
            bgColor1: '#529857',
            bgColor2: '#3c3c3c',
            bgColor3: '#3c3c3c',
            submitButton: true,
        }

        this.imgFile = "";
        this.imgName = 'null';
        this.noteStatus = "low";

        this.putImgSubmit = this.putImgSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.selectImage = this.selectImage.bind(this);
    }

    handleChange(event) {
        this.setState({value: event.target.value});
    }

    async selectImage(evt){
        let typeCheck = evt.target.files[0].type

        if(typeCheck === 'application/pdf'){
            this.imgFile = ""
            this.setState({
                filePreview: null
            })
            document.getElementById("selectedImage").value = ""
            alert('a pdf can not be posted as a note image but can be added to the documents section or you can screenshot the pdf and try again')
            return
        }
        if((typeCheck === 'image/jpeg') || (typeCheck === 'image/png') || (typeCheck === 'image/gif')){
            this.setState({
                filePreview: URL.createObjectURL(evt.target.files[0])
            });      
            this.imgFile = evt.target.files[0];
        } else {
            this.imgFile = ""
            this.setState({
                filePreview: null
            })
            document.getElementById("selectedImage").value = ""
            alert('only image files may be selected for notes...JPEG, PNG, GIF. all other files can be added to the documents section')
            return
        }
    }

  //status button clicks
    statusSelect() {
        this.noteStatus = this.click

        if(this.noteStatus === "low"){
            this.setState({
                bgColor1: '#529857',
                bgColor2: '#3c3c3c',
                bgColor3: '#3c3c3c'
            })
        }
        if(this.noteStatus === "middle"){
            this.setState({
                bgColor1: '#3c3c3c',
                bgColor2: '#529857',
                bgColor3: '#3c3c3c'
            })
        }
        if(this.noteStatus === "high"){
            this.setState({
                bgColor1: '#3c3c3c',
                bgColor2: '#3c3c3c',
                bgColor3: '#529857'
            })
        }
        
        return(
            this.noteStatus
        )
    }

    async putImgSubmit(evt){
        evt.preventDefault();

        if(this.state.value === "" && this.imgFile === ""){
            alert("cannot post empty notes")
            return
        };

        this.setState({
            submitButton: false,
            uploadingImg: true
        });

        if(this.imgFile !== ''){
            let today = new Date();
            let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
            let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds() + ":" + today.getMilliseconds();
            let dateTime = date+"T"+time;
            this.imgName = this.local + dateTime;

            const forceState2 = this;

            try {
                await Storage.put(this.imgName, this.imgFile, {
                    progressCallback(progress) {
                        let percent = progress.loaded / progress.total * 100
                        let rounded = Math.round(percent);
                        let percentView = `${rounded}%`
                        forceState2.setState({
                            uploadImgPercent: percentView
                        });
                    },
                });
            } catch(error) {
                alert('error posting image')
                return
            }
        };

        this.lineBreak = this.state.value.replace(/\n/g, "<br>");

        const addNote = `mutation CreatePost {
            createPost(input: {
                author: "${this.userName}"
                content:"${this.lineBreak}"
                status: "${this.noteStatus}"
                postBlogId: "${this.state.blogID}"
                img: "${this.imgName}"
            }) {
                id
                content
                status
            }
        }`

        try {
            this.addNoteResults = await API.graphql(graphqlOperation(addNote));
        } catch(error) {
            alert('error posting note please try again')
            return
        }

        this.stuckStatus = this.noteStatus;
        this.stuckValue = this.state.value;
        this.noteStatus = 'low';

        this.props.getNotes();

        this.imgName = 'null';
        this.imgFile = "";

        this.setState({
            value: '',
            file: null,
            inputKey: Date.now(),
            submitButton: true,
            filePreview: null,
            bgColor1: '#529857',
            bgColor2: '#3c3c3c',
            bgColor3: '#3c3c3c',
            uploadingImg: false,
            uploadImgPercent: '0%',
        })

        alert("note posted successfully");
    }


    render(){
        var imageCheck = require('../Nowledge-App-Icons-CHECK.png')
        var imageCaution = require('../Nowledge-App-Icons-CAUTION.png')
        var imageStop = require('../Nowledge-App-Icons-STOP.png')

        return(
            <div>
                { this.state.uploadingImg &&
                    <div>
                        <div className="Loading-button">Uploading...</div>
                        <div className="Loading-button">{this.state.uploadImgPercent}</div>
                    </div>
                }
                {!this.state.noteHide &&
                    <div>
                        <form className ="Submit-form" onSubmit={this.putImgSubmit}>
                            <label>
                                <img src={this.state.filePreview} style={{width: '25%'}} alt=""/>
                                <textarea value={this.state.value} placeholder="Enter your note here...." onChange={this.handleChange} className="Text-area" />
                            </label>
                            <input type="file" id="selectedImage" onChange={this.selectImage} className="File-upload" key={this.state.inputKey}/>
                            {
                                <div className='Time-wrapper' style={{marginTop: '10px'}}>
                                    <button type="button" className="Graph-time" onClick={(e) => this.statusSelect(this.click = 'middle')} style={{backgroundColor:this.state.bgColor2}}>
                                        <img className="Emoji1" src={imageCaution} alt="caution" />
                                    </button>
                                    <button type="button" className="Graph-time" onClick={(e) => this.statusSelect(this.click = 'low')} style={{backgroundColor:this.state.bgColor1}}>
                                        <img className="Emoji1" src={imageCheck} alt="check" />
                                    </button>
                                    <button type="button" className="Graph-time" onClick={(e) => this.statusSelect(this.click = 'high')} style={{backgroundColor:this.state.bgColor3}}>
                                        <img className="Emoji1" src={imageStop} alt="stop"/>
                                    </button>
                                </div>
                            }
                            <div className='One-space'/>
                            {this.state.submitButton === true &&
                                <input type="submit" value="submit" className="Add-button" />
                            }
                            <div className='One-space'/>
                        </form>
                    </div>
                }
            </div>
        )
    }
}

export default NoteSubmit
