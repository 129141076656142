import React, { Component } from 'react';
import {PDFDownloadLink } from '@react-pdf/renderer';
import { PDFDocument } from './NotesDownloadDocument';
import { PDFDocumentPrint } from './NotesDownloadDocumentPrint';
import { Auth } from 'aws-amplify';

class NotesDownload extends Component {

    constructor(props){
        super(props);
        this.org = props.org
        this.nickname = props.deviceName
        this.fullNotes = props.notes
        this.selectedNotes = props.selectedNotes
        this.allNotes = props.allNotes
        this.printable = props.printable
        
        this.state = {
            loading: true,
            fileName: ''
        }
      }

    // change utc to device local time
    renderNoteTime(time){
        let utcDate = time;
        let localDate = new Date(utcDate);

        let year = localDate.getFullYear()
        let month = localDate.getMonth() + 1
    
        return({
            year: year, month: month
        });
    };

    async componentDidMount(){
        let finalNotes = []
        let fileName = `${this.org}_${this.nickname}_Notes`
        let titlePageNotes = []

        try{
            let user = await Auth.currentAuthenticatedUser()
            this.userName = user.username
        } catch(error) {
            console.log(error)
            this.userName = ''
        }

        let dateNow = new Date();
        let year = dateNow.getFullYear()
        let month = dateNow.getMonth()+1
        let day = dateNow.getDate()
        this.finalCurrentDate = `${month}/${day}/${year}`

        let sortedNotesTitle = this.selectedNotes.sort((a, b) => parseFloat(b.year) - parseFloat(a.year))
        let finalTitleIncludedNotes = []
        sortedNotesTitle.forEach(note => {
            note.months.sort((a, b) => parseFloat(a) - parseFloat(b))
        })

        sortedNotesTitle.forEach(note => {
            let newMonths = []
            note.months.forEach(months => {
                let monthsText = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
                if(months === `${note.year}all`){
                    newMonths.push(months)
                } else {
                    newMonths.push(monthsText[months -1])
                }
                
            })

            finalTitleIncludedNotes.push({year: note.year, months: newMonths})

        })

        if(this.allNotes){
            titlePageNotes.push('All available notes')
        } else {
            finalTitleIncludedNotes.forEach(note => {
                
                if(note.months[0] === `${note.year}all`){
                    titlePageNotes.push(`${note.year}: All months available`)
                } else {
                    titlePageNotes.push(`${note.year}: ${note.months.join(', ')}`)
                }
                              
            })
        }

        if(this.allNotes){
            finalNotes = this.fullNotes
        } else {
            this.fullNotes.forEach(note => {
                let timestamp = this.renderNoteTime(note.createdAt)
                this.selectedNotes.forEach(noteHeld => {
                    if(timestamp.year === noteHeld.year){
                        if(noteHeld.months[0] === `${timestamp.year}all` || noteHeld.months.includes(timestamp.month)){
                            finalNotes.push(note)
                        } 
                    }
                })
            });
            
        }
      
        this.setState({
            fileName: fileName,
            loading: false,
            finalNotes: finalNotes,
            includedNotes: titlePageNotes
        })
    }


    render(){

        return(
            <div className="App">
                {!this.state.loading && !this.printable &&
                    <PDFDownloadLink document={<PDFDocument data={this.state.finalNotes} org={this.org} deviceName={this.nickname} currentDate={this.finalCurrentDate} userName={this.userName} includedNotes={this.state.includedNotes}/>} fileName={this.state.fileName}>
                        {({ blob, url, loading, error }) =>
                            loading ? <div className="Loading-noback">creating....</div> : <button className='Add-button'>download</button>                       
                        }
                    </PDFDownloadLink>
                }
                {!this.state.loading && this.printable &&
                    <PDFDownloadLink document={<PDFDocumentPrint data={this.state.finalNotes} org={this.org} deviceName={this.nickname} currentDate={this.finalCurrentDate} userName={this.userName} includedNotes={this.state.includedNotes}/>} fileName={this.state.fileName}>
                        {({ blob, url, loading, error }) =>
                            loading ? <div className="Loading-noback">creating....</div> : <button className='Add-button'>download</button>                       
                        }
                    </PDFDownloadLink>
                }
            </div>
        )
    }

}

export default NotesDownload