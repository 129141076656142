import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class EntSettings extends Component {

    constructor(props){
        super(props)

        this.state = {
        group: '',
        userName: ''
        }
    }

    async UNSAFE_componentWillReceiveProps(nextProps) {
        let receivedGroup = nextProps.group
        let receivedUserName = nextProps.userName

        this.setState({
            group: receivedGroup,
            userName: receivedUserName
        })
    }

    render() {
        return(
            <div className="App">
                {
                    <div className="Title">Main Settings</div>
                }
                {
                    <div className="Settings-wrapper">
                        <div className='One-space'/>
                        <div className="title-two">Onboard Device</div>
                        <Link style={{ textDecoration: 'none' }} to={{ pathname: '/EntUserOnboard' }}>
                            <button className="resend-link">add</button>
                        </Link>
                        <div className='One-space'/>
                    </div>
                }

                {
                    <div>
                        <div className='One-space'/>
                        <div className="title-two">Create or Remove Division or Organization</div>
                        <Link style={{ textDecoration: 'none' }} to={{ pathname: '/EntAddDivOrg' }}>
                            <button className="signup-link" >enter</button>
                        </Link>
                    </div>
                }

                {
                    <div>
                        <div className='One-space'/>
                        <div className="title-two">Get Division or Organization ID to invite a user</div>
                        <Link style={{ textDecoration: 'none' }} to={{ pathname: '/UserInvite' }}>
                            <button className="signup-link">get id</button>
                        </Link>
                    </div>
                }
                {
                    <div>
                        <div className='One-space'/>
                        <div className="title-two">Device Inventory</div>
                        <Link style={{ textDecoration: 'none' }} to={{ pathname: '/EntInventory' }}>
                            <button className="signup-link" >enter</button>
                        </Link>
                    </div>
                }

                {
                    <div>
                        <div className='One-space'/>
                        <div className="title-two">Want to change your password?</div>
                        <Link style={{ textDecoration: 'none' }} to={{ pathname: '/EntChangePass' }}>
                            <button className="signup-link">change</button>
                        </Link>
                    </div>
                }
                <div className = "div-height" />
                <div className="version-number">version 3.3.4</div>
                <div className='Two-Space'/>
            </div>
        )
    }
}

export default EntSettings
