import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';


class SignUp extends Component {

  constructor(props){
    super(props)

    this.state = {
      username: '',
      password: '',
      confirmPassword: '',
      emailAddress: '',
      confirmEmail: '',
      code: '',
      rememberDevice: true,
      loading: false,
      created: false,
      verifyEmail: false
     }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);

  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  async handleSubmit(event) {
    event.preventDefault();

    let username = this.state.username.toLowerCase();
    let password = this.state.password;
    let email = this.state.emailAddress;

    if(this.state.username === '' || this.state.password === '' || this.state.confirmPassword === '' || this.state.emailAddress === '' || this.state.confirmEmail === ''){
      alert('Please fill in the empty fields.')
      return
    }

    if(username.indexOf(' ') >= 0){
      alert('Spaces are not allowed within the username.')
      return
    }

    if(password.indexOf(' ') >= 0){
      alert('Spaces are not allowed within the password.')
      return
    }

    if(email.indexOf(' ') >= 0){
      alert('Spaces are not allowed within the email.')
      return
    }

    if(this.state.password !== this.state.confirmPassword){
      alert("Passwords do not match.")
      return
    }

    if(this.state.emailAddress !== this.state.confirmEmail){
      alert("Emails do not match.")
      return
    }

    this.setState({loading: true})


    try {
      await Auth.signUp({
          username,
          password,
          attributes: {
              email
          }
      });

        this.setState({
          loading: false,
          created: true
        })

        alert("A verification code was sent to your email (6 digits).")


      } catch (error) {
          if(error.name === "UsernameExistsException"){
            this.setState({loading: false})
            alert("Username already in use. If you are the owner and created a username without completing the onboarding process go to SIGN IN to continue, NOT SIGN UP.")
          }
          if(error.name === "InvalidPasswordException"){
            this.setState({loading: false})
            alert("Passwords must be 8 characters long and include an uppercase letter, a lowercase letter, a number and a symbol.")
          }
      }


  }

  async handleConfirm(event) {
    event.preventDefault();

    this.setState({verifyEmail: true})

    let username = this.state.username.toLowerCase();
    let code = this.state.code.replace(/\s+/g, '');


    try {
        await Auth.confirmSignUp(username, code)

        try {
          await Auth.signIn(this.state.username.toLowerCase(), this.state.password);

        } catch (error) {
            if(error.code === 'UserNotFoundException'){
              alert('Username not found.')
              return
            }
            if(error.code === 'NotAuthorizedException'){
              alert('Incorrect password.')
              return
            }
            if(error.code === 'UserNotConfirmedException'){
              alert('Account email has not been verified. Please click resend verification code and enter the six digit code')
              this.props.history.push('/Verify');
            }
        }


        this.props.history.push('/Authenticate')
      } catch (error) {
          console.log(error)
          if(error.code === 'CodeMismatchException'){
            alert('Code does not match please try again. Click resend code if a new code is needed.')
            this.props.history.push('/Verify')
          } else {
            alert('Error verifying email address.')
            this.props.history.push('/Verify')
          }

      }
      this.setState({verifyEmail: false})
    }

  render(){
    var backIcon = require('../Back-01.png');

    return(
      <div className="App">

        <div className="Nav-header">
          <Link style={{textDecoration: 'none'}} to="/SignIn">
            <button className="Back-wrap">
              <img className="Back-button" src={backIcon} alt="back"/>
            </button>
          </Link>
        </div>


        <div className="Title">Create an Account</div>

        {this.state.loading &&
          <div className="Settings-wrapper">
            <br />
            <div className="Loading-buttonstatus">Creating Account...</div>
            <br />
          </div>
        }

        {this.state.created &&
          <div className="Settings-wrapper">
            <br />
            <div className="Loading-buttonstatus">Account Created Verify Email Below</div>
            <br />
          </div>
        }

        {!this.state.loading &&  !this.state.created &&
          <div className="Settings-wrapper">

            <form onSubmit={this.handleSubmit}>

              <div className="form-name">username</div>
                <input value={this.state.username} name="username" className="Reading-name" type="text" onChange={this.handleChange}/>
              <br />
              <br />
              <div className="form-name">password</div>
                <input value={this.state.password} name="password" className="Reading-name" type="password" onChange={this.handleChange}/>

              <div className="form-name">confirm password</div>
                <input value={this.state.confirmPassword} name="confirmPassword" className="Reading-name" type="password" onChange={this.handleChange}/>

              <br />
              <br />

              <div className="form-name">email address</div>
                <input value={this.state.emailAddress} name="emailAddress" className="Reading-name" type="text" onChange={this.handleChange}/>

              <div className="form-name">confirm email address</div>
                <input value={this.state.confirmEmail} name="confirmEmail" className="Reading-name" type="text" onChange={this.handleChange}/>

              <br />
              <br />

              <input type="submit" className="Add-button" value="submit"/>
            </form>

          </div>
        }
        <br />
        <br />
        {this.state.created && !this.state.verifyEmail &&
          <form onSubmit={this.handleConfirm}>
            <div className="form-name">verify email code (6 digits)</div>
            <input value={this.state.code} name="code" className="Reading-name" type="text" onChange={this.handleChange}/>
            <br />
            <br />
            <input type="submit" className="Add-button" value="submit"/>
          </form>
        }
        {this.state.created && this.state.verifyEmail &&
          <div className='Loading-noback'>verifying email....</div>
        }



      </div>
    )

  }
}

export default SignUp
