import React, { Component } from 'react';
import { CSVLink } from "react-csv";
import { API, Storage } from 'aws-amplify';

class DownloadCSV extends Component {
    constructor(props) {
        super(props)

        this.local = props.csvSerial;
        this.queryName = '/items/' + this.local

        this.data = [];
        this.icondata = [];
        this.noteStatus = '';
        this.athenaID = '';
        this.timeSet = ''
        this.timeSetAll = ''

        this.state = {
        data: [],
        bgColor1: '#3c3c3c',
        bgColor2: '#3c3c3c',
        bgColor4: '#3c3c3c',
        s3url: '',
        downloadType: true,
        serial: this.local,
        loading: false,
        clickedCheck: false,
        fetching: false
        }

        this.stopInterval = this.stopInterval.bind(this);
        this.dynamoClick = this.dynamoClick.bind(this);
        this.athenaClick = this.athenaClick.bind(this);
    }


    stopInterval(event) {
        clearInterval(this.timeSet);
        clearInterval(this.timeSetAll);
    }

    //data call for all using long term database
    async athenaClick() {
        this.noteStatus = this.click

        this.setState({
            bgColor1: '#3c3c3c',
            bgColor2: '#3c3c3c',
            bgColor4: '#529857',
            downloadType: false,
            clickedCheck: true,
            fetching: true
        })

        let myInit = {
            queryStringParameters: {
                location: this.local
            }
        }

        const apiName = 'AthenaAPI';
        const path = '/alldata';

        let expressId = ''

        try {
            expressId = await API.put(apiName, path, myInit);
        } catch (error) {
            alert('error fetching csv data')
        }

        this.athenaID = expressId.dataDownload.QueryExecutionId;

        const fileName = "athenaresults/" + this.athenaID + ".csv";

        let urlForFetch = '';

        await Storage.get(fileName, { expires: 300 })
        .then(result => {
            urlForFetch = result;
        })
        .catch(err => console.log(err));

        this.setState({
            s3url: urlForFetch
        });

        function athenaTimer() {

        fetch(urlForFetch)
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            }).then(response => {
                console.log("ok");
                this.setState({ fetching: false })
                clearInterval(this.timeSetAll);
            }).catch(error => {
                console.log("not there yet");
            });
        }
        this.timeSetAll = setInterval(athenaTimer.bind(this), 5000);
    }

  //status button clicks for week/month using short term database
    async dynamoClick() {
        this.noteStatus = this.click

        if (this.noteStatus === "week") {
            this.setState({
                bgColor1: '#529857',
                bgColor2: '#3c3c3c',
                bgColor4: '#3c3c3c',
                downloadType: true,
                clickedCheck: true,
                fetching: true
            })
            try {
                this.data = await API.get('dataqueryapi', this.queryName);
            } catch (error) {
                alert('error fetching csv data')
            }

            this.setState({
                fetching: false
            })
        }

        if (this.noteStatus === "month") {
            this.setState({
                bgColor1: '#3c3c3c',
                bgColor2: '#529857',
                bgColor4: '#3c3c3c',
                downloadType: true,
                clickedCheck: true,
                fetching: true
            })
            try {
                this.data = await API.get('monthGraphs', this.queryName);
                this.data.splice(8641)
            } catch (error) {
                alert('error fetching csv data')
            }

            this.setState({
                fetching: false
            })
        }
    }

    render() {
        return (
            <div className="App">              
                {
                    <div>
                        <div className='title-two'>select timerange</div>
                    </div>
                }
                {
                    <div className="Overviewbutton-wrapper">
                        <button type="button" className="Graph-time" onClick={(e) => this.dynamoClick(this.click = 'week')} style={{ backgroundColor: this.state.bgColor1 }}>
                            <div>week</div>
                        </button>
                        <button type="button" className="Graph-time" onClick={(e) => this.dynamoClick(this.click = 'month')} style={{ backgroundColor: this.state.bgColor2 }}>
                            <div>month</div>
                        </button>
                        <button type="button" className="Graph-time" onClick={(e) => this.athenaClick(this.click = 'all')} style={{ backgroundColor: this.state.bgColor4 }}>
                            <div>all data</div>
                        </button>
                    </div>
                }
                {this.state.fetching &&
                    <div>
                    <br />
                    <div className='title-two'>Loading....</div>
                    </div>
                }
                {this.state.downloadType && this.state.clickedCheck && !this.state.fetching &&
                    <div>
                        <div className='One-space'/>
                        <CSVLink data={this.data} filename={this.local + this.noteStatus + ".csv"} className="Add-button" style={{textDecoration: 'none', height: '40px', marginTop: '15px', marginBottom: '20px', paddingTop: '10px', paddingBottom: '10px' }}>
                            download
                        </CSVLink>
                    </div>

                }
                {!this.state.downloadType && this.state.clickedCheck && !this.state.fetching &&
                    <div>
                        <div className='One-space'/>
                        <a href={this.state.s3url} download className="Add-button" style={{ textDecoration: 'none',  height: '40px', marginTop: '15px', marginBottom: '20px', paddingTop: '10px', paddingBottom: '10px'  }}>download</a>
                    </div>    
                }
            </div>
        )
    }

}

export default DownloadCSV
