import React, { Component } from 'react';
import { API, graphqlOperation, Auth } from 'aws-amplify'
import * as queries from '../graphql/queries.js';
import { Link } from 'react-router-dom';
import EntButtons from "../components/EntButtons"

class EntHome extends Component {
    constructor(props){
        super(props)

        this.state = {
        buttonData: [],
        level: ''
        }
    }

    async componentDidMount(){
        await Auth.currentAuthenticatedUser({
            bypassCache: false
        }).then( async user => {
            const userGroup = user.signInUserSession.accessToken.payload["cognito:groups"];
            if(userGroup === undefined){
                this.props.history.push('/Authenticate')
            }
            let groupCheck = userGroup[0];

            this.setState({group: groupCheck})

            let userButtons = []
            let queryUpper = user.username
            let queryName = queryUpper.toLowerCase()

            if (groupCheck === "ent"){
                try{
                    let data = await API.graphql(graphqlOperation(queries.listEnterpriseButtonss, {
                        limit: 10000,
                        filter: {
                            user:{
                                eq: queryName
                            }
                        }
                    }));
                if(data.data.listEnterpriseButtonss.items.length === 0){
                    this.setState({
                        buttonData: userButtons,
                        level: '1'
                    })
                } else {
                    data.data.listEnterpriseButtonss.items.sort((a, b) => a.org.localeCompare(b.org, undefined, { numeric: true }))
                    userButtons = data.data.listEnterpriseButtonss.items

                    this.setState({
                        buttonData: userButtons,
                        level: '1'
                    })
                }
                } catch(error) {
                    console.log('error caught getting buttons')
                }
            }

            if (groupCheck === "div"){
                try{
                    let data = await API.graphql(graphqlOperation(queries.listDivisionButtonss, {
                        limit: 10000,
                        filter: {
                        user:{
                            eq: queryName
                        }
                        }
                    }));
                    if(data.data.listDivisionButtonss.items.length === 0){
                        this.setState({
                            buttonData: userButtons,
                            level: '2'
                        })
                    } else {
                        data.data.listDivisionButtonss.items.sort((a, b) => a.org.localeCompare(b.org, undefined, { numeric: true }))
                        userButtons = data.data.listDivisionButtonss.items

                        this.setState({
                            buttonData: userButtons,
                            level: '2'
                        })
                    }
                } catch(error) {
                    console.log('error caught getting buttons')
                }
            }

            if (groupCheck === "org"){

                let queryUpper = user.username
                let queryName = queryUpper.toLowerCase()
                try{
                    let data = await API.graphql(graphqlOperation(queries.listOrganizationButtonss, {
                        limit: 10000,
                        filter: {
                        user:{
                            eq: queryName
                        }
                        }
                    }));
                
                    if(data.data.listOrganizationButtonss.items.length === 0){
                        this.setState({
                            buttonData: userButtons,
                            level: '3'
                        })
                    } else {
                        data.data.listOrganizationButtonss.items.sort((a, b) => a.title.localeCompare(b.title, undefined, { numeric: true }))
                        userButtons = data.data.listOrganizationButtonss.items

                        this.setState({
                            buttonData: userButtons,
                            level: '3'
                        })
                    }
                } catch(error) {
                    console.log('error caught getting buttons')
                }
            }
        }
    )
        .catch(error => {
            if(error === 'not authenticated'){
                this.props.history.push('/SignIn');
            }
        }
    );
    }

    render() {
        var settingsIcon = require('../Settings-01.png')
        var imageName = require('../Nowledge-Logo_ALL-WHT.png');
        return(
            <div className="App">
                {
                    <div className="Nav-header">
                    <img className="Logo-user" src={imageName} alt="nowledge" />
                    <Link style={{ textDecoration: 'none' }} to={{ pathname: '/SettingsPage' }}>
                        <button className="Settings-button">
                        <img className="Settings-icon" src={settingsIcon} alt="settings" />
                        </button>
                    </Link>
                    </div>
                }

                <EntButtons data={this.state.buttonData} level={this.state.level}/>
                <div className='One-space'/>
            </div>
        )
  }
}

export default EntHome
