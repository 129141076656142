import React, { Component } from 'react';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as queries from '../graphql/queries.js';
import { deleteHideAlarm } from '../graphql/mutations';
import { Link } from 'react-router-dom';

class HiddenAlarms extends Component {
    constructor(props){
        super(props)

        this.state = {
            hiddenAlarmsArray: [],
            loading: true,
            loadingUpload: false,
            index: '',
            group: props.location.state.group,
            alarmsEmpty: false
        }
    }

    unHideClicked(index, alarmName){
        this.setState({
            index: index,
        })
    }

    noClicked(index){
        this.setState({
            index: ''
        })
    }

    async yesClicked(index, id){
        this.setState({
            loadingUpload: true
        })
        
        try{
            API.graphql(graphqlOperation(deleteHideAlarm, { input: { id: id }}));
            this.alarmArray.splice(index, 1)
            let emptyCheck = ''
            if(this.alarmArray.length === 0){
                emptyCheck = true
            } else {
                emptyCheck = false
            }

            this.setState({
                hiddenAlarms: this.alarmArray,
                index: '',
                alarmsEmpty: emptyCheck,
                loadingUpload: false
            })
        } catch(error){
            this.setState({
                index: '',
                loadingUpload: false
            })
            console.log(error)
            alert('Error unhiding alarm. Alarm still hidden.')
        }
        
    }

    async componentDidMount(){
        let user = await Auth.currentAuthenticatedUser();
        const userName = user.username;

        let group = await API.graphql(
            graphqlOperation(queries.getUser, { userName: userName })
        );


        this.groupName = group.data.getUser.groupName;

        const hiddenAlarms = `query GetHidden {
            getOrganizationLink(orgName: "${this.groupName}") {
                id
                    hiddenAlarms {
                        items {
                            id
                            groupName
                            readingName
                            serial
                            subGroup
                            deviceName
                        }
                    }
            }
        }`

    
        let hiddenAlarmsArray = []
        //return from Unit db all units attached to the organization
        try{
            let hiddenAlarmsFetched = await API.graphql(graphqlOperation(hiddenAlarms));
            hiddenAlarmsArray = hiddenAlarmsFetched.data.getOrganizationLink.hiddenAlarms.items
        } catch(error) {
            console.log(error)
        };

        let orderAlarmdevice = hiddenAlarmsArray.sort((a, b) => a.serial.localeCompare(b.serial, undefined, { numeric: true }))
        let orderedAlarmArray = orderAlarmdevice.sort((a, b) => a.subGroup.localeCompare(b.subGroup, undefined, { numeric: true }))

        this.alarmArray = orderedAlarmArray

        let emptyCheck = ''
        if(this.alarmArray.length === 0){
            emptyCheck = true
        } else {
            emptyCheck = false
        }

        this.setState({
            hiddenAlarms: orderedAlarmArray,
            loading: false,
            alarmsEmpty: emptyCheck
        })
    }

    render(){
        var backIcon = require('../Back-01.png');
        return(
            <div className='App'>
                {
                    <div className='Nav-header'>
                        {
                            <Link style={{ textDecoration: 'none' }} to={{pathname: '/Overview', state: { data: this.props.location.state.device, group: this.props.location.state.group} }}>
                                <button onClick={this.stopInterval} className='Back-wrap'>
                                <img className='Back-button' src={backIcon} alt='back' />
                                </button>
                            </Link>
                        }
                    </div>
                }
                {this.state.loading &&
                    <div>
                        <div className="Loading-noback">Loading....</div>
                    </div>
                }
                <div className='One-space'/>
                {!this.state.loading &&
                    this.state.hiddenAlarms.map((alarm, index) => (
                        <div>
                            <div className='One-space'></div>
                            {alarm.readingName === 'offline_hide_alarm_check' && this.state.group === 'enerstar' &&
                                <div style={{fontSize: '25px', color: 'white', fontWeight: 'bold', textAlign: 'center'}}>{alarm.subGroup} -- {alarm.deviceName} -- offline/online status</div>
                            }
                            {alarm.readingName !== 'offline_hide_alarm_check' && this.state.group === 'enerstar' &&
                                <div style={{fontSize: '25px', color: 'white', fontWeight: 'bold', textAlign: 'center'}}>{alarm.subGroup} -- {alarm.deviceName} -- {alarm.readingName}</div>
                            }
                            {alarm.readingName === 'offline_hide_alarm_check' && this.state.group === 'multiloc' &&
                                <div style={{fontSize: '25px', color: 'white', fontWeight: 'bold', textAlign: 'center'}}>{alarm.deviceName} -- offline/online status</div>
                            }
                            {alarm.readingName !== 'offline_hide_alarm_check' && this.state.group === 'multiloc' &&
                                <div style={{fontSize: '25px', color: 'white', fontWeight: 'bold', textAlign: 'center'}}>{alarm.deviceName} -- {alarm.readingName}</div>
                            }
                            {this.state.index !== index &&
                                <div>
                                    <button onClick={() => this.unHideClicked(index, alarm.name)} className="Dropdown-button-full">
                                        <div>unhide</div>
                                    </button>
                                </div>
                            }
                            {this.state.index === index &&
                                <div>
                                    {alarm.readingName === 'offline_hide_alarm_check' &&
                                        <div style={{fontSize: '20px', color: 'white', fontWeight: 'bold', marginTop: '20px', textAlign: 'center'}}>confirm unhide alarm for "offline/online status"</div>
                                    }
                                    {alarm.readingName !== 'offline_hide_alarm_check' &&
                                        <div style={{fontSize: '20px', color: 'white', fontWeight: 'bold', marginTop: '20px', textAlign: 'center'}}>confirm unhide alarm for "{alarm.readingName.replace(/_/g, ' ')}"</div>
                                    }                                   
                                    <div className='confirm-wrapper-full'>
                                        <button className="confirm-button" onClick={() => this.yesClicked(index, alarm.id)}>yes</button>
                                        <button className="confirm-button" onClick={() => this.noClicked(index)}>no</button>
                                    </div>
                                    {this.state.loadingUpload &&
                                        <div>
                                            <div className='One-space'/>
                                            <div className="Loading-buttonstatus">Loading...</div>
                                            <div className='One-space'/>
                                        </div>
                                    }                                    
                                </div>                    
                            }
                            
                            <div className='Two-space'/>
                        </div>                        
                    ))
                }
                {!this.state.loading && this.state.alarmsEmpty &&
                    <div style={{fontSize: '25px', color: 'white', fontWeight: 'bold', marginTop: '20px', textAlign: 'center'}}>No hidden alarms found</div>
                }
                
            </div>
        )
    }

}

export default HiddenAlarms