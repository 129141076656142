import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';


class SignIn extends Component {

    constructor(props){
        super(props)

        this.state = {
            username: '',
            password: '',
            rememberDevice: true,
            changePassForm: false,
            resendCode: false,
            loading: false
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        //this.handleChangePass = this.handChangePass.bind(this);

    }


    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }


    async handleSubmit(event) {
        event.preventDefault();

        if(this.state.username === ''){
            alert('please enter a username')
            return
        }

        if(this.state.password === ''){
            alert('please enter a password')
            return
        }

        if(this.state.username.indexOf(' ') >= 0){
            alert('usernames do not contain spaces please try again')
            return
        }

        if(this.state.password.indexOf(' ') >= 0){
            alert('password do not contain spaces please try again')
            return
        }

        let username = this.state.username.toLowerCase();
        let password = this.state.password;

        this.setState({loading: true})

        try {
            await Auth.signIn(username, password);
        } catch (error) {
            console.log(error)
            if(error.code === 'UserNotFoundException'){
                let upperFirst = username[0].toUpperCase() + username.slice(1)
                let indexGrab = upperFirst.indexOf("@")
                let finalName =upperFirst.slice(0, indexGrab + 1) + upperFirst[indexGrab + 1].toUpperCase() + upperFirst.slice(indexGrab + 2)

            try {
                await Auth.signIn(finalName, password);
            } catch (error) {
                if(error.code === 'UserNotFoundException'){
                    alert('username not found')
                    this.setState({loading: false})
                    return
                }
                if(error.code === 'NotAuthorizedException' && error.message === "Incorrect username or password."){
                    alert('incorrect password')
                    this.setState({loading: false})
                    return
                }
                if(error.code === 'NotAuthorizedException' && error.message === "Password attempts exceeded"){
                    alert('exceeded the password attemps please try again in a half an hour')
                    this.setState({loading: false})
                    return
                }
                if(error.code === 'UserNotConfirmedException'){
                    alert('account email has not been verified please click resend verification code and enter the six digit code')
                    this.props.history.push('/Verify');
                }
            }
            }
            if(error.code === 'NetworkError'){
                alert('network error check your internet connection')
                this.setState({loading: false})
                return
            }
            if(error.code === 'NotAuthorizedException' && error.message === "Incorrect username or password."){
                alert('incorrect password')
                this.setState({loading: false})
                return
            }
            if(error.code === 'NotAuthorizedException' && error.message === "Password attempts exceeded"){
                alert('exceeded the password attemps please try again in a half an hour')
                this.setState({loading: false})
                return
            }
            if(error.code === 'UserNotConfirmedException'){
                alert('account email has not been verified please click resend verification code and enter the six digit code')
                this.setState({loading: false})
                this.props.history.push('/Verify');
            }
        }


        Auth.currentAuthenticatedUser({
            bypassCache: false
        }).then(user => {
            const userGroup = user.signInUserSession.accessToken.payload["cognito:groups"];

            if(userGroup === undefined){
                alert('please enter your organization number to proceed using the app')
                this.setState({loading: false})
                this.props.history.push('/Authenticate')
            } else {
                this.setState({loading: false})
                this.props.history.push('/')
            }
        }
    )
        .catch(err => {
            this.setState({loading: false})
        });
    }





  render(){
    var imageName = require('../Nowledge-Logo_ALL-WHT.png')

    return(
        <div className="App">
            <div className="Nav-header">
                <img className="Logo-user" src={imageName} alt="nowledge" />
            </div>


            <div className="Title">Sign In</div>

            {this.state.loading &&
                <div className="Settings-wrapper">
                    <div className='Two-space'/>
                    <div className="Loading-buttonstatus">Signing In...</div>
                    <div className='Two-space'/>
                </div>
            }

            {!this.state.loading &&
                <div className="Settings-wrapper">
                    <form onSubmit={this.handleSubmit}>
                        <div className="form-name">username</div>
                            <input value={this.state.username} name="username" className="Reading-name" type="text" onChange={this.handleChange}/>
                        <div className="form-name">password</div>
                            <input value={this.state.password} name="password" className="Reading-name" type="password" onChange={this.handleChange}/>
                        <div className='Two-space'/>
                        <input type="submit" className="Add-button" value="submit"/>
                    </form>
                </div>
            }
            <div className='Two-space'/>
            <button className="signup-link" onClick={ () => this.props.history.push('/ForgotPass') }>forgot password</button>

            <div className="title-two">New to Nowledge?</div>
            <Link style={{ textDecoration: 'none' }} to={{ pathname: '/SignUp' }}>
                <button className="signup-link" >Sign Up</button>
            </Link>
        </div>
    )
  }
}

export default SignIn
