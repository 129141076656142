import React, { Component } from 'react';
import { API, Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';

class Inventory extends Component {

  constructor(props){
    super(props)


    this.state = {
      devices: [],
      shelved: [],
      onboarded: [],
      shelfbutton: true,
      loading: true,
      timeColor1: '#529857',
      timeColor2: '#3c3c3c'
     }

    this.inventorySelect = this.inventorySelect.bind(this);
  }


  async inventorySelect () {
    this.timeStatus = this.click

    if(this.timeStatus === "inactive"){
      this.setState({
        timeColor1: '#529857',
        timeColor2: '#3c3c3c',
        shelfbutton: true
      })
    }

    if(this.timeStatus === "active"){
      this.setState({
        timeColor1: '#3c3c3c',
        timeColor2: '#529857',
        shelfbutton: false
      })
    }

  }

  renderNoteTime(time){
    let utcDate = time;
    let localDate = new Date(utcDate);


    let todayyear = localDate.getFullYear();
    let todaymonth = (localDate.getMonth()+1);
    let todaydate = localDate.getDate();


    let date4 = todaymonth + "/" + todaydate + "/" + todayyear;


    let hour = localDate.getHours()
    let minute = localDate.getMinutes();

    if(minute < 10){
      minute = ('0' + minute).slice(-2)
    };

    let amPm = "am";
    if(hour === "12"){
      amPm = "pm"
    };
    if(hour > 12){
      hour = hour - 12
      amPm = "pm"
    };
    if(hour === "0"){
      hour = 12
    };
    let newHour = hour;

    let time1 = newHour + ":" + minute + amPm

    let dateTime1 = date4+" "+time1;


    return(
      <div>{dateTime1}</div>
    );
  };


  async componentDidMount(){

    Auth.currentAuthenticatedUser({
      bypassCache: false
      }).then(user => {
        this.loggedIn = true
      }
    )
    .catch(error => {
      if(error === 'not authenticated'){
        this.props.history.push('/SignIn');
      }
    }
    );

    function getDevices() {

      const apiName = 'DeviceInventory';
      const path = '/info';

      const myInit = {
          queryStringParameters: {
            name: 'group',
          }
      };

      return API.get(apiName, path, myInit);
    }

    let response = await getDevices();

    let devicesArray = response.devices

    let shelvedArray = [];
    let onboardedArray = [];

    devicesArray.forEach(element => {
      if(element.onboard.S === 'false'){
        shelvedArray.push(element)
      } else {
        onboardedArray.push(element)
      }
    })

    let shelvedArraySorted = shelvedArray.sort((a, b) => a.serial.S.localeCompare(b.serial.S, undefined, { numeric: true }))

    let onboardedArraySorted = onboardedArray.sort((a, b) => a.group.S.localeCompare(b.group.S, undefined, { numeric: true }))

    this.setState({
      devices: devicesArray,
      shelved: shelvedArraySorted,
      onboarded: onboardedArraySorted,
      loading: false
    });


  }


  render(){

    var backIcon = require('../Back-01.png');
    var imageCheck = require('../Nowledge-App-Icons-CHECK.png')

    return(
      <div className="App">

        {
          <div className="Nav-header">
            <Link style={{textDecoration: 'none'}} to="/MainSettings">
              <button className="Back-wrap">
                <img className="Back-button" src={backIcon} alt="back"/>
              </button>
            </Link>
          </div>
        }

        <div className="Title">Device Inventory</div>

        <div className="Status-wrapper">

          { this.state.loading &&
            <div>
              <br />
              <div className="Loading-buttonstatus">Loading</div>
              <br />
            </div>
          }

          { !this.state.loading &&
            <div className="Status-container">
            <button className="Status-button">
              <img className="Emoji-icon" src={imageCheck} alt="check" />
              <div className="Status-title">inactive</div>
              <div className="Status-reading">{this.state.shelved.length}</div>
            </button>
            <button className="Status-button">
              <img className="Emoji-icon" src={imageCheck} alt="check" />
              <div className="Status-title">active</div>
              <div className="Status-reading">{this.state.onboarded.length}</div>
            </button>
            </div>
          }

        </div>

        { !this.state.loading &&
          <div className="Button2-wrapper">
            <button type="button" className="Button2-select" onClick={(e) => this.inventorySelect(this.click = 'inactive')} style={{backgroundColor:this.state.timeColor1}}>
              <div>inactive</div>
            </button>
            <button type="button" className="Button2-select" onClick={(e) => this.inventorySelect(this.click = 'active')} style={{backgroundColor:this.state.timeColor2}}>
                <div>active</div>
            </button>
          </div>
        }


        {!this.state.loading && this.state.shelfbutton === true &&
          this.state.shelved.map((device, index) => (
            <div key={index}>
            <br/>
            <br/>
              <div className="Device-wrapper">
                <div style={{fontSize: '15px', color: '#529857'}}>Serial Number:</div>
                <div style={{fontSize: '25px', color: 'white', fontWeight: 'bold'}}>{device.serial.S}</div>
                <div style={{fontSize: '15px', color: '#529857'}}>Software Installed:</div>
                <div style={{fontSize: '25px', color: 'white', fontWeight: 'bold'}}>{this.renderNoteTime(device.createdAt.S)}</div>
              </div>
            </div>
          ))
        }

        {!this.state.loading && this.state.shelfbutton === false &&
          this.state.onboarded.map((device, index) => (
            <div key={index}>
            <br/>
            <br/>
              <div className="Device-wrapper">
                <div style={{fontSize: '15px', color: '#529857'}}>Organization Name:</div>
                <div style={{fontSize: '25px', color: 'white', fontWeight: 'bold'}}>{device.group.S}</div>
                <div style={{fontSize: '15px', color: '#529857'}}>Serial Number:</div>
                <div style={{fontSize: '25px', color: 'white', fontWeight: 'bold'}}>{device.serial.S}</div>
                <div style={{fontSize: '15px', color: '#529857'}}>Device Name:</div>
                <div style={{fontSize: '25px', color: 'white', fontWeight: 'bold'}}>{device.title.S}</div>
                <div style={{fontSize: '15px', color: '#529857'}}>Activated:</div>
                <div style={{fontSize: '25px', color: 'white', fontWeight: 'bold'}}>{this.renderNoteTime(device.onboardedAt.S)}</div>
              </div>
            </div>
          ))
        }

      </div>
    )
  }
}

export default Inventory
