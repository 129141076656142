import React, { Component } from 'react';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';

class Enterprise extends Component {

    constructor(props){
        super(props)

        this.state ={
        entName: '',
        id:  '',
        createid: false,
        loading: true
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.finishedAdd = this.finishedAdd.bind(this);
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    async handleSubmit(event) {
        event.preventDefault();

        this.setState({loading: true})
        let enterpriseName = this.state.entName.trim()

        function addOrg(enterpriseName) {
            const apiName = 'EntAuth';
            const path = '/items';

            const myInit = {
                body: {
                    entName: enterpriseName,
                    divisionName: "null",
                    orgName: "null",
                    level: "1"
                }
            };
            return API.post(apiName, path, myInit);
        }

        let response = {}
        try {
            response = await addOrg(enterpriseName);
        } catch(error){
            alert('error creating enterprise')
        }

        if(response['status'] === 'success'){
            const addEnt = `mutation CreateEnt {
                createEnt(input: {
                    entName: "${enterpriseName}"
                    }) {
                    id
                }
            }`

            //add ent if not found in User db
            try {
                await API.graphql(graphqlOperation(addEnt));
            } catch(error) {
                console.log(error)
                var groupFound = error.errors[0].errorType
                if(groupFound === "DynamoDB:ConditionalCheckFailedException"){
                }
            };

            this.setState({
                id:response['uuid'],
                createid: true,
                loading: false
            })
        } else {
            alert('error adding organization please try again')
        }
    }

    copyId = (event) => {
        this.textArea.select();
        document.execCommand('copy');
        event.target.focus();
        alert('id copied')
    };

    async finishedAdd(event){
        this.setState({
        entName: '',
        id:  '',
        createid: false,
        loading: false
        })
    }

    async componentDidMount(){
        Auth.currentAuthenticatedUser({
            bypassCache: false
        }).then(user => {
            this.loggedIn = true
            if(user.signInUserSession.accessToken.payload["cognito:groups"][0] !== "enerstar"){
                this.props.history.push('/');
            }

        }
        )
        .catch(error => {
            if(error === 'not authenticated'){
                this.props.history.push('/SignIn');
            }
        }
        );
        alert('note that you are in enterprise settings')
        this.setState({loading: false})
    }

    render() {
        var backIcon = require('../Back-01.png');

        return(
            <div className="App">
                {
                    <div className="Nav-header">
                        <Link style={{textDecoration: 'none'}} to="/MainSettings">
                            <button className="Back-wrap">
                            <img className="Back-button" src={backIcon} alt="back"/>
                            </button>
                        </Link>
                    </div>
                }

                <div className="Title">Add Enterprise</div>
                <div className="Settings-wrapper">
                    {this.state.loading &&
                        <div className="Settings-wrapper">
                            <div className='One-space'/>
                            <div className="Loading-buttonstatus">Loading...</div>
                            <div className='One-space'/>
                        </div>
                    }

                    {!this.state.createid && !this.state.loading &&
                        <form onSubmit={this.handleSubmit}>
                            <div className='One-space'/>
                            <div className="form-name">enterprise name</div>
                            <input value={this.state.entName} name="entName" className="Reading-name" type="text" onChange={this.handleChange}/>
                            <div className='One-space'/>
                            <input type="submit" className="submit-buttons" value="submit"/>
                            <div className='One-space'/>
                        </form>
                    }
                    {this.state.createid && !this.state.loading &&
                        <div>
                            <button className="copy-link"  onClick={this.finishedAdd}>Finished</button>
                            <div className='One-space'/>
                            <div className="form-name">enterprise name</div>
                            <div className="title-two">{this.state.entName}</div>
                            <div className='One-space'/>
                            <div className="form-name">enterprise id</div>
                            <textarea className="copy-id" readOnly = {true} ref={(textarea) => this.textArea = textarea} value={this.state.id} />
                            <button onClick={this.copyId} className="copy-link">Copy</button>
                            <div className='One-space'/>
                        </div>
                    }
                </div>
                <div className='One-space'/>
                {!this.state.loading &&
                    <div>
                        <div className='One-space'/>
                        <div className="title-two">Onboard Enterprise Devices</div>
                        <Link style={{ textDecoration: 'none' }} to={{ pathname: '/EntOnboard' }}>
                            <button className="signup-link" >enter</button>
                        </Link>
                        <div className='One-space'/>
                    </div>
                }
                {!this.state.loading &&
                    <div>
                        <div className='One-space'/>
                        <div className="title-two">Get Enterprise Id</div>
                        <Link style={{ textDecoration: 'none' }} to={{ pathname: '/EnterpriseInvite' }}>
                            <button className="signup-link" >enter</button>
                        </Link>
                        <div className='One-space'/>
                    </div>
                }
            </div>
        )
    }
}

export default Enterprise
