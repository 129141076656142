import React, { Component } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import Notes from "../components/Notes"
import NoteSubmit from "../components/NoteSubmit"
import NotesDownloadForm from './NotesDownloadForm';

class Note extends Component {

    constructor(props){
        super(props);

        this.blogID = props.blogID
        this.userName = props.userName
        this.local = props.serial
        this.nickname = props.nickname
        this.org = props.org

        this.heldNotes = [];
    
        this.state = {
            notes: [],
            nextToken: '',
            noNotes: false,
            noteSection: false,
            noteHide: true,
            loading: true,
            hideMore: false,
            loadingMore: false,
            underFive: false,
            downloadNotesClick: false
        }

        this.getNotes = this.getNotes.bind(this);
        this.moreNotes = this.moreNotes.bind(this);
        this.downloadClicked = this.downloadClicked.bind(this);
        this.downloadUnClicked = this.downloadUnClicked.bind(this);
    }

    async moreNotes(event){
        event.preventDefault();
        this.setState({loadingMore: true})
        let hideMoreCheck = false

        const getMoreNotes = `query GetConvo {
            getBlog(id:"${this.blogID}") {
                id
                location
                posts(nextToken: "${this.state.nextToken}"sortDirection:DESC limit: 10) {
                    nextToken
                    items {
                        id
                        author
                        content
                        status
                        createdAt
                        img
                    }
                }
            }
        }`
        this.moreNotesReturn = await API.graphql(graphqlOperation(getMoreNotes));

        let nextToken = this.moreNotesReturn.data.getBlog.posts.nextToken

        if(nextToken === null){
            hideMoreCheck = true
        }

        this.heldNotes.push(...this.moreNotesReturn.data.getBlog.posts.items)

        this.setState({
            notes: this.heldNotes,
            nextToken: nextToken,
            loading: false,
            hideMore: hideMoreCheck,
            loadingMore: false
        })
    }

    // called after new note posted
    async getNotes(){
        this.heldNotes.length = 0

        const getNotes = `query GetConvo {
            getBlog(id:"${this.blogID}") {
                id
                location
                posts(sortDirection:DESC limit: 5) {
                    nextToken
                    items {
                        id
                        author
                        content
                        status
                        createdAt
                        img
                    }
                }
            }
        }`
        let refreshNotes = await API.graphql(graphqlOperation(getNotes));
        let nextToken = refreshNotes.data.getBlog.posts.nextToken

        let underFiveCheck = false
        if(nextToken === null){
            underFiveCheck = true
        }

        this.heldNotes.push(...refreshNotes.data.getBlog.posts.items)

        this.setState({
            notes: this.heldNotes,
            underFive: underFiveCheck,
            nextToken: nextToken,
            noNotes: false
        });
    }

    downloadClicked(){
        this.setState({
            downloadNotesClick: true
        })
    }
    downloadUnClicked(){
        this.setState({
            downloadNotesClick: false,
            notes: this.heldNotes,
            noNotes: false
        })
    }

    async componentDidMount(){
        const getNotes = `query GetConvo {
            getBlog(id:"${this.blogID}") {
                id
                location
                posts(sortDirection:DESC limit: 5) {
                    nextToken
                    items {
                        id
                        author
                        content
                        status
                        createdAt
                        img
                    }
                }
            }
        }`
        let newestNotes = await API.graphql(graphqlOperation(getNotes));
        this.heldNotes = newestNotes.data.getBlog.posts.items
        let nextToken = newestNotes.data.getBlog.posts.nextToken

        let underFiveCheck = false
        if(nextToken === null){
            underFiveCheck = true
        }
        
        if(this.heldNotes.length === 0){
            this.setState({noNotes: true})
        } else {
            this.setState({noNotes: false})
        }

        this.setState({
            notes: this.heldNotes,
            nextToken: nextToken,
            noteSection: true,
            loading: false,
            underFive: underFiveCheck
        });
    }

    // toggle note submit form open/close
    async noteForm(){
        this.setState(prevState => ({
            noteHide: !prevState.noteHide
        }));
    }


    render() {
        var addIcon = require('../icons8-plus-100.png');
        var downIcon = require('../icons8-download-100.png');
        return (
            <div className='App'>
                {this.state.loading &&
                    <div>
                        <div className='Two-space'/>
                        <div className="Loading-button">Loading....</div>
                    </div>
                }
                <div className='One-space'/>
                {this.state.noteHide  && this.state.noteSection && !this.state.downloadNotesClick &&
                    <button className='Add-button' onClick={(e) => this.noteForm()}>
                        <img className="Plus-button" src={addIcon} alt="add"/>
                        post note
                    </button>                  
                }
                {!this.state.noteHide && this.state.noteSection && !this.state.downloadNotesClick &&
                    <button className="Dropdown-button-full" onClick={(e) => this.noteForm()}>close</button>
                }
                {!this.state.noteHide && !this.state.downloadNotesClick &&
                    <div>
                        <NoteSubmit blogID={this.blogID} userName={this.userName} serial={this.local} getNotes={this.getNotes}/>
                    </div>
                }
                <div className='One-space'/>
                {!this.state.downloadNotesClick && !this.state.loading &&
                    <button type="button" className="Download-button" onClick={this.downloadClicked}>
                        <img className="Back-button" src={downIcon} alt="logs"/>
                    </button>
                }
                {this.state.downloadNotesClick &&
                    <button className="Dropdown-button-full"  onClick={this.downloadUnClicked}>close</button>
                }
                {this.state.downloadNotesClick &&
                    <NotesDownloadForm blogID={this.blogID} nickname={this.nickname} org={this.org}/>
                }
                {this.state.noNotes &&
                    <div>
                        <div className='One-space'/>
                        <div className="title-two">no notes posted</div>
                    </div>
                }
                {
                    <Notes notes={this.state.notes} noNotes={this.state.noNotes}/>                 
                }
                {this.state.loadingMore &&
                    <div>
                        <div className='Two-space'/>
                        <div className="Loading-button">Loading....</div>
                    </div>
                }
                { !this.state.hideMore && this.state.noteSection && !this.state.noNotes && !this.state.underFive &&
                    <div>
                        <div className='Two-space'/>
                        <button onClick={this.moreNotes} className="Dropdown-button-full">
                            <div>more</div>
                        </button>
                    </div> 
                }
                { this.state.noteSection && !this.state.noNotes && this.state.hideMore &&
                    <div>
                        <div className='Two-space'/>
                        <div className="location-dev">no older notes</div>
                    </div>
                }
            </div>
        )
    }
}

export default Note