import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
//import { BrowserRouter, Route, Switch } from 'react-router-dom';
//import {Location} from './components/Location'

//import * as serviceWorker from "./registerServiceWorker";


//youtube addition
import Amplify from 'aws-amplify'
import config from './aws-exports'

//serviceWorker.unregister();

Amplify.configure(config)

ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
