import React, { Component } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import NotesDownload from './NotesDownload';

class NotesDownloadForm extends Component {

    constructor(props){
        super(props);

        this.org = props.org
        this.nickname = props.nickname
        this.blogID = props.blogID
        this.selectedNotesHeld = []
        this.indexCollected = []
        this.allNotesSelected = false
        this.printable = false
    
        this.state = {
            loading: true,
            finalButtons: [],
            yearSelected: '',
            heldMonths: [],
            selectedNotes: [],
            downloadButton: false,
            downloadClicked: false,
            printColor1: '#529857',
            printColor2: '#3c3c3c'
        }
        this.printSelect = this.printSelect.bind(this);
    }

    async printSelect () {
        this.printSelected = this.click
    
        if(this.printSelected === "noPrint"){
            this.printable = false
            this.setState({
                printColor1: '#529857',
                printColor2: '#3c3c3c',
                downloadClicked: false,
            })
        }
    
        if(this.printSelected === "print"){
            this.printable = true
            this.setState({
            printColor1: '#3c3c3c',
            printColor2: '#529857',
            downloadClicked: false,
          })
        }
    
      }

    // mount NotesDownload component on click send org/device name/all notes/selected notes/all notes pressed
    downloadClick(){
        this.setState({
            downloadClicked: true
        })
    }

    // change utc to device local time
    renderNoteTime(time){
        let utcDate = time;
        let localDate = new Date(utcDate);

        let year = localDate.getFullYear()
        let month = localDate.getMonth() + 1
    
        return({year: year, month: month});
    };

    // year select buttons check for all pressed only one button selected at a time
    yearSelect(year, index){
        if(year === 'all'){
            this.allNotesSelected = true
        } else {
            this.allNotesSelected = false
        }

        let tempMonths = []
        this.state.finalButtons.forEach(x => {
            if(year === x.year){
                tempMonths = x.months
            }
        });

        this.setState({
            yearSelected: year,
            heldMonths: tempMonths,
            yearIndex: index,
            downloadClicked: false
        })
    }

    // month select in a particular year
    monthSelect(years, month, index){
        let indexCheck = `${years}${month}`
        // catch all button pressed for a particular year
        if(month === 'all'){
            // remove all collected months for that year
            if(this.selectedNotesHeld.some(e => e.year === years)){
                let indexYears = this.selectedNotesHeld.findIndex(e => e.year === years)
                this.selectedNotesHeld.splice(indexYears, 1)
            }
            // check if this is selecting or unselecting all for that year
            if(this.indexCollected.includes(indexCheck)){
                // unselecting remove the year all entry
                let removeAll = this.indexCollected.indexOf(indexCheck)
                this.indexCollected.splice(removeAll, 1)
            } else {
                // selecting all save other years selections and add new object for year all
                let otherYears = this.indexCollected.filter(e => !e.includes(years))
                this.indexCollected = otherYears
                this.indexCollected.push(indexCheck)
                this.selectedNotesHeld.push({year: years, months: [indexCheck]})
            }

            let noSelectedCatch = true

            if(this.indexCollected.length === 0){
                noSelectedCatch = false
            }
            this.setState({
                downloadClicked: false,
                downloadButton: noSelectedCatch,
            })
        } else {
            // non all selected remove the all selection
            let removeAll = this.indexCollected.filter(e => e !== `${years}all`)
            this.indexCollected = removeAll

            // check if the button is a select or unselect
            if(this.indexCollected.includes(indexCheck)){
                // remove selection from the buttons array
                let filterArray = this.indexCollected.filter(e => e !== indexCheck)
                this.indexCollected = filterArray
                
                // find index of year and month and remove that entry
                let index = this.selectedNotesHeld.findIndex(e => e.year === years)
                let indexMonth = this.selectedNotesHeld[index].months.findIndex(x => x === month)
                this.selectedNotesHeld[index].months.splice(indexMonth, 1)

                // if unselecting the the last and final month for that year remove that years object
                if(this.selectedNotesHeld[index].months.length === 0){
                    this.selectedNotesHeld.splice(index, 1)
                }

                let noSelectedCatch = true

                if(this.indexCollected.length === 0){
                    noSelectedCatch = false
                }
                
                this.setState({
                    downloadButton: noSelectedCatch,
                    downloadClicked: false
                })
            } else {
                // add selection to the buttons array
                this.indexCollected.push(`${years}${month}`)

                // check if there is a year object already created
                if(this.selectedNotesHeld.some(e => e.year === years)){
                    // if year object found add month to the month array
                    let index = this.selectedNotesHeld.findIndex(x => x.year === years)
                    this.selectedNotesHeld[index].months.push(month)
                } else {
                    // if its the first month to be added create year object and add month to the month array
                    this.selectedNotesHeld.push({year: years, months: [month]})            
                }
                this.setState({
                    downloadButton: true,
                    downloadClicked: false
                })
            }
        }
    }

    // convert numeric months to words for rendering buttons
    monthWord(month){
        let monthArrayMatch = month -1
        let months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']
        return(months[monthArrayMatch])
    }

    async componentDidMount(){
        let newestNotes
        // fetch all notes to create the form
        const getNotes = `query GetConvo {
            getBlog(id:"${this.blogID}") {
                id
                location
                posts(sortDirection:DESC limit: 1000) {
                    nextToken
                    items {
                        id
                        author
                        content
                        status
                        createdAt
                        img
                    }
                }
            }
        }`
        try{
            newestNotes = await API.graphql(graphqlOperation(getNotes));
        } catch(error){
            console.log(error)
            alert('error fetching notes')
        }
        

        let cleanedNotes = newestNotes.data.getBlog.posts.items
        this.allNotes = cleanedNotes

        // convert from utc before generating the form
        let currentDate = this.renderNoteTime(cleanedNotes[0].createdAt)
        let currentYear = currentDate.year
        let heldYear = currentYear
        let finalForm = [{year: currentYear, months: []}]
        let indexFinalForm = 0
        let noteLength = cleanedNotes.length
        
        // create array of year object with an array of months in order remove duplicates
        cleanedNotes.map((note, index) => {
            let timestamp = this.renderNoteTime(note.createdAt)
            if(heldYear !== timestamp.year){
                finalForm[indexFinalForm].months = [...new Set(finalForm[indexFinalForm].months)]
                indexFinalForm++
                heldYear = timestamp.year
                finalForm.push({year: timestamp.year, months: [timestamp.month]})
            } else {
                finalForm[indexFinalForm].months.unshift(timestamp.month)
            }
            if(noteLength === index + 1){
                finalForm[indexFinalForm].months = [...new Set(finalForm[indexFinalForm].months)]
                return(finalForm.reverse())
            }   
            return(finalForm)        
        })

        this.setState({
            loading: false,
            finalButtons: finalForm
        })

    }  

    render(){

        return(
            <div className="App">
                {this.state.loading &&
                    <div className='Loading-button'>loading....</div>
                }
                {!this.state.loading &&
                    <div className='Months-wrapper'>
                        {
                            this.state.finalButtons.map((note, yearIndex) => (
                                <div className='Months-buttons' key={yearIndex}>
                                    <button style={{background: this.state.yearIndex === yearIndex ? '#529857' : '#3c3c3c'}} className='Months-button100' onClick={event => this.yearSelect(note.year, yearIndex)}>{note.year}</button>
                                </div>
                                
                            ))                    
                        }
                        {   
                            <div className='Months-buttons'>
                                <button style={{background: this.state.yearIndex === 'all' ? '#529857' : '#3c3c3c'}} className='Months-button100' onClick={event => this.yearSelect('all', 'all')}>all</button>
                            </div>                          
                        }
                    </div>
                }

    
                <div></div>
                {!this.state.loading && this.allNotesSelected &&
                    <div className='Months-wrapper'>
                            <div style={{fontSize: '20px', color: 'white', margin: '5px'}}>All available notes will be downloaded.</div>
                    </div>
                }                        
                {this.state.yearSelected !== '' && this.state.yearSelected !== 'all' && !this.state.loading &&
                    <div>
                        <div className='One-space'/>
                        {this.indexCollected.includes(`${this.state.yearSelected}${'all'}`) &&
                            <div>
                                <div className='All-wrap'>
                                    <button style={{background: '#529857'}} className='Months-button100' onClick={event => this.monthSelect(this.state.yearSelected, 'all', 'all')}>all</button>
                                </div>
                                
                                <div className='Months-wrapper'>
                                    <div style={{fontSize: '20px', color: 'white', margin: '5px'}}>All notes from {this.state.yearSelected} will be downloaded.</div>
                                </div>
                            </div>

                            
                        }
                        {!this.indexCollected.includes(`${this.state.yearSelected}${'all'}`) &&
                            <div className='All-wrap'>
                                <button style={{background: '#3c3c3c'}} className='Months-button100' onClick={event => this.monthSelect(this.state.yearSelected, 'all', 'all')}>all</button>
                            </div>
                        }
                    </div>
                    
                } 
                {this.state.yearSelected !== '' && this.state.yearSelected !== 'all' && !this.state.loading && !this.indexCollected.includes(`${this.state.yearSelected}${'all'}`) &&
                    <div className='Months-wrapper'>
                        {                           
                            this.state.heldMonths.map((month, index) => (
                                <div key={month} className='Months-buttons'>
                                    {this.indexCollected.includes(`${this.state.yearSelected}${month}`) &&
                                        <button style={{background: '#529857'}}className='Months-button100' key={index} onClick={event => this.monthSelect(this.state.yearSelected, month, index)}>{this.monthWord(month)}</button>
                                    }
                                    {!this.indexCollected.includes(`${this.state.yearSelected}${month}`) &&
                                        <button style={{background: '#3c3c3c'}}className='Months-button100' key={index} onClick={event => this.monthSelect(this.state.yearSelected, month, index)}>{this.monthWord(month)}</button>
                                    }
                                </div>
                                
                            ))
                        }
                        

                    </div>
                }
                <div className='One-space'></div>
                {!this.state.loading && this.state.downloadButton &&
                    <div>
                        <div style={{fontSize: '20px', color: 'white'}}>Printer-friendly version:</div>
                        <div className="Button2-wrapper">
                            <button type="button" className="Button2-select" onClick={(e) => this.printSelect(this.click = 'noPrint')} style={{backgroundColor:this.state.printColor1}}>
                                <div>no</div>
                            </button>
                            <button type="button" className="Button2-select" onClick={(e) => this.printSelect(this.click = 'print')} style={{backgroundColor:this.state.printColor2}}>
                                <div>yes</div>
                            </button>
                        </div>
                    </div>
                }
                {!this.state.loading && this.allNotesSelected && !this.state.downloadButton &&
                    <div>
                        <div style={{fontSize: '20px', color: 'white'}}>Printer-friendly version:</div>
                        <div className="Button2-wrapper">
                            <button type="button" className="Button2-select" onClick={(e) => this.printSelect(this.click = 'noPrint')} style={{backgroundColor:this.state.printColor1}}>
                                <div>no</div>
                            </button>
                            <button type="button" className="Button2-select" onClick={(e) => this.printSelect(this.click = 'print')} style={{backgroundColor:this.state.printColor2}}>
                                <div>yes</div>
                            </button>
                        </div>
                    </div>
                }
                <div className='One-space'></div>
                {!this.state.loading && this.state.downloadButton && !this.state.downloadClicked &&
                    <button onClick={() => this.downloadClick()} className='Add-button' >
                        create pdf
                    </button>                  
                }
                {!this.state.loading && this.allNotesSelected && !this.state.downloadButton && !this.state.downloadClicked &&
                    <button onClick={() => this.downloadClick()} className='Add-button' >
                        create pdf
                    </button>                  
                }
                {this.state.downloadClicked &&
                    <NotesDownload notes={this.allNotes} selectedNotes={ this.selectedNotesHeld} org={this.org} deviceName={this.nickname} allNotes={this.allNotesSelected} printable={this.printable}/>
                }
                <div className='Two-space'/>
                <div className='Two-space'/>
                <div className='Two-space'/>
            </div>
        )
    }

}

export default NotesDownloadForm