import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries.js';

class UserInvite extends Component {

    constructor(props){
        super(props)

        this.state = {
            loading: true,
            loadingDiv: false,
            loadingOrg: false,
            selectedLevel: '',
            userName: '',
            enterprise: '',
            division: '',
            organization: '',
            divIndex: '',
            orgIndex: '',
            divArray: [],
            orgArray: [],
            noDivs: false,
            noOrgs: false,
            noDivsOrgs: false,
            id: '',
            hideCopyEmail: false
        }
        this.getUUID = this.getUUID.bind(this)
        this.close = this.close.bind(this)
        this.emailId = this.emailId.bind(this);
    }

    async selectLevel(level){
        if(level === 'enterprise'){
            await this.setState({
                id: 'loading....',
                division: '',
                organization: '',
                hideCopyEmail: true
            })
            this.getUUID(level)
        }
        this.setState({
            selectedLevel: level
        })
    }

    async close(event){
        this.setState({
            divIndex: '',
            orgIndex: '',
            hideCopyEmail: true
        })
    }

    async divClicked(division, index){
        await this.setState({
            loadingDiv: true,
            division: division,
            divIndex: index,
            organization: '',
            id: 'loading....',
            hideCopyEmail: true
        })
        this.getUUID('division')
    }

    async orgClicked(division, organization, index){
        await this.setState({
            loadingOrg: true,
            division: division,
            organization: organization,
            orgIndex: index,
            id: 'loading....',
            hideCopyEmail: true
        })
        this.getUUID('organization')
    }

    async getUUID(level){
        let entSend = this.state.enterprise
        let divSend = ''
        let orgSend = ''

        if(this.state.division === ''){
            divSend = 'null'
        } else {
            divSend = this.state.division
        }
        if(this.state.organization === ''){
            orgSend = 'null'
        } else {
            orgSend = this.state.organization
        }

        function getId() {
            const apiName = 'EntAuth';
            const path = '/items';

            const myInit = {
                queryStringParameters: {
                    level: level,
                    entName: entSend,
                    divisionName: divSend,
                    orgName: orgSend,
                }
            };
            return API.get(apiName, path, myInit);
        }

        let response = ''
        try{
            response = await getId();
        } catch(error){
            console.log(error)
        }

        if(response.status === 'success'){
            this.setState({
                loading: false,
                id: response.uuid,
                loadingDiv: false,
                loadingOrg: false,
                hideCopyEmail: false
            })
        } else {
            alert('error fetching organization id number')
        }
    }

    async goBack(event){
        this.setState({
            selectedLevel: ''
        })
    }

    copyId = (event) => {
        this.textArea.select();
        document.execCommand('copy');
        event.target.focus();
        alert('id copied to clipboard')
    };

  // pull up email on click
    emailId(event) {
        let emailBody = "You have been given an access id number for nowledgeapp.com. \n\nYour access id number is: " + this.state.id + "\n\nGo to nowledgeapp.com and click signup where it asks if you are new to nowledge. \n\nCopy and paste this number during the step where it asks for an access id number." 
        let mailToLink = "mailto:?subject=Invitation to nowledgeapp.com&body=" + encodeURIComponent(emailBody);
        window.location.href = mailToLink;
    }

    async componentDidMount(){
        Auth.currentAuthenticatedUser({
            bypassCache: false
        }).then(user => {
            this.loggedIn = true
        }
        )
        .catch(error => {
            if(error === 'not authenticated'){
                this.props.history.push('/SignIn');
            }
        }
        );

        let user = await Auth.currentAuthenticatedUser()
        const userName = user.username;

        let userInfo = await API.graphql(graphqlOperation(queries.getEntUser, { userName: userName }));
        let userEnt = userInfo.data.getEntUser.entName

        let getentDivsOrgs = `query GetEntDivsOrgs {
            getEnt(entName: "${userEnt}") {
                id
                entdivsorgs {
                    items {
                        id
                        entName
                        divName
                        orgName
                        orgName
                        isOrg
                    }
                }
            }
        }`

        let divsOrgs = []

        try{
            let returnDivsOrgs = await API.graphql(graphqlOperation(getentDivsOrgs));
            divsOrgs = returnDivsOrgs.data.getEnt.entdivsorgs.items
        } catch(error) {
            console.log(error)
        };

        let finalDivs = []
        let finalOrgs = []

        divsOrgs.forEach(function (unit) {
            if(unit.isOrg === 'false'){
                finalDivs.push(unit)
            } else {
                finalOrgs.push(unit)
            }
        })

        let noDivsFound = false
        if(finalDivs.length === 0){
            noDivsFound = true
        }
        let noOrgsFound = false
        if(finalOrgs.length === 0){
            noOrgsFound = true
        }
        let noDivsOrgsFound = false
        if(finalDivs.length === 0 && finalOrgs.length === 0){
            noDivsOrgsFound = true
        }

        if(noDivsFound){
            finalOrgs.sort((a,b) => a.orgName.localeCompare(b.orgName, undefined, { numeric: true }))
        } else {
            finalOrgs.sort((a,b) => a.divName.localeCompare(b.divName, undefined, { numeric: true }))
        }

        finalDivs.sort((a,b) => a.divName.localeCompare(b.divName, undefined, { numeric: true }))

        this.setState({
            userName: user.username,
            enterprise: userEnt,
            divArray: finalDivs,
            orgArray: finalOrgs,
            noDivs: noDivsFound,
            noOrgs: noOrgsFound,
            noDivsOrgs: noDivsOrgsFound,
            loading: false
        });
        alert('Note: Carefully select an authorization number. The authorization ID number you select effects what devices the new user will be able to access.')
    }

    render() {
        var backIcon = require('../Back-01.png');
        return(
            <div className="App">
                {
                    <div className="Nav-header">
                        <Link style={{textDecoration: 'none'}} to="/SettingsPage">
                        <button className="Back-wrap">
                            <img className="Back-button" src={backIcon} alt="back"/>
                        </button>
                        </Link>
                    </div>
                }
                {
                    <div className="Title">Get ID to Invite a User</div>
                }
                {this.state.loading &&
                    <div className='Settings-wrapper'>                          
                        <div className='One-space'/>
                        <div className="Loading-buttonstatus">Loading....</div>
                        <div className='Two-space'/>            
                    </div>
                }
                {!this.state.loading && this.state.selectedLevel === '' &&
                    <div className="Settings-wrapper">
                        <div className='One-space'/>
                        {
                            <div>
                                <div className="form-name">Enteprise ID: share all devices within your enteprise?</div>
                                <div className="resend-link" onClick={() => this.selectLevel('enterprise')}>get id</div>
                                <div className='One-space'/>
                            </div>
                        }
                        {!this.state.noDivs &&
                            <div>
                                <div className="form-name">Division ID: share all devices from all organizations within a particular division?</div>
                                <div className="resend-link" onClick={() => this.selectLevel('division')}>get id</div>
                                <div className='One-space'/>
                            </div>
                        }
                        {!this.state.noOrgs &&
                            <div>
                                <div className="form-name">Organization ID: share only devices within a particular organization?</div>
                                <div className="resend-link" onClick={() => this.selectLevel('organization')}>get id</div>
                                <div className='One-space'/>
                            </div>
                        }
                        <div className='One-space'/>
                    </div>
                }
                {this.state.selectedLevel === 'enterprise' &&
                    <div>
                        <div className="Settings-wrapper">
                            <button className="resend-link" onClick={(e) => this.goBack()}>back</button>
                            <div className='One-space'/>
                            <div className="form-name">Below you will find the authorization ID that will allow a user to access all devices within your enteprise. Only share this ID number if you want the user to have access to all the devices you youself have.</div>
                            <div className='One-space'/>
                        </div>
                        <div className='One-space'/>
                        {
                            <div>
                                {this.state.loadingDiv &&
                                    <div className="Device-wrapper">
                                        <div className='One-space'/>
                                        <div className="Loading-buttonstatus">Loading...</div>
                                        <div className='One-space'/>
                                    </div>
                                }
                                {!this.state.loading &&
                                    <div className="Device-wrapper">
                                        <div className="notesdocopen-link">{this.state.enterprise}</div>
                                        <div className="form-name">enterprise id</div>
                                        <textarea className="copy-id" readOnly = {true} ref={(textarea) => this.textArea = textarea} value={this.state.id} />
                                        {!this.state.hideCopyEmail &&
                                        <div>
                                            <div className='One-space'/>
                                            <button onClick={this.copyId} className="confirm-link">copy</button>
                                            <button onClick={this.emailId} className="confirm-link">email</button>
                                        </div>
                                        }                     
                                        <div className='One-space'/>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                }
                {this.state.selectedLevel === 'division' &&
                    <div>
                        <div className="Settings-wrapper">
                            <button className="resend-link" onClick={(e) => this.goBack()}>back</button>
                            <div className='One-space'/>
                            <div className="form-name">Below are all the divisions within your enterprise. Click to access its authorization ID number. Be aware that this ID number will give the user access to all devices in all the organizations within that division.</div>
                            <div className='One-space'/>
                        </div>
                        <div className='One-space'/>
                        {
                            this.state.divArray.map((div, index) => (
                                <div key={index}>
                                {this.state.divIndex === index &&
                                    <div>
                                        {this.state.loading &&
                                            <div className="Device-wrapper">
                                                <div className='One-space'/>
                                                <div className="Loading-buttonstatus">Loading...</div>
                                                <div className='One-space'/>
                                            </div>
                                        }
                                        {!this.state.loading &&
                                            <div className="Device-wrapper">
                                                <button className="copy-link"  onClick={this.close}>hide</button>
                                                <div className="notesdocopen-link">{this.state.division}</div>
                                                <div className="form-name">division id</div>
                                                <textarea className="copy-id" readOnly = {true} ref={(textarea) => this.textArea = textarea} value={this.state.id} />
                                                {!this.state.hideCopyEmail &&
                                                    <div>
                                                    <div className='One-space'/>
                                                    <button onClick={this.copyId} className="confirm-link">copy</button>
                                                    <button onClick={this.emailId} className="confirm-link">email</button>
                                                    </div>
                                                }  
                                                <div className='One-space'/>
                                            </div>
                                        }
                                    </div>
                                }
                                {this.state.divIndex !== index &&
                                    <div>
                                        <div className='One-space'/>
                                        <div className="Device-wrapper" onClick={() => this.divClicked(div.divName, index)}>
                                            <div className="org-get-id">{div.divName}</div>
                                        </div>
                                        <div className='One-space'/>
                                    </div>
                                }
                                </div>
                            ))
                        }
                    </div>
                }
                {this.state.selectedLevel === 'organization' &&
                    <div>
                        <div className="Settings-wrapper">
                            <button className="resend-link" onClick={(e) => this.goBack()}>back</button>
                            <div className='One-space'/>
                            <div className="form-name">Below are all the organizations within your enterprise. Click to access its authorization ID number. This ID number will give the user access to all the devices within that organization.</div>
                            <div className='One-space'/>
                        </div>
                        <div className='One-space'/>
                        {
                            this.state.orgArray.map((org, index) => (
                                <div key={index}>
                                {this.state.orgIndex === index &&
                                <div>
                                    {this.state.loading &&
                                        <div className="Device-wrapper">
                                            <div className='One-space'/>
                                            <div className="Loading-buttonstatus">Loading...</div>
                                            <div className='One-space'/>
                                        </div>
                                    }
                                    {!this.state.loading &&
                                        <div className="Device-wrapper">
                                            <button className="copy-link"  onClick={this.close}>hide</button>
                                            <div className="notesdocopen-link">{this.state.organization}</div>
                                            <div className="form-name">organization id</div>
                                            <textarea className="copy-id" readOnly = {true} ref={(textarea) => this.textArea = textarea} value={this.state.id} />
                                            {!this.state.hideCopyEmail &&
                                                <div>
                                                    <div className='One-space'/>
                                                    <button onClick={this.copyId} className="confirm-link">copy</button>
                                                    <button onClick={this.emailId} className="confirm-link">email</button>
                                                </div>
                                            }  
                                            <div className='One-space'/>
                                        </div>
                                    }
                                </div>
                                }
                                {this.state.orgIndex !== index && !this.state.noDivs &&
                                    <div>                                      
                                        <div className='One-space'/>
                                        <div className="Device-wrapper" onClick={() => this.orgClicked(org.divName, org.orgName, index)}>
                                            <div className='One-space'/>
                                            <div className='title-two'>{org.divName}</div>
                                            <div className="org-get-id">{org.orgName}</div>
                                        </div>
                                        <div className='One-space'/>
                                    </div>
                                }
                                {this.state.orgIndex !== index && this.state.noDivs &&
                                    <div>                                      
                                        <div className='One-space'/>
                                        <div className="Device-wrapper" onClick={() => this.orgClicked(org.divName, org.orgName, index)}>
                                            <div className="org-get-id">{org.orgName}</div>
                                        </div>
                                        <div className='One-space'/>
                                    </div>
                                }
                                </div>
                            ))
                        }
                    </div>
                }
            </div>
        )
    }
}

export default UserInvite
