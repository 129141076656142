import React, { Component } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import {S3Image} from 'aws-amplify-react';
import * as queries from '../graphql/queries.js';
import { Link } from 'react-router-dom';

class NewNotes extends Component {
    constructor(props){
        super(props)
            
        this.state = {
            devices: props.data,
            group: props.group,
            notes: [],
            noNotes: [],
            loading: true,
            index: '',
            moreNotes: [],
            moreLoading: false
        }
        
    }

    async handleClick(blogID, index) {
        this.setState({
            index: index,
            moreLoading: true
        })

        let getMoreNotes = `query GetConvo { getBlog(id:"${blogID}") { id location posts(sortDirection:DESC limit: 5) { items { id author content status createdAt img } } } }` 
        let moreNotesData = await API.graphql(graphqlOperation(getMoreNotes));
        let finalMoreNotes = moreNotesData.data.getBlog.posts.items

        this.setState({
            moreLoading: false,
            moreNotes: finalMoreNotes
        })

    }

    async handleClose() {
        this.setState({
            index: ''
        })
    }

    renderNoteTime(time){
        let utcDate = time;
        let localDate = new Date(utcDate);   
        let date4 = (localDate.getMonth()+1) + "/" + localDate.getDate() + "/" + localDate.getFullYear()    
        let hour = localDate.getHours()
        let minute = localDate.getMinutes();
    
        if(minute < 10){
            minute = ('0' + minute).slice(-2)
        };   
        let amPm = "am";
        if(hour === 12){
            amPm = "pm"
        };
        if(hour > 12){
            hour = hour - 12
            amPm = "pm"
        };
        if(hour === 0){
            hour = 12
        };
    
        let time1 = hour + ":" + minute + amPm
        let dateTime1 = date4 + " " + time1;
        return(
            <div>{dateTime1}</div>
        );
    };

    async componentDidMount() {
        let devices = this.state.devices
        // get all parent db IDs to fetch notes
        let serialIdArray = [];
        let serialArray = []
        let cleanedBlogIds = []

        // array of just serial numbers for latest data api
        devices.forEach(unit => {
            serialArray.push(unit.location)
        })
        
        let field = "location"

        let filterArray = serialArray.map((item)  => JSON.parse(`{"${field}":{"eq":"${item}"}}`))

        if(filterArray.length > 100){
            let array1 = filterArray.slice(0,100)
            let array2 = filterArray.slice(100)
            let finalFilter1 = {or:array1}
            let finalFilter2 = {or:array2}

            let blogIds1 = []
            let blogIds2 = []
            try{
                blogIds1 = await API.graphql(graphqlOperation(queries.listBlogs,{limit: 1000, filter:finalFilter1}));
            } catch(error){
                console.log(error)
                alert('error loading alarms')
            }
            try{
                blogIds2 = await API.graphql(graphqlOperation(queries.listBlogs,{limit: 1000, filter:finalFilter2}));
            } catch(error){
                console.log(error)
            }
            let cleanedBlogIds1 = blogIds1.data.listBlogs.items
            let cleanedBlogIds2 = blogIds2.data.listBlogs.items
            cleanedBlogIds = cleanedBlogIds1.concat(cleanedBlogIds2)


        } else {
            let finalFilter = {or:filterArray}
            let testBlogIds = []
            try{
                testBlogIds = await API.graphql(graphqlOperation(queries.listBlogs,{limit: 1000, filter:finalFilter}));
            } catch(error){
                console.log(error)
                alert('error loading alarms')
            }       
            cleanedBlogIds = testBlogIds.data.listBlogs.items
        }   

        devices.forEach(device => {
            cleanedBlogIds.forEach(idDevice => {
                if(device.location === idDevice.location){
                    serialIdArray.push({'serial': device.location, 'group': device.group, 'title': device.title, 'blogID': idDevice.id, 'version': device.version})
                }
            })
        })  




 
        

        // fetch notes and match to device information seperate devices with notes from those without
        let matchedNotesArray = []
        let matchedNoNotesArray = []
        let getNotesMatch = async() => {
            await Promise.all(serialIdArray.map(async (object) => {
                let getNotes = `query GetConvo { getBlog(id:"${object.blogID}") { id location posts(sortDirection:DESC limit: 1) { items { id author content status createdAt img } } } }` 
                let notesData = await API.graphql(graphqlOperation(getNotes));
                if(notesData.data.getBlog.posts.items.length === 0){
                    let newNoneObject = {'serial': object.serial, 'group': object.group, 'title': object.title, 'blogID': object.blogID, 'version': object.version, timestamp: '0'}
                    matchedNoNotesArray.push(newNoneObject)
                } else {
                    let newNotesObject = {'serial': object.serial, 'group': object.group, 'title': object.title, 'blogID': object.blogID, 'version': object.version, timestamp: notesData.data.getBlog.posts.items[0].createdAt, author: notesData.data.getBlog.posts.items[0].author, content: notesData.data.getBlog.posts.items[0].content, status: notesData.data.getBlog.posts.items[0].status, image: notesData.data.getBlog.posts.items[0].img}
                    matchedNotesArray.push(newNotesObject)
                }
            }))
        }
        try {
            await getNotesMatch()
        } catch(error)  {
            console.log(error)
        }

        // order seperated notes
        let orderedNotes = matchedNotesArray.sort((a, b) => b.timestamp.localeCompare(a.timestamp, undefined, { numeric: true }))
        let orderedNoNotes = matchedNoNotesArray.sort((a, b) => a.group.localeCompare(b.group, undefined, { numeric: true }))

        this.setState({
            notes: orderedNotes,
            noNotes: orderedNoNotes,
            loading: false
        })

    }

    render(){
        return(
            <div className='App'>
                {this.state.loading &&
                    <div>
                        <div className='Two-space'/>
                        <div className='NewNotes-wrapper'>
                            <div className='One-space'/>
                            <div className="Loading-buttonstatus">Loading...</div>
                            <div className='One-space'/>
                        </div>
                    </div>
                }
                <div className='One-space'/>
                {!this.state.loading &&                                       
                    this.state.notes.map((notes, index) => (
                        <div key={index}>
                        <div className='One-space'/>
                            {this.state.index !== index &&
                                <div className="NewNotes-wrapper">
                                    <div style={{fontSize: '30px', color: 'white', fontWeight: 'bold'}}>{this.renderNoteTime(notes.timestamp)}</div>
                                    <div style={{fontSize: '20px', color: '#529857'}}>Device:</div>
                                    {this.state.group !== 'multiloc' &&
                                        <div style={{fontSize: '25px', color: 'white', fontWeight: 'bold'}}>{notes.group}</div>
                                    }
                                    <div style={{fontSize: '25px', color: 'white', fontWeight: 'bold'}}>{notes.title}</div>
                                    {this.state.group === 'enerstar' &&
                                        <Link style={{ textDecoration: 'none' }} to={{ pathname: '/location', state: { loc: notes.serial, title: notes.title, org: notes.group, version: notes.version} }}>
                                            <button className="goto-device">go to device</button>
                                        </Link>
                                    }
                                    {this.state.group === 'multiloc' &&
                                        <Link style={{ textDecoration: 'none' }} to={{ pathname: '/location', state: { loc: notes.serial, title: notes.title, org: 'none', version: notes.version} }}>
                                            <button className="goto-device">go to device</button>
                                        </Link>
                                    }
                                    <div style={{fontSize: '20px', color: '#529857'}}>Author:</div>
                                    <div style={{fontSize: '20px', color: 'white', fontWeight: 'bold'}}>{notes.author}</div>
                                    <div style={{fontSize: '20px', color: '#529857'}}>Note:</div>
                                    <div style={{fontSize: '20px', color: 'white', fontWeight: 'bold'}}>{notes.content.replace(/<br\s*\\?>/g, "\r\n").split('\n').map( (line, i) => <div key={'x'+i}>{line}</div> )}</div>
                                    { notes.image !== undefined && notes.image !== null && notes.image !== 'null' &&
                                        <S3Image imgKey={notes.image} theme={{ photoImg: { width: '100%' } }}/>
                                    }
                                    <button onClick={() => this.handleClick(notes.blogID, index)} className="Newnotes-drop-button">
                                        <div>more</div>
                                    </button>
                                </div>
                            }
                            {this.state.moreLoading &&
                                    <div>
                                        <div className='One-space'/>
                                        <div className='Loading-button'>Loading...</div>
                                        <div className='One-space'/>
                                    </div>
                            }
                            {
                                <div className='NewNotes-wrapper'>
                                    {this.state.index === index && !this.state.moreLoading &&
                                        <div>
                                            <button onClick={() => this.handleClose()} className="Newnotes-drop-button">
                                                <div>less</div>
                                            </button>
                                            <div style={{fontSize: '20px', color: '#529857'}}>Device:</div>
                                            <div style={{fontSize: '30px', color: 'white', fontWeight: 'bold'}}>{notes.group}</div>
                                            <div style={{fontSize: '30px', color: 'white', fontWeight: 'bold'}}>{notes.title}</div>
                                            {this.state.group === 'enerstar' &&
                                                <Link style={{ textDecoration: 'none' }} to={{ pathname: '/location', state: { loc: notes.serial, title: notes.title, org: notes.group, version: notes.version} }}>
                                                    <button className="goto-device">go to device</button>
                                                </Link>
                                            }
                                            {this.state.group === 'multiloc' &&
                                                <Link style={{ textDecoration: 'none' }} to={{ pathname: '/location', state: { loc: notes.serial, title: notes.title, org: 'none', version: notes.version} }}>
                                                    <button className="goto-device">go to device</button>
                                                </Link>
                                            }
                                            <div className='Two-space'/>
                                        </div>

                                    }
                                    {this.state.index === index && !this.state.moreLoading &&
                                            this.state.moreNotes.map((notes, index) => (
                                                <div key={index}>
                                                    <div style={{fontSize: '30px', color: 'white', fontWeight: 'bold'}}>{this.renderNoteTime(notes.createdAt)}</div>
                                                    <div style={{fontSize: '20px', color: '#529857'}}>Author:</div>
                                                    <div style={{fontSize: '20px', color: 'white', fontWeight: 'bold'}}>{notes.author}</div>
                                                    <div style={{fontSize: '20px', color: '#529857'}}>Note:</div>
                                                    <div style={{fontSize: '20px', color: 'white', fontWeight: 'bold'}}>{notes.content.replace(/<br\s*\\?>/g, "\r\n").split('\n').map( (line, i) => <div key={'x'+i}>{line}</div> )}</div>
                                                    { notes.img !== undefined && notes.img !== null && notes.img !== 'null' &&
                                                        <S3Image imgKey={notes.img} theme={{ photoImg: { width: '100%' } }}/>
                                                    }
                                                    <div className='Two-space'/>
                                                </div>
                                            ))
                                        
                                    }
                                    {this.state.index === index && !this.state.moreLoading &&
                                        <div>
                                            {this.state.group === 'enerstar' &&
                                                <Link style={{ textDecoration: 'none' }} to={{ pathname: '/location', state: { loc: notes.serial, title: notes.title, org: notes.group, version: notes.version} }}>
                                                    <button className="goto-device">go to device</button>
                                                </Link>
                                            }
                                            {this.state.group === 'multiloc' &&
                                                <Link style={{ textDecoration: 'none' }} to={{ pathname: '/location', state: { loc: notes.serial, title: notes.title, org: 'none', version: notes.version} }}>
                                                    <button className="goto-device">go to device</button>
                                                </Link>
                                            }
                                            <div className='One-space'/>
                                            <button onClick={() => this.handleClose()} className="Newnotes-drop-button">
                                                <div>less</div>
                                            </button>
                                        </div>

                                    }
                                </div>
                            }
 
                        
                        </div>
                    ))                   
                }
                {!this.state.loading &&                                       
                    this.state.noNotes.map((notes, index) => (
                        <div key={index}>
                        <div className='One-space'/>
                            <div className="NewNotes-wrapper">
                                <div style={{fontSize: '20px', color: '#529857'}}>Device:</div>
                                {this.state.group !== 'multiloc' &&
                                        <div style={{fontSize: '25px', color: 'white', fontWeight: 'bold'}}>{notes.group}</div>
                                }
                                <div style={{fontSize: '25px', color: 'white', fontWeight: 'bold'}}>{notes.title}</div>
                                <div style={{fontSize: '20px', color: '#529857'}}>Note:</div>
                                <div style={{fontSize: '25px', color: 'white', fontWeight: 'bold'}}>No Notes Found</div>
                                {this.state.group === 'enerstar' &&
                                    <Link style={{ textDecoration: 'none' }} to={{ pathname: '/location', state: { loc: notes.serial, title: notes.title, org: notes.group, version: notes.version} }}>
                                        <button className="goto-device">go to device</button>
                                    </Link>
                                }
                                {this.state.group === 'multiloc' &&
                                    <Link style={{ textDecoration: 'none' }} to={{ pathname: '/location', state: { loc: notes.serial, title: notes.title, org: 'none', version: notes.version} }}>
                                        <button className="goto-device">go to device</button>
                                    </Link>
                                }
                            </div>
                        </div>
                    ))                   
                }
                <div className='div-height'/>
            </div>
        )
    }
}

export default NewNotes