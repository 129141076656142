import React, { Component } from 'react';
import { Storage } from 'aws-amplify';

class Documents extends Component {

    constructor(props){
        super(props);

        this.state = {
            documents: [],
            noDocs: false
        }
    }

    async UNSAFE_componentWillReceiveProps(nextProps) {
        let receivedDocuments = nextProps.documents
        let noDocs = nextProps.noDocs

        this.setState({
            documents: receivedDocuments,
            noDocs: noDocs
        })
    }

    async downloadDoc(event){
        let url = this.click;
        const fileName = "documents/" + url;
        let urlForFetch = '';

        await Storage.get(fileName, { expire: 600 })
        .then(result => {
            urlForFetch = result;
        })
        .catch(err => console.log(err));

        window.location.assign(urlForFetch);
    }

    render(){
        return(
            <div>
                {this.state.noDocs &&
                    <div>
                        <div className='One-space'/>
                        <div className="title-two">no documents attached</div>
                    </div>
                }

                {!this.state.noDocs &&
                    this.state.documents.map((doc, index) =>  (
                        <button  key={index} className="Docs-button" onClick={(e) => this.downloadDoc(this.click = doc.filename)}>
                            <div>{doc.name}</div>
                            <div>{doc.uploadtime}</div>
                        </button>
                    ))
                }
            </div>
        )
    }

}

export default Documents
