import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';

class Verify extends Component {

  constructor(props){
    super(props)

    this.state = {
      username: '',
      code: '',
      password: '',
      loading: false
     }

     this.loggedIn = ''

    this.handleChange = this.handleChange.bind(this);
    this.resendCode = this.resendCode.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  async resendCode(event) {
    event.preventDefault();
    try {
        await Auth.resendSignUp(this.state.username.toLowerCase());
        alert('New code sent to your email.')
    } catch (error) {
        alert('Error enter your username and click resend code.')
    }
  }


  async handleConfirm(event) {
    event.preventDefault();

    let username = this.state.username.toLowerCase();
    let code = this.state.code.replace(/\s+/g, '');

    this.setState({loading: true})

    try {
        await Auth.confirmSignUp(username, code);
        alert("Email has been verified")

        try {
          await Auth.signIn(this.state.username.toLowerCase(), this.state.password);

        } catch (error) {
            if(error.code === 'UserNotFoundException'){
              alert('Username not found.')
              this.setState({loading: false})
              return
            }
            if(error.code === 'NotAuthorizedException'){
              alert('Incorrect password.')
              this.setState({loading: false})
              return
            }
            if(error.code === 'UserNotConfirmedException'){
              alert('Account email has not been verified please click resend verification code and enter the six digit code')
              this.setState({loading: false})
              this.props.history.push('/Verify');
            }
        }

        this.props.history.push('/Authenticate');
      } catch (error) {
          console.log(error);
          if(error.name === 'CodeMismatchException'){
            this.setState({loading: false})
            alert('Entered code is not correct please resend code and try again')
          }
      }

    }

    async componentDidMount(){

      Auth.currentAuthenticatedUser({
        bypassCache: false
        }).then(user => {
          this.loggedIn = true
        }
      )
      .catch(error => {
        if(error === 'not authenticated'){
          //this.props.history.push('/SignIn');
        }
      }
      );
      }

  render(){
    var backIcon = require('../Back-01.png');

    return(
      <div className="App">

        <div className="Nav-header">
          <Link style={{textDecoration: 'none'}} to="/SignIn">
            <button className="Back-wrap">
              <img className="Back-button" src={backIcon} alt="back"/>
            </button>
          </Link>
        </div>

        <div className="Title">Verify Account</div>

        {this.state.loading &&
          <div className="Settings-wrapper">
            <br />
            <div className="Loading-buttonstatus">Signing In...</div>
            <br />
          </div>
        }
        {!this.state.loading &&
          <div className="Settings-wrapper">

          <div className="form-name">username</div>
          <input value={this.state.username} name="username" className="Reading-name" type="text" onChange={this.handleChange}/>

          <button className="resend-link" onClick={this.resendCode} >Click to Resend Code</button>

          <div className="form-name">password</div>
          <input value={this.state.password} name="password" className="Reading-name" type="password" onChange={this.handleChange}/>


            <form onSubmit={this.handleConfirm}>
              <div className="form-name">verify email code (6 digits)</div>
              <input value={this.state.code} name="code" className="Reading-name" type="text" onChange={this.handleChange}/>
              <br />
              <br />
              <input type="submit" className="Add-button" value="submit"/>
            </form>
          </div>
      }
      </div>
    )
  }

}

export default Verify
