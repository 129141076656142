import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as queries from '../graphql/queries.js';

class OnboardDevice extends Component{
    constructor(props) {
        super(props)
        this.state = {
            serial: '',
            nickname: '',
            organization: '',
            version: '1',
            loading: false,
            userName: ''
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    async handleSubmit(event){
        event.preventDefault();
        if(this.state.serial === ''){
            alert('you must enter a valid serial number')
            return
        }
        if(this.state.nickname === ''){
            alert('you must enter a device name')
        }
        this.setState({loading: true});

        let serialFinal = this.state.serial;
        //let nicknameFinal = this.state.nickname;
        let organizationFinal = this.state.organization;
        
        // get all users within group linked to the parent group db
        const getUnits = `query GetUnits {
            getGroup(groupName: "${organizationFinal}") {
            id
            units(filter: {serial: {eq: "${serialFinal}"}}) {
                items {
                    id
                }
            }
            }
        }`
        let returnUsers = {}
        let orgUsers = []
        try{
            returnUsers = await API.graphql(graphqlOperation(getUnits));
            orgUsers = returnUsers.data.getGroup.units.items;
            console.log(orgUsers)
        } catch(error) {
            console.log(error)
            // alert('error getting organization users to create their buttons')
        }
    }

    async componentDidMount(){
        let userInfo = ''
        let userOrg = ''
        let userName = ''
        await Auth.currentAuthenticatedUser({
            bypassCache: false
            }).then(user => {
                userInfo = user
                userName = userInfo.username
            }
        )
        .catch(error => {
            if(error === 'not authenticated'){
                this.props.history.push('/SignIn');
            } else {
                alert('error fetching user info')
                return
            }
        }
        );
        try {
            let userData = await API.graphql(graphqlOperation(queries.getUser, { userName: userName }));
            userOrg = userData.data.getUser.groupName
        } catch(error) {
            alert('error fetching your user information please be sure your device has reception')
            return
        }

        this.setState({
            userName: userName,
            organization: userOrg
        });
    }

    render(){
        var backIcon = require('../Back-01.png');
        return(
            <div className='App'>
                {
                    <div className="Nav-header">
                        <Link style={{textDecoration: 'none'}} to="/MainSettings">
                        <button className="Back-wrap">
                            <img className="Back-button" src={backIcon} alt="back"/>
                        </button>
                        </Link>
                    </div>
                }
                <div className="Title">Onboard Device</div>
                <div className='Settings-wrapper'>
                    {this.state.loading &&
                        <div>
                            <div className='One-space'/>
                            <div className="Loading-inbanner">creating resources...</div>
                            <div className='One-space'/>
                        </div>
                    }
                    {!this.state.loading &&
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-name">serial number</div>
                            <input value={this.state.serial} name="serial" className="Reading-name" type="text" onChange={this.handleChange}/>
                            <div className="form-name">device name</div>
                            <input value={this.state.nickname} name="nickname" className="Reading-name" type="text" onChange={this.handleChange}/>
                            <div className='One-space'/>
                            <input type="submit" className="Icon-submit" value="submit"/>
                        </form>
                    }
                </div>
            </div>
        )
    }
}
export default OnboardDevice