import React, { Component } from 'react';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import { Link } from 'react-router-dom';
import * as queries from '../graphql/queries.js';

class EnterpriseInvite extends Component {
    constructor(props){
        super(props)

        this.state = {
            loading: true,
            entList: [],
            index: '',
            entName: '',
            hideCopyEmail: true
        }
        this.emailId = this.emailId.bind(this);
        this.close = this.close.bind(this);
    }

    copyId = (event) => {
        this.textArea.select();
        document.execCommand('copy');
        event.target.focus();
        alert('id copied to clipboard')
    };

      // pull up email on click
    emailId(event) {
        let emailBody = "You have been given an access id number for nowledgeapp.com. \n\nYour access id number is: " + this.state.id + "\n\nGo to nowledgeapp.com and click signup where it asks if you are new to nowledge. \n\nCopy and paste this number during the step where it asks for an access id number." 
        let mailToLink = "mailto:?subject=Invitation to nowledgeapp.com&body=" + encodeURIComponent(emailBody);
        window.location.href = mailToLink;
    }

    async close(event){
        this.setState({
            entName: '',
            id: '',
            loading: false,
            index: ''
        })
    }

    async handleClick(entName, index) {
        this.setState({
            index: index,
            id: 'loading....',
            hideCopyEmail: true,
            entName: entName
        });

        function getId() {
            const apiName = 'EntAuth';
            const path = '/items';

            const myInit = {
                queryStringParameters: {
                    level: 'enterprise',
                    entName: entName,
                    divisionName: 'null',
                    orgName: 'null',
                }
            };
            return API.get(apiName, path, myInit);
        }

        let response = ''
        try{
            response = await getId();
        } catch(error){
            console.log(error) 
        }

        if(response.status === 'success'){
            this.setState({
                loading: false,
                id: response.uuid,
                hideCopyEmail: false
            })
        } else {
            alert('error fetching organization id number')
        }
    }

    async componentDidMount(){
        Auth.currentAuthenticatedUser({
            bypassCache: false
        }).then(user => {
            this.loggedIn = true
            if(user.signInUserSession.accessToken.payload["cognito:groups"][0] !== "enerstar"){
                this.props.history.push('/');
            }
        }
        )
        .catch(error => {
            if(error === 'not authenticated'){
                this.props.history.push('/SignIn');
            }
        }
        );
        try{
            let data = await API.graphql(graphqlOperation(queries.listEnts, { limit: 10000 }));
            let orderedEnts = data.data.listEnts.items.sort((a, b) => a.entName.localeCompare(b.entName))

            this.setState({
                entList: orderedEnts,
                loading: false
            })
        } catch(error) {
            alert('there was an error selecting a group please try again')
        }
    }

    render() {
        var backIcon = require('../Back-01.png');

        return(
            <div className="App">
                {
                    <div className="Nav-header">
                        <Link style={{textDecoration: 'none'}} to="/Enterprise">
                            <button className="Back-wrap">
                            <img className="Back-button" src={backIcon} alt="back"/>
                            </button>
                        </Link>
                    </div>
                }
                <div className="Title">Enterprise Ids</div>
                {this.state.loading &&
                    <div className="Device-wrapper">
                        <div className='One-space'/>
                        <div className="Loading-buttonstatus">Loading...</div>
                        <div className='One-space'/>
                    </div>
                }
                {!this.state.loading &&
                    this.state.entList.map((ent, index) =>  (
                        <div key={index}>
                            {this.state.index === index &&
                                <div>
                                    {!this.state.loading &&
                                        <div className="Device-wrapper">
                                            <button className="copy-link"  onClick={this.close}>hide</button>
                                            <div className="notesdocopen-link">{this.state.entName}</div>
                                            <div className="form-name">enterprise id</div>
                                            <textarea className="copy-id" readOnly = {true} ref={(textarea) => this.textArea = textarea} value={this.state.id} />
                                            {!this.state.hideCopyEmail &&
                                                <div>
                                                    <div className='One-space'/>
                                                    <button onClick={this.copyId} className="confirm-link">copy</button>
                                                    <button onClick={this.emailId} className="confirm-link">email</button>
                                                    <div className='One-space'/>
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            }
                            {this.state.index !== index &&
                                <div>
                                    <div className='One-space'/>
                                    <div className="Device-wrapper">
                                        <button onClick={() => this.handleClick(ent.entName, index)} className="org-get-id">
                                            <div>{ent.entName}</div>
                                        </button>
                                    </div>
                                    <div className='One-space'/>
                                </div>
                            }
                        </div>
                    ))
                }                
            </div>
        )
    }
}

export default EnterpriseInvite
