import React, { Component } from 'react';
import { API, graphqlOperation, Storage } from 'aws-amplify';

class EntDocumentSubmit extends Component {
  constructor(props){
    super(props);

    let receivedBlogID = props.blogID
    this.userName = props.userName
    this.title = props.title

    this.state = {
      blogID: receivedBlogID,
      uploadingDoc: false,
      uploadPercent: '0%',
      fileNickName: ''
    }
    this.putFileSubmit = this.putFileSubmit.bind(this);
    this.handleFileName = this.handleFileName.bind(this);
    this.selectFile = this.selectFile.bind(this);
  }

async UNSAFE_componentWillReceiveProps(nextProps) {
    let receivedblogID = nextProps.blogID

    this.setState({
      blogID: receivedblogID
    })

  }

  handleFileName(event) {
    this.setState({fileNickName: event.target.value});
  }

  async selectFile(evt){

    this.file = evt.target.files[0];
    this.userFileName = evt.target.files[0].name;

    let lastDot = this.userFileName.lastIndexOf('.');
    this.fileType = this.userFileName.substring(lastDot);

  }

  async putFileSubmit(event){
    event.preventDefault();

    if(this.file === "" || this.state.fileNickName === ''){
      await this.setState({
        addDocForm: true,
        uploadingDoc: false,
        fileNickName: ''
      });
      alert("you must include a name and select a file");
      return;
    }

    await this.setState({
      addDocForm: false,
      uploadingDoc: true
    });


    var today = new Date();

    this.dateForData = (today.getMonth()+1)+'/'+today.getDate() + '/' + today.getFullYear();

    let file = this.file;

    let locationName = this.title;

    let lowerName = locationName.toLowerCase();
    let finalLocation = lowerName.replace(/\s+/g, '_');

    let fileNickname2 = this.state.fileNickName;
    let lowerNickname = fileNickname2.toLowerCase();
    let finalNickname = lowerNickname.replace(/\s+/g, '_');

    var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    var time = today.getHours() + '-' + today.getMinutes() + '-' + today.getSeconds() + '-' + today.getMilliseconds();
    var dateTime = date+"T"+time;

    let fileInfo = finalLocation + '_' + finalNickname + '_' + dateTime;

    this.fileFullName = finalLocation + '_' + finalNickname + '_' + dateTime + this.fileType

    let fileName = "documents/" + fileInfo + this.fileType;

    const forceState = this;

    try{
      await Storage.put(fileName, file, {
        contentDisposition: "attachment",
        progressCallback(progress) {
          let percent = progress.loaded / progress.total * 100
          let rounded = Math.round(percent);
          let percentView = `${rounded}%`
          forceState.setState({
            uploadPercent: percentView
          });
        },
      });
    } catch(error){
      alert('error uploading document')
    }

    const addToData = `mutation CreateDoc {
      createEntDoc(input: {
        entDocBlogId: "${this.state.blogID}"
        filename: "${this.fileFullName}"
        name: "${this.state.fileNickName}"
        uploadtime: "${this.dateForData}"
        username: "${this.userName}"
      }) {
        id
        name
        filename
        uploadtime
        username
      }
    }`;

    try{
      this.addDocResults = await API.graphql(graphqlOperation(addToData));
    } catch(error) {
      alert('error uploading document')
    }

    this.props.getDocs();

    let allDocs = 'test'

    await this.setState({
      docs: allDocs,
      addDocForm: true,
      uploadingDoc: false,
      fileNickName: '',
      uploadPercent: '0%',
      noDocs: false
    });

    this.file = "";

    alert("document uploaded");

  }

  render(){

    return(
      <div>
        { !this.state.uploadingDoc &&
          <form onSubmit={this.putFileSubmit}>
          <div style={{color: 'white', fontSize: '25px'}}>name</div>
          <input type="text" className="Text-line" placeholder="Enter file name here...." value={this.state.fileNickName} onChange={this.handleFileName}></input>
          <div style={{color: 'white', fontSize: '25px'}}>select file</div>
          <input type="file" onChange={this.selectFile} className="File-select"></input>
          <input type="submit" className="Show-all" value="upload"></input>
          </form>
        }
        { this.state.uploadingDoc &&
          <div>
            <div className="Loading-button">Uploading...</div>
            <div className="Loading-button">{this.state.uploadPercent}</div>
          </div>
        }
      </div>
    )

  }
}

export default EntDocumentSubmit
