import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect'

class NowButtons extends Component {

    constructor(props) {
        super(props);

        this.state = {
            devices: [],
            organizations: [],
            currentOrg: '',
            currentDevices: [],
            mobile: false
        }
    }

    async UNSAFE_componentWillReceiveProps(nextProps) {
        let receivedData = nextProps.data
        let receivedOrgs = nextProps.orgs

        this.setState({
            devices: receivedData,
            organizations: receivedOrgs
        })
    }

    async getDevices(orgName){

        if(orgName === this.state.currentOrg){
            this.setState({
                currentOrg: '',
                currentDevices: []
            })
            return
        }

        let fetchedDevices = this.state.devices
        let devicesMatchedToOrg = fetchedDevices.filter(({group}) => group.includes(orgName))
        let devicesMatchedOrdered = devicesMatchedToOrg.sort((a, b) => a.title.localeCompare(b.title, undefined, { numeric: true }))

        this.setState({
            currentOrg: orgName,
            currentDevices: devicesMatchedOrdered
        })
    }

    render() {
        return(
            <div className="App">
                
                { isMobile &&
                    this.state.organizations.map((org, index) => (
                        <div key={index}>
                            <div className='One-space'/>
                            <div className="Homebuttonwrapper-100">
                                <div className="Homebutton-100" onClick={(e) => this.getDevices(org)}>{org}</div>
                            </div>
                            { org === this.state.currentOrg &&
                                this.state.currentDevices.map((device, index) => (
                                <div  key={index}>
                                    <div className='One-space'/>
                                        <div className="Homebuttonwrapper-90">
                                            <Link style={{ textDecoration: 'none' }} to={{ pathname: '/location', state: { loc: device.location, title: device.title, org: device.group, version: device.version} }}>
                                                <button className="Homebutton-90">{device.title}</button>
                                            </Link>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    ))                    
                }

                { !isMobile &&
                    this.state.devices.map((device, index) => (
                        <Link key={index} style={{ textDecoration: 'none' }} to={{ pathname: '/location', state: { loc: device.location, title: device.title, org: device.group, version: device.version} }}>
                            <button className='Homebutton-20'>
                                <div className='Homebutton-org'>{device.group}</div>
                                <div className='Homebutton-nickname'>{device.title}</div>
                            </button>
                        </Link>
                    ))
                }

            </div>        
        )
    }
}

export default NowButtons