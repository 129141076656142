import React, { Component } from 'react';

class Status extends Component {


    constructor(props){
        super(props);

        this.state = {
            status: [],
            icons: [],
            noData: false,
            online: true,
            timestamp: '',
            error: '',
            errorMessage: '',
            loading: true,
            timeZone: 'America/Chicago'
        }
    }

    async UNSAFE_componentWillReceiveProps(nextProps) {
        let receivedData = nextProps.data
        let receivedIcons = nextProps.icons
        let receivedNoData = nextProps.noData
        this.timeZone = nextProps.timezone
        let receivedError = ''
        let errorMessage = ''
        let tempTimestamp = receivedData.timestamps
        let deviceOnline = true

        if(receivedNoData){
            this.setState({
                noData: true,
                loading: false
            })
            return
        }
        
        // check last timestamp for 6 minute or > delay
        let dateNow = new Date();
        let modTimestamp = '';
        let minLate = 360000;
        let finalTimestamp = ''

        if(this.timeZone === 'America/Chicago'){
            modTimestamp = tempTimestamp.replace(/\s/, 'T')

            // reformat timestamp
            let year = tempTimestamp.slice(0,4);
            let month = tempTimestamp.slice(5,7);
            let newMonth = Number(month);
            let day = tempTimestamp.slice(8,10);
            let newDay = Number(day);
            let hour = tempTimestamp.slice(11,13);
            let newHour = Number(hour)
            let minute = tempTimestamp.slice(14,16);
            let amPm = "am";
            if(newHour === 12){
                amPm = "pm"
            };
            if(newHour > 12){
                newHour = newHour - 12
                amPm = "pm"
            };
            if(hour === "00"){
                newHour = "12"
            };
            let reform = newMonth + "/" + newDay + "/" + year + " " + newHour + ":" + minute + amPm;
            finalTimestamp = reform;
        } else {
            let dateObject = new Date(tempTimestamp)
            let changedStamp = new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'long', timeZone: this.timeZone }).format(dateObject)
            let monthZone = changedStamp.slice(3,5)
            let dayZone = changedStamp.slice(0,2)
            let yearZone = changedStamp.slice(6,10)
            let hourZone = changedStamp.slice(12,14)
            let newHourZone = Number(hourZone)
            let minuteZone = changedStamp.slice(15,17)
            let milliSecondsZone = changedStamp.slice(18,20)
            let amPm = "am"
            if(newHourZone === 12){
                amPm = "pm"
            };
            if(newHourZone > 12){
                newHourZone = newHourZone - 12
                amPm = "pm"
            };
            if(hourZone === "00"){
                newHourZone = "12"
            };
            
            modTimestamp = yearZone + "-" + monthZone + "-" + dayZone + "T" + hourZone + ":" + minuteZone + ":" + milliSecondsZone

            let reform = monthZone + "/" + dayZone + "/" + yearZone + " " + newHourZone + ":" + minuteZone + amPm;
            finalTimestamp = reform;
        }

        if((dateNow - new Date(modTimestamp)) > minLate) {
            deviceOnline = false;
        }
        else{
            deviceOnline = true;
        }

        // handle an error message sent from device
        if(typeof receivedData["device_status"] === 'undefined'){
            receivedError = false;
        } else {
            receivedError = true;
            errorMessage = receivedData["device_status"];
        }

        this.setState({
            status: receivedData,
            icons: receivedIcons,
            noData: receivedNoData,
            online: deviceOnline,
            timestamp: finalTimestamp,
            error: receivedError,
            errorMessage: errorMessage,
            loading: false
        });
        

        if(receivedNoData){
            this.setState({
                noData: true,
                loading: false
            })
            return
        }
        


    }

    renderStatus(newStatus) {

        var imageCheck = require('../Nowledge-App-Icons-CHECK.png')
        var imageCaution = require('../Nowledge-App-Icons-CAUTION.png')
        var imageStop = require('../Nowledge-App-Icons-STOP.png')

        return (
            this.state.icons.map((icon, index) =>  (
                Object.keys(newStatus).map(function(keyName, keyIndex){
                    return(
                        <div key={keyIndex}>
                            { icon.value === keyName &&
                                <button  key={keyIndex} className="Status-button">
                                    {newStatus[keyName] <= icon.lowest &&
                                        <img className="Emoji-icon" src={imageStop} alt="stop" />
                                    }
                                    {newStatus[keyName] > icon.lowest && newStatus[keyName] <= icon.low &&
                                        <img className="Emoji-icon" src={imageCaution} alt="caution" />
                                    }
                                    {newStatus[keyName] > icon.low && newStatus[keyName] < icon.high &&
                                        <img className="Emoji-icon" src={imageCheck} alt="check" />
                                    }
                                    {newStatus[keyName] >= icon.high && newStatus[keyName] < icon.highest &&
                                        <img className="Emoji-icon" src={imageCaution} alt="caution" />
                                    }
                                    {newStatus[keyName] >= icon.highest &&
                                        <img className="Emoji-icon" src={imageStop} alt="stop" />
                                    }
                                    <div className="Status-title">{keyName.replace(/_/g, ' ')}</div>
                                    <div className="Status-reading">{newStatus[keyName]}</div>
                                </button>
                            }
                        </div>
                    )
                })
            ))
        )
    }

    render(){

        return(
            <div className="App">
                <div className="Status-wrapper">
                {this.state.loading &&
                    <div>
                        <br />
                        <div className="Loading-buttonstatus">Loading...</div>
                        <br />
                    </div>
                }

                {this.state.noData &&
                    <div>
                        <br />
                        <div style={{color: '#d42d0a', fontSize: '30px', marginBottom: '5px'}}>no device data found</div>
                        <br />
                    </div>
                }

                {!this.state.loading &&
                    <div className="Status-container">
                        {this.renderStatus(this.state.status)}
                    </div>
                }

                {this.state.error &&
                    <div>
                        <div style={{color: '#d42d0a', fontSize: '25px', marginBottom: '5px'}}>{this.state.errorMessage}</div>
                    </div>
                }

                {
                    <button className="Timestamp-button">
                        <div>{this.state.timestamp}</div>
                    </button>
                }

                {this.state.online && !this.state.loading && !this.state.noData &&
                    <div style={{color: '#529857', fontSize: '20px', marginBottom: '0px'}}>online</div>
                }
                {!this.state.online && !this.state.loading && !this.state.noData &&
                    <div style={{color: '#d42d0a', fontSize: '20px', marginBottom: '0px'}}>offline</div>
                }
                </div>
            </div>
        )
    }
}

export default Status
