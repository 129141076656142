import React, { Component } from 'react';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import { createDevice, createBlog } from '../graphql/mutations';
import { Link } from 'react-router-dom';
import * as queries from '../graphql/queries.js';
import * as mutations from '../graphql/mutations';


class Onboard extends Component{

    constructor(props){
        super(props)

        this.state = {
        serial: '',
        nickname: '',
        group: '',
        version: 1,
        loading: false,
        loadingGroups: false,
        selectGroupDrop: false,
        groupList: [],
        selectedGroup: '',
        userName: '',
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.selectGroup = this.selectGroup.bind(this);
        this.selectGroupHide = this.selectGroupHide.bind(this);
        this.removeGroups = this.removeGroups.bind(this);
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    // drop down groups fetch all groups sort alpha
    async selectGroup(event){
        this.setState({
            selectGroupDrop: true,
            loadingGroups: true
        })

        try{
            let data = await API.graphql(graphqlOperation(queries.listGroups, { limit: 1000 }));
            data.data.listGroups.items.sort((a, b) => a.groupName.localeCompare(b.groupName))

            this.setState({
                groupList: data.data.listGroups.items,
                loadingGroups: false
            })
        } catch(error) {
            alert('there was an error selecting a group please try again')
        }
    }

    // hide button to hide all group bring back select group
    async selectGroupHide(event){
        this.setState({
            selectGroupDrop: false,
            selectedGroup: ''
        })
    }

    // click selected group display groupname below with button to remove
    async addGroups(groupName){
        this.setState({
            selectedGroup: groupName,
            selectGroupDrop: false,
        })
    }

    // click remove button empty group state display select group
    async removeGroups(event){
        this.setState({
            selectedGroup: '',
        })
    }

    // submit onboarding
    async handleSubmit(event) {
        event.preventDefault();

        if(this.state.serial === ''){
            alert("serial number cannot be blank")
            this.setState({loading:false});
            return
        }
        if(this.state.nickname === ''){
            alert("device name cannot be blank")
            this.setState({loading:false});
            return
        }
        if(this.state.selectedGroup === ''){
            alert('please select a group before onboarding')
            this.setState({loading: false})
            return
        }


        let serialFinal = this.state.serial.trim();
        let nicknameFinal = this.state.nickname;
        let groupFinal = this.state.selectedGroup;
        
        this.setState({loading: true});

        // check inventory to confirm the device by that serial number exists
        // and that the software has been loaded
        const apiNameCheck = 'InventoryCheck';
        const pathCheck = '/items';
        let checkedInventory = {};

        const myInitCheck = {
            queryStringParameters: {
                name: serialFinal
            }
        };
        try{
            checkedInventory = await API.get(apiNameCheck, pathCheck, myInitCheck);
        } catch(error) {
            console.log(error)
            alert('an error occurred accessing the inventory database please try again')
            return
        }
        if(checkedInventory.status === 'fail'){
            alert('an error occurred accessing the inventory database please try again')
            return
        }
        if(checkedInventory.status === 'no device'){
            alert('the device was not found in inventory check that you have the correct serial number and try again')
            return
        }

        let createdDate = ''
        let onboardCheck = ''

        if(checkedInventory.status === 'success'){
            // set to send on the mutation to inventory
            createdDate = checkedInventory.return.createdAt.S;
            onboardCheck = checkedInventory.return.onboard.S;
        }

        // being the admin group onboard should always be false at this point
        if(onboardCheck === 'true'){
            alert("device is already onboarded")
            this.setState({loading:false});
            return
        };

        // create button for admin group with nickname provided
        try{
            await API.graphql(graphqlOperation(createDevice, { input: { group: groupFinal, location: serialFinal, title: nicknameFinal, version: this.state.version }}));
        } catch(error) {
            console.log(error)
            alert('error adding the button for nowledge groupmembers')
            this.setState({loading: false})
        }
        // create entry in parent db for icons/notes/docs
        try{
            await API.graphql(graphqlOperation(createBlog, { input: { location: serialFinal }}));
        } catch(error) {
            console.log(error)
            alert('error creating device entry into database')
            this.setState({loading: false})
            return
        }
        
        // onboard device lambda
        function addDevice() {
            const apiName = 'OnboardDevice';
            const path = '/device';
            const myInit = {
                body: {
                    serial: serialFinal
                }
            };
            return API.put(apiName, path, myInit);
        }
        let response = {}
        try{
            response = await addDevice();
        } catch(error) {
            console.log(error)
            alert('error creating resources for device')
            this.setState({loading: false})
            return
        }

        
        if(response.error === "true"){
            this.setState({loading: false});
            alert('error creating resources for device')
            return
        }

        // get current time to use as onboard time in inventory db
        let timeNowUnix = Date.now();
        let timeNow = new Date(timeNowUnix);
        let timeNowIso = timeNow.toISOString();

        // update inventory database with serial/group name/onboard time/ nickname
        function updateInventory() {
            const apiName2 = 'DeviceInventory';
            const path2 = '/info';
            const myInit2 = {
                body: {
                    serial: serialFinal,
                    certificates: "true",
                    createdAt: createdDate,
                    group: groupFinal,
                    onboard: "true",
                    onboardedAt: timeNowIso,
                    title: nicknameFinal,
                }
            };
            return API.put(apiName2, path2, myInit2);
        }
        try{
            await updateInventory();
        } catch(error) {
            console.log(error)
            alert('error updating device information in inventory database')
        }

        // add unit to the units db linked to the parent group db
        const addUnit = `mutation CreateUnit {
            createUnit(input: {
                nickName: "${nicknameFinal}"
                serial: "${serialFinal}"
                unitGroupId: "${groupFinal}"
                version: 1
            }) {
                id
            }
        }`
        try{
            await API.graphql(graphqlOperation(addUnit));
        } catch(error) {
            console.log(error)
            alert('error adding unit to database used for onboarding users')
        }
        
        // get all users within group linked to the parent group db
        const getUsers = `query GetUsers {
            getGroup(groupName: "${groupFinal}") {
            id
            users {
                items {
                    id
                    userName
                }
            }
            }
        }`
        let foundUsers = false
        let returnUsers = {}
        let orgUsers = []
        try{
            returnUsers = await API.graphql(graphqlOperation(getUsers));
            orgUsers = returnUsers.data.getGroup.users.items;
            foundUsers =  true
        } catch(error) {
            console.log(error)
            // alert('error getting organization users to create their buttons')
        }

        if(foundUsers === true){
            // for each member in the group create a button for the new device
            try{
                orgUsers.forEach( async function(user){
                    await API.graphql(graphqlOperation(mutations.createMultidevice,
                    { input: { group: user.userName, location: serialFinal,  title: nicknameFinal, version: 1 }}));
                })
            } catch(error) {
                console.log(error)
                // alert('error creating button for all organization users some users may not see the new device')
            }
        }

        // after a success onboard reset all states
        this.setState({
            serial: '',
            nickname: '',
            loading: false,
            selectedGroup: '',
        })
        alert("new device was onboarded")

    }

    async componentDidMount(){
        let userInfo = ''
        let userGroup = ''
        await Auth.currentAuthenticatedUser({
            bypassCache: false
            }).then(user => {
                userInfo = user
                userGroup = user.signInUserSession.accessToken.payload["cognito:groups"]
            }
        )
        .catch(error => {
            if(error === 'not authenticated'){
                this.props.history.push('/SignIn');
            } else {
                alert('error fetching user info')
                return
            }
        }
        );

        this.setState({
            group: userGroup[0],
            userName: userInfo.username
        });
    }

    render(){
        var backIcon = require('../Back-01.png');

        return(
            <div className="App">
                {
                    <div className="Nav-header">
                        <Link style={{textDecoration: 'none'}} to="/MainSettings">
                        <button className="Back-wrap">
                            <img className="Back-button" src={backIcon} alt="back"/>
                        </button>
                        </Link>
                    </div>
                }
                <div className="Title">Onboard Device</div>
                <div className="Settings-wrapper">
                    { this.state.loading &&
                        <div>
                            <div className='One-space'/>
                            <div className="Loading-inbanner">creating resources...</div>
                            <div className='One-space'/>
                        </div>
                    }
                    { !this.state.loading && 
                        <div>
                            {this.state.selectedGroup === '' &&
                                <div>
                                    <div className='One-space'/>
                                    <div className="form-name">Include another Organization?</div>
                                </div>
                            }
                            {!this.state.selectGroupDrop && this.state.selectedGroup === '' &&
                                <div>
                                    <button className="Dropdown-button-full" onClick={this.selectGroup}>select</button>
                                    <div className='One-space'/>
                                </div>
                            }
                            {this.state.selectGroupDrop &&
                                <button className="Dropdown-button-full" onClick={this.selectGroupHide}>hide</button>
                            }
                            {this.state.selectGroupDrop &&
                                <Link style={{ textDecoration: 'none' }} to={{ pathname: '/Organization' }}>
                                    <button className="Add-button" style={{fontSize: '15pt'}} >new organization</button>
                                </Link>
                            }
                            {this.state.loadingGroups &&
                                <div className='form-name'>Loading...</div>
                            }
                            {this.state.selectGroupDrop &&
                                this.state.groupList.map((group, index) => (
                                    <div className="resend-link" key={index} onClick={() => this.addGroups(group.groupName, index)}>{group.groupName}</div>
                                ))
                            }
                            {this.state.selectedGroup !== '' &&
                                <div>
                                    <div className='One-space'/>
                                    <div className="form-name">you have selected....</div>
                                    <div className="Title">{this.state.selectedGroup}</div>
                                    <button className="Dropdown-button-full" onClick={this.removeGroups}>remove</button>
                                    <div className='One-space'/>
                                </div>
                            }
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-name">serial number</div>
                                <input value={this.state.serial} name="serial" className="Reading-name" type="text" onChange={this.handleChange}/>
                                <div className="form-name">device name</div>
                                <input value={this.state.nickname} name="nickname" className="Reading-name" type="text" onChange={this.handleChange}/>
                                <div className='One-space'/>
                                <input type="submit" className="Add-button" value="submit"/>
                            </form>
                        </div>
                    }
                </div>
                <div className='Two-space'/>
            </div>
        )
    }
}

export default Onboard
