import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {S3Image} from 'aws-amplify-react';
import { isMobile } from 'react-device-detect'


class Intro extends Component {
    constructor(props){
        super(props)

        this.state = {
            width: ""
        }

    }

    async componentDidMount(){
        let width = '50%'
        if(isMobile){
            width = '50%'
        } else {
            width = '15%'
        }
        this.setState({width: width})
    }

    render(){
        var backIcon = require('../Back-01.png');

        return(
            <div className="App">
                <div className="Nav-header">
                    <Link style={{textDecoration: 'none'}} to="/SignIn">
                        <button className="Back-wrap">
                            <img className="Back-button" src={backIcon} alt="back"/>
                        </button>
                    </Link>
                </div>

                <div className="Title">Congratulations!!!</div>
                <div className='One-space'/>

                <div className="Settings-wrapper">
                    <div className='One-space'/>
                    <div className="form-name">You have successfully been added to the organization and are ready to start using nowledge.</div>
                    <div className='One-space'/>
                </div>

                <div className='Two-space'/>
                <div className="form-name">To begin using the app you can click the start button below but we would recommend scrolling down for a short introduction</div>

                <Link style={{textDecoration: 'none'}} to="/">
                    <button className='Add-button'>
                        start
                    </button>
                </Link>
                <div className='Two-space'/>

                <div className="form-name">Tip: If you want easier access to Nowledge from your phone/tablet go to nowledgeapp.com in your browser and click add to homescreen. Add to homescreen can be found by clicking the box with an arrow icon in iphone/ipad and on android click the three dot menu.</div>
                <div className='Two-space'/>
                <div className='Two-space'/>

                <div className="form-name">Clicking the overview button shows you a status over all devices within your network. You can look at what readings are currently in alarm, devices that are offline and all the newest notes posted.</div>
                <S3Image imgKey={'images/home.png'} theme={{ photoImg: { width: this.state.width } }} alt='homescreen'/>
                <div className='Two-space'/>
                <div className='Two-space'/>

                <div className="form-name">Clicking the overview button shows you a status over all devices within your network. You can look at what readings are currently in alarm, devices that are offline and all the newest notes posted.</div>
                <S3Image imgKey={'images/overview.png'} theme={{ photoImg: { width: this.state.width } }} alt='main settings'/>
                <div className='Two-space'/>
                <div className='Two-space'/>

                <div className="form-name">Clicking on a device will bring you to that devices data page. This will show you the current status of the devices readings with a timestamp. Below are five icons used to access all data connected to that device. Graphs is selected by default and you can examine the data by day/week/month.</div>
                <S3Image imgKey={'images/locgraphs.png'} theme={{ photoImg: { width: this.state.width } }} alt='status'/>
                <div className='Two-space'/>
                <div className='Two-space'/>

                <div className="form-name">Clicking the logs icon will bring up any recent readings out of range and device offline alarms.</div>
                <S3Image imgKey={'images/logs.png'} theme={{ photoImg: { width: this.state.width } }} alt='graphs'/>
                <div className='Two-space'/>
                <div className='Two-space'/>

                <div className="form-name">Clicking the spreadsheet icon will allow you to download a spreadsheet of the devices data. You can select a week, month or all of the data saved. A custom option is available if you want a particular timeline and/or select which readings to download.</div>
                <S3Image imgKey={'images/spreadsheet.png'} theme={{ photoImg: { width: this.state.width } }} alt='docs notes'/>
                <div className='Two-space'/>
                <div className='Two-space'/>

                <div className="form-name">Clicking the notes icon will bring up all notes attached to that device. You can also post a note here including notes with an image.</div>
                <S3Image imgKey={'images/notes.png'} theme={{ photoImg: { width: this.state.width } }} alt='device settings'/>
                <div className='Two-space'/>
                <div className='Two-space'/>

                <div className="form-name">Clicking the documents image will bring up all documents associated with that device. You can post nearly any type of document or file you would like linked to this device.</div>
                <S3Image imgKey={'images/docs.png'} theme={{ photoImg: { width: this.state.width } }} alt='icon ranges'/>
                <div className='Two-space'/>
                <div className='Two-space'/>

                <div className="form-name">Clicking the gear in the upper right corner brings you to that devices settings. Here you can program the device, sign up for email alerts and set the alarm ranges.</div>
                <S3Image imgKey={'images/settings.png'} theme={{ photoImg: { width: this.state.width } }} alt='device set'/>
                <div className='Two-space'/>
                <div className='Two-space'/>

                <div className="form-name">Now that you have learned how to use nowledge's features click the start button below to begin.</div>
                <Link style={{textDecoration: 'none'}} to="/">
                    <button className='Add-button'>
                        start
                    </button>
                </Link>
                <div className = "div-height" />
            </div>
        )
        }
    }

export default Intro
