import React, { Component } from 'react';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';

class Organization extends Component {

    constructor(props) {
        super(props)

        this.state = {
            orgName: '',
            id: '',
            createid: false,
            loading: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.finishedAdd = this.finishedAdd.bind(this);
        this.emailId = this.emailId.bind(this);
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    async handleSubmit(event) {
        event.preventDefault();
        if(this.state.orgName === ''){
            alert('organization name can not be blank')
            return
        }
        this.setState({ loading: true })
        let organizationName = this.state.orgName.trim()

        function addOrg(organizationName) {
            const apiName = 'organizationid';
            const path = '/items';

            const myInit = {
                body: {
                    orgName: organizationName
                }
            };
            return API.post(apiName, path, myInit);
        }

        let response = await addOrg(organizationName);

        if (response['status'] === 'success') {

            const addGroup = `mutation CreateGroup { createGroup(input: { groupName: "${organizationName}" }) { id }}`

            //add group to parent group db
            try {
                await API.graphql(graphqlOperation(addGroup));
            } catch (error) {
                let groupFound = error.errors[0].errorType
                if (groupFound === "DynamoDB:ConditionalCheckFailedException") {
                    alert('group was already in the system')
                }
            };

            const addOrgLink = `mutation CreateGroupLink { createOrganizationLink(input: { orgName: "${organizationName}" }) { id }}`

            //add group to parent group link db
            try {
                await API.graphql(graphqlOperation(addOrgLink));
            } catch (error) {
                let groupFound = error.errors[0].errorType
                if (groupFound === "DynamoDB:ConditionalCheckFailedException") {
                    alert('group was already in the system')
                }
            };

            this.setState({
                id: response['uuid'],
                createid: true,
                loading: false
            })
        } else {
            alert('error adding organization please try again')
        }
    }

    copyId = (event) => {
        this.textArea.select();
        document.execCommand('copy');
        event.target.focus();
        alert('id copied')
    };

    async finishedAdd(event) {
        this.setState({
            orgName: '',
            id: '',
            createid: false,
            loading: false
        })
    }

    // pull up email on click
    emailId(event) {
        let emailBody = "You have been given an access id number for nowledgeapp.com. \n\nYour access id number is: " + this.state.id + "\n\nGo to nowledgeapp.com and click signup where it asks if you are new to nowledge. \n\nCopy and paste this number during the step where it asks for an access id number."
        let mailToLink = "mailto:?subject=Invitation to nowledgeapp.com&body=" + encodeURIComponent(emailBody);
        window.location.href = mailToLink;
    }

    async componentDidMount() {
        Auth.currentAuthenticatedUser({
            bypassCache: false
        }).then(user => {
            this.loggedIn = true
        }
        )
            .catch(error => {
                if (error === 'not authenticated') {
                    this.props.history.push('/SignIn');
                }
            }
            );

    }

    render() {
        var backIcon = require('../Back-01.png');

        return (
            <div className="App">
                {
                    <div className="Nav-header">
                        <Link style={{ textDecoration: 'none' }} to="/MainSettings">
                            <button className="Back-wrap">
                                <img className="Back-button" src={backIcon} alt="back" />
                            </button>
                        </Link>
                    </div>
                }
                <div className="Title">Add Organization</div>
                <div className="Settings-wrapper">
                    {this.state.loading &&
                        <div className="Settings-wrapper">
                            <div className='One-space'/>
                            <div className="Loading-buttonstatus">Loading...</div>
                            <div className='One-space'/>
                        </div>
                    }
                    {!this.state.createid && !this.state.loading &&
                        <form onSubmit={this.handleSubmit}>
                            <div className='One-space'/>
                            <div className="form-name">organization name</div>
                            <input value={this.state.orgName} name="orgName" className="Reading-name" type="text" onChange={this.handleChange} />
                            <div className='One-space'/>
                            <input type="submit" className="Add-button" value="submit" />
                            <div className='One-space'/>
                        </form>
                    }
                    {this.state.createid && !this.state.loading &&
                        <div>
                            <button className="Dropdown-button" onClick={this.finishedAdd}>close</button>
                            <div className='One-space'/>
                            <div className="form-name">organization name</div>
                            <div className="title-two">{this.state.orgName}</div>
                            <div className='One-space'/>
                            <div className="form-name">organization id</div>
                            <textarea className="copy-id" readOnly={true} ref={(textarea) => this.textArea = textarea} value={this.state.id} />
                            <div className='Two-button-wrapper'>
                                <div className='One-space'/>
                                <button onClick={this.copyId} className="Two-button">copy</button>
                                <button onClick={this.emailId} className="Two-button">email</button>
                            </div>
                            <div className='One-space'/>
                        </div>
                    }
                </div>
                <div className='One-space' />
            </div>
        )
    }
}

export default Organization
