import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as queries from '../graphql/queries.js';
import RemoveDivOrg from "../components/RemoveDivOrg"

class EntAddDivOrg extends Component {

    constructor(props){
        super(props)

        this.state = {
            enterprise: '',
            division: '',
            organization: '',
            group: '',
            userName: '',
            divArray: [],
            orgArray: [],
            noDivs: false,
            dropDownDivs: false,
            selectedDiv: '',
            loadingDivs: false,
            loadingOrgs: false,
            loading: true
        }
        this.handleChange = this.handleChange.bind(this);
        this.submitDiv = this.submitDiv.bind(this);
        this.submitOrg = this.submitOrg.bind(this);
        this.selectDiv = this.selectDiv.bind(this);
        this.addDiv = this.addDiv.bind(this);
        this.removeDiv = this.removeDiv.bind(this);
        this.refreshDivsOrgs = this.refreshDivsOrgs.bind(this);
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    async selectDiv(event){
            this.setState({
            dropDownDivs: true
        })
    }

    async hideDivs(event){
            this.setState({
            dropDownDivs: false
        })
    }

    async addDiv(div){
            this.setState({
            selectedDiv: div
        })
    }

    async removeDiv(event){
        this.setState({
            dropDownDivs: false,
            selectedDiv: ''
        })
    }

    async refreshDivsOrgs(userEnt){

        let getentDivsOrgs = `query GetEntDivsOrgs {
            getEnt(entName: "${userEnt}") {
                id
                entdivsorgs {
                items {
                    id
                    entName
                    divName
                    orgName
                    orgName
                    isOrg
                }
                }
            }
        }`

        let divsOrgs = []

        try{
            let returnDivsOrgs = await API.graphql(graphqlOperation(getentDivsOrgs));
            divsOrgs = returnDivsOrgs.data.getEnt.entdivsorgs.items
        } catch(error) {
            console.log(error)
        };

        let finalDivs = []
        let finalOrgs = []

        divsOrgs.forEach(function (unit) {
            if(unit.isOrg === 'false'){
                finalDivs.push(unit)
            } else {
                finalOrgs.push(unit)
            }
        })

        let noDivsFound = false

        if(finalDivs.length === 0){
            noDivsFound = true
        }

        finalDivs.sort((a,b) => a.divName.localeCompare(b.divName, undefined, { numeric: true }))
        finalOrgs.sort((a,b) => a.divName.localeCompare(b.divName, undefined, { numerica: true }))

        this.setState({
            divArray: finalDivs,
            orgArray: finalOrgs,
            noDivs: noDivsFound
        });
    }

    async submitDiv(event) {
        event.preventDefault();

        this.setState({loadingDivs: true})

        if(this.state.division === ''){
            this.setState({loadingDivs: false})
            alert('division name can not be blank')
            return
        }

        let divName = this.state.division
        let entName = this.state.enterprise
        let orgName = 'null'

        function createDiv() {
            const apiName = 'EntAuth';
            const path = '/items';

            const myInit = {
                body: {
                    entName: entName,
                    divisionName: divName,
                    orgName: orgName,
                    level: "2"
                }
            };
            return API.post(apiName, path, myInit);
        }

        try{
            await createDiv();
        } catch(error){
            this.setState({loadingDivs: false})
            alert('error creating division id number')
            return
        }

        const addDiv = `mutation CreateEntDivsOrgs {
            createEntDivsOrgs(input: {
                entName: "${entName}"
                divName: "${divName}"
                orgName: "null"
                isOrg: "false"
                entDivsOrgsEntId: "${entName}"
            }) {
                id
            }
        }`

        try{
            await API.graphql(graphqlOperation(addDiv));
            await this.refreshDivsOrgs(entName)
            alert('new division was created')
        } catch(error){
            console.log(error)
            this.setState({loadingDivs: false})
            alert('error adding division please try again')
        }

        this.setState({
        division: '',
        loadingDivs: false
        })
    }

    async submitOrg(event){
        event.preventDefault();

        if(this.state.organization === ''){
            alert('organization name can not be blank')
        }

        if(this.state.selectedDiv === '' && !this.state.noDivs){
            this.setState({loadingOrgs: false})
            alert('please select a div before creating an organization')
            return
        }

        this.setState({loadingOrgs: true})

        let divName = this.state.selectedDiv
        let entName = this.state.enterprise
        let orgName = this.state.organization

        if(this.state.noDivs){
            divName = 'null'
        }

        function createOrg() {
            const apiName = 'EntAuth';
            const path = '/items';

            const myInit = {
                body: {
                    entName: entName,
                    divisionName: divName,
                    orgName: orgName,
                    level: "3"
                }
            };
            return API.post(apiName, path, myInit);
        }

        try{
            await createOrg();
        }catch(error){
            console.log(error)
            this.setState({loadingOrgs: false})
            alert('error adding organization please try again')
        }

        const addOrg = `mutation CreateEntDivsOrgs {
            createEntDivsOrgs(input: {
                entName: "${entName}"
                divName: "${divName}"
                orgName: "${orgName}"
                isOrg: "true"
                entDivsOrgsEntId: "${entName}"
            }) {
                id
            }
        }`

        try{
            await API.graphql(graphqlOperation(addOrg));
            await this.refreshDivsOrgs(entName)
            this.setState({loadingOrgs: false})
            alert('new organization has been created')
        } catch(error){
            console.log(error)
            this.setState({loadingOrgs: false})
            alert('error adding new organization please try again')
        }

        this.setState({
            organization: '',
            selectedDiv: '',
            dropDownDivs: false
        })
    }

    async componentDidMount(){

        Auth.currentAuthenticatedUser({
            bypassCache: false
        }).then(user => {
            this.loggedIn = true
        }
        )
        .catch(error => {
            if(error === 'not authenticated'){
                this.props.history.push('/SignIn');
            }
        }
        );

        let user = await Auth.currentAuthenticatedUser()
        let userGroupGet = user.signInUserSession.accessToken.payload["cognito:groups"];
        const userName = user.username;

        let userInfo = await API.graphql(graphqlOperation(queries.getEntUser, { userName: userName }));

        let userEnt = userInfo.data.getEntUser.entName

        let getentDivsOrgs = `query GetEntDivsOrgs {
            getEnt(entName: "${userEnt}") {
                id
                entdivsorgs {
                items {
                    id
                    entName
                    divName
                    orgName
                    orgName
                    isOrg
                }
                }
            }
        }`

        let divsOrgs = []

        try{
            let returnDivsOrgs = await API.graphql(graphqlOperation(getentDivsOrgs));
            divsOrgs = returnDivsOrgs.data.getEnt.entdivsorgs.items
        } catch(error) {
            console.log(error)
        };

        let finalDivs = []
        let finalOrgs = []

        divsOrgs.forEach(function (unit) {
            if(unit.isOrg === 'false'){
                finalDivs.push(unit)
            } else {
                finalOrgs.push(unit)
            }
        })

        let noDivsFound = false

        if(finalDivs.length === 0){
            noDivsFound = true
        }

        finalDivs.sort((a,b) => a.divName.localeCompare(b.divName, undefined, { numeric: true }))
        finalOrgs.sort((a,b) => a.orgName.localeCompare(b.orgName, undefined, { numeric: true }))

        this.setState({
            group: userGroupGet[0],
            userName: user.username,
            enterprise: userEnt,
            divArray: finalDivs,
            orgArray: finalOrgs,
            noDivs: noDivsFound,
            loading: false
        });

    }

  render() {
    var backIcon = require('../Back-01.png');

    return(
      <div className="App">
        {
            <div className="Nav-header">
                <Link style={{textDecoration: 'none'}} to="/SettingsPage">
                <button className="Back-wrap">
                    <img className="Back-button" src={backIcon} alt="back"/>
                </button>
                </Link>
            </div>
        }
        {
            <div className="Title">Create a Division or Organization</div>
        }
        <div className="Settings-wrapper">
            {this.state.loading &&
                <div>
                    <div className='One-space'/>
                    <div className="Loading-buttonstatus">Loading....</div>
                    <div className='Two-space'/>
                </div>
            }
            {this.state.loadingDivs &&
                <div>
                    <div className='One-space'/>
                    <div className="Loading-buttonstatus">Creating...</div>
                    <div className='Two-space'/>
                </div>
            }
            {!this.state.loadingDivs && !this.state.loading &&
                <form onSubmit={this.submitDiv}>
                    <div className='One-space'/>
                    <div className="Title">Create Division</div>
                    <input value={this.state.division} name="division" className="Reading-name" type="text" onChange={this.handleChange}/>
                    <div className='One-space'/>
                    <input type="submit" className="submit-buttons" value="submit"/>
                    <div className='One-space'/>
                </form>
            }
        </div>
        <div className='One-space'/>
        <div className="Settings-wrapper">
            {this.state.loadingOrgs &&
                <div>
                    <div className='One-space'/>
                    <div className="Loading-buttonstatus">Creating...</div>
                    <div className='Two-space'/>
                </div>
            }
            {!this.state.loadingOrgs && !this.state.loading &&
                <div>
                    <div className='One-space'/>
                    <div className="Title">Create Organization</div>
                </div>
                
            }
            {!this.state.loading && !this.state.loadingOrgs && !this.state.dropDownDivs && this.state.selectedDiv === '' && !this.state.noDivs &&
                <div>
                    <div className="form-name">Select a Division</div>
                </div>
            }
            {!this.state.loading && !this.state.loadingOrgs && !this.state.dropDownDivs && this.state.selectedDiv === '' && !this.state.noDivs &&
                <div>
                    <button className="resend-link" onClick={this.selectDiv}>select</button>
                    <div className='One-space'/>
                </div>
            }
            {!this.state.loading && !this.state.loadingOrgs && this.state.dropDownDivs && this.state.selectedDiv === '' && !this.state.noDivs &&
                <div>
                    <div className="form-name">select division below</div>
                </div>
            }
            {!this.state.loading && !this.state.loadingOrgs &&
                <div>
                    {this.state.dropDownDivs && this.state.selectedDiv === '' && !this.state.noDivs &&
                        <button className="resend-link" onClick={(e) => this.hideDivs()}>hide</button>
                    }
                    {this.state.dropDownDivs && this.state.selectedDiv === '' && !this.state.noDivs &&
                        this.state.divArray.map((div, index) => (
                            <div className="resend-link" key={index} onClick={() => this.addDiv(div.divName, index)}>{div.divName}</div>
                        ))
                    }
                </div>
            }
            {!this.state.loading && !this.state.loadingOrgs && this.state.selectedDiv !== '' && !this.state.noDivs &&
                <div>
                    <div className="form-name">you have selected the division....</div>
                    <div className="Title">{this.state.selectedDiv}</div>
                    <button className="resend-link" onClick={this.removeDiv}>remove</button>
                    <div className='One-space'/>
                </div>
            }
            {!this.state.loading && !this.state.loadingOrgs &&
                <form onSubmit={this.submitOrg}>
                    <input value={this.state.organization} name="organization" className="Reading-name" type="text" onChange={this.handleChange}/>
                    <div className='One-space'/>
                    <input type="submit" className="submit-buttons" value="submit"/>
                    <div className='One-space'/>
                </form>
            }
        </div>
        {!this.state.loading &&
            <RemoveDivOrg divisions={this.state.divArray} organizations={this.state.orgArray} noDivisions={this.state.noDivs}/>
        }
      </div>
    )
  }
}

export default EntAddDivOrg
