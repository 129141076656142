import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries.js';

class EntUserOnboard extends Component {
    constructor(props){
        super(props)

        this.state = {
            enterprise: '',
            serial: '',
            nickname:'',
            noDivs: false,
            noOrgs: false,
            noDivsOrgs: false,
            dropDownDivs: false,
            selectedDiv: '',
            dropDownOrgs: false,
            selectedOrg: '',
            divArray: [],
            orgArray: [],
            currentOrgArray: [],
            loadingCreate: false,
            loading: true
        }
        this.handleChange = this.handleChange.bind(this);
        this.selectDiv = this.selectDiv.bind(this);
        this.hideDivs = this.hideDivs.bind(this);
        this.addDiv = this.addDiv.bind(this);
        this.removeDiv = this.removeDiv.bind(this);
        this.selectOrg = this.selectOrg.bind(this);
        this.hideOrgs = this.hideOrgs.bind(this);
        this.addOrg = this.addOrg.bind(this);
        this.removeOrg = this.removeOrg.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    // toggles for divisions
    async selectDiv(event){
        this.setState({
            dropDownDivs: true
        })
    }
    async hideDivs(event){
        this.setState({
            dropDownDivs: false
        })
    }
    async addDiv(div){
        this.setState({
            selectedDiv: div,
            dropDownDivs: false
        })
    }
    async removeDiv(div){
        this.setState({
            selectedDiv: '',
            dropDownOrgs: false,
            currentOrgArray: []
        })
    }

  // toggles for organizations
    async selectOrg(event){
        if(!this.state.noDivs && this.state.selectedDiv === ''){
            alert('please select a division before selecting an organization')
            return
        }

        if(this.state.noDivs){
            this.setState({
                dropDownOrgs: true,
                currentOrgArray: this.state.orgArray
            })
        } else {
            let fetchedOrganizations = this.state.orgArray
            const orgsMatchedToDivs = fetchedOrganizations.filter(({divName}) => divName.includes(this.state.selectedDiv))

            this.setState({
                dropDownOrgs: true,
                currentOrgArray: orgsMatchedToDivs
            })
        }
    }
    async hideOrgs(event){
        this.setState({
            dropDownOrgs: false
        })
    }
    async addOrg(org){
        this.setState({
            selectedOrg: org,
            dropDownOrgs: false
        })
    }
    async removeOrg(org){
        this.setState({
            selectedOrg: ''
        })
    }

    async handleSubmit(event) {
        event.preventDefault();

        let finalEnterprise = this.state.enterprise
        let finalDivision = this.state.selectedDiv
        let finalOrganization = this.state.selectedOrg
        let finalSerial = this.state.serial
        let finalNickname = this.state.nickname

        if(finalSerial === ''){
            alert('the serial number can not be blank')
            return
        }

        this.setState({
            loadingCreate: true
        })

        let deviceFoundMatched = false
        let foundUnitInfo = []

        try{
            let unitInfo = await API.graphql(graphqlOperation(queries.getEntUnit, { serial: finalSerial }));
            if(unitInfo.data.getEntUnit === null){
                alert('The serial number was not found in the system. Please check that the number is correct and try again')
                return
        } else {
            if(unitInfo.data.getEntUnit.entName !== finalEnterprise){
                alert('The serial number was not found in the system. Please check that the number is correct and try again')
                return
            } else {
                console.log('device found and was matched to enterprise')
                foundUnitInfo = unitInfo.data.getEntUnit
                console.log(foundUnitInfo)
                deviceFoundMatched = true
            }
        }
        } catch(error) {
            console.log(error)
            alert('An error occurred while trying to find the device in the system. Please try again')
            return
        }

        if(deviceFoundMatched === true){
            if(finalDivision === ''){
                finalDivision = 'null'
            }
            if(finalOrganization === ''){
                finalOrganization = 'null'
            }
            if(finalNickname === ''){
                finalNickname = 'null'
            }

            const updateUnit = `mutation UpdateEntUnit {
                updateEntUnit(input: {
                id: "${foundUnitInfo.id}"
                serial: "${foundUnitInfo.serial}"
                divName: "${finalDivision}"
                orgName: "${finalOrganization}"
                nickName: "${finalNickname}"
                }){
                id

                }
            }`
            try {
                API.graphql(graphqlOperation(updateUnit));
            } catch(error) {
                alert('An error occurred onboarding please try again')
                return
            }

            let finalId = ''

            try{
                let getId = await API.graphql(graphqlOperation(queries.listEntInventorys, { limit: 10000, filter: { serial:{ eq: finalSerial}}}));
                finalId = getId.data.listEntInventorys.items[0].id
            } catch(error){
                console.log(error)
            }

            let timeNowUnix = Date.now();
            let timeNow = new Date(timeNowUnix);
            let timeNowIso = timeNow.toISOString();

            const updateEntInv = `mutation UpdateEntInventory {
                updateEntInventory(input: {
                    id: "${finalId}"
                    divName: "${finalDivision}"
                    orgName: "${finalOrganization}"
                    onboardedAt: "${timeNowIso}"
                }){
                    id
                }
            }`

            try {
                API.graphql(graphqlOperation(updateEntInv));
            } catch(error) {
                alert('An error occurred onboarding please try again')
                return
            }

            this.setState({
                loadingCreate: false,
                serial: '',
                nickname: ''
            })
        }
        this.props.history.push('/DeviceSync');
    }

    async componentDidMount(){
        Auth.currentAuthenticatedUser({
            bypassCache: false
            }).then(user => {
                this.loggedIn = true
            }
            )
        .catch(error => {
            if(error === 'not authenticated'){
                this.props.history.push('/SignIn');
            }
        }
        );

        let user = await Auth.currentAuthenticatedUser()
        let userGroupGet = user.signInUserSession.accessToken.payload["cognito:groups"];
        const userName = user.username;

        let userInfo = await API.graphql(graphqlOperation(queries.getEntUser, { userName: userName }));

        let userEnt = userInfo.data.getEntUser.entName

        let getentDivsOrgs = `query GetEntDivsOrgs {
        getEnt(entName: "${userEnt}") {
            id
            entdivsorgs {
                items {
                    id
                    entName
                    divName
                    orgName
                    orgName
                    isOrg
                }
            }
        }
        }`

        let divsOrgs = []

        try{
            let returnDivsOrgs = await API.graphql(graphqlOperation(getentDivsOrgs));
            divsOrgs = returnDivsOrgs.data.getEnt.entdivsorgs.items
        } catch(error) {
            console.log(error)
        };

        let finalDivs = []
        let finalOrgs = []

        divsOrgs.forEach(function (unit) {
            if(unit.isOrg === 'false'){
                finalDivs.push(unit)
            } else {
                finalOrgs.push(unit)
            }
        })

        let noDivsFound = false

        if(finalDivs.length === 0){
            noDivsFound = true
        }

        let noOrgsFound = false

        if(finalOrgs.length === 0){
            noOrgsFound = true
        }

        let noDivsOrgsFound = false

        if(finalDivs.length === 0 && finalOrgs.length === 0){
            noDivsOrgsFound = true
        }

        finalDivs.sort((a,b) => a.divName.localeCompare(b.divName, undefined, { numeric: true }))
        finalOrgs.sort((a,b) => a.orgName.localeCompare(b.orgName, undefined, { numeric: true }))

        this.setState({
            group: userGroupGet[0],
            userName: user.username,
            enterprise: userEnt,
            divArray: finalDivs,
            orgArray: finalOrgs,
            noDivs: noDivsFound,
            noOrgs: noOrgsFound,
            noDivsOrgs: noDivsOrgsFound,
            loading: false
        });

  }

  render(){
    var backIcon = require('../Back-01.png');

    return(
        <div className="App">
            {
                <div className="Nav-header">
                    <Link style={{textDecoration: 'none'}} to="/SettingsPage">
                        <button className="Back-wrap">
                            <img className="Back-button" src={backIcon} alt="back"/>
                        </button>
                    </Link>
                </div>
            }
            <div className="Title">Onboard a Device</div>
            <div className="Settings-wrapper">
                {this.state.loading &&                      
                    <div>
                        <div className='One-space'/>
                        <div className="Loading-buttonstatus">Loading....</div>
                        <div className='Two-space'/>
                    </div>              
                }
                {!this.state.loading &&
                    <div>
                        <div className='One-space'/>
                        {this.state.noDivsOrgs &&
                            <div>
                                <div className="form-name">No divisions or organizations found would you like to create one?</div>
                                <Link style={{textDecoration: 'none'}} to="/EntAddDivOrg">
                                    <button className="resend-link">create</button>
                                </Link>
                            </div>
                        }
                        {!this.state.noDivs && !this.state.loading &&
                            <div>
                                {!this.state.dropDownDivs && this.state.selectedDiv === '' &&
                                    <div>
                                        <div className="form-name">select a division</div>
                                        <button className="resend-link" onClick={this.selectDiv}>select</button>
                                        <div className='One-space'/>
                                    </div>
                                }
                                {
                                <div>
                                    {this.state.dropDownDivs &&
                                        <button className="resend-link" onClick={(e) => this.hideDivs()}>hide</button>
                                    }
                                    {this.state.dropDownDivs &&
                                        this.state.divArray.map((div, index) => (
                                            <div className="resend-link" key={index} onClick={() => this.addDiv(div.divName, index)}>{div.divName}</div>
                                        ))
                                    }
                                </div>
                                }
                                {!this.state.dropDownDivs && this.state.selectedDiv !== '' &&
                                <div>
                                    <div className="form-name">you have selected the division....</div>
                                    <div className="Title">{this.state.selectedDiv}</div>
                                    <button className="resend-link" onClick={this.removeDiv}>remove</button>
                                    <div className='One-space'/>
                                </div>
                                }
                            </div>
                        }
                        {!this.state.noOrgs && !this.state.loading &&
                            <div>
                                {!this.state.dropDownOrgs && this.state.selectedOrg === '' &&
                                    <div>
                                        <div className="form-name">select an organization</div>
                                        <button className="resend-link" onClick={this.selectOrg}>select</button>
                                        <div className='One-space'/>
                                    </div>
                                }
                                {
                                    <div>
                                        {this.state.dropDownOrgs &&
                                            <button className="resend-link" onClick={(e) => this.hideOrgs()}>hide</button>
                                        }
                                        {this.state.dropDownOrgs &&
                                            this.state.currentOrgArray.map((org, index) => (
                                                <div className="resend-link" key={index} onClick={() => this.addOrg(org.orgName, index)}>{org.orgName}</div>
                                            ))
                                        }
                                    </div>
                                }
                                {!this.state.dropDownOrgs && this.state.selectedOrg !== '' &&
                                    <div>
                                    <div className="form-name">you have selected the organization....</div>
                                    <div className="Title">{this.state.selectedOrg}</div>
                                    <button className="resend-link" onClick={this.removeOrg}>remove</button>
                                    <div className='One-space'/>
                                    </div>
                                }
                            </div>
                        }
                        {!this.state.loadingCreate &&
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-name">serial number</div>
                                <input value={this.state.serial} name="serial" className="Reading-name" type="text" onChange={this.handleChange}/>
                                <div className='One-space'/>
                                <div className="form-name">device name</div>
                                <input value={this.state.nickname} name="nickname" className="Reading-name" type="text" onChange={this.handleChange}/>
                                <div className='One-space'/>
                                <input type="submit" className="Icon-submit" value="submit"/>
                            </form>
                        }
                    </div>
                }
            </div>
        </div>
    )
  }
}

export default EntUserOnboard
