import React, { Component } from 'react';
import { deleteEntDivsOrgs } from '../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';

class RemoveOrg extends Component {
    constructor(props){
        super(props)

        this.state = {
            organizations: [],
            noOrgs: '',
            dropDownOrgs: false,
            orgIndex: '',
            selectedOrg: ''
        }
        this.getOrganizations = this.getOrganizations.bind(this);
        this.hideOrganizations = this.hideOrganizations.bind(this);
        this.selectOrg = this.selectOrg.bind(this);
        this.confirmOrg = this.confirmOrg.bind(this);
    }

    async getOrganizations(event){
        this.setState({
            dropDownOrgs: true
        })
    }
    async hideOrganizations(event){
        this.setState({
            dropDownOrgs: false
        })
    }
    async selectOrg(orgID, index){
        this.setState({
            selectedOrg: orgID,
            orgIndex: index
        })
    }
    async confirmOrg(orgID, yesNo){
        if(yesNo === 'yes'){
            try{
                API.graphql(graphqlOperation(deleteEntDivsOrgs, { input: { id: orgID }}));
                let newOrgArray = this.state.organizations.filter(org => org.id !== orgID)
                this.setState({
                    selectedOrg: '',
                    orgIndex: '',
                    organizations: newOrgArray
                })
                alert('organization successfully removed')
            } catch(error) {
                alert('there was an error attempting to delete the organization')
            }
        } else {
            this.setState({
                selectedOrg: '',
                orgIndex: ''
            })
        }
    }

    async UNSAFE_componentWillReceiveProps(nextProps) {
        let receivedOrganizations = nextProps.organizations
        let receivedNoOrgs = nextProps.noOrganizations

        this.setState({
            organizations: receivedOrganizations,
            noOrgs: receivedNoOrgs
        })
    }
    render(){
        return(
            <div className="App">
                <div className='One-space'/>
                {!this.state.dropDownOrgs &&
                    <button className="signup-link" onClick={(e) => this.getOrganizations()}>remove organization</button>
                }
                {this.state.dropDownOrgs &&
                    <button className="signup-link" onClick={(e) => this.hideOrganizations()}>hide</button>
                }
                {this.state.dropDownOrgs &&
                    this.state.organizations.map((org, index) => (
                        <div key={index}>
                        <div>
                            <div className='One-space'/>
                            <div className="signup-link" key={index} onClick={() => this.selectOrg(org.id, index)}>{org.orgName}</div>
                            <div className='One-space'/>
                        </div>
                        {this.state.orgIndex === index &&
                            <div className="Settings-wrapper">
                                <div className='One-space'/>
                                <div className="form-name">Are you sure you want to delete the following organization?</div>
                                <div className="Title">{org.orgName}</div>
                                <div className="confirm-link" onClick={() => this.confirmOrg(org.id, 'no')}>no</div>
                                <div className="confirm-link" onClick={() => this.confirmOrg(org.id, 'yes')}>yes</div>
                                <div className='One-space'/>
                            </div>
                        }
                        </div>
                    ))
                }
            </div>
        )
    }
}

export default RemoveOrg
