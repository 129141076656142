import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';

class ChangePass extends Component {
  constructor(props){
    super(props)

    this.state = {
      username: '',
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      resetting: false
     }
    this.handleChange = this.handleChange.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  async handleConfirm(event){
    event.preventDefault()

    this.setState({resetting: true})

    if(this.state.oldPassword === ''){
      alert('old password cant be blank')
      this.setState({resetting: true})
      return
    }
    if(this.state.newPassword === ''){
      alert('new password cant be blank')
      this.setState({resetting: true})
      return
    }

    if(this.state.confirmNewPassword === ''){
      alert('new password cant be blank')
      this.setState({resetting: true})
      return
    }

    if(this.state.newPassword !== this.state.confirmNewPassword){
      alert("passwords do not match")
      this.setState({resetting: true})
      return
    }

    let user = await Auth.currentAuthenticatedUser()

    try{
      await Auth.changePassword(user, this.state.oldPassword, this.state.newPassword)
      this.setState({resetting: false})
      alert('password has been updated successfully')
      this.props.history.push('/');
    } catch(error){
      if(error.code === 'InvalidPasswordException'){
        this.setState({resetting: false})
        alert("passwords must be 8 characters long and include an uppercase letter, a lowercase letter, a number and a symbol")
      }
      if(error.code === 'NotAuthorizedException'){
        this.setState({resetting: false})
        alert('old password is incorrect')
      }
      if(error.code === 'LimitExceededException' || error.code === 'TooManyRequestsException'){
        this.setState({resetting: false})
        alert('too many password changes submitted please wait an hour and try again')
      }
      else{
        this.setState({resetting: false})
        alert('error resetting password please try again')
      }
    }

  }

  async componentDidMount(){
    Auth.currentAuthenticatedUser({
      bypassCache: false
      }).then(user => {
        this.loggedIn = true
      }
    )
    .catch(error => {
      if(error === 'not authenticated'){
        this.props.history.push('/SignIn');
      }
    }
    );
  }

render(){
  var backIcon = require('../Back-01.png');

  return(
    <div className="App">
      <div className="Nav-header">
        <Link style={{textDecoration: 'none'}} to="/MainSettings">
          <button className="Back-wrap">
            <img className="Back-button" src={backIcon} alt="back"/>
          </button>
        </Link>
      </div>
      <div className="Title">Change Password</div>
      {this.state.resetting &&
        <div>
          <div className="Settings-wrapper">
            <div className='One-space'/>
            <div className="Loading-buttonstatus">Resetting Password...</div>
            <div className='One-space'/>
          </div>
        </div>
      }
      {!this.state.resetting &&
        <div className="Settings-wrapper">
          <form onSubmit={this.handleConfirm}>
            <div className="form-name">old password</div>
            <input value={this.state.oldPassword} name="oldPassword" className="Reading-name" type="password" onChange={this.handleChange}/>
            <div className='One-space'/>
            <div className="form-name">new password</div>
            <input value={this.state.newPassword} name="newPassword" className="Reading-name" type="password" onChange={this.handleChange}/>
            <div className='One-space'/>
            <div className="form-name">confirm new password</div>
            <input value={this.state.confirmNewPassword} name="confirmNewPassword" className="Reading-name" type="password" onChange={this.handleChange}/>
            <div className='One-space'/>
            <input type="submit" className="Add-button" value="submit"/>
          </form>
        </div>
      }
    </div>
  )
}

}

export default ChangePass
