import React from 'react';
import { Document, Page, Text, Image, View, StyleSheet } from '@react-pdf/renderer';
import { Storage } from 'aws-amplify';

const styles = StyleSheet.create({
    page: {
        backgroundColor: "#3c3c3c",
        textAlign: 'center',
    },

    org: {
        fontSize: '35pt',
        color: '#ffffff',
        alignSelf: 'center',
        display: 'inline-block',
        marginTop: '150pt'
    },

    deviceName: {
        color: '#ffffff',
        fontSize: '25pt',
        marginTop: '5pt',
        marginBottom: '15pt',
    },

    titleWhite: {
        color: '#ffffff',
        fontSize: '20pt',
        marginTop: '5pt'
    },

    titleGreen: {
        color: '#529857',
        fontSize: '15pt',
        marginTop: '10pt'
    },

    date: {
        fontSize: '25pt',
        color: '#ffffff',
        alignSelf: 'center',
        display: 'inline-block'
    },

    titlePage: {
        backgroundColor: '#323232',
    },

    pageNumberTitle: {
        color: '#ffffff',
        fontSize: '20pt',
        marginTop: '5pt'
    },

    pageNumber: {
        color: '#ffffff',
        fontSize: '15pt',
        marginTop: '10pt'
    },

    header: {
        height: '75pt',
        backgroundColor: '#529857',
    },

    image: {
        alignSelf: 'center',
        width: '80%',
    },

    logo: {
        width: '40%',
        float: 'left'
    },

    downloadedAt: {
        color: '#ffffff',
        fontSize: '15pt',
        float: 'left',
        width: '40%',
    },

    content: {
        color: '#ffffff',
        fontSize: '15pt',
        margin: '10pt'
    },

    signature: {
        color: '#ffffff',
        fontSize: '20pt',
        margin: '10pt',
        marginBottom: '20pt'
    },

    icon: {
        height: 30,
        width: 30,
        marginLeft: '20pt',
        marginTop: '5pt'
    },

    number: {
        color: '#ffffff',
        fontSize: '25pt',
        margin: '10pt',
        position: 'relative',
        bottom: '0'
    },

    line: {
        color: '#ffffff',
        fontSize: '25pt',
        marginBottom: '20pt'
    },

});



export function PDFDocument(props) {
    var imageCheck = require('../Nowledge-App-Icons-CHECK.png')
    var imageCaution = require('../Nowledge-App-Icons-CAUTION.png')
    var imageStop = require('../Nowledge-App-Icons-STOP.png')
    var imageName = require('../Nowledge-Logo_ALL-WHT.png');

    async function renderImages(id){
        let urlForFetch = '';
        await Storage.get(id, { expires: 300 })
        .then(result => {
          urlForFetch = result;
        })
        .catch(err => console.log(err));
       
        return(urlForFetch)
    }

    function renderNoteTime(time){
        let utcDate = time;
        let localDate = new Date(utcDate);  
        let date4 = (localDate.getMonth()+1) + "/" + localDate.getDate() + "/" + localDate.getFullYear()   
        let hour = localDate.getHours()
        let minute = localDate.getMinutes();
    
        if(minute < 10){
            minute = ('0' + minute).slice(-2)
        };   
        let amPm = "am";  
        if(hour === 12){
            amPm = "pm"
        };
        if(hour > 12){
            hour = hour - 12
            amPm = "pm"
        };
        if(hour === 0){
             hour = 12
        };
    
        let time1 = hour + ":" + minute + amPm   
        let dateTime1 = date4 + " " + time1;
    
        return(dateTime1);
      };
    
    return (
        <Document style={styles.page}>
            <Page style={styles.titlePage}>
                {
                    <View>
                        {   
                            <View style={styles.header}>
                                <Image src={imageName} style={styles.logo}/>
                            </View>
                        }
                        {props.org === props.deviceName &&
                            <Text style={styles.org}>
                                {props.org}
                            </Text>
                        }
                        {props.org !== props.deviceName &&
                            <Text style={styles.org}>
                                {props.org}
                            </Text>
                        }
                        {props.org !== props.deviceName &&
                            <Text style={styles.deviceName}>
                                {props.deviceName}
                            </Text>
                        }
                        {      
                            <Text style={styles.titleGreen}>
                                Created on:
                            </Text>
                        }
                        {
                            <Text style={styles.titleWhite}>
                                {props.currentDate}
                            </Text>  
                        }
                        {
                            <Text style={styles.titleGreen}>
                                Created by: 
                            </Text>
                        }
                        {
                            <Text style={styles.titleWhite}>
                                {props.userName}
                            </Text>
                        }

                        {
                            <Text style={styles.titleGreen}>
                                This report contains notes from: 
                            </Text>
                        }
                        {
                            props.includedNotes.map((note, index) => {
                                return(
                                    <Text key={index} style={styles.titleWhite}>
                                        {note}
                                    </Text>
                                )

                            }) 
                        }
                    </View>                   
                }
            </Page>
            
            <Page  wrap={false} style={styles.page}>                
                {
                    props.data.map((note, index) => {
                        return(
                            <View wrap={false} key={index}>
                                { note.status === "low" &&
                                    <Image src={imageCheck} style={styles.icon}/>
                                }
                                { note.status === "middle" &&
                                    <Image src={imageCaution} style={styles.icon}/>
                                }
                                { note.status === "high" &&
                                    <Image src={imageStop} style={styles.icon}/>
                                }
                                <Text style={styles.date}>
                                    {renderNoteTime(note.createdAt)}
                                </Text>
                                
                                <Text style={styles.content}>
                                    {note.content.split('<br>').join('\n')}
                                </Text>
                                
                                { note.img != null && note.img !== 'null' &&
                                    <View>
                                        <Image src={renderImages(note.img)} style={styles.image}/> 
                                    </View>

                                }
                                <Text style={styles.signature}>
                                    {note.author}
                                </Text>
                                <Text style={styles.line}>- - - - - - - - - - - - - - - - - - - - - - - - - - - - -</Text>
                            </View>
                        )
                    })
                }
            </Page>
        </Document>
    )
    
}