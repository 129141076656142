import React, { Component } from 'react';
import { API, graphqlOperation, Storage } from 'aws-amplify';

class EntNoteSubmit extends Component {

  constructor(props){
    super(props);

    let receivedBlogID = props.blogID
    this.userName = props.userName
    this.local = props.serial

    this.state = {
      blogID: receivedBlogID,
      uploadingImg: false,
      noteHide: false,
      uploadImgPercent: '0%',
      filePreview: null,
      value: '',
      inputKey: Date.now(),
      bgColor1: '#EBEBEB',
      bgColor2: '#666666',
      bgColor3: '#666666',
      submitButton: true,
    }

    this.imgFile = "";
    this.imgName = 'null';
    this.noteStatus = "low";

    this.putImgSubmit = this.putImgSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.selectImage = this.selectImage.bind(this);

  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  async selectImage(evt){
    this.setState({
      filePreview: URL.createObjectURL(evt.target.files[0])
    });
    this.imgFile = evt.target.files[0];
  }

  //status button clicks
  statusSelect() {
    this.noteStatus = this.click

    if(this.noteStatus === "low"){
    this.setState({
        bgColor1: '#EBEBEB',
        bgColor2: '#666666',
        bgColor3: '#666666'
    })
    }
    if(this.noteStatus === "middle"){
    this.setState({
        bgColor1: '#666666',
        bgColor2: '#EBEBEB',
        bgColor3: '#666666'
    })
    }
    if(this.noteStatus === "high"){
    this.setState({
        bgColor1: '#666666',
        bgColor2: '#666666',
        bgColor3: '#EBEBEB'
    })
    }
    return(
        this.noteStatus
    )
    }

  async putImgSubmit(evt){
    evt.preventDefault();
    console.log('put image')

    if(this.state.value === "" && this.imgFile === ""){
      alert("cannot post empty notes")
      return
    };

    this.setState({
      submitButton: false,
      uploadingImg: true
    });

    if(this.imgFile !== ''){
      let today = new Date();
      let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds() + ":" + today.getMilliseconds();
      let dateTime = date+"T"+time;
      this.imgName = this.local + dateTime;

      const forceState2 = this;

      try {
        console.log('storage put try')
        await Storage.put(this.imgName, this.imgFile, {
          progressCallback(progress) {
            let percent = progress.loaded / progress.total * 100
            let rounded = Math.round(percent);
            let percentView = `${rounded}%`
            forceState2.setState({
              uploadImgPercent: percentView
            });
          },
        });
      } catch(error) {
        console.log(error)
        alert('error posting note')
        return
      }
    };

    this.lineBreak = this.state.value.replace(/\n/g, "<br>");

    const addNote = `mutation CreatePost {
      createEntPost(input: {
        author: "${this.userName}"
        content:"${this.lineBreak}"
        status: "${this.noteStatus}"
        entPostBlogId: "${this.state.blogID}"
        img: "${this.imgName}"
      }) {
        id
        content
        status
      }
    }`
    console.log(addNote)

    try {
        console.log('caught add note no image')
        this.addNoteResults = await API.graphql(graphqlOperation(addNote));
    } catch(error) {
        console.log(error)
        alert('error posting note')
        return
    }

    this.stuckStatus = this.noteStatus;
    this.stuckValue = this.state.value;
    this.noteStatus = 'low';

    this.props.getNotes();

    this.imgName = 'null';
    this.imgFile = "";

    this.setState({
      value: '',
      file: null,
      inputKey: Date.now(),
      submitButton: true,
      filePreview: null,
      bgColor1: '#EBEBEB',
      bgColor2: '#666666',
      bgColor3: '#666666',
      uploadingImg: false,
      uploadImgPercent: '0%',
    })

    alert("note posted");
  }


  render(){
    var imageCheck = require('../Nowledge-App-Icons-CHECK.png')
    var imageCaution = require('../Nowledge-App-Icons-CAUTION.png')
    var imageStop = require('../Nowledge-App-Icons-STOP.png')

    return(
      <div>
        { this.state.uploadingImg &&
          <div>
            <div className="Loading-button">Uploading...</div>
            <div className="Loading-button">{this.state.uploadImgPercent}</div>
          </div>
        }

        {!this.state.noteHide &&
          <div>
          <form className ="Submit-form" onSubmit={this.putImgSubmit}>
            <label>
              <img src={this.state.filePreview} style={{width: '25%'}} alt=""/>
              <textarea value={this.state.value} placeholder="Enter your note here...." onChange={this.handleChange} className="Text-area" />
            </label>
            <input type="file" onChange={this.selectImage} className="File-upload" key={this.state.inputKey}/>
            <button type="button" className="Select-button" onClick={(e) => this.statusSelect(this.click = 'low')} style={{backgroundColor:this.state.bgColor1}}>
              <img className="Emoji1" src={imageCheck} alt="check" />
            </button>
            <button type="button" className="Select-button" onClick={(e) => this.statusSelect(this.click = 'middle')} style={{backgroundColor:this.state.bgColor2}}>
                <img className="Emoji1" src={imageCaution} alt="caution" />
            </button>
            <button type="button" className="Select-button" onClick={(e) => this.statusSelect(this.click = 'high')} style={{backgroundColor:this.state.bgColor3}}>
              <img className="Emoji1" src={imageStop} alt="stop"/>
            </button>
            {this.state.submitButton === true &&
              <input type="submit" value="submit" className="Submit-button" />
            }
            <br />
            <br />
          </form>
          </div>
        }
      </div>
    )
  }
}

export default EntNoteSubmit
