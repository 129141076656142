/* eslint-disable no-unused-expressions */
import React, { Component } from 'react';
import { API, graphqlOperation, Auth } from 'aws-amplify'
import * as queries from '../graphql/queries.js';
import { Link } from 'react-router-dom';



class OnlineStatus extends Component {
    constructor(props){
        super(props)

        this.timeZone = ''
    
        this.state = {
                offlineDevices: [],
                onlineDevices: [],
                offlineCount: '',
                onlineCount: '',
                loading: true,
                offlinePressed: true,
                timeColor1: '#529857',
                timeColor2: '#3c3c3c'
            }
        this.inventorySelect = this.inventorySelect.bind(this);
    }

    async inventorySelect () {
        this.timeStatus = this.click
        if(this.timeStatus === "offline"){
            this.setState({
                timeColor1: '#529857',
                timeColor2: '#3c3c3c',
                offlinePressed: true
            })
        }   
        if(this.timeStatus === "online"){
            this.setState({
                timeColor1: '#3c3c3c',
                timeColor2: '#529857',
                offlinePressed: false
            })
        }
    }

    modTimeStamp(time){
        let formatted = ''
        let tempTimestamp = time
        if(this.timeZone === 'America/Chicago'){
            let year = tempTimestamp.slice(0,4);
            let month = tempTimestamp.slice(5,7);
            let newMonth = Number(month);
            let day = tempTimestamp.slice(8,10);
            let newDay = Number(day);
            let hour = tempTimestamp.slice(11,13);
            let newHour = Number(hour)
            let minute = tempTimestamp.slice(14,16);
            let amPm = "am";
            if(newHour === 12){
                amPm = "pm"
            };
            if(newHour > 12){
                newHour = newHour - 12
                amPm = "pm"
            };
            if(hour === "00"){
                newHour = "12"
            };
            formatted = newMonth + "/" + newDay + "/" + year + " " + newHour + ":" + minute + amPm;
        } else {
            let dateObject = new Date(tempTimestamp) 
            let changedStamp = new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'long', timeZone: this.timeZone }).format(dateObject)
            let monthZone = changedStamp.slice(3,5)
            let dayZone = changedStamp.slice(0,2)
            let yearZone = changedStamp.slice(6,10)
            let hourZone = changedStamp.slice(12,14)
            let newHourZone = Number(hourZone)
            let minuteZone = changedStamp.slice(15,17)
            //let milliSecondsZone = changedStamp.slice(18,20)
            let amPm = "am"
            if(newHourZone === 12){
                amPm = "pm"
            };
            if(newHourZone > 12){
                hourZone = newHourZone - 12
                amPm = "pm"
            };
            if(hourZone === "00"){
                hourZone = "12"
            };
            formatted = monthZone + "/" + dayZone + "/" + yearZone + " " + hourZone + ":" + minuteZone + amPm;
        }

    
        return(
          <div>{formatted}</div>
        );
      };

    async componentDidMount() {
        Auth.currentAuthenticatedUser({
            bypassCache: false
        }).then(user => {
            this.loggedIn = true
        }
        )
        .catch(error => {
            if (error === 'not authenticated') {
                this.props.history.push('/SignIn');
            }
        }
        );
        
        let devFetch = await API.graphql(graphqlOperation(queries.listDevices, { limit: 10000 }));
        let devicesArray = devFetch.data.listDevices.items

        let serialArray = []
        devicesArray.forEach(unit => {
            serialArray.push(unit.location)
        })
        let serialArrayString = "[" + serialArray.map(serial => `"${serial}"`).join(',') + "]"
        
        function getTimestamps() {
            const apiName = 'DeviceConnectStatus';
            const path = '/items';
    
            const myInit = {
                queryStringParameters: {
                  name: serialArrayString
                }
            };
            return API.get(apiName, path, myInit);
        }
    
        let response = {};
        let timestampsArray = []
        try {
            response = await getTimestamps()
            timestampsArray = response.return
        } catch(error) {
            console.log(error)
            alert('error fetching devices online status')
            return
        };
        if (response.result === 'error') {
            alert('error fetching devices online status')
            return
        }

        //check for users timezone offset compared to central times offset
        let changedCentral = new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'long', timeZone: 'America/Chicago' }).format()
        let offsetCentral = Number(changedCentral.slice(-1))
        let localDate = new Date()
        let localOffset = localDate.getTimezoneOffset() / 60

        let dateNow = new Date();
        let minLate = 600000;
        let offlineArray = [];
        let onlineArray = [];

        if(offsetCentral === localOffset){
            this.timeZone = 'America/Chicago'
        } else {
            let offsetDifference = offsetCentral - localOffset
            if(offsetDifference === 1){
                this.timeZone = 'America/Halifax'
            }
            if(offsetDifference === -1){
                this.timeZone = 'America/Los_Angeles'
            }
            if(offsetDifference === -2){
                this.timeZone = 'America/Anchorage'
            }
        }

        timestampsArray.forEach( timestampData => {
            let deviceOnline = ''
            let modTimestamp = ''
            try{
                if(this.timeZone === 'America/Chicago'){
                    modTimestamp = timestampData.timestamps.replace(/\s/, 'T')
                } else {
                    let dateObject = new Date(timestampData.timestamps)
                    let changedStamp = new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'long', timeZone: this.timeZone }).format(dateObject)
                    let monthTest = changedStamp.slice(3,5)
                    let dayTest = changedStamp.slice(0,2)
                    let yearTest = changedStamp.slice(6,10)
                    let hourTest = changedStamp.slice(12,14)
                    let minuteTest = changedStamp.slice(15,17)
                    let milliSeconds = changedStamp.slice(18,20)
            
                    modTimestamp = yearTest + "-" + monthTest + "-" + dayTest + "T" + hourTest + ":" + minuteTest + ":" + milliSeconds
                }
                
                if((dateNow - new Date(modTimestamp)) > minLate) {
                    deviceOnline = false;
                }
                else{
                    deviceOnline = true;
                }
                devicesArray.forEach( deviceData => {
                    if(timestampData.device === deviceData.location){
                        let newObject = {'serial': deviceData.location, 'organization': deviceData.group, 'nickname': deviceData.title, 'timestamp': timestampData.timestamps}
                        if(!deviceOnline){
                            offlineArray.push(newObject)
                        } else {
                            onlineArray.push(newObject)
                        }
                    }
                })
                
            } catch(error) {
                if (error instanceof TypeError) {
                    console.log('null')
                }
                else {
                    console.log(error)
                }
            }            
        })
        let offlineArraySorted = offlineArray.sort((a, b) => b.timestamp.localeCompare(a.timestamp, undefined, { numeric: true }))
        let onlineArraySorted = onlineArray.sort((a, b) => a.organization.localeCompare(b.organization, undefined, { numeric: true }))

        this.setState({
            offlineDevices: offlineArraySorted,
            onlineDevices: onlineArraySorted,
            offlineCount: offlineArraySorted.length,
            onlineCount: onlineArraySorted.length,
            loading: false
        })
        
    }
    render(){
        var backIcon = require('../Back-01.png');
        var imageCheck = require('../Nowledge-App-Icons-CHECK.png')
        var imageStop = require('../Nowledge-App-Icons-STOP.png')
        return(
            <div className='App'>
                {
                    <div className="Nav-header">
                        <Link style={{textDecoration: 'none'}} to="/MainSettings">
                        <button className="Back-wrap">
                            <img className="Back-button" src={backIcon} alt="back"/>
                        </button>
                        </Link>
                    </div>
                }
                <div className="Title">Online Status</div>
                <div className="Status-wrapper">
                    {this.state.loading &&
                        <div>
                            <div className='One-space'/>
                            <div className="Loading-buttonstatus">Loading...</div>
                            <div className='One-space'/>
                        </div>
                    }
                    { !this.state.loading &&
                        <div className="Status-container">
                            <button className="Status-button">
                            <img className="Emoji-icon" src={imageStop} alt="check" />
                            <div className="Status-title">offline</div>
                            <div className="Status-reading">{this.state.offlineCount}</div>
                            </button>
                            <button className="Status-button">
                            <img className="Emoji-icon" src={imageCheck} alt="check" />
                            <div className="Status-title">online</div>
                            <div className="Status-reading">{this.state.onlineCount}</div>
                            </button>
                        </div>
                    }
                </div>
                { !this.state.loading &&
                    <div className="Button2-wrapper">
                        <button type="button" className="Button2-select" onClick={(e) => this.inventorySelect(this.click = 'offline')} style={{backgroundColor:this.state.timeColor1}}>
                            <div>offline</div>
                        </button>
                        <button type="button" className="Button2-select" onClick={(e) => this.inventorySelect(this.click = 'online')} style={{backgroundColor:this.state.timeColor2}}>
                            <div>online</div>
                        </button>
                    </div>
                }
                {!this.state.loading && this.state.offlinePressed &&
                    this.state.offlineDevices.map((device, index) => (
                        <div key={index}>
                        <div className='One-space'/>
                        <div className="Device-wrapper">
                            <div style={{color: '#d42d0a', fontSize: '20px', marginBottom: '5px'}}>offline</div>
                            <div style={{fontSize: '25px', color: 'white', fontWeight: 'bold'}}>{this.modTimeStamp(device.timestamp)}</div>
                            <div className='One-space'/>
                            <div style={{fontSize: '15px', color: '#529857'}}>Organization Name:</div>
                            <div style={{fontSize: '25px', color: 'white', fontWeight: 'bold'}}>{device.organization}</div>
                            <div style={{fontSize: '15px', color: '#529857'}}>Device Name:</div>
                            <div style={{fontSize: '25px', color: 'white', fontWeight: 'bold'}}>{device.nickname}</div>
                            <div style={{fontSize: '15px', color: '#529857'}}>Serial Number:</div>
                            <div style={{fontSize: '25px', color: 'white', fontWeight: 'bold'}}>{device.serial}</div>
                        </div>
                        </div>
                    ))
                }
                {!this.state.loading && !this.state.offlinePressed &&
                    this.state.onlineDevices.map((device, index) => (
                        <div key={index}>
                        <div className='One-space'/>
                        <div className="Device-wrapper">
                            <div style={{color: '#529857', fontSize: '20px', marginBottom: '5px'}}>online</div>
                            <div style={{fontSize: '25px', color: 'white', fontWeight: 'bold'}}>{this.modTimeStamp(device.timestamp)}</div>
                            <div className='One-space'/>
                            <div style={{fontSize: '15px', color: '#529857'}}>Organization Name:</div>
                            <div style={{fontSize: '25px', color: 'white', fontWeight: 'bold'}}>{device.organization}</div>
                            <div style={{fontSize: '15px', color: '#529857'}}>Device Name:</div>
                            <div style={{fontSize: '25px', color: 'white', fontWeight: 'bold'}}>{device.nickname}</div>
                            <div style={{fontSize: '15px', color: '#529857'}}>Serial Number:</div>
                            <div style={{fontSize: '25px', color: 'white', fontWeight: 'bold'}}>{device.serial}</div>
                        </div>
                        </div>
                    ))
                }
            </div>
        )
    }
}
export default OnlineStatus