import React, { Component } from 'react';
import Graph from "../components/Graph"
import Note from "../components/Note"
import Document from "../components/Document"
import CSV from "../components/CSV"
import Log from './Log.js';

class LocationViews extends Component {
    constructor(props){
        super(props)

        this.blogID = props.blogID
        this.userName = props.userName
        this.serial = props.serial
        this.title = props.title
        this.org = props.org
        this.version = props.version

        this.state = {
            buttonPressed: 'graphs',
            buttonColor1: '#3c3c3c',
            buttonColor2: '#3c3c3c',
            buttonColor3: '#529857',
            buttonColor4: '#3c3c3c',
            buttonColor5: '#3c3c3c',
            loading: true
        }
        this.buttonPress = this.buttonPress.bind(this);
    }

    buttonPress() {
        this.buttonPressed = this.click

        if(this.buttonPressed === this.state.buttonPressed){
            return
        }
        if(this.buttonPressed === "csv"){
            this.setState({buttonPressed: 'csv', buttonColor1: '#529857', buttonColor2: '#3c3c3c', buttonColor3: '#3c3c3c', buttonColor4: '#3c3c3c', buttonColor5: '#3c3c3c'})
        }
        if(this.buttonPressed === "logs"){
            this.setState({buttonPressed: 'logs', buttonColor1: '#3c3c3c', buttonColor2: '#529857', buttonColor3: '#3c3c3c', buttonColor4: '#3c3c3c', buttonColor5: '#3c3c3c'})
        }  
        if(this.buttonPressed === "graphs"){
            this.setState({buttonPressed: 'graphs', buttonColor1: '#3c3c3c', buttonColor2: '#3c3c3c', buttonColor3: '#529857', buttonColor4: '#3c3c3c', buttonColor5: '#3c3c3c'})
        }
        if(this.buttonPressed === "notes"){
            this.setState({buttonPressed: 'notes', buttonColor1: '#3c3c3c', buttonColor2: '#3c3c3c', buttonColor3: '', buttonColor4: '#529857', buttonColor5: '#3c3c3c'})
        }
        if(this.buttonPressed === "documents"){
            this.setState({buttonPressed: 'documents', buttonColor1: '#3c3c3c', buttonColor2: '#3c3c3c', buttonColor3: '#3c3c3c', buttonColor4: '#3c3c3c', buttonColor5: '#529857'})
        }
        

    }

    async UNSAFE_componentWillReceiveProps(nextProps) {
        this.blogID = nextProps.blogID
        this.userName = nextProps.userName
        this.serial = nextProps.serial
        this.locationData = nextProps.data
        this.locationIcons = nextProps.icons
        this.timezone = nextProps.timezone
        this.sendNoData = nextProps.noData

        this.setState({
            loading: false
        })
    }

    render(){
        var graphIcon = require('../icons8-graph-100.png');
        var notesIcon = require('../icons8-inscription-100.png')
        var docsIcon = require('../icons8-documents-100.png');
        var csvIcon = require('../icons8-spreadsheet-100.png')
        var logIcon = require('../icons8-log-100.png')
        return(
            <div className='App'>               
                {!this.state.loading &&
                    <div className='Locationview-buttonswrap'>
                        <button type="button" className="Location-button" onClick={(e) => this.buttonPress(this.click = 'csv')} style={{backgroundColor:this.state.buttonColor1}}>
                            <img className="Back-button" src={csvIcon} alt="csv"/>
                        </button>
                        <button type="button" className="Location-button" onClick={(e) => this.buttonPress(this.click = 'logs')} style={{backgroundColor:this.state.buttonColor2}}>
                            <img className="Back-button" src={logIcon} alt="logs"/>
                        </button>
                        <button type="button" className="Location-button" onClick={(e) => this.buttonPress(this.click = 'graphs')} style={{backgroundColor:this.state.buttonColor3}}>
                            <img className="Back-button" src={graphIcon} alt="graphs"/>
                        </button>
                        <button type="button" className="Location-button" onClick={(e) => this.buttonPress(this.click = 'notes')} style={{backgroundColor:this.state.buttonColor4}}>
                            <img className="Back-button" src={notesIcon} alt="notes"/>
                        </button>
                        <button type="button" className="Location-button" onClick={(e) => this.buttonPress(this.click = 'documents')} style={{backgroundColor:this.state.buttonColor5}}>
                            <img className="Back-button" src={docsIcon} alt="documents"/>
                        </button>
                    </div>
                }
                {this.state.buttonPressed === 'csv' &&
                    <CSV loc={this.serial} title={this.title} org={this.org} version={this.version}/>
                }
                {this.state.buttonPressed === 'logs' &&
                    <div className="Logloc-wrapper">
                        <div className='One-space'/>
                        <Log serial={this.serial}/>
                    </div>
                }
                {this.state.buttonPressed === 'graphs' &&
                    <Graph data={this.locationData} icons={this.locationIcons} loc={this.serial} noData={this.sendNoData} timezone={this.timezone}/>
                }
                {this.state.buttonPressed === 'notes' &&
                    <Note nickname={this.title} org={this.org} blogID={this.blogID} userName={this.userName} serial={this.serial}/>
                }
                {this.state.buttonPressed === 'documents' &&
                    <Document blogID={this.blogID} userName={this.userName} title={this.title}/>
                }
                <div className='div-height'/>
                <div className='div-height'/>
                <div className='div-height'/>
            </div>
        )
    }
}

export default LocationViews