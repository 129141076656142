import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import DownloadCSV from "../components/DownloadCSV"

class CSV extends Component {

    constructor(props){
        super(props)
        
        this.local = props.loc
        this.title = props.title
        this.org = props.org
        this.version = props.version

      }

    render() {
        return (
            <div className='App'>
                {
                    <div>
                        <div className='location-org'>spreadsheet</div>
                        <div className='One-space'/>
                        <DownloadCSV csvSerial={this.local}/>
                        <div className='One-space'/>
                        <div className='title-two'>or</div>
                        <Link style={{ textDecoration: 'none' }} to={{ pathname: '/CustomCSV', state: { loc: this.local, title: this.title, org: this.org, version: this.version } }}>
                            <button className='Add-button' onClick={this.stopInterval}>
                                custom
                            </button>                  
                        </Link>


                    </div>
                }
            </div>
        )
    }
}

export default CSV