import React, { Component } from 'react';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as queries from '../graphql/queries.js';
import * as mutations from '../graphql/mutations';


class SyncDevicesButton extends Component {

    constructor(props){
        super(props)

        this.state = {
            syncing: false
        }

    }

    async componentDidMount(){
        let user = await Auth.currentAuthenticatedUser();
        this.userName = user.username;

        if(this.userName === ''){
            alert('Must be logged in to sync')
        }

        this.setState({userName: this.userName});

    }

    async syncDevices(){

        this.setState({
            syncing: true
        })

        // fetch user group
        let group = await API.graphql(
            graphqlOperation(queries.getUser, { userName: this.state.userName })
        );
        this.groupName = group.data.getUser.groupName;

        const getUnits = `query GetUnits {
            getGroup(groupName: "${this.groupName}") {
            id
            units {
                items {
                id
                serial
                nickName
                version
                }
            }
            }
        }`

        //return from Unit db all units attached to the organization
        try{
            let orgUnits = await API.graphql(graphqlOperation(getUnits));
            this.orgUnits = orgUnits.data.getGroup.units.items

            if(this.orgUnits.length === 0){
                alert('no devices associated with this organization')
                return
            }
        } catch(error) {
            console.log(error)
            alert('error finding devices of this organization')
        };

        let userButtons = await API.graphql(graphqlOperation(queries.listMultidevices, {
            limit: 10000,
            filter: {
                group:{
                    eq: this.state.userName
                }
            }
        }));

        let foundButtons = userButtons.data.listMultidevices.items
        
        let removeButtons = []
        let addButtons = []

        // find devices new to the user
        this.orgUnits.forEach( orgUnit => {
            let matchFound = false
            foundButtons.forEach( button => {
                if(button.location === orgUnit.serial){
                    matchFound = true
                } 
            })

            if(!matchFound){
                addButtons.push(orgUnit)
            }
        })

        // find devices that are no longer in organization
        foundButtons.forEach( button => {
            let matchFound = false
            this.orgUnits.forEach( orgUnit => {
                if(button.location === orgUnit.serial){
                    matchFound = true
                }
            })
            if(!matchFound){
                removeButtons.push(button.id)
            }
        })

        // add new devices to user buttons
        const userNameFinal = this.userName

        try{
            addButtons.forEach( async function(device){
                await API.graphql(graphqlOperation(mutations.createMultidevice,
                { input: { group: userNameFinal, location: device.serial,  title: device.nickName, version: device.version }}));
            })
        } catch(error) {
            console.log(error)
            alert('error adding new buttons to user account')
        }

        // remove old devices from users buttons
        try{
            removeButtons.forEach( async function(idRemove) {
                await API.graphql(graphqlOperation(mutations.deleteMultidevice, { input: { id: idRemove }}));
            });
        } catch(error){
            console.log(error)
            alert('error removing buttons from user account')
        }

        this.setState({
            syncing: false
        })
    }

    render(){

        return(
            <div className="App">
                {!this.state.syncing &&
                     <button className="Dropdown-button-full" onClick={(e) => this.syncDevices()}>sync</button>
                }
                {this.state.syncing &&
                    <div className="Loading-noback">syncing....</div>
                }
            </div>
        )
    }
}

export default SyncDevicesButton
