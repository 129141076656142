import React, { Component } from 'react';
import { API, graphqlOperation } from 'aws-amplify';

class IconRanges extends Component {

  constructor(props){
    super(props);

    this.blogID = props.blogID

    this.state = {
      icons: props.icons
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
      this.setState({
        [event.target.name]: event.target.value
      });
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {

    let iconsReceived = nextProps.icons

    await this.setState({
      icons: iconsReceived
    })

  }

  async handleSubmit(event) {
    event.preventDefault();

    var lowest = parseFloat(event.target.lowest.value);
    var low = parseFloat(event.target.low.value);
    var high = parseFloat(event.target.high.value);
    var highest = parseFloat(event.target.highest.value);
    var order = parseFloat(event.target.order.value);

    var name = event.target.value.value;

    if(isNaN(order)){
      order = null;
    }

    const update = `mutation UpdateIcon {
      updateIcon(input: {
        id: "${event.target.id.value}"
        value:"${name}"
        lowest: ${lowest}
        low: ${low}
        high: ${high}
        highest: ${highest}
        order: ${order}
        iconBlogId: "${this.blogID}"
      }){
        id
        value
        lowest
        low
        high
        highest
        order
      }
    }`
    try {
      this.addedIcon = API.graphql(graphqlOperation(update));
    } catch(error) {
      alert('error occurred updating icon settings')
    }
    await this.props.getIcons();
    //alert('parameters for' + ' ' + name.replace(/_/g, ' ') + ' ' + 'updated');
    alert(`parameters for ${name.replace(/_/g, ' ')} updated`)
  }

  render(){
    var imageCheck = require('../Nowledge-App-Icons-CHECK.png')
    var imageCaution = require('../Nowledge-App-Icons-CAUTION.png')
    var imageStop = require('../Nowledge-App-Icons-STOP.png')

    return (
      <div>
        {
          this.state.icons.map((icon,index) => (
            <form key={icon.id} onSubmit={this.handleSubmit}>
                <div className="Icon-label">{icon.value.replace(/_/g, ' ')}</div>
                <br/>
                <input type="hidden" name="value" value={icon.value} />
                <input type="hidden" name="id" value={icon.id} />
                <div className="Iconemo-containter">
                  <img className="Icon-emoji" src={imageStop} alt="stop"/>
                  <img className="Icon-emoji" src={imageCaution} alt="caution" />
                  <img className="Icon-emoji" src={imageCheck} alt="check" />
                  <img className="Icon-emoji" src={imageCaution} alt="caution" />
                  <img className="Icon-emoji" src={imageStop} alt="stop" />
                </div>
                <br/>
                <input type="number" step="0.01" className="Icon-box-form" name="lowest" defaultValue={icon.lowest} value={this.state.icons.lowest} onChange={this.handleChange} />
                <input type="number" step="0.01" className="Icon-box4" name="low" defaultValue={icon.low} value={this.state.icons.low} onChange={this.handleChange} />
                <input type="number" step="0.01" className="Icon-box5" name="high" defaultValue={icon.high} value={this.state.icons.high} onChange={this.handleChange} />
                <input type="number" step="0.01" className="Icon-box-form" name="highest" defaultValue={icon.highest} value={this.state.icons.highest} onChange={this.handleChange} />
                <br/>
                <div className="Icon-orderlabel">graph order of appearance</div>
                <br/>
                <input type="number" className="Icon-box-form" name="order" defaultValue={icon.order} value={this.state.icons.order} onChange={this.handleChange} />
                <br/>
                <input type="submit" className="Add-button" value="submit"/>
            </form>
          ))
        }
      </div>
    )
  }
}

export default IconRanges
