import React, { Component } from 'react';
import { Auth, API, Storage } from 'aws-amplify';
import { Link } from 'react-router-dom';

class CustomCSV extends Component {
    constructor(props){
        super(props)

        const {loc} = props.location.state
        this.local = loc
        const {title} = props.location.state
        this.title = title
        const {org} = props.location.state
        this.org = org
        const {version} = props.location.state
        this.version = version

        this.state = {
            serial: '',
            loading: true,
            columns: [],
            s3url: '',
            startDate: '',
            endDate: '',
            selectAll: false,
            downloadReady: false,
            creatingCSV: false
        }
        this.selectedReadings = []
        this.originalReadings = []
        this.submit = this.submit.bind(this)
        this.handleChange = this.handleChange.bind(this);
        this.readingClicked = this.readingClicked.bind(this);
    }
    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    resetForm(){
        this.setState({
            s3url: '',
            startDate: '',
            endDate: '',
            selectAll: false,
            downloadReady: false,
            creatingCSV: false
        })
    }

    async readingClicked(index) {
        //let removeDuplicateReading = [...new Set(this.selectedReadings)];
        let foundReading = this.selectedReadings.indexOf(index)
        
        if(foundReading === -1){
            this.selectedReadings.push(index)
        } else {
            this.selectedReadings.splice(foundReading, 1)
        }
    }

    async submit(event){
        event.preventDefault();
        let startDateSent = this.state.startDate
        let endDateSent = this.state.endDate
        
        if(startDateSent === ''){
            alert('select a start date and try again')
            return
        }
        if(endDateSent === ''){
            alert('select an end date and try again')
            return
        }
        if(endDateSent < startDateSent){
            alert('start date should be before end date')
            return
        }

        if(!this.state.selectAll && this.selectedReadings.length === 0){
            alert('one or more readings must be selected')
            return
        }
        this.setState({
            creatingCSV: true
        })

        let startFinal = startDateSent + " 00:00:00"
        let endFinal = endDateSent + " 24:00:00"

        let selectedArray = []
        let finalColumnString = ''

        if(this.state.selectAll){
            selectedArray = this.originalReadings
        } else {
            selectedArray = this.selectedReadings
        }

        let arrayToString = JSON.stringify(selectedArray)
        let removeQuotes = arrayToString.replace(/['"]+/g, '')
        let removeBrack = removeQuotes.replace(/[\])}[{(]/g, '')
        finalColumnString = 'timestamps,' + removeBrack

        let myInit = {
            queryStringParameters: {
                location: this.local,
                columnSelect: finalColumnString,
                startDate: startFinal,
                endDate: endFinal
            }
        }
      
        const apiName = 'AthenaAPI';
        const path = '/alldata';

        let expressId = {}

        try {
            expressId = await API.post(apiName, path, myInit);
        } catch(error){
            alert('error fetching csv data')
        }

        this.athenaID = expressId.dataDownload.QueryExecutionId;
        const fileName = "athenaresults/" + this.athenaID + ".csv";
        let urlForFetch = '';
    
        await Storage.get(fileName, { expires: 300 })
          .then(result => {
            urlForFetch = result;
          })
          .catch(err => console.log(err));
    
        this.setState({
            s3url: urlForFetch
        });

        function athenaTimer() {
            fetch(urlForFetch)
                .then(response => {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response;
                }).then(response => {
                    console.log("done");
                    this.setState({
                        creatingCSV: false,
                        downloadReady: true
                    })
                    clearInterval(this.timeSetAll);
                }).catch(error => {
                    console.log("results not found");
                });
        }     
        this.timeSetAll = setInterval(athenaTimer.bind(this), 5000);
    }
    
    async componentDidMount() {
        Auth.currentAuthenticatedUser({
            bypassCache: false
        }).then(user => {
            this.loggedIn = true
        }
        )
        .catch(error => {
            if (error === 'not authenticated') {
                this.props.history.push('/SignIn');
            }
        }
        );
        
        let myInit = {
            queryStringParameters: {
                location: this.local
            }
          }
      
        const apiName = 'AthenaAPI';
        const path = '/alldata';
        let columnReturn = {}

        try {
            columnReturn = await API.get(apiName, path, myInit);
        } catch(error){
            alert('error fetching csv data')
        }
        let justColumns = columnReturn.dataDownload.Items.map(function(column) { return column["column_name"]; })
        let removeDuplicates = [...new Set(justColumns)];
        let cleanedColumns = removeDuplicates.filter(function(column){ 
            let removeable = ["ttl", "device", "device_status", "timestamps", "groups", "partition_0", "partition_1", "partition_2", "partition_3"]
            return !removeable.includes(column) 
        })
        let finalColumns = cleanedColumns.sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))
        this.originalReadings = finalColumns

        this.setState({
            loading: false,
            columns: finalColumns
        })
    }

    render() {
        var backIcon = require('../Back-01.png');
        return(
            <div className='App'>
                {
                    <div className="Nav-header">
                        <button className="Back-wrap">
                        <Link style={{ textDecoration: 'none' }} to={{ pathname: '/Location', state: { loc: this.local, org: this.org, title: this.title, version: this.version} }}>
                            <img onClick={this.stopInterval} className="Back-button" src={backIcon} alt="back" />
                        </Link>
                        </button>
                    </div>
                }
                <div className='Title'>Spreadsheet</div>
                
                <div className='Settings-wrapper'>
                    {this.state.loading && !this.state.creatingCSV &&
                        <div>
                            <div className='One-space'/>
                            <div className="Loading-inbanner">loading....</div>
                            <div className='One-space'/>
                        </div>     
                    }
                    {!this.state.loading && this.state.creatingCSV &&
                        <div>
                            <div className='One-space'/>
                            <div className="Loading-inbanner">creating spreadsheet</div>
                            <div className='One-space'/>
                        </div>     
                    }
                    {!this.state.loading && !this.state.downloadReady && !this.state.creatingCSV &&
                        <div>
                            <div className='One-space'/>
                                <div className='form-name'>Add the date range below</div>
                                <div className='One-space'/>
                                {!this.state.loading &&
                                    <div>
                                        <div className="form-name">start date</div>
                                        <input value={this.state.startDate} name="startDate" className="Reading-name" type="date" onChange={this.handleChange}/>
                                        <div className='One-space'/>
                                        <div className="form-name">end date</div>
                                        <input value={this.state.endDate} name="endDate" className="Reading-name" type="date" onChange={this.handleChange}/>
                                    </div>
                                }                                  
                            <div className='One-space'/>
                        </div>
                    }
                    {!this.state.loading && this.state.downloadReady && !this.state.creatingCSV &&
                        <div className='Settings-wrapper'>
                            <div>
                                <div className='One-space'/>
                                <button className="resend-link" onClick={(e) => this.resetForm()}>reset</button>
                                <div className='One-space'/>
                                <div className='form-name'>spreadsheet created and ready to download</div>
                                <a href={this.state.s3url} download className="Show-all" style={{ textDecoration: 'none' }}>download</a>
                                <div className='One-space'/>
                            </div>                                 
                        </div>
                    }
                </div>
                {!this.state.loading && !this.state.downloadReady && !this.state.creatingCSV &&
                    <div>
                        <div className='One-space'/>
                        <div className='form-name'>select the readings you want included and then hit submit</div> 
                        <div className='One-space'/>
                        <div className='checkbox-container'>
                            <input type='checkbox' onChange={(e) => {this.setState({selectAll: !this.state.selectAll})}}/>
                        </div>
                        <div className='toggle-labels'>select all</div>
                        <div className='Two-space'/>
                        <div className='Two-space'/>
                    </div>
                }
                {!this.state.loading && !this.state.selectAll && !this.state.downloadReady && !this.state.creatingCSV &&
                    this.state.columns.map((column, index) => (
                        <div key={index}>
                            <div className='checkbox-container'>
                                <input type='checkbox' onClick={() => this.readingClicked(column)}/>
                            </div>
                            <div key={index} className='toggle-labels'>{column.replace(/_/g, ' ')}</div>
                            <div className='Two-space'/>
                            <div className='Two-space'/>
                        </div>
                    ))
                }
                {!this.state.loading && !this.state.downloadReady && !this.state.creatingCSV &&
                    <button onClick={this.submit} className="Add-button">submit</button>
                }               
            </div>
        )
    }
}

export default CustomCSV