import React, { Component } from 'react';
import { API } from 'aws-amplify';
import Graphs from "../components/Graphs";

class Graph extends Component {
    constructor(props){
        super(props);
        this.heldData = props.data
        this.heldIcons = props.icons
        this.heldTimestamp = props.timezone
        this.heldSerial = props.loc

        this.timeStatus = 'week'

        //empty props
        this.dataGraph = [];
        this.dayData = [];
        this.monthData = [];
        this.day0 = [];
        this.day1 = [];
        this.day2 = [];
        this.day3 = [];
        this.day4 = [];
        this.day5 = [];
        this.day6 = [];
        this.timeStatus = 'week';
        this.buttonHold = 'hr';
        this.monthClicked = false;
        this.monthSynced =  false;
        this.timeZone = 'CT'

        this.state = {
            dataGraph: [],
            icons: [],
            dayButtonArray: [],
            timeZone: 'CT',
            hideButtons: true,
            graphLoading: false,
            timeColor1: '#529857',
            timeColor2: '#3c3c3c',
            timeColor3: '#3c3c3c',
            hrColor: '#529857',
            dayColor0: '#3c3c3c',
            dayColor1: '#3c3c3c',
            dayColor2: '#3c3c3c',
            dayColor3: '#3c3c3c',
            dayColor4: '#3c3c3c',
            dayColor5: '#3c3c3c',
            dayColor6: '#3c3c3c',
        }
        this.timeLine = this.timeLine.bind(this);
    }

    // toggle week/month worth of data for the graphs
    async timeLine() {
        this.timeStatus = this.click
        let queryName = '/items/'+this.heldSerial

        if(this.timeStatus === "day"){
            this.setState({timeColor1: '#3c3c3c', timeColor2: '#3c3c3c', timeColor3: '#529857', graphLoading: true})
            this.monthClicked = false
            await this.dayTimeLine(this.buttonHold)
            this.setState({ graphLoading: false})
        }

        if(this.timeStatus === "week"){
            this.setState({timeColor1: '#529857', timeColor2: '#3c3c3c', timeColor3: '#3c3c3c', graphLoading: true})
            this.monthClicked = false
            this.setState({dataGraph: this.dataGraph, graphLoading: false})
        }

        if(this.timeStatus === "month"){
            this.setState({timeColor1: '#3c3c3c', timeColor2: '#529857', timeColor3: '#3c3c3c', graphLoading: true})
            
            if(this.monthSynced === false) {
                try{
                    this.monthData = await API.get('monthTrimmed', queryName)
                    this.monthSynced = true
                } catch(error) {
                    console.log(error)
                }
            }
            this.monthClicked = true
            this.setState({dataGraph: this.monthData, graphLoading: false})
        }    

    }

    async dayTimeLine(button) {
        this.buttonHold = button

        if(button === 'hr'){
            this.setState({hrColor: '#529857', dayColor0: '#3c3c3c', dayColor1: '#3c3c3c', dayColor2: '#3c3c3c', dayColor3: '#3c3c3c', dayColor4: '#3c3c3c', dayColor5: '#3c3c3c', dayColor6: '#3c3c3c', dataGraph: this.dayData})
        }
        if(button === 0){
            this.setState({hrColor: '#3c3c3c', dayColor0: '#529857', dayColor1: '#3c3c3c', dayColor2: '#3c3c3c', dayColor3: '#3c3c3c', dayColor4: '#3c3c3c', dayColor5: '#3c3c3c', dayColor6: '#3c3c3c', dataGraph: this.day0})
        }
        if(button === 1){
            this.setState({hrColor: '#3c3c3c', dayColor0: '#3c3c3c', dayColor1: '#529857', dayColor2: '#3c3c3c', dayColor3: '#3c3c3c', dayColor4: '#3c3c3c', dayColor5: '#3c3c3c', dayColor6: '#3c3c3c', dataGraph: this.day1})
        }
        if(button === 2){
            this.setState({hrColor: '#3c3c3c', dayColor0: '#3c3c3c', dayColor1: '#3c3c3c', dayColor2: '#529857', dayColor3: '#3c3c3c', dayColor4: '#3c3c3c', dayColor5: '#3c3c3c', dayColor6: '#3c3c3c', dataGraph: this.day2})
        }
        if(button === 3){
            this.setState({hrColor: '#3c3c3c', dayColor0: '#3c3c3c', dayColor1: '#3c3c3c', dayColor2: '#3c3c3c', dayColor3: '#529857', dayColor4: '#3c3c3c', dayColor5: '#3c3c3c', dayColor6: '#3c3c3c', dataGraph: this.day3})
        }
        if(button === 4){
            this.setState({hrColor: '#3c3c3c', dayColor0: '#3c3c3c', dayColor1: '#3c3c3c', dayColor2: '#3c3c3c', dayColor3: '#3c3c3c', dayColor4: '#529857', dayColor5: '#3c3c3c', dayColor6: '#3c3c3c', dataGraph: this.day4})
        }
        if(button === 5){
            this.setState({hrColor: '#3c3c3c', dayColor0: '#3c3c3c', dayColor1: '#3c3c3c', dayColor2: '#3c3c3c', dayColor3: '#3c3c3c', dayColor4: '#3c3c3c', dayColor5: '#529857', dayColor6: '#3c3c3c', dataGraph: this.day5})
        }
        if(button === 6){
            this.setState({hrColor: '#3c3c3c', dayColor0: '#3c3c3c', dayColor1: '#3c3c3c', dayColor2: '#3c3c3c', dayColor3: '#3c3c3c', dayColor4: '#3c3c3c', dayColor5: '#3c3c3c', dayColor6: '#529857', dataGraph: this.day6})
        }
    }

    async divideDataDay (data) {
        this.day0 = []
        this.day1 = []
        this.day2 = []
        this.day3 = []
        this.day4 = []
        this.day5 = []
        this.day6 = []
        let buttonDates = []
        let lastDay = data[0].timestamps.slice(8, 10)
        buttonDates.push(data[0].timestamps.slice(5, 10).replace('-', '/'))
        let arrayChange = 0

        for (let i = 0; i < data.length; i++){
            if(data[i].timestamps.slice(8, 10) !== lastDay){
                arrayChange++
                lastDay = data[i].timestamps.slice(8, 10)
                if(buttonDates.length < 7){
                    buttonDates.push(data[i].timestamps.slice(5, 10).replace('-', '/'))
                }               
            }
            if(arrayChange === 0){
                this.day0.push(data[i])
            }
            if(arrayChange === 1){
                this.day1.push(data[i])
            }
            if(arrayChange === 2){
                this.day2.push(data[i])
            }
            if(arrayChange === 3){
                this.day3.push(data[i])
            }
            if(arrayChange === 4){
                this.day4.push(data[i])
            }
            if(arrayChange === 5){
                this.day5.push(data[i])
            }
            if(arrayChange === 6){
                this.day6.push(data[i])
            }
        }
        return buttonDates
    }

    async componentDidMount() {
        let buttonDates = []

        try{
            this.dayData = this.heldData.slice(0, 288);
            buttonDates = await this.divideDataDay(this.heldData);
        } catch(error){
            console.log('no data yet')
        }
        this.monthSynced = false

        if(this.heldData !== undefined){
            this.dataGraph = this.heldData
            let componentSlicedData = [...this.heldData]

            //data trim 
            var i = Math.floor(componentSlicedData.length / 3);

            while (i--){
                componentSlicedData.splice((i + 1) * 3, 2);
            }
            
            this.dataGraph = componentSlicedData

            this.setState({
                dataGraph: componentSlicedData,
                dayButtonArray: buttonDates,
                icons: this.heldIcons,
                hideButtons: false,
                timeZone: this.heldTimestamp
            })
        }
    }

    async UNSAFE_componentWillReceiveProps(nextProps) {
        let receivedData = nextProps.data
        let receivedIcons = nextProps.icons
        this.local = nextProps.loc
        let noData = nextProps.noData
        this.timeZone = nextProps.timezone

        if(noData === true){
            return
        }

        this.dayData = receivedData.slice(0, 288);
        let slicedData = [...receivedData]

        let buttonDates = []
        buttonDates = await this.divideDataDay(receivedData);

        //data trim for mobile viewing
        var i = Math.floor(slicedData.length / 3);

        while (i--){
            slicedData.splice((i + 1) * 3, 2);
        }

        this.newDataCaught = 'false'
        this.dataGraph = slicedData
        this.monthSynced = false
        let renderData = slicedData
        if(this.monthClicked){
            let queryName = '/items/'+this.local
            try{
                this.monthData = await API.get('monthTrimmed', queryName)
                renderData = this.monthData
                this.monthSynced = true
            } catch(error) {
                console.log(error)
            }
        }
        if(this.timeStatus === 'day'){
            this.dayTimeLine(this.buttonHold)
        } else {
            this.setState({
                dataGraph: renderData,
                icons: receivedIcons,
                dayButtonArray: buttonDates,
                hideButtons: false,
                timeZone: this.timeZone,
            })
        }
        

    }

    render(){
        return(
            <div className='App'>
                {!this.state.hideButtons &&
                    <div className="Time-wrapper">
                        <button type="button" className="Graph-time" onClick={(e) => this.timeLine(this.click = 'day')} style={{backgroundColor:this.state.timeColor3}}>
                            <div>day</div>
                        </button>
                        <button type="button" className="Graph-time" onClick={(e) => this.timeLine(this.click = 'week')} style={{backgroundColor:this.state.timeColor1}}>
                            <div>week</div>
                        </button>
                        <button type="button" className="Graph-time" onClick={(e) => this.timeLine(this.click = 'month')} style={{backgroundColor:this.state.timeColor2}}>
                            <div>month</div>
                        </button>
                    </div>
                }
                {this.state.graphLoading &&
                    <div>
                        <div className='Loading-button'>Loading...</div>
                    </div>
                }
                <div/>
                {this.timeStatus === 'day' &&
                    <div className='Day-wrapper'>
                        <button type="button" className='Day-graphs' onClick={(event) => this.dayTimeLine(6)} style={{backgroundColor:this.state.dayColor6}}>{this.state.dayButtonArray[6]}</button>
                        <button type="button" className='Day-graphs' onClick={(event) => this.dayTimeLine(5)} style={{backgroundColor:this.state.dayColor5}}>{this.state.dayButtonArray[5]}</button>
                        <button type="button" className='Day-graphs' onClick={(event) => this.dayTimeLine(4)} style={{backgroundColor:this.state.dayColor4}}>{this.state.dayButtonArray[4]}</button>
                        <button type="button" className='Day-graphs' onClick={(event) => this.dayTimeLine(3)} style={{backgroundColor:this.state.dayColor3}}>{this.state.dayButtonArray[3]}</button>
                        <button type="button" className='Day-graphs' onClick={(event) => this.dayTimeLine(2)} style={{backgroundColor:this.state.dayColor2}}>{this.state.dayButtonArray[2]}</button>
                        <button type="button" className='Day-graphs' onClick={(event) => this.dayTimeLine(1)} style={{backgroundColor:this.state.dayColor1}}>{this.state.dayButtonArray[1]}</button>
                        <button type="button" className='Day-graphs' onClick={(event) => this.dayTimeLine(0)} style={{backgroundColor:this.state.dayColor0}}>{this.state.dayButtonArray[0]}</button>
                        <button type="button" className='Day-graphs' onClick={(event) => this.dayTimeLine('hr')} style={{backgroundColor:this.state.hrColor}}>24hr</button>
                    </div>
                }
                <Graphs dataForGraph={this.state.dataGraph} icons={this.state.icons} timezone={this.state.timeZone} test={'true'}/>
            </div>
        )
    }
}
export default Graph