import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as queries from '../graphql/queries.js';
import RemoveOrg from "../components/RemoveOrg"

class DivAddOrg extends Component {

  constructor(props){
    super(props)

    this.state = {
      loading: false,
      createdID: false,
      enterprise: '',
      division: '',
      organization: '',
      orgArray: [],
      noOrgs: false
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.refreshOrgs = this.refreshOrgs.bind(this);
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  async refreshOrgs() {
    let userEnt = this.state.enterprise
    let userDiv = this.state.division

    let getEntOrgs = `query GetEntDivsOrgs { getEnt(entName: "${userEnt}") { id entdivsorgs (filter: {divName: {eq: "${userDiv}"}}) { items { id entName divName orgName orgName isOrg }}}}`
    let foundOrgs = []

    try{
      let returnedOrgs = await API.graphql(graphqlOperation(getEntOrgs));
      foundOrgs = returnedOrgs.data.getEnt.entdivsorgs.items
    } catch(error) {
      console.log(error)
    };

    let noOrgsFound = false
    if(foundOrgs.length === 0){
      noOrgsFound = true
    }
    foundOrgs.sort((a,b) => a.orgName.localeCompare(b.orgName, undefined, { numeric: true }))

    this.setState({
      orgArray: foundOrgs,
      noOrgs: noOrgsFound
    })

  }

  async handleSubmit(event){
    event.preventDefault();
    this.setState({loading: true})

    if(this.state.organization === ''){
      alert('organization can not be blank')
      return
    }

    let finalEnt = this.state.enterprise
    let finalDiv = this.state.division
    let finalOrg = this.state.organization.trim()

    function createOrg() {
      const apiName = 'EntAuth';
      const path = '/items';

      const myInit = {
          body: {
            entName: finalEnt,
            divisionName: finalDiv,
            orgName: finalOrg,
            level: "3"
          }
      };
      return API.post(apiName, path, myInit);
    }

    try{
      await createOrg();
    }catch(error){
      console.log(error)
      this.setState({loading: false})
      alert('error adding organization please try again')
    }

    const addOrg = `mutation CreateEntDivsOrgs {
      createEntDivsOrgs(input: {
        entName: "${finalEnt}"
        divName: "${finalDiv}"
        orgName: "${finalOrg}"
        isOrg: "true"
        entDivsOrgsEntId: "${finalEnt}"
      }) {
        id
      }
    }`

    try{
      await API.graphql(graphqlOperation(addOrg));
      await this.refreshOrgs()
      this.setState({loading: false})
      alert('new organization has been created')
    } catch(error){
      console.log(error)
      this.setState({loading: false})
      alert('error adding new organization please try again')
    }

    this.setState({
      loading: false,
      organization: ''
    })
  }

  async componentDidMount(){
    Auth.currentAuthenticatedUser({
      bypassCache: false
      }).then(user => {
        this.loggedIn = true
      }
    )
    .catch(error => {
      if(error === 'not authenticated'){
        this.props.history.push('/SignIn');
      }
    }
    );

    let user = await Auth.currentAuthenticatedUser()
    const userName = user.username;

    let userInfo = await API.graphql(graphqlOperation(queries.getEntUser, { userName: userName }));
    let userEnt = userInfo.data.getEntUser.entName
    let userDiv = userInfo.data.getEntUser.divName

    let getEntOrgs = `query GetEntDivsOrgs { getEnt(entName: "${userEnt}") { id entdivsorgs (filter: {divName: {eq: "${userDiv}"}}) { items { id entName divName orgName orgName isOrg }}}}`

    let foundOrgs = []

    try{
      let returnedOrgs = await API.graphql(graphqlOperation(getEntOrgs));
      foundOrgs = returnedOrgs.data.getEnt.entdivsorgs.items
    } catch(error) {
      console.log(error)
    };

    let noOrgsFound = false
    if(foundOrgs.length === 0){
      noOrgsFound = true
    }
    foundOrgs.sort((a,b) => a.orgName.localeCompare(b.orgName, undefined, { numeric: true }))

    this.setState({
      enterprise: userEnt,
      division: userDiv,
      orgArray: foundOrgs,
      noOrgs: noOrgsFound
    })
  }

  render(){
    var backIcon = require('../Back-01.png');

    return(
      <div className="App">
        {
          <div className="Nav-header">
            <Link style={{textDecoration: 'none'}} to="/SettingsPage">
              <button className="Back-wrap">
                <img className="Back-button" src={backIcon} alt="back"/>
              </button>
            </Link>
          </div>
        }

        <div className="Title">Add Organization</div>

        <div className="Settings-wrapper">
          {this.state.loading &&
            <div className="Settings-wrapper">
              <div className='One-space'/>
              <div className="Loading-buttonstatus">Loading...</div>
              <div className='One-space'/>
            </div>
          }
          {!this.state.createdID && !this.state.loading &&
            <form onSubmit={this.handleSubmit}>
              <div className='One-space'/>
              <div className="form-name">organization name</div>
              <input value={this.state.organization} name="organization" className="Reading-name" type="text" onChange={this.handleChange}/>
              <div className='One-space'/>
              <input type="submit" className="submit-buttons" value="submit"/>
              <div className='One-space'/>
            </form>
          }
        </div>
        {!this.state.noOrgs &&
          <RemoveOrg organizations={this.state.orgArray} noOrganizations={this.state.noOrgs}/>
        }
      </div>
    )
  }

}

export default DivAddOrg
