/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createDevice = /* GraphQL */ `
  mutation CreateDevice($input: CreateDeviceInput!) {
    createDevice(input: $input) {
      id
      group
      location
      title
      version
      createdAt
      updatedAt
    }
  }
`;
export const updateDevice = /* GraphQL */ `
  mutation UpdateDevice($input: UpdateDeviceInput!) {
    updateDevice(input: $input) {
      id
      group
      location
      title
      version
      createdAt
      updatedAt
    }
  }
`;
export const deleteDevice = /* GraphQL */ `
  mutation DeleteDevice($input: DeleteDeviceInput!) {
    deleteDevice(input: $input) {
      id
      group
      location
      title
      version
      createdAt
      updatedAt
    }
  }
`;
export const createMultidevice = /* GraphQL */ `
  mutation CreateMultidevice($input: CreateMultideviceInput!) {
    createMultidevice(input: $input) {
      id
      group
      location
      title
      version
      createdAt
      updatedAt
    }
  }
`;
export const updateMultidevice = /* GraphQL */ `
  mutation UpdateMultidevice($input: UpdateMultideviceInput!) {
    updateMultidevice(input: $input) {
      id
      group
      location
      title
      version
      createdAt
      updatedAt
    }
  }
`;
export const deleteMultidevice = /* GraphQL */ `
  mutation DeleteMultidevice($input: DeleteMultideviceInput!) {
    deleteMultidevice(input: $input) {
      id
      group
      location
      title
      version
      createdAt
      updatedAt
    }
  }
`;
export const createBlog = /* GraphQL */ `
  mutation CreateBlog($input: CreateBlogInput!) {
    createBlog(input: $input) {
      id
      location
      posts {
        nextToken
      }
      icons {
        nextToken
      }
      docs {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateBlog = /* GraphQL */ `
  mutation UpdateBlog($input: UpdateBlogInput!) {
    updateBlog(input: $input) {
      id
      location
      posts {
        nextToken
      }
      icons {
        nextToken
      }
      docs {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteBlog = /* GraphQL */ `
  mutation DeleteBlog($input: DeleteBlogInput!) {
    deleteBlog(input: $input) {
      id
      location
      posts {
        nextToken
      }
      icons {
        nextToken
      }
      docs {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost($input: CreatePostInput!) {
    createPost(input: $input) {
      id
      author
      content
      status
      blog {
        id
        location
        createdAt
        updatedAt
      }
      createdAt
      img
      updatedAt
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost($input: UpdatePostInput!) {
    updatePost(input: $input) {
      id
      author
      content
      status
      blog {
        id
        location
        createdAt
        updatedAt
      }
      createdAt
      img
      updatedAt
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost($input: DeletePostInput!) {
    deletePost(input: $input) {
      id
      author
      content
      status
      blog {
        id
        location
        createdAt
        updatedAt
      }
      createdAt
      img
      updatedAt
    }
  }
`;
export const createIcon = /* GraphQL */ `
  mutation CreateIcon($input: CreateIconInput!) {
    createIcon(input: $input) {
      id
      value
      lowest
      low
      high
      highest
      order
      blog {
        id
        location
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateIcon = /* GraphQL */ `
  mutation UpdateIcon($input: UpdateIconInput!) {
    updateIcon(input: $input) {
      id
      value
      lowest
      low
      high
      highest
      order
      blog {
        id
        location
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteIcon = /* GraphQL */ `
  mutation DeleteIcon($input: DeleteIconInput!) {
    deleteIcon(input: $input) {
      id
      value
      lowest
      low
      high
      highest
      order
      blog {
        id
        location
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createDoc = /* GraphQL */ `
  mutation CreateDoc($input: CreateDocInput!) {
    createDoc(input: $input) {
      id
      name
      filename
      createdAt
      uploadtime
      username
      blog {
        id
        location
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const updateDoc = /* GraphQL */ `
  mutation UpdateDoc($input: UpdateDocInput!) {
    updateDoc(input: $input) {
      id
      name
      filename
      createdAt
      uploadtime
      username
      blog {
        id
        location
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const deleteDoc = /* GraphQL */ `
  mutation DeleteDoc($input: DeleteDocInput!) {
    deleteDoc(input: $input) {
      id
      name
      filename
      createdAt
      uploadtime
      username
      blog {
        id
        location
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const createOrganizationLink = /* GraphQL */ `
  mutation CreateOrganizationLink($input: CreateOrganizationLinkInput!) {
    createOrganizationLink(input: $input) {
      id
      orgName
      hiddenAlarms {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateOrganizationLink = /* GraphQL */ `
  mutation UpdateOrganizationLink($input: UpdateOrganizationLinkInput!) {
    updateOrganizationLink(input: $input) {
      id
      orgName
      hiddenAlarms {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrganizationLink = /* GraphQL */ `
  mutation DeleteOrganizationLink($input: DeleteOrganizationLinkInput!) {
    deleteOrganizationLink(input: $input) {
      id
      orgName
      hiddenAlarms {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createHideAlarm = /* GraphQL */ `
  mutation CreateHideAlarm($input: CreateHideAlarmInput!) {
    createHideAlarm(input: $input) {
      id
      groupName
      subGroup
      serial
      readingName
      deviceName
      hideUntilDate
      createdAt
      orgLink {
        id
        orgName
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const updateHideAlarm = /* GraphQL */ `
  mutation UpdateHideAlarm($input: UpdateHideAlarmInput!) {
    updateHideAlarm(input: $input) {
      id
      groupName
      subGroup
      serial
      readingName
      deviceName
      hideUntilDate
      createdAt
      orgLink {
        id
        orgName
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const deleteHideAlarm = /* GraphQL */ `
  mutation DeleteHideAlarm($input: DeleteHideAlarmInput!) {
    deleteHideAlarm(input: $input) {
      id
      groupName
      subGroup
      serial
      readingName
      deviceName
      hideUntilDate
      createdAt
      orgLink {
        id
        orgName
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup($input: CreateGroupInput!) {
    createGroup(input: $input) {
      id
      groupName
      users {
        nextToken
      }
      units {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup($input: UpdateGroupInput!) {
    updateGroup(input: $input) {
      id
      groupName
      users {
        nextToken
      }
      units {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup($input: DeleteGroupInput!) {
    deleteGroup(input: $input) {
      id
      groupName
      users {
        nextToken
      }
      units {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      userName
      groupName
      group {
        id
        groupName
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      userName
      groupName
      group {
        id
        groupName
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      id
      userName
      groupName
      group {
        id
        groupName
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUnit = /* GraphQL */ `
  mutation CreateUnit($input: CreateUnitInput!) {
    createUnit(input: $input) {
      id
      serial
      nickName
      group {
        id
        groupName
        createdAt
        updatedAt
      }
      createdAt
      version
      updatedAt
    }
  }
`;
export const updateUnit = /* GraphQL */ `
  mutation UpdateUnit($input: UpdateUnitInput!) {
    updateUnit(input: $input) {
      id
      serial
      nickName
      group {
        id
        groupName
        createdAt
        updatedAt
      }
      createdAt
      version
      updatedAt
    }
  }
`;
export const deleteUnit = /* GraphQL */ `
  mutation DeleteUnit($input: DeleteUnitInput!) {
    deleteUnit(input: $input) {
      id
      serial
      nickName
      group {
        id
        groupName
        createdAt
        updatedAt
      }
      createdAt
      version
      updatedAt
    }
  }
`;
export const createEnt = /* GraphQL */ `
  mutation CreateEnt($input: CreateEntInput!) {
    createEnt(input: $input) {
      id
      entName
      entusers {
        nextToken
      }
      entunits {
        nextToken
      }
      entdivsorgs {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateEnt = /* GraphQL */ `
  mutation UpdateEnt($input: UpdateEntInput!) {
    updateEnt(input: $input) {
      id
      entName
      entusers {
        nextToken
      }
      entunits {
        nextToken
      }
      entdivsorgs {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteEnt = /* GraphQL */ `
  mutation DeleteEnt($input: DeleteEntInput!) {
    deleteEnt(input: $input) {
      id
      entName
      entusers {
        nextToken
      }
      entunits {
        nextToken
      }
      entdivsorgs {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createEntUser = /* GraphQL */ `
  mutation CreateEntUser($input: CreateEntUserInput!) {
    createEntUser(input: $input) {
      id
      userName
      level
      entName
      divName
      orgName
      ent {
        id
        entName
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateEntUser = /* GraphQL */ `
  mutation UpdateEntUser($input: UpdateEntUserInput!) {
    updateEntUser(input: $input) {
      id
      userName
      level
      entName
      divName
      orgName
      ent {
        id
        entName
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteEntUser = /* GraphQL */ `
  mutation DeleteEntUser($input: DeleteEntUserInput!) {
    deleteEntUser(input: $input) {
      id
      userName
      level
      entName
      divName
      orgName
      ent {
        id
        entName
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createEntUnit = /* GraphQL */ `
  mutation CreateEntUnit($input: CreateEntUnitInput!) {
    createEntUnit(input: $input) {
      id
      serial
      nickName
      entName
      divName
      orgName
      ent {
        id
        entName
        createdAt
        updatedAt
      }
      createdAt
      version
      updatedAt
    }
  }
`;
export const updateEntUnit = /* GraphQL */ `
  mutation UpdateEntUnit($input: UpdateEntUnitInput!) {
    updateEntUnit(input: $input) {
      id
      serial
      nickName
      entName
      divName
      orgName
      ent {
        id
        entName
        createdAt
        updatedAt
      }
      createdAt
      version
      updatedAt
    }
  }
`;
export const deleteEntUnit = /* GraphQL */ `
  mutation DeleteEntUnit($input: DeleteEntUnitInput!) {
    deleteEntUnit(input: $input) {
      id
      serial
      nickName
      entName
      divName
      orgName
      ent {
        id
        entName
        createdAt
        updatedAt
      }
      createdAt
      version
      updatedAt
    }
  }
`;
export const createEntDivsOrgs = /* GraphQL */ `
  mutation CreateEntDivsOrgs($input: CreateEntDivsOrgsInput!) {
    createEntDivsOrgs(input: $input) {
      id
      entName
      isOrg
      divName
      orgName
      ent {
        id
        entName
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateEntDivsOrgs = /* GraphQL */ `
  mutation UpdateEntDivsOrgs($input: UpdateEntDivsOrgsInput!) {
    updateEntDivsOrgs(input: $input) {
      id
      entName
      isOrg
      divName
      orgName
      ent {
        id
        entName
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteEntDivsOrgs = /* GraphQL */ `
  mutation DeleteEntDivsOrgs($input: DeleteEntDivsOrgsInput!) {
    deleteEntDivsOrgs(input: $input) {
      id
      entName
      isOrg
      divName
      orgName
      ent {
        id
        entName
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createEnterpriseButtons = /* GraphQL */ `
  mutation CreateEnterpriseButtons($input: CreateEnterpriseButtonsInput!) {
    createEnterpriseButtons(input: $input) {
      user
      id
      ent
      div
      org
      serial
      title
      version
      createdAt
      updatedAt
    }
  }
`;
export const updateEnterpriseButtons = /* GraphQL */ `
  mutation UpdateEnterpriseButtons($input: UpdateEnterpriseButtonsInput!) {
    updateEnterpriseButtons(input: $input) {
      user
      id
      ent
      div
      org
      serial
      title
      version
      createdAt
      updatedAt
    }
  }
`;
export const deleteEnterpriseButtons = /* GraphQL */ `
  mutation DeleteEnterpriseButtons($input: DeleteEnterpriseButtonsInput!) {
    deleteEnterpriseButtons(input: $input) {
      user
      id
      ent
      div
      org
      serial
      title
      version
      createdAt
      updatedAt
    }
  }
`;
export const createDivisionButtons = /* GraphQL */ `
  mutation CreateDivisionButtons($input: CreateDivisionButtonsInput!) {
    createDivisionButtons(input: $input) {
      user
      id
      ent
      div
      org
      serial
      title
      version
      createdAt
      updatedAt
    }
  }
`;
export const updateDivisionButtons = /* GraphQL */ `
  mutation UpdateDivisionButtons($input: UpdateDivisionButtonsInput!) {
    updateDivisionButtons(input: $input) {
      user
      id
      ent
      div
      org
      serial
      title
      version
      createdAt
      updatedAt
    }
  }
`;
export const deleteDivisionButtons = /* GraphQL */ `
  mutation DeleteDivisionButtons($input: DeleteDivisionButtonsInput!) {
    deleteDivisionButtons(input: $input) {
      user
      id
      ent
      div
      org
      serial
      title
      version
      createdAt
      updatedAt
    }
  }
`;
export const createOrganizationButtons = /* GraphQL */ `
  mutation CreateOrganizationButtons($input: CreateOrganizationButtonsInput!) {
    createOrganizationButtons(input: $input) {
      user
      id
      ent
      div
      org
      serial
      title
      version
      test
      createdAt
      updatedAt
    }
  }
`;
export const updateOrganizationButtons = /* GraphQL */ `
  mutation UpdateOrganizationButtons($input: UpdateOrganizationButtonsInput!) {
    updateOrganizationButtons(input: $input) {
      user
      id
      ent
      div
      org
      serial
      title
      version
      test
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrganizationButtons = /* GraphQL */ `
  mutation DeleteOrganizationButtons($input: DeleteOrganizationButtonsInput!) {
    deleteOrganizationButtons(input: $input) {
      user
      id
      ent
      div
      org
      serial
      title
      version
      test
      createdAt
      updatedAt
    }
  }
`;
export const createEntBlog = /* GraphQL */ `
  mutation CreateEntBlog($input: CreateEntBlogInput!) {
    createEntBlog(input: $input) {
      id
      location
      posts {
        nextToken
      }
      icons {
        nextToken
      }
      docs {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateEntBlog = /* GraphQL */ `
  mutation UpdateEntBlog($input: UpdateEntBlogInput!) {
    updateEntBlog(input: $input) {
      id
      location
      posts {
        nextToken
      }
      icons {
        nextToken
      }
      docs {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteEntBlog = /* GraphQL */ `
  mutation DeleteEntBlog($input: DeleteEntBlogInput!) {
    deleteEntBlog(input: $input) {
      id
      location
      posts {
        nextToken
      }
      icons {
        nextToken
      }
      docs {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createEntPost = /* GraphQL */ `
  mutation CreateEntPost($input: CreateEntPostInput!) {
    createEntPost(input: $input) {
      id
      author
      content
      status
      blog {
        id
        location
        createdAt
        updatedAt
      }
      createdAt
      img
      updatedAt
    }
  }
`;
export const updateEntPost = /* GraphQL */ `
  mutation UpdateEntPost($input: UpdateEntPostInput!) {
    updateEntPost(input: $input) {
      id
      author
      content
      status
      blog {
        id
        location
        createdAt
        updatedAt
      }
      createdAt
      img
      updatedAt
    }
  }
`;
export const deleteEntPost = /* GraphQL */ `
  mutation DeleteEntPost($input: DeleteEntPostInput!) {
    deleteEntPost(input: $input) {
      id
      author
      content
      status
      blog {
        id
        location
        createdAt
        updatedAt
      }
      createdAt
      img
      updatedAt
    }
  }
`;
export const createEntIcon = /* GraphQL */ `
  mutation CreateEntIcon($input: CreateEntIconInput!) {
    createEntIcon(input: $input) {
      id
      value
      lowest
      low
      high
      highest
      order
      blog {
        id
        location
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateEntIcon = /* GraphQL */ `
  mutation UpdateEntIcon($input: UpdateEntIconInput!) {
    updateEntIcon(input: $input) {
      id
      value
      lowest
      low
      high
      highest
      order
      blog {
        id
        location
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteEntIcon = /* GraphQL */ `
  mutation DeleteEntIcon($input: DeleteEntIconInput!) {
    deleteEntIcon(input: $input) {
      id
      value
      lowest
      low
      high
      highest
      order
      blog {
        id
        location
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createEntDoc = /* GraphQL */ `
  mutation CreateEntDoc($input: CreateEntDocInput!) {
    createEntDoc(input: $input) {
      id
      name
      filename
      createdAt
      uploadtime
      username
      blog {
        id
        location
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const updateEntDoc = /* GraphQL */ `
  mutation UpdateEntDoc($input: UpdateEntDocInput!) {
    updateEntDoc(input: $input) {
      id
      name
      filename
      createdAt
      uploadtime
      username
      blog {
        id
        location
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const deleteEntDoc = /* GraphQL */ `
  mutation DeleteEntDoc($input: DeleteEntDocInput!) {
    deleteEntDoc(input: $input) {
      id
      name
      filename
      createdAt
      uploadtime
      username
      blog {
        id
        location
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const createEntInventory = /* GraphQL */ `
  mutation CreateEntInventory($input: CreateEntInventoryInput!) {
    createEntInventory(input: $input) {
      entName
      id
      serial
      createdAt
      divName
      orgName
      onboardedAt
      updatedAt
    }
  }
`;
export const updateEntInventory = /* GraphQL */ `
  mutation UpdateEntInventory($input: UpdateEntInventoryInput!) {
    updateEntInventory(input: $input) {
      entName
      id
      serial
      createdAt
      divName
      orgName
      onboardedAt
      updatedAt
    }
  }
`;
export const deleteEntInventory = /* GraphQL */ `
  mutation DeleteEntInventory($input: DeleteEntInventoryInput!) {
    deleteEntInventory(input: $input) {
      entName
      id
      serial
      createdAt
      divName
      orgName
      onboardedAt
      updatedAt
    }
  }
`;
