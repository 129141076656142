import React, { Component } from 'react';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as queries from '../graphql/queries.js';
import * as mutations from '../graphql/mutations';
import { Link } from 'react-router-dom';

class DeviceSync extends Component {

    async componentDidMount(){
        let user = await Auth.currentAuthenticatedUser();

        const userName = user.username;
        let ent = await API.graphql(graphqlOperation(queries.getEntUser, { userName: userName }));

        const level = ent.data.getEntUser.level
        const entName = ent.data.getEntUser.entName
        const divName = ent.data.getEntUser.divName
        const orgName = ent.data.getEntUser.orgName

        let getEntUnits = ''
        let updateDeviceMutationName = ""
        let updateDeviceMutation = ""

        if(level === "1"){
            getEntUnits = `query GetEntUnits {
                getEnt(entName: "${entName}") {
                    id
                    entunits {
                        items {
                            id
                            serial
                            entName
                            divName
                            orgName
                            nickName
                        }
                    }
                }
            }`
            updateDeviceMutationName = "UpdateEnterpriseButtons"
            updateDeviceMutation = "updateEnterpriseButtons"
        }

        if(level === "2"){
            getEntUnits = `query GetEntUnits {
                getEnt(entName: "${entName}") {
                    id
                    entunits (filter: {divName: {eq: "${divName}"}}) {
                        items {
                            id
                            serial
                            entName
                            divName
                            orgName
                            nickName
                        }
                    }
                }
            }`
            updateDeviceMutationName = "UpdateDivisionButtons"
            updateDeviceMutation = "updateDivisionButtons"
        }

        if (level === "3"){
            getEntUnits = `query GetEntUnits {
                getEnt(entName: "${entName}") {
                id
                entunits (filter: {and: {divName: {eq: "${divName}"}, orgName: {eq: "${orgName}"}}}) {
                    items {
                        id
                        serial
                        entName
                        divName
                        orgName
                        nickName
                    }
                }
                }
            }`
            updateDeviceMutationName = "UpdateOrganizationButtons"
            updateDeviceMutation = "updateOrganizationButtons"
        }

        let groupDevices = []

        try{
            let returnEntUnits = await API.graphql(graphqlOperation(getEntUnits));
            groupDevices = returnEntUnits.data.getEnt.entunits.items
        } catch(error) {
            console.log(error)
        };

        let userButtons = []
        let queryName = userName.toLowerCase()

        if (level === "1"){
            try{
                let data = await API.graphql(graphqlOperation(queries.listEnterpriseButtonss, {
                limit: 10000,
                filter: {
                    user:{
                    eq: queryName
                    }
                }
                }));
                if(data.data.listEnterpriseButtonss.items.length === 0){
                    console.log('no buttons enterprise')
                } else {
                    data.data.listEnterpriseButtonss.items.sort((a, b) => a.title.localeCompare(b.title, undefined, { numeric: true }))
                    userButtons = data.data.listEnterpriseButtonss.items
                }
            } catch(error) {
                console.log('error caught getting buttons')
            }
        }

        if (level === "2"){
            try{
                let data = await API.graphql(graphqlOperation(queries.listDivisionButtonss, {
                limit: 10000,
                filter: {
                    user:{
                    eq: queryName
                    }
                }
                }));
                if(data.data.listDivisionButtonss.items.length === 0){
                    console.log('no buttons division')
                } else {
                    data.data.listDivisionButtonss.items.sort((a, b) => a.title.localeCompare(b.title, undefined, { numeric: true }))
                    try{
                        userButtons = data.data.listDivisionButtonss.items
                    } catch(error)  {
                        console.log(error)
                    }
                }
            } catch(error) {
                console.log('error caught getting buttons')
            }
        }

        if (level === "3"){
            try{
                let data = await API.graphql(graphqlOperation(queries.listOrganizationButtonss, {
                    limit: 10000,
                    filter: {
                        user:{
                        eq: queryName
                        }
                    }
                }));
            if(data.data.listOrganizationButtonss.items.length === 0){
                    console.log('no buttons organization')
                } else {
                    data.data.listOrganizationButtonss.items.sort((a, b) => a.title.localeCompare(b.title, undefined, { numeric: true }))
                    userButtons = data.data.listOrganizationButtonss.items
                }
            } catch(error) {
                console.log('error caught getting buttons')
            }
        }

        let newDevices = []

        groupDevices.forEach(function (device) {
            let newDevice = true
            userButtons.forEach(async function (button) {
                if(device.serial === button.serial){
                    newDevice = false
                    if(device.divName !== button.div || device.orgName !== button.org || device.nickName !== button.title){
                        const update = `mutation ${updateDeviceMutationName} {
                            ${updateDeviceMutation}(input: {
                                id: "${button.id}"
                                serial: "${button.serial}"
                                user: "${button.user}"
                                ent: "${button.ent}"
                                div: "${device.divName}"
                                org: "${device.orgName}"
                                title: "${device.nickName}"
                                version: ${button.version}
                            }){
                                id
                            }
                        }`
                        
                        try {
                            await API.graphql(graphqlOperation(update));
                        } catch(error) {
                            console.log(error)
                        }
                    }
                }
            })
            if(newDevice === true){
                if(device.nickName !== 'null'){
                    newDevices.push(device)
                }
                
            }
        })

        if(newDevices.length !== 0){
            async function addButtons(orgUnits, userName, level) {
                if(level === "1"){
                    orgUnits.map(async (unit) => {
                        await API.graphql(graphqlOperation(mutations.createEnterpriseButtons, { input: { user: userName.toLowerCase(), serial: unit.serial,  ent: unit.entName, div: unit.divName, org: unit.orgName, title: unit.nickName, version: 1 }}));
                    })
                }
                if(level === "2"){
                    orgUnits.map(async (unit) => {
                        await API.graphql(graphqlOperation(mutations.createDivisionButtons, { input: { user: userName.toLowerCase(), serial: unit.serial,  ent: unit.entName, div: unit.divName, org: unit.orgName, title: unit.nickName, version: 1 }}));
                    })
                }
                if(level === "3"){
                    orgUnits.map(async (unit) => {
                        await API.graphql(graphqlOperation(mutations.createOrganizationButtons, { input: { user: userName.toLowerCase(), serial: unit.serial,  ent: unit.entName, div: unit.divName, org: unit.orgName, title: unit.nickName, version: 1 }}));
                    })
                }
            }

            try{
                await addButtons(newDevices, userName, level);
            } catch (error) {
                console.log(error)
            }
        }
        await new Promise(sleep => setTimeout(sleep, 2000));
        this.props.history.push('/EntHome');
    }


    render(){
        var backIcon = require('../Back-01.png');
        return (
            <div className='App'>
                <div className="Nav-header">                
                    <Link style={{textDecoration: 'none'}} to="/Home">
                        <button onClick={this.stopInterval} className="Back-wrap">
                            <img className="Back-button" src={backIcon} alt="back"/>
                        </button>
                    </Link>
                </div>
                <div className='Two-space'/>
                <div className='Two-space'/>
                <div className="Status-wrapper">
                    <div className='Two-space'/>
                    <div className='Loading-buttonstatus'>Loading....</div>
                    <div className='Two-space'/>
                </div>
            </div>
        )
    }
}
export default DeviceSync
