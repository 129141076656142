import React, { Component } from 'react';
import Log from './Log.js';
import HideAlarms from './HideAlarms.js'

class AlarmSettings extends Component {

    constructor(props){
        super(props)
    
        this.state = {
          timeColor1: '#666666',
          timeColor2: '#666666'
         }
    
        this.menuSelect = this.menuSelect.bind(this);
      }

    async menuSelect () {
        this.menuOption = this.click
    
        if(this.menuOption === "logs"){
            this.setState({
                timeColor1: '#529857',
                timeColor2: '#666666',
            })
        }
    
        if(this.menuOption === "hide"){
            this.setState({
                timeColor1: '#666666',
                timeColor2: '#529857',
            })
        }   
    }

    render(){
        return(
            <div>
                {
                    <div className="Button2-wrapper-alarm">
                        <button type="button" className="Button2-select-alarm" onClick={(e) => this.menuSelect(this.click = 'logs')} style={{backgroundColor:this.state.timeColor1}}>
                            <div>logs</div>
                        </button>
                        <button type="button" className="Button2-select-alarm" onClick={(e) => this.menuSelect(this.click = 'hide')} style={{backgroundColor:this.state.timeColor2}}>
                            <div>hide</div>
                        </button>
                    </div>
                }
                <div className='One-space'/>
                {this.click === 'logs' &&
                    <div>
                        <Log serial={this.props.serial}/>
                        <div className='One-space'/>
                    </div>
                }
                {this.click === 'hide' &&
                    <div>
                        <HideAlarms group={this.props.group} count={this.props.count} setStateCount={this.props.setStateCount} name={this.props.name} subGroup={this.props.subGroup} serial={this.props.serial} currentAlarms={this.props.currentAlarms} route={this.props.route}/>
                        <div className='One-space'/>
                    </div>
                }
                <div className='Two-space'/>
                <div className='Two-space'/>
            </div>
        )
    }

}

export default AlarmSettings