import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries.js';

class OrgUserInvite extends Component {
    constructor(props){
        super(props)
    
        this.state={
            loading: true,
            enterprise: '',
            division: '',
            organization: '',
            id: '',
        }
        this.getUUID = this.getUUID.bind(this);
        this.emailId = this.emailId.bind(this);
    }

    async getUUID(ent, div, org){
        let entSend = ent
        let divSend = div
        let orgSend = org
    
        function getId() {
            const apiName = 'EntAuth';
            const path = '/items';
    
            const myInit = {
                queryStringParameters: {
                    level: 'organization',
                    entName: entSend,
                    divisionName: divSend,
                    orgName: orgSend,
                }
            };
            return API.get(apiName, path, myInit);
        }
    
        let response = ''
        try{
            response = await getId();
        } catch(error){
            console.log(error)
        }
    
        if(response.status === 'success'){
            this.setState({
                loading: false,
                id: response.uuid,
                hideCopyEmail: false
            })
        } else {
          alert('error fetching organization id number')
        }
    }

    copyId = (event) => {
        this.textArea.select();
        document.execCommand('copy');
        event.target.focus();
        alert('id copied to clipboard')
    };
    
      // pull up email on click
    emailId(event) {
        let emailBody = "You have been given an access id number for nowledgeapp.com. \n\nYour access id number is: " + this.state.id + "\n\nGo to nowledgeapp.com and click signup where it asks if you are new to nowledge. \n\nCopy and paste this number during the step where it asks for an access id number." 
        let mailToLink = "mailto:?subject=Invitation to nowledgeapp.com&body=" + encodeURIComponent(emailBody);
        window.location.href = mailToLink;
    }

    async componentDidMount(){
        Auth.currentAuthenticatedUser({
            bypassCache: false
            }).then(user => {
                this.loggedIn = true
            }
            )
            .catch(error => {
                if(error === 'not authenticated'){
                    this.props.history.push('/SignIn');
                }
            }
            );
        let user = await Auth.currentAuthenticatedUser()
        const userName = user.username;
        let userInfo = await API.graphql(graphqlOperation(queries.getEntUser, { userName: userName }));

        let userEnt = userInfo.data.getEntUser.entName
        let userDiv = userInfo.data.getEntUser.divName
        let userOrg = userInfo.data.getEntUser.orgName

        await this.getUUID(userEnt, userDiv, userOrg)

        this.setState({
            enterprise: userEnt,
            division: userDiv,
            organization: userOrg,
            loading: false
        })
        alert('Note: Only share your organization number with those you want to access your device information')
    }

    render(){
        var backIcon = require('../Back-01.png');
            return(
                <div className="App">
                {
                    <div className="Nav-header">
                        <Link style={{textDecoration: 'none'}} to="/SettingsPage">
                            <button className="Back-wrap">
                            <img className="Back-button" src={backIcon} alt="back"/>
                            </button>
                        </Link>
                    </div>
                }
                {
                    <div className="Title">Get ID to Invite a User</div>
                }
                {
                    <div>
                        <div className="Settings-wrapper">
                            <div className='One-space'/>
                            <div className="form-name">Below is your organizations access ID number. This ID number will give the user access to all the devices within your organization.</div>
                            <div className='One-space'/>
                            </div>
                        <div className='One-space'/>
                    </div>
                }
                {
                    <div>
                        {this.state.loading &&
                            <div className="Device-wrapper">
                                <div className='One-space'/>
                                <div className="Loading-buttonstatus">Loading...</div>
                                <div className='One-space'/>
                            </div>
                        }
                        {!this.state.loading &&
                            <div className="Device-wrapper">
                                <div className="notesdocopen-link">{this.state.organization}</div>
                                <div className="form-name">organization id</div>
                                <textarea className="copy-id" readOnly = {true} ref={(textarea) => this.textArea = textarea} value={this.state.id} />
                                <div className='One-space'/>
                                <button onClick={this.copyId} className="confirm-link">copy</button>
                                <button onClick={this.emailId} className="confirm-link">email</button>
                                <div className='One-space'/>
                            </div>
                        }
                    </div>
                }
                </div>
            )
    }
}

export default OrgUserInvite