import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class EntButtons extends Component {

    constructor(props){
        super(props)

        this.state = {
            data: [],
            divisions: [],
            organizations: [],
            organizationsFull: [],
            devices: [],
            level: '',
            dataReady: false,
            divNoOrgs: false,
            entNoDivNoOrg: false,
            entNoDivs: false,
            entDivsandOrgs: false,
            currentDiv: '',
            currentOrg: '',
            currentDevices: [],
            currentOrganizations: [],
            entNoNicknames: false,
            noOrgButtons: false,
            noDivButtons: false
        }
        this.getOrganizations = this.getOrganizations.bind(this);
        this.getDevices = this.getDevices.bind(this);
    }

    async UNSAFE_componentWillReceiveProps(nextProps) {
        let receivedData = nextProps.data
        let receivedLevel = nextProps.level

        this.setState({
            buttonData: receivedData,
            level: receivedLevel
        })

        let divFound = false
        let orgFound = false
        let nicknameFound = false

        let divisionFound = []
        let organizationFound = []
        let organizationFoundNoDiv = []
        let devicesFound = []

        if(receivedLevel === '1'){
            receivedData.forEach(function (device) {

                if(device.div !== "null"){
                    divFound = true
                }
                if(device.org !== "null"){
                    orgFound = true
                }
                if(device.title !== "null"){
                    nicknameFound = true
                    divisionFound.push(device.div)
                    organizationFound.push({"div": device.div, "org": device.org})
                    organizationFoundNoDiv.push(device.org)
                    devicesFound.push({"org": device.org, "serial": device.serial, "title": device.title})
                }
            })

            if(!orgFound && !divFound && !nicknameFound){
                this.setState({
                    entNoNicknames: true,
                    dataReady: true
                })
            }

            if(!orgFound && !divFound){
                this.state.buttonData.sort((a, b) => a.title.localeCompare(b.title, undefined, { numeric: true }))
                this.setState({
                    entNoDivNoOrg: true,
                    dataReady: true
                })
            }


            if(orgFound && !divFound){
                let reducedOrgs = [...new Set(organizationFoundNoDiv)];
                this.setState({
                    dataReady: true,
                    entNoDivs: true,
                    organizations: reducedOrgs,
                    devices: devicesFound
                })
            }

            if(orgFound && divFound){

                let reducedDivs = [...new Set(divisionFound)];
                reducedDivs.sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))

                this.setState({
                    dataReady: true,
                    divisions: reducedDivs,
                    organizations: organizationFoundNoDiv,
                    organizationsFull: organizationFound,
                    devices: devicesFound,
                    entDivsandOrgs: true
                })
            }
        }

        if(receivedLevel === '2'){
            if(receivedData.length === 0){
                this.setState({
                    noDivButtons: true,
                    dataReady: true
                })
            } else {
                this.setState({
                    noOrgButtons: false,
                })
            }
            receivedData.forEach(function (device) {
                if(device.org !== 'null'){
                    orgFound = true
                }
                organizationFound.push(device.org)
                devicesFound.push({"org": device.org, "serial": device.serial, "title": device.title})
            })

            if(!orgFound){
                this.state.buttonData.sort((a, b) => a.title.localeCompare(b.title, undefined, { numeric: true }))
                this.setState({
                    divNoOrgs: true,
                    dataReady: true,
                })
            } else {
                let reducedOrgs = [...new Set(organizationFound)];
                this.setState({
                    dataReady: true,
                    organizations: reducedOrgs,
                    devices: devicesFound
                })
            }
        }
     
        if(receivedLevel === '3'){
            if(receivedData.length === 0){
                this.setState({
                    noOrgButtons: true,
                    dataReady: true
                })
            } else {
                this.setState({
                    noOrgButtons: false,
                    dataReady: true
                })
            }
        }
    }

    async getDevices(orgName){

        if(orgName === this.state.currentOrg){
            this.setState({
                currentOrg: '',
                currentDevices: []
            })
            return
        }

        let fetchedDevices = this.state.devices

        let devicesMatchedToOrg = fetchedDevices.filter(({org}) => org.includes(orgName))
        let devicesMatchedOrdered = devicesMatchedToOrg.sort((a, b) => a.title.localeCompare(b.title, undefined, { numeric: true }))

        this.setState({
            currentOrg: orgName,
            currentDevices: devicesMatchedOrdered
        })
    }

    async getOrganizations(divName){

        if(divName === this.state.currentDiv){
            this.setState({
                currentOrg: '',
                currentOrganizations: [],
                currentDiv: ''
            })
            return
        }

        let fetchedOrganizations = this.state.organizationsFull
        const orgsMatchedToDivs = fetchedOrganizations.filter(({div}) => div.includes(divName))

        let orgArrayOnly = orgsMatchedToDivs.map((orgObject) => orgObject.org)

        let reducedOrgs = [...new Set(orgArrayOnly)];

        this.setState({
            currentDiv: divName,
            currentOrganizations: reducedOrgs,
            currentDevices: []
        })

    }

    render() {
        return(
            <div className="App">

                { this.state.level === '1' && this.state.dataReady &&
                    <div>
                        <div className='One-space'/>
                        { this.state.entNoNicknames &&
                            <div>
                                <div className='title-one'>no devices have been onboarded</div>
                                <div className='One-space'/>
                                <div className='title-two'>create divisions or organizations</div>
                                <div className='One-space'/>
                                <Link style={{ textDecoration: 'none' }} to={{ pathname: '/EntAddDivOrg' }}>
                                    <button className="signup-link" >here</button>
                                </Link>
                                <div className='One-space'/>
                                <div className='title-two'>onboard devices</div>
                                <div className='One-space'/>
                                <Link style={{ textDecoration: 'none' }} to={{ pathname: '/EntUserOnboard' }}>
                                    <button className="signup-link" >here</button>
                                </Link>
                            </div>
                        }
                        { this.state.entNoDivNoOrg && !this.state.entNoNicknames &&
                            <div>
                                {
                                    this.state.buttonData.map((device, index) =>  (
                                    <div key={index}>
                                    <br/>
                                        <div className="Homebuttonwrapper-90">
                                        <Link style={{ textDecoration: 'none' }} key={index} to={{ pathname: '/Device', state: { loc: device.serial, title: device.title, version: device.version} }}>
                                            <button className="Homebutton-90">{device.title}</button>
                                        </Link>
                                        </div>
                                    </div>
                                    ))
                                }
                            </div>
                        }

                        { this.state.entNoDivs && !this.state.entNoNicknames &&
                            this.state.organizations.map((org, index) => (
                                <div key={index}>
                                <br/>
                                <div className="Homebuttonwrapper-100">
                                    <div className="Homebutton-100" onClick={(e) => this.getDevices(org)}>{org}</div>
                                </div>
                                { org === this.state.currentOrg &&
                                    this.state.currentDevices.map((device, index) => (
                                    <div  key={index}>
                                    <br/>
                                        <div className="Homebuttonwrapper-90">
                                            <Link style={{ textDecoration: 'none' }} to={{ pathname: '/Device', state: { loc: device.serial, title: device.title, version: device.version} }}>
                                                <button className="Homebutton-90">{device.title}</button>
                                            </Link>
                                        </div>
                                    </div>
                                    ))
                                }
                                </div>
                            ))
                        }

                        { this.state.entDivsandOrgs && !this.state.entNoNicknames &&
                            this.state.divisions.map((div, index) => (
                                <div key={index}>
                                    <br/>
                                    <div className="Homebuttonwrapper-100">
                                        <div className="Homebutton-100" onClick={(e) => this.getOrganizations(div)}>{div}</div>
                                    </div>
                                    { div === this.state.currentDiv &&
                                        this.state.currentOrganizations.map((org, index) => (
                                            <div  key={index}>
                                            <br/>
                                            <div className="Homebuttonwrapper-90">
                                                <div className="Homebutton-90" onClick={(e) => this.getDevices(org)}>{org}</div>
                                            </div>
                                            { org === this.state.currentOrg &&
                                                this.state.currentDevices.map((device, index) => (
                                                <div  key={index}>
                                                <br/>
                                                    <div className="Homebuttonwrapper-80">
                                                    <Link style={{ textDecoration: 'none' }} to={{ pathname: '/Device', state: { loc: device.serial, title: device.title, version: device.version} }}>
                                                        <button className="Homebutton-80">{device.title}</button>
                                                    </Link>
                                                    </div>
                                                </div>
                                                ))
                                            }
                                            </div>
                                        ))
                                    }
                                </div>
                            ))
                        }
                    </div>
                }

                { this.state.level === '2' && this.state.dataReady &&
                    <div>
                    {
                        <div>
                        <div className='Two-space'/>
                        <div>
                            <div className='title-two'>no devices have been onboarded in your division</div>
                            <div className='Two-space'/>
                            <div className='Two-space'/>
                            <div className='title-two'>onboard devices</div>
                            <div className='One-space'/>
                            <Link style={{ textDecoration: 'none' }} to={{ pathname: '/DivUserOnboard' }}>
                                <button className="signup-link" >here</button>
                            </Link>
                        </div>
                    </div>
                    }
                    { this.state.divNoOrgs &&
                        <div>
                            {
                                this.state.buttonData.map((device, index) =>  (
                                <div>
                                <br/>
                                    <div className="Homebuttonwrapper-90">
                                    <Link style={{ textDecoration: 'none' }} key={index} to={{ pathname: '/Device', state: { loc: device.serial, title: device.title, version: device.version} }}>
                                        <button className="Homebutton-90">{device.title}</button>
                                    </Link>
                                    </div>
                                </div>
                                ))
                            }
                        </div>
                    }

                    { !this.state.divNoOrgs &&
                        this.state.organizations.map((org, index) => (
                            <div key={index}>
                                <br/>
                                <div className="Homebuttonwrapper-100">
                                    <div className="Homebutton-100" onClick={(e) => this.getDevices(org)}>{org}</div>
                                </div>
                                { org === this.state.currentOrg &&
                                    this.state.currentDevices.map((device, index) => (
                                    <div  key={index}>
                                    <br/>
                                        <div className="Homebuttonwrapper-90">
                                        <Link style={{ textDecoration: 'none' }} to={{ pathname: '/Device', state: { loc: device.serial, title: device.title, version: device.version} }}>
                                            <button className="Homebutton-90">{device.title}</button>
                                        </Link>
                                        </div>
                                    </div>
                                    ))
                                }
                            </div>
                        ))
                    }
                </div>
                }

                { this.state.level === '3' && this.state.dataReady &&
                    <div>
                    {this.state.noOrgButtons &&
                        <div>
                            <div className='Two-space'/>
                            <div>
                                <div className='title-two'>no devices have been onboarded in your organization</div>
                                <div className='Two-space'/>
                                <div className='Two-space'/>
                                <div className='title-two'>onboard devices</div>
                                <div className='One-space'/>
                                <Link style={{ textDecoration: 'none' }} to={{ pathname: '/OrgUserOnboard' }}>
                                    <button className="signup-link" >here</button>
                                </Link>
                            </div>
                        </div>
                    }
                    {
                        this.state.buttonData.map((device, index) =>  (
                            <div key={index}>
                                <br/>
                                <div className="Homebuttonwrapper-90">
                                    <Link style={{ textDecoration: 'none' }} to={{ pathname: '/Device', state: { loc: device.serial, title: device.title, version: device.version} }}>
                                    <button className="Homebutton-90">{device.title}</button>
                                    </Link>
                                </div>
                            </div>
                        ))
                    }
                    </div>
                }
            </div>
        )
    }

}

export default EntButtons
