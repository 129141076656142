import React, { Component } from 'react';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as queries from '../graphql/queries.js';
import { Link } from 'react-router-dom';
import EmailSignup from "../components/EmailSignup"
import IconUpdate from "../components/IconRanges"


class DeviceSettings extends Component {

constructor(props){
  super(props);

  this.icondata = [];

  const {loc} = props.location.state
  this.local = loc
  const {title} = props.location.state
  this.title = title
  const {version} = props.location.state
  this.version = version

  this.loggedIn = ''

  this.state = {
    icons: [],
    serial: this.local,
    clickedIcons: false,
    loading: false
   }

   this.handleChange = this.handleChange.bind(this);
   this.getIcons = this.getIcons.bind(this);
   this.hideIcons = this.hideIcons.bind(this);
}

signOut = () => {
    Auth.signOut()
    .then(data => console.log(data))
    .catch(err => console.log(err));
    this.props.history.push('/SignIn');
  }


handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
}

async getIcons(){
  this.setState( { loading: true });

  const getIcons = `query GetIcons {
    getEntBlog(id:"${this.blogID}") {
    id
    location
    icons {
      items {
        id
        value
        lowest
        low
        high
        highest
        order
      }
    }
  }
  }`

  this.icondata = await API.graphql(graphqlOperation(getIcons));
  this.modIcons = this.icondata.data.getEntBlog.icons.items;
  this.orderedIcons =this.modIcons.sort(function(a, b) {
      return (a.order===null)-(b.order===null) || +(a.order>b.order)||-(a.order<b.order);
  });

  await this.setState({
    icons: this.orderedIcons,
    clickedIcons: true,
    loading: false
  });

}

async hideIcons(){
  await this.setState({
    clickedIcons: false
  });
}

async componentDidMount(){
  Auth.currentAuthenticatedUser({
    bypassCache: false
    }).then(user => {
      this.loggedIn = true
    }
  )
  .catch(error => {
    if(error === 'not authenticated'){
      this.props.history.push('/SignIn');
    }
  }
  );

  let noteQuery = this.local

  this.blogCall = await API.graphql(graphqlOperation(queries.listEntBlogs, {
    limit: 10000,
    filter: {
      location:{
        eq: noteQuery
      }
    }
    }));

  //set the id to this.blogID
  this.blogID = this.blogCall.data.listEntBlogs.items[0].id

}
  render() {
    var backIcon = require('../Back-01.png');

    return (

      <div className="App">
        {
          <div className="Nav-header">
            <button className="Back-wrap">
            <Link style={{ textDecoration: 'none' }} to={{ pathname: '/Device', state: { loc: this.local, title: this.title, version: this.version} }}>
                <img onClick={this.stopInterval} className="Back-button" src={backIcon} alt="back" />
            </Link>
            </button>
            <button onClick={this.signOut} className="Settings-button">signout</button>
          </div>
        }

        <div className="Title">Device Settings</div>
        <div className="Settings-wrapper">
          <br />
          <div className="Settings-header">Program Device</div>
          {
            <div>
              <Link style={{ textDecoration: 'none' }} to={{ pathname: '/DeviceProgram', state: { loc: this.local, title: this.title, version: this.version } }}>
                <button className="resend-link">
                  <div>update</div>
                </button>
              </Link>
              <br />
              <br />
            </div>
          }
        </div>

        {
          <div>
            <div className="Settings-header">Email Alerts</div>
            <EmailSignup serial={this.state.serial}/>
          </div>
        }

        {
          <div className="Settings-header">Status Icon Ranges</div>
        }

        {!this.state.clickedIcons &&
          <button className="signup-link" onClick={(e) => this.getIcons()}>update</button>
        }

        {this.state.clickedIcons &&
          <button className="signup-link" onClick={(e) => this.hideIcons()}>hide</button>
        }

        {this.state.loading &&
          <div className="Loading-button">Loading....</div>
        }

        {this.state.clickedIcons &&
          <div>
            <IconUpdate icons={this.state.icons} blogID={this.blogID} getIcons={this.getIcons}/>
          </div>
        }
        <div className = "div-height" />
        <br />
      </div>
    )
  }
}

export default DeviceSettings
