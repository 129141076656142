/* eslint-disable no-unused-expressions */
import React, { Component } from 'react';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as queries from '../graphql/queries.js';
import { Link } from 'react-router-dom';
import AlarmSettings from './AlarmSettings.js';

class Offline extends Component {
    constructor(props){
        super(props)

        this.timeZone = ''
    
        this.state = {
            devices: props.data,
            group: props.group,
            offlineDevices: [],
            offlineCount: '',
            loading: true,
            index: '',
        }
        this.menuSelected = this.menuSelected.bind(this);   
        this.menuClosed = this.menuClosed.bind(this); 
        this.setStateCount = this.setStateCount.bind(this);
    }

    menuSelected(index){
        this.setState({
            index: index,
            menuClicked: true
        })
    }
    menuClosed(index){
        this.setState({
            index: '',
            menuClicked: false
        })
    }
    setStateCount(count, serial){
        let currentOffline = this.state.offlineDevices

        let indexGet = currentOffline.findIndex(x => x.serial === serial)

        currentOffline.splice(indexGet, 1)
        this.setState({
            offlineCount: count,
            offlineDevices: currentOffline,
            index: ''
        })
    }


    modTimeStamp(time){
        let formatted = ''
        let tempTimestamp = time
        if(this.timeZone === 'America/Chicago'){
            let year = tempTimestamp.slice(0,4);
            let month = tempTimestamp.slice(5,7);
            let newMonth = Number(month);
            let day = tempTimestamp.slice(8,10);
            let newDay = Number(day);
            let hour = tempTimestamp.slice(11,13);
            let newHour = Number(hour)
            let minute = tempTimestamp.slice(14,16);
            let amPm = "am";
            if(newHour === 12){
                amPm = "pm"
            };
            if(newHour > 12){
                newHour = newHour - 12
                amPm = "pm"
            };
            if(hour === "00"){
                newHour = "12"
            };
            formatted = newMonth + "/" + newDay + "/" + year + " " + newHour + ":" + minute + amPm;
        } else {
            let dateObject = new Date(tempTimestamp) 
            let changedStamp = new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'long', timeZone: this.timeZone }).format(dateObject)
            let monthZone = changedStamp.slice(3,5)
            let dayZone = changedStamp.slice(0,2)
            let yearZone = changedStamp.slice(6,10)
            let hourZone = changedStamp.slice(12,14)
            let newHourZone = Number(hourZone)
            let minuteZone = changedStamp.slice(15,17)

            let amPm = "am"
            if(newHourZone === 12){
                amPm = "pm"
            };
            if(newHourZone > 12){
                hourZone = newHourZone - 12
                amPm = "pm"
            };
            if(hourZone === "00"){
                hourZone = "12"
            };
            formatted = monthZone + "/" + dayZone + "/" + yearZone + " " + hourZone + ":" + minuteZone + amPm;
        }

    
        return(
          <div>{formatted}</div>
        );
      };

    async componentDidMount() {
        let devices = this.state.devices
        let serialArray = []

        let user = await Auth.currentAuthenticatedUser();
        const userName = user.username;

        // array of just serial numbers for latest data api
        devices.forEach(unit => {
            serialArray.push(unit.location)
        })
        
        let serialArrayString = "[" + serialArray.map(serial => `"${serial}"`).join(',') + "]"

        function getTimestamps() {
            const apiName = 'DeviceConnectStatus';
            const path = '/items';
    
            const myInit = {
                queryStringParameters: {
                  name: serialArrayString
                }
            };
            return API.get(apiName, path, myInit);
        }
    
        let response = {};
        let timestampsArray = []
        try {
            response = await getTimestamps()
            timestampsArray = response.return

        } catch(error) {
            console.log(error)
            if(error.message === 'Network Error'){
                alert('network error check your internet connection')
                return
            } else {
                alert('error loading devices data')
                return
            }
        };
        if (response.result === 'error') {
            alert('error fetching devices online status')
            return
        }

        //check for users timezone offset compared to central times offset
        let changedCentral = new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'long', timeZone: 'America/Chicago' }).format()
        let offsetCentral = Number(changedCentral.slice(-1))
        let localDate = new Date()
        let localOffset = localDate.getTimezoneOffset() / 60

        let dateNow = new Date();
        let minLate = 600000;

        let offlineArray = [];
        let onlineArray = [];

        if(offsetCentral === localOffset){
            this.timeZone = 'America/Chicago'

        } else {
            let offsetDifference = offsetCentral - localOffset
            if(offsetDifference === 1){
                this.timeZone = 'America/Halifax'
            }
            if(offsetDifference === -1){
                this.timeZone = 'America/Los_Angeles'
            }
            if(offsetDifference === -2){
                this.timeZone = 'America/Anchorage'
            }
        }

        timestampsArray.forEach( timestampData => {
            let deviceOnline = ''
            let modTimestamp = ''
            try{
                if(this.timeZone === 'America/Chicago'){
                    modTimestamp = timestampData.timestamps.replace(/\s/, 'T')
                } else {
                    let dateObject = new Date(timestampData.timestamps)
                    let changedStamp = new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'long', timeZone: this.timeZone }).format(dateObject)
                    let monthTest = changedStamp.slice(3,5)
                    let dayTest = changedStamp.slice(0,2)
                    let yearTest = changedStamp.slice(6,10)
                    let hourTest = changedStamp.slice(12,14)
                    let minuteTest = changedStamp.slice(15,17)
                    let milliSeconds = changedStamp.slice(18,20)
            
                    modTimestamp = yearTest + "-" + monthTest + "-" + dayTest + "T" + hourTest + ":" + minuteTest + ":" + milliSeconds
                }
                
                if((dateNow - new Date(modTimestamp)) > minLate) {
                    deviceOnline = false;
                }
                else{
                    deviceOnline = true;
                }

                devices.forEach( deviceData => {
                    if(timestampData.device === deviceData.location){
                        let newObject = {'serial': deviceData.location, 'organization': deviceData.group, 'nickname': deviceData.title, 'timestamp': timestampData.timestamps, 'version': deviceData.version}
                        if(!deviceOnline){
                            offlineArray.push(newObject)
                        } else {
                            onlineArray.push(newObject)
                        }
                    }
                })
                
            } catch(error) {
                if (error instanceof TypeError) {
                    console.log('null')
                }
                else {
                    console.log(error)
                }
            }            
        })

        
        let group = await API.graphql(
            graphqlOperation(queries.getUser, { userName: userName })
        );

        this.groupName = group.data.getUser.groupName;

        const hiddenAlarms = `query GetHidden {
            getOrganizationLink(orgName: "${this.groupName}") {
                id
                    hiddenAlarms {
                        items {
                            groupName
                            readingName
                            serial
                            subGroup
                        }
                    }
            }
        }`

    
        let hiddenAlarmsArray = []
        //fetch all hidden alarms
        try{
            let hiddenAlarmsFetched = await API.graphql(graphqlOperation(hiddenAlarms));
            hiddenAlarmsArray = hiddenAlarmsFetched.data.getOrganizationLink.hiddenAlarms.items
        } catch(error) {
            console.log(error)
        }; 

        let trimmedOfflineArray = []

        offlineArray.forEach(device => {
            let found = hiddenAlarmsArray.some( e => e.serial === device.serial && e.readingName === 'offline_hide_alarm_check')
            if(!found){
                trimmedOfflineArray.push(device)
            } 
        })

        let offlineArraySorted = trimmedOfflineArray.sort((a, b) => b.timestamp.localeCompare(a.timestamp, undefined, { numeric: true }))
        let offlineDeviceHeld = offlineArraySorted.length
        
        this.setState({
            offlineDevices: offlineArraySorted,
            offlineCount: offlineDeviceHeld,
            loading: false
        })
        
    }
    
    render(){
        var menuIcon = require('../icons8-menu-100.png')
        return(
            <div className='App'>
                <div className='Two-space'/>
                <div className="Device-wrapper">
                    {this.state.loading &&
                        <div>
                            <div className='One-space'/>
                            <div className="Loading-buttonstatus">Loading...</div>
                            <div className='One-space'/>
                        </div>
                    }
                    { !this.state.loading &&
                        <div>  
                            <div className='One-space'/>                         
                            {this.state.offlineCount === 0 &&
                                <div style={{fontSize: '40px', color: '#529857', fontWeight: 'bold'}}>{this.state.offlineCount}</div>
                            }
                            {this.state.offlineCount !== 0 &&
                                <div style={{fontSize: '40px', color: '#d42d0a', fontWeight: 'bold'}}>{this.state.offlineCount}</div>
                            }
                            {this.state.offlineCount !== 1 &&
                                <div style={{fontSize: '20px', color: 'white'}}>devices are offline</div>
                            }
                            {this.state.offlineCount === 1 &&
                                <div style={{fontSize: '20px', color: 'white'}}>device is offline</div>
                            }    
                            <div className='One-space'/>                            
                        </div>
                    }
                </div>
                { !this.state.loading &&
                    <Link style={{ textDecoration: 'none' }} to={{ pathname: '/HiddenAlarms', state: { device: this.props.data, group: this.props.group} }}>
                        <button className="signup-link">hidden alarms</button>
                    </Link>
                }  
                {!this.state.loading &&
                    this.state.offlineDevices.map((device, index) => (
                        <div key={index}>
                            <div className='One-space'/>
                            <div className="Device-wrapper">
                            {this.state.index !== index &&
                                    <div style={{width: '100%'}}>
                                        <button onClick={() => this.menuSelected(index)}style={{backgroundColor: '#3c3c3c', border: 'none', height: '40px', display: 'inline-block', position: 'relative', marginLeft: '80%'}}>
                                            <img style={{height: '40px'}}src={menuIcon} alt="settings" />
                                        </button>    
                                    </div>

                                }
                                {this.state.index === index && this.state.menuClicked === true &&
                                    <div>
                                        <button onClick={() => this.menuClosed(index)} className="Dropdown-button">
                                            <div>close</div>
                                        </button>
                                    </div>
                                }
                                <div style={{fontSize: '25px', color: '#d42d0a', fontWeight: 'bold'}}>{this.modTimeStamp(device.timestamp)}</div>
                                {this.state.group === 'enerstar' && this.state.index !== index &&
                                    <Link style={{ textDecoration: 'none' }} to={{ pathname: '/location', state: { loc: device.serial, title: device.nickname, org: device.organization, version: device.version} }}>
                                        <button className="goto-device">go to device</button>
                                    </Link>
                                }
                                {this.state.group === 'multiloc' && this.state.index !== index &&
                                    <Link style={{ textDecoration: 'none' }} to={{ pathname: '/location', state: { loc: device.serial, title: device.nickname, org: 'none', version: device.version} }}>
                                        <button className="goto-device">go to device</button>
                                    </Link>
                                }
                                <div style={{fontSize: '15px', color: '#529857'}}>Device</div>
                                {this.state.group !== 'multiloc' &&
                                    <div style={{fontSize: '25px', color: 'white', fontWeight: 'bold'}}>{device.organization}</div>
                                }                               
                                <div style={{fontSize: '25px', color: 'white', fontWeight: 'bold'}}>{device.nickname}</div>
                                {this.state.menuClicked === true && this.state.index === index && 
                                    <AlarmSettings group={this.groupName} count={this.state.offlineCount} setStateCount={this.setStateCount} name={device.nickname} subGroup={device.organization} serial={device.serial} route='offline'/>
                                }
                                <div className='Two-space'/>
                            </div>
                        </div>
                    ))
                }
                <div className='div-height'/>
            </div>
        )
    }
}
export default Offline