import React, { Component } from 'react';
import {S3Image} from 'aws-amplify-react';

class Notes extends Component {

  constructor(props){
    super(props);

    this.state = {
      notes: [],
      noNotes: false
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let receivedNotes = nextProps.notes
    let noNotes = nextProps.noNotes

    this.setState({
      notes: receivedNotes,
      noNotes: noNotes
    })
  }

  renderNoteTime(time){
    let utcDate = time;
    let localDate = new Date(utcDate);

    let date4 = (localDate.getMonth()+1) + "/" + localDate.getDate() + "/" + localDate.getFullYear()

    let hour = localDate.getHours()
    let minute = localDate.getMinutes();

    if(minute < 10){
      minute = ('0' + minute).slice(-2)
    };

    let amPm = "am";

    if(hour === 12){
      amPm = "pm"
    };
    if(hour > 12){
      hour = hour - 12
      amPm = "pm"
    };
    if(hour === 0){
      hour = 12
    };

    let time1 = hour + ":" + minute + amPm

    let dateTime1 = date4 + " " + time1;

    return(
      <p>{dateTime1}</p>
    );
  };

  render(){

    var imageCheck = require('../Nowledge-App-Icons-CHECK.png')
    var imageCaution = require('../Nowledge-App-Icons-CAUTION.png')
    var imageStop = require('../Nowledge-App-Icons-STOP.png')

    return(
      <div>
      {
        this.state.notes.map((note, index) =>  (
            <button  key={index} className="Note-button">
              { note.status === "low" &&
                <img className="Emoji2" src={imageCheck} alt="check" />
              }
              { note.status === "middle" &&
                <img className="Emoji2" src={imageCaution} alt="caution" />
              }
              { note.status === "high" &&
                <img className="Emoji2" src={imageStop} alt="stop" />
              }
              <div className="Created-at">{this.renderNoteTime(note.createdAt)}</div>
              {note.content.replace(/<br\s*\\?>/g, "\r\n").split('\n').map( (line, i) => <div key={'x'+i}>{line}</div> )}<br/>

              { note.img != null && note.img !== 'null' &&
              <S3Image imgKey={note.img} theme={{ photoImg: { width: '100%' } }}/>
              }

              <p className="Posted-by">Posted by: {note.author}</p>
            </button>
        ))
      }
      </div>
    )
  }

}

export default Notes
