import React, { Component } from 'react';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';
import * as queries from '../graphql/queries.js';

class OrganizationGet extends Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            orgName: '',
            id: ''
        }
        this.emailId = this.emailId.bind(this);
    }

    copyId = (event) => {
        this.textArea.select();
        document.execCommand('copy');
        event.target.focus();
        alert('id copied to clipboard')
    };
    // pull up email on click
    emailId(event) {
        let emailBody = "You have been given an access id number for nowledgeapp.com. \n\nYour access id number is: " + this.state.id + "\n\nGo to nowledgeapp.com and click signup where it asks if you are new to nowledge. \n\nCopy and paste this number during the step where it asks for an access id number."
        let mailToLink = "mailto:?subject=Invitation to nowledgeapp.com&body=" + encodeURIComponent(emailBody);
        window.location.href = mailToLink;
    }

    async componentDidMount() {
        Auth.currentAuthenticatedUser({
            bypassCache: false
        }).then(user => {
            this.loggedIn = true
        }
        )
            .catch(error => {
                if (error === 'not authenticated') {
                    this.props.history.push('/SignIn');
                }
            }
            );

        let user = await Auth.currentAuthenticatedUser()
        const userName = user.username
        console.log(userName)
        let group = await API.graphql(graphqlOperation(queries.getUser, { userName: userName }));
        console.log(group)
        const groupName = group.data.getUser.groupName


        function getId() {
            const apiName = 'organizationid';
            const path = '/items';

            const myInit = {
                queryStringParameters: {
                    name: groupName,
                }
            };
            return API.get(apiName, path, myInit);
        }

        let response = await getId();

        if (response.status === 'success') {
            this.setState({
                loading: false,
                orgName: groupName,
                id: response.id
            })
        } else {
            console.log('error fetching group id')
        }
        alert('Note: Only share your organization number with those you want to access your device information')
    }

    render() {
        var backIcon = require('../Back-01.png');

        return (
            <div className="App">
                {
                    <div className="Nav-header">
                        <Link style={{ textDecoration: 'none' }} to="/MainSettings">
                            <button className="Back-wrap">
                                <img className="Back-button" src={backIcon} alt="back" />
                            </button>
                        </Link>
                    </div>
                }
                <div className="Title">Invite New User</div>
                {this.state.loading &&
                    <div>
                        <div className='One-space' />
                        <div className="Settings-wrapper">
                            <div className='One-space' />
                            <div className="Loading-buttonstatus">Loading....</div>
                            <div className='One-space' />
                        </div>
                    </div>
                }
                {!this.state.loading &&
                    <div>
                        <div className='One-space' />
                        <div className="Settings-wrapper">
                            <div className="notesdocopen-link">{this.state.orgName}</div>
                            <div className="form-name">organization id</div>
                            <textarea className="copy-id" readOnly={true} ref={(textarea) => this.textArea = textarea} value={this.state.id} />
                            <div className='One-space'/>
                            {
                                <div className='Two-button-wrapper'>
                                    <button onClick={this.copyId} className="Two-button">copy</button>
                                    <button onClick={this.emailId} className="Two-button">email</button>
                                </div>
                            }
                            <div className='One-space' />
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default OrganizationGet
