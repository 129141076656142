import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries.js';

class EntInvetory extends Component {
    constructor(props){
        super(props);

        this.state = {
            loading: true,
            devices: [],
            shelved: [],
            onboarded: [],
            shelfbutton: true,
            timeColor1: '#529857',
            timeColor2: '#666666',
            noInactive: false,
            noActive: false
        }
    }
    async inventorySelect () {
        this.timeStatus = this.click

        if(this.timeStatus === "inactive"){
            this.setState({
                timeColor1: '#529857',
                timeColor2: '#666666',
                shelfbutton: true
            })
        }
        if(this.timeStatus === "active"){
            this.setState({
                timeColor1: '#666666',
                timeColor2: '#529857',
                shelfbutton: false
            })
        }
    }

    renderNoteTime(time){
        let utcDate = time;
        let localDate = new Date(utcDate);
        let date4 = (localDate.getMonth()+1) + "/" + localDate.getDate() + "/" + localDate.getFullYear();
        let hour = localDate.getHours()
        let minute = localDate.getMinutes();

        if(minute < 10){
            minute = ('0' + minute).slice(-2)
        };
        let amPm = "am";
        if(hour === "12"){
            amPm = "pm"
        };
        if(hour > 12){
            hour = hour - 12
            amPm = "pm"
        };
        if(hour === "0"){
            hour = 12
        };
        let newHour = hour;
        let time1 = newHour + ":" + minute + amPm
        let dateTime1 = date4+" "+time1;

        return(
            <div>{dateTime1}</div>
        );
    };

    async componentDidMount(){
        Auth.currentAuthenticatedUser({
                bypassCache: false
            }).then(user => {
                this.loggedIn = true
            }
            )
            .catch(error => {
                if(error === 'not authenticated'){
                    this.props.history.push('/SignIn');
                }
            }
        );

        let noInactiveFound = false
        let noActiveFound = false

        let user = await Auth.currentAuthenticatedUser()
        const userName = user.username;

        let userInfo = await API.graphql(graphqlOperation(queries.getEntUser, { userName: userName }));
        let userEnt = userInfo.data.getEntUser.entName

        let deviceInventoryFetch = await API.graphql(graphqlOperation(queries.listEntInventorys, { limit: 10000, filter: { entName:{ eq: userEnt}}}));
        let deviceInventory = deviceInventoryFetch.data.listEntInventorys.items

        let getEntUnits = `query GetEntUnits { getEnt(entName: "${userEnt}") { id entunits { items { id serial entName divName orgName nickName updatedAt }}}}`
        let groupDevices = []

        try{
            let returnEntUnits = await API.graphql(graphqlOperation(getEntUnits));
            groupDevices = returnEntUnits.data.getEnt.entunits.items
        } catch(error) {
            console.log(error)
        };

        let inactiveDevices = []
        let activeDevices = []
        let changesFound = false

        groupDevices.forEach(function (entUnit) {
        if(entUnit.nickName === 'null' && entUnit.divName === 'null' && entUnit.orgName === 'null'){
            inactiveDevices.push(entUnit)
        } else {
            deviceInventory.forEach(async function (invUnit) {
            if(entUnit.serial === invUnit.serial){
                if(entUnit.divName !== invUnit.divName || entUnit.orgName !== invUnit.orgName){
                    changesFound = true
                    const updateEntInv = `mutation UpdateEntInventory { updateEntInventory(input: { id: "${invUnit.id}" divName: "${entUnit.divName}" orgName: "${entUnit.orgName}" onboardedAt: "${entUnit.updatedAt}" }){ id } }`
                    try {
                        await API.graphql(graphqlOperation(updateEntInv));
                    } catch(error) {
                        alert('An error occurred onboarding please try again')
                        return
                    }
                } else {
                    activeDevices.push(invUnit)
                }
            }
            })
        }
        })
        if(inactiveDevices.length === 0){
            noInactiveFound = true
        }
        if(activeDevices.length === 0 && !changesFound){
            noActiveFound = true
        }

        inactiveDevices.sort((a, b) => a.serial.localeCompare(b.serial, undefined, { numeric: true }))
        activeDevices.sort((a, b) => a.serial.localeCompare(b.serial, undefined, {numeric: true}))

        if(changesFound){
            let newDeviceInventoryFetch = await API.graphql(graphqlOperation(queries.listEntInventorys, { limit: 10000, filter: { entName:{ eq: userEnt}}}));
            let newDeviceInventory = newDeviceInventoryFetch.data.listEntInventorys.items

            let newInactiveDevices = []
            let newActiveDevices = []

            groupDevices.forEach(function (entUnit) {
                if(entUnit.nickName === 'null' && entUnit.divName === 'null' && entUnit.orgName === 'null'){
                    newInactiveDevices.push(entUnit)
                } else {
                    newDeviceInventory.forEach(async function (intUnit){
                        if(entUnit.serial === intUnit.serial){
                            newActiveDevices.push(intUnit)
                        }
                    })
                }
            })

            let newNoInactiveFound = false
            let newNoActiveFound = false
            if(newInactiveDevices.length === 0){
                newNoInactiveFound = true
            }
            if(newActiveDevices.length === 0){
                newNoActiveFound = true
            }

            this.setState({
                shelved: newInactiveDevices,
                onboarded: newActiveDevices,
                loading: false,
                noInactive: newNoInactiveFound,
                noActive: newNoActiveFound
            });
        } else {
            this.setState({
                shelved: inactiveDevices,
                onboarded: activeDevices,
                loading: false,
                noInactive: noInactiveFound,
                noActive: noActiveFound
            });
        }
    }
    render(){
        var backIcon = require('../Back-01.png');
        var imageCheck = require('../Nowledge-App-Icons-CHECK.png')
        return(
            <div className="App">
                {
                    <div className="Nav-header">
                    <Link style={{textDecoration: 'none'}} to="/SettingsPage">
                        <button className="Back-wrap">
                        <img className="Back-button" src={backIcon} alt="back"/>
                        </button>
                    </Link>
                    </div>
                }
                <div className="Title">Device Inventory</div>

                <div className="Status-wrapper">
                    { this.state.loading &&
                        <div>
                            <div className='One-space'/>
                            <div className="Loading-buttonstatus">Loading</div>
                            <div className='One-space'/>
                        </div>
                    }
                    { !this.state.loading &&
                        <div className="Status-container">
                            <button className="Status-button">
                                <img className="Emoji-icon" src={imageCheck} alt="check" />
                                <div className="Status-title">inactive</div>
                                <div className="Status-reading">{this.state.shelved.length}</div>
                            </button>
                            <button className="Status-button">
                                <img className="Emoji-icon" src={imageCheck} alt="check" />
                                <div className="Status-title">active</div>
                                <div className="Status-reading">{this.state.onboarded.length}</div>
                            </button>
                        </div>
                    }
                </div>
                { !this.state.loading &&
                    <div className="Time-wrapper">
                        <button type="button" className="Time-box" onClick={(e) => this.inventorySelect(this.click = 'inactive')} style={{backgroundColor:this.state.timeColor1}}>
                        <span role="img" aria-label="check">inactive</span>
                        </button>
                        <button type="button" className="Time-box2" onClick={(e) => this.inventorySelect(this.click = 'active')} style={{backgroundColor:this.state.timeColor2}}>
                            <span role="img" aria-label="check">active</span>
                        </button>
                    </div>
                }
                {!this.state.loading && this.state.shelfbutton && this.state.noInactive &&
                    <div>
                        <div className='One-space'/>
                        <div className="title-two">no inactive devices</div>
                    </div>
                }
                {!this.state.loading && this.state.shelfbutton && !this.state.noInactive &&
                    this.state.shelved.map((device, index) => (
                        <div key={index}>
                            <div className='One-space'/>
                            <div className="Device-wrapper">
                                <div style={{fontSize: '15px', color: '#529857'}}>Serial Number:</div>
                                <div style={{fontSize: '25px', color: 'white', fontWeight: 'bold'}}>{device.serial}</div>
                                <div style={{fontSize: '15px', color: '#529857'}}>Software Installed:</div>
                                <div style={{fontSize: '25px', color: 'white', fontWeight: 'bold'}}>{this.renderNoteTime(device.updatedAt)}</div>
                            </div>
                        </div>
                    ))
                }
                {!this.state.loading && !this.state.shelfbutton && this.state.noActive &&
                    <div>
                        <div className='One-space'/>
                        <div className="title-two">no active devices</div>
                    </div>
                }
                {!this.state.loading && !this.state.shelfbutton && !this.state.noActive &&
                    this.state.onboarded.map((device, index) => (
                        <div key={index}>
                            <div className='One-space'/>
                            <div className="Device-wrapper">
                                {device.divName !== 'null' &&
                                <div>
                                    <div style={{fontSize: '15px', color: '#529857'}}>Division Name:</div>
                                    <div style={{fontSize: '25px', color: 'white', fontWeight: 'bold'}}>{device.divName}</div>
                                </div>
                                }
                                {device.orgName !== 'null' &&
                                <div>
                                    <div style={{fontSize: '15px', color: '#529857'}}>Organization Name:</div>
                                    <div style={{fontSize: '25px', color: 'white', fontWeight: 'bold'}}>{device.orgName}</div>
                                </div>
                                }
                                <div style={{fontSize: '15px', color: '#529857'}}>Serial Number:</div>
                                <div style={{fontSize: '25px', color: 'white', fontWeight: 'bold'}}>{device.serial}</div>
                                <div style={{fontSize: '15px', color: '#529857'}}>Activated:</div>
                                <div style={{fontSize: '25px', color: 'white', fontWeight: 'bold'}}>{this.renderNoteTime(device.updatedAt)}</div>
                            </div>
                        </div>
                    ))
                }
            </div>
        )
    }
}

export default EntInvetory
