import React, { Component } from 'react';
import { API, graphqlOperation } from 'aws-amplify';


class HideAlarms extends Component {

    constructor(props){
        super(props)

        this.currentAlarms = props.currentAlarms
        this.emptyAlarms = false
        this.newCount = props.count
        
        this.state = {
            index: '',
            offlineHidden: false
        }
    }

    hideClicked(index, alarmName){
        this.setState({
            index: index
        })
    }

    noClicked(index){
        this.setState({
            index: ''
        })
    }

    async yesClicked(index, reading){

        this.setState({
            hidingAlarm: true
        })

        // add unit to the hiddenAlarms database
        const addHiddenAlarm = `mutation CreateHiddenAlarm {
            createHideAlarm(input: {
                groupName: "${this.props.group}"
                subGroup: "${this.props.subGroup}"
                serial: "${this.props.serial}"
                deviceName: "${this.props.name}"
                readingName: "${reading}"
                hideUntilDate: "null"
                hideAlarmOrgLinkId: "${this.props.group}"
            }) {
                id
            }
        }`
        try{
            await API.graphql(graphqlOperation(addHiddenAlarm));
            if(this.props.route === 'alarm'){
                this.currentAlarms.splice(index, 1)
                if(this.currentAlarms.length === 0){
                    this.emptyAlarms = true
                    this.newCount--
                    this.props.setStateCount(this.newCount, this.props.serial)
                } else {
                    this.newCount--
                    this.props.setStateCount(this.newCount, 'null')
                }

            }
            if(this.props.route === 'offline'){
                this.setState({
                    offlineHidden: true
                })
                this.newCount--
                this.props.setStateCount(this.newCount, this.props.serial)
            }
        } catch(error) {
            console.log(error)
            alert('error adding unit to database used for onboarding users')
        }

        this.setState({
            hidingAlarm: false,
            index: ''
        })
    }

    render(){
        return(
            <div>
                {this.props.route === 'alarm' &&
                    this.currentAlarms.map((alarm, index) => (
                        <div>
                            {this.state.index !== index &&
                                <div style={{fontSize: '25px', color: 'white', fontWeight: 'bold', marginTop: '20px'}}>{alarm.name.replace(/_/g, ' ')}</div>
                            }                            
                            {this.state.index !== index &&
                                <div>
                                    <button onClick={() => this.hideClicked(index, alarm.name)} className="Dropdown-button">
                                        <div>hide</div>
                                    </button>
                                </div>
                            }
                            {this.state.index === index && !this.state.hidingAlarm &&
                                <div>
                                    <div style={{fontSize: '20px', color: 'white', fontWeight: 'bold', marginTop: '20px'}}>confirm hide alarm for "{alarm.name.replace(/_/g, ' ')}"</div>
                                    <div className='confirm-wrapper'>
                                        <button className="confirm-button" onClick={() => this.yesClicked(index, alarm.name)}>yes</button>
                                        <button className="confirm-button" onClick={() => this.noClicked(index)}>no</button>
                                    </div>                                    
                                </div>                    
                            }
                            {this.state.index === index && this.state.hidingAlarm &&
                                <div style={{fontSize: '20px', color: 'white', fontWeight: 'bold', marginTop: '20px'}}>hiding alarm</div>
                            }
                        </div>
                    ))
                }
                {this.props.route === 'alarm' && this.emptyAlarms &&
                    <div style={{fontSize: '20px', color: 'white', fontWeight: 'bold', marginTop: '20px'}}>All alarms have been hidden</div>
                }
                {this.props.route === 'offline' &&
                    <div>
                            {!this.state.offlineHidden &&
                                <div>
                                    <div style={{fontSize: '20px', color: 'white', fontWeight: 'bold', marginTop: '20px'}}>hide when this device is offline?</div>
                                    <button className='confirm-button' onClick={() => this.yesClicked(this.props.serial, 'offline_hide_alarm_check')}>yes</button>
                                </div>
                            }
                            {this.state.offlineHidden &&
                                <div style={{fontSize: '20px', color: 'white', fontWeight: 'bold', marginTop: '20px'}}>Device connection status is hidden</div>
                            }
                    </div>
                }

            </div>
        )
    }

}

export default HideAlarms