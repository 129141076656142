import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries.js';

class DivUserInvite extends Component {
  constructor(props){
    super(props)

    this.state={
      loading: false,
      enterprise: '',
      division: '',
      organization: '',
      orgArray: [],
      orgIndex: '',
      noOrgs: false,
      selectedLevel: '',
      id: '',
      hideCopyEmail: false
    }
    this.getUUID = this.getUUID.bind(this);
    this.close = this.close.bind(this);
    this.emailId = this.emailId.bind(this);
  }

  async selectLevel(level){
    if(level === 'division'){
      await this.setState({
        id: 'loading....',
        organization: '',
        hideCopyEmail: true
      })
      this.getUUID(level)
    }
    this.setState({
      selectedLevel: level
    })
  }

  async close(event){
    this.setState({
      orgIndex: '',
      hideCopyEmail: true
    })
  }

  async orgClicked(organization, index){
    await this.setState({
      loadingOrg: true,
      organization: organization,
      orgIndex: index,
      id: 'loading....',
      hideCopyEmail: true
    })
    this.getUUID('organization')
  }

  async getUUID(level){
    let entSend = this.state.enterprise
    let divSend = this.state.division
    let orgSend = ''

    if(this.state.organization === ''){
      orgSend = 'null'
    } else {
      orgSend = this.state.organization
    }

    function getId() {
      const apiName = 'EntAuth';
      const path = '/items';

      const myInit = {
          queryStringParameters: {
            level: level,
            entName: entSend,
            divisionName: divSend,
            orgName: orgSend,
          }
      };
      return API.get(apiName, path, myInit);
    }

    let response = ''
    try{
      response = await getId();
    } catch(error){
      console.log(error)
    }

    if(response.status === 'success'){
      this.setState({
        loading: false,
        id: response.uuid,
        hideCopyEmail: false
      })
    } else {
      alert('error fetching organization id number')
    }
  }

  async goBack(event){
    this.setState({
      selectedLevel: ''
    })
  }

  copyId = (event) => {
    this.textArea.select();
    document.execCommand('copy');
    event.target.focus();
    alert('id copied to clipboard')
  };

  // pull up email on click
  emailId(event) {
    let emailBody = "You have been given an access id number for nowledgeapp.com. \n\nYour access id number is: " + this.state.id + "\n\nGo to nowledgeapp.com and click signup where it asks if you are new to nowledge. \n\nCopy and paste this number during the step where it asks for an access id number." 
    let mailToLink = "mailto:?subject=Invitation to nowledgeapp.com&body=" + encodeURIComponent(emailBody);
    window.location.href = mailToLink;
  }

  async componentDidMount(){
    Auth.currentAuthenticatedUser({
      bypassCache: false
      }).then(user => {
        this.loggedIn = true
      }
    )
    .catch(error => {
      if(error === 'not authenticated'){
        this.props.history.push('/SignIn');
      }
    }
    );

    let user = await Auth.currentAuthenticatedUser()
    const userName = user.username;

    let userInfo = await API.graphql(graphqlOperation(queries.getEntUser, { userName: userName }));
    let userEnt = userInfo.data.getEntUser.entName
    let userDiv = userInfo.data.getEntUser.divName

    let getEntOrgs = `query GetEntDivsOrgs { getEnt(entName: "${userEnt}") { id entdivsorgs (filter: {divName: {eq: "${userDiv}"}, isOrg: {eq: "true"}}) { items { id entName divName orgName orgName isOrg }}}}`

    let foundOrgs = []

    try{
      let returnedOrgs = await API.graphql(graphqlOperation(getEntOrgs));
      foundOrgs = returnedOrgs.data.getEnt.entdivsorgs.items
    } catch(error) {
      console.log(error)
    };

    let noOrgsFound = false
    if(foundOrgs.length === 0){
      noOrgsFound = true
    }
    foundOrgs.sort((a,b) => a.orgName.localeCompare(b.orgName, undefined, { numeric: true }))

    this.setState({
      enterprise: userEnt,
      division: userDiv,
      orgArray: foundOrgs,
      noOrgs: noOrgsFound
    })

    alert('Note: Carefully select an authorization number. The authorization ID number you select effects what devices the new user will be able to access.')

  }

  render(){
    var backIcon = require('../Back-01.png');

    return(
      <div className="App">
      {
        <div className="Nav-header">
          <Link style={{textDecoration: 'none'}} to="/SettingsPage">
            <button className="Back-wrap">
              <img className="Back-button" src={backIcon} alt="back"/>
            </button>
          </Link>
        </div>
      }
      {
        <div className="Title">Get ID to Invite a User</div>
      }
      {!this.state.loading && this.state.selectedLevel === '' &&
        <div className="Settings-wrapper">
          <div className='One-space'/>
          {!this.state.noDivs &&
            <div>
              <div className="form-name">Division ID: share all devices from all organizations within your division?</div>
              <div className="resend-link" onClick={() => this.selectLevel('division')}>get id</div>
              <div className='One-space'/>
              <div className='One-space'/>
            </div>
          }
          {!this.state.noOrgs &&
            <div>
              <div className="form-name">Organization ID: share only devices within a particular organization?</div>
              <div className="resend-link" onClick={() => this.selectLevel('organization')}>get id</div>
              <div className='One-space'/>
              <div className='One-space'/>
            </div>
          }
          <div className='One-space'/>
        </div>
      }
      {this.state.selectedLevel === 'division' &&
        <div>
          <div className="Settings-wrapper">
            <button className="resend-link" onClick={(e) => this.goBack()}>back</button>
            <div className='One-space'/>
            <div className="form-name">Below you will find the authorization ID that will allow a user to access all devices within your division. Only share this ID number if you want the user to have access to all the devices you youself have.</div>
            <div className='One-space'/>
          </div>
          <div className='One-space'/>
          {
            <div>
                {!this.state.loading &&
                  <div className="Device-wrapper">
                    <div className="notesdocopen-link">{this.state.division}</div>
                    <div className="form-name">division id</div>
                    <textarea className="copy-id" readOnly = {true} ref={(textarea) => this.textArea = textarea} value={this.state.id} />
                    {!this.state.hideCopyEmail &&
                      <div>
                        <div className='One-space'/>
                        <button onClick={this.copyId} className="confirm-link">copy</button>
                        <button onClick={this.emailId} className="confirm-link">email</button>
                      </div>
                    }
                    <div className='One-space'/>
                  </div>
                }
            </div>
          }
        </div>
      }
      {this.state.selectedLevel === 'organization' &&
          <div>
            <div className="Settings-wrapper">
              <button className="resend-link" onClick={(e) => this.goBack()}>back</button>
              <div className='One-space'/>
              <div className="form-name">Below are all the organizations within your enterprise. Click to access its authorization ID number. This ID number will give the user access to all the devices within that organization.</div>
              <div className='One-space'/>
            </div>
            <div className='One-space'/>
            {
              this.state.orgArray.map((org, index) => (
                <div key={index}>
                {this.state.orgIndex === index &&
                  <div>
                      {this.state.loading &&
                        <div className="Device-wrapper">
                          <br />
                          <div className="Loading-buttonstatus">Loading...</div>
                          <br />
                        </div>
                      }
                      {!this.state.loading &&
                        <div className="Device-wrapper">
                          <button className="copy-link"  onClick={this.close}>hide</button>
                          <div className="notesdocopen-link">{this.state.organization}</div>
                          <div className="form-name">organization id</div>
                          <textarea className="copy-id" readOnly = {true} ref={(textarea) => this.textArea = textarea} value={this.state.id} />
                          {!this.state.hideCopyEmail &&
                            <div>
                              <div className='One-space'/>
                              <button onClick={this.copyId} className="confirm-link">copy</button>
                              <button onClick={this.emailId} className="confirm-link">email</button>
                            </div>
                          }
                          <br />
                          <br />
                        </div>
                      }
                  </div>
                  }
                  {this.state.orgIndex !== index &&
                    <div>
                      <div className='One-space'/>
                      <div className="Device-wrapper" onClick={() => this.orgClicked(org.orgName, index)}>
                        <div className="org-get-id">{org.orgName}</div>
                      </div>
                      <div className='One-space'/>
                    </div>
                  }
                </div>
              ))
            }
          </div>
        }
      </div>
    )
  }
}

export default DivUserInvite
